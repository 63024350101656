.toast-container{
    min-width: 300px;
    width: auto;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 70px;
    padding: 0.5rem 2rem 1rem 0rem;
    border-radius: 8px;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.46);
    margin-top: 3rem;
}

.toast-success{
    background-color: #c9f2c7;
    border: 2px solid green;
    border-left: 8px solid green;
}

.toast-error{
    background-color: #eecaca;
    border: 2px solid #d62929;
    border-left: 8px solid #d62929;
}

.toast-info{
    background-color: rgb(187, 223, 235);
    border: 2px solid  #20acdb;
    border-left: 8px solid rgb(35, 177, 224);
}

.toast-warning{
    background-color: rgb(252, 225, 199);
    border: 2px solid #ff7300;
    border-left: 8px solid #ff7300;
} 