.price-label {
    font-weight: 500;
    font-size: 14px;
    z-index: 2;
    position: relative;
    background-color: white;
    top: 14px;
    width: fit-content;
    left: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }