.scrollbar-visible::-webkit-scrollbar,.scrollbar-visible::-webkit-scrollbar-track{
  /* background: red !important; */
  width: 10px !important;
  display: block !important;
  display: block;
  width: 10em;
 
  height: 2em;
}
.scrollbar-visible{ overflow: scroll;}
.scrollbar-visible::-webkit-scrollbar{
  background-color: #454545;
}

.okamisama-tasukete-kudasai{
  height: calc(100vh - 80px);
  max-height: (100vh - 80px);
}

.dots {
  flex-grow: 1; /* Allow the dots container to grow and fill space */
  height: 1px; /* Dot line height */
  background: radial-gradient(circle, black 20%, transparent 20%) center;
  background-size: 5px 5px;
}

.order-summary-container {
  border: 1px solid lightgrey;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
  border-radius: 0.25rem;
  padding: 0.6rem;
}

.order-summary-line {
  display: flex;
  gap: 1rem;
  justify-content: center;
  width: 100%;
  align-items: baseline;
}

.listLink{
  cursor: pointer;
  color:#007bff;
}


.listLink:hover{
  color:#80bdff;
}

.MUIDataTableToolbar-left-25 {
  display: none;
}

.modalSize{
  max-height: 80vh;
  overflow: scroll;
}
.loadingCss{
  text-align:center;
  position:fixed;
  z-index:99999999;
  top:0;
  height: 100vh;
  width:100%;
  background-color: rgba(33,33,33,0.5);
}
.loadingCss > div{
  position: absolute;
  top:calc(50vh - 20px);
  left:calc(50vw - 90px);
  text-align: center;
  margin: 0 auto;
}
.centeredNavigation{
  vertical-align: middle;
}
.userIcon.left{
  margin-right: 0;
  text-align: left;
}

.userIcon.left .profile {
  margin-right: 5px;
}

.contenteditable{
  background-color: #FFF6D9;
  min-height: 200px;
  max-height: 580px;
  position: relative;
}

.contenteditable .ql-toolbar.ql-snow{
  background-color: #fff;
}

.contactForm{
  display: block;
}

.contactCreation{
  background-color: aliceblue;
  padding: 15px;
  margin-bottom: 15px;
}
.contactCreation.abunai{
  background-color: #fddddd;
}


/* .conflict{

} */

.conflict .conflictText{
  font-size: 24px;
  color: #df1111;
  display: inline-block;
  margin-left: 10px;
}

.conflict .conflictIcon{
  font-size: 50px;
  color: #df1111;
  display: inline-block;
  vertical-align: top;
}

.topRight{
  position: absolute;
  top:43px;
  right:21px;
}

.messageArchiveContainer h6{
  margin-top: 15px;
}

.messageArchive{
  margin-top: 5px;
  background-color: #FFF6D9;
  border:1px solid #ac8c25;
  padding: 5px;
  border-radius: 10px;
}

.messageArchive2{
  margin-top: 5px;
  background-color: #f8f8f8;  /*#e9e8e5;    */
  border:1px solid #ac8c25;
  padding: 5px;
  border-radius: 10px;
}

.userIcon{
  vertical-align: middle;
  height: 80px;
  float: right;
  overflow:hidden;
  margin-right:60px;
  width: 100%;
  
  text-align: right;
}

.userIcon .profile{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border:2px solid #888;
  display: inline-block;
  
  vertical-align: middle;
  margin-left: 5px;

}

.topRow{
  margin-top: 15px;
}

.userIcon .userTitle{
  display: inline;
    vertical-align: middle;
    text-overflow: ellipsis;
    word-break: keep-all;
    max-width:100px;
    white-space: nowrap;
    font-size: 20px;
}

@media(max-width:999px ){
  .userIcon .userTitle{
    display: none;
  }
}

@media(max-width:767px ){
  .userIcon{
    display: none;
  }
}
/* .userIcon .userTitle h4{
  
  
} */

.darkNavLink{
  color:#333 !important;
}
:not(.scrollbar-visible)::-webkit-scrollbar { 

  width: 0 !important;
  display: none;
}

.ql-editor{
  min-height: 175px !important;
  border-radius: 8px;
  height: auto;
  max-height: 530px;
  overflow: hidden;
  overflow-y: scroll;
  margin-bottom: 0px;
  /* overflow-x: scroll; */
}
label>h5{
  font-size: 1.25em !important;
}

.react-calendar{
  z-index:99999999
}


.rowContainer{
  margin-top: 12px;
  padding:18px;
  border:2px #676767 dashed;
}

.neoComponent{
  padding: 18px;
  border-bottom:2px #676767 solid;
}

.neoRow{
  padding: 18px;
  border-bottom:2px #676767 dashed;
}

.neoComponent:last-child{
  padding: 18px;
  border-bottom:none;
}

.dropBox{
  transition: 1s ;
}
.dropBox:hover{
  background-color: #d3e4f7 !important;
}

.neotextarea{
  text-align: left;
  resize:vertical !important;
  min-height:100px !important;

    /* margin:0px 0px; this is redundant anyways since its specified below*/
    padding-top:10px !important;
    padding-bottom:25px !important; /* increased! */
    /* height:16px; */
    /* line-height:16px !important; */
    width:100% !important; /* changed from 96 to 100% */
    /* display:block !important; */
    /* margin:0px auto; not needed since i have width 100% now */
}


.bg-gradient-info{
  background: linear-gradient(87deg, #0052BB 0, #003271 70%,#1479fb 100%) !important; 
}

.sidebar{
  padding: 15px;
  max-width:300px;
  white-space: normal;
  word-wrap: break-word;
  overflow-x: hidden;
}
.sidebar li{
  list-style: none;
}

body {
  margin:0;
  font-family: 'Ubuntu',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.red{color:red}

.giga-max-width{
  margin:0 0 0 0!important;
  padding: 0 !important;
  /* margin-left: 15px !important; */
  max-width:10000px !important;
  /* width:100vw !important; */
}

.weirdScroll{
  height: calc(100vh - 70px);
}

.weirdScrollPDFPublic{
  height: calc(100vh - 24px);
}

.dropBox{
  width:100% !important;
}
.container{
  margin: 0;
}

#root{
  padding:0 !important;
  margin: 0 !important;
}

.scrollbar-visible{
  overflow-y: scroll;
  overflow-x: hidden;
  
}

.scrollbar-visible::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 8px;
  padding-right: 10px;
  display: block !important;
}

.scrollbar-visible::-webkit-scrollbar-thumb:hover {
  background-color: grey;
  padding-right: 10px;
}

.scrollbar-visible::-webkit-scrollbar {
  position: relative;
  right: 10px;
  width: 8px !important;
  height: 8px !important;
  background-color: inherit;
  padding-right: 1rem;
  border-radius: 8px;
  display: block !important;
}


.scrollbar-visible::-webkit-scrollbar-track {
  position: relative;
  top: 10px;
  background-color: none;
  display: block !important;
}

/* .scrollbar-visible::-webkit-scrollbar-track-piece{
  background: #594;
}  */


.app-bar iframe{
  position: absolute; height: 100%; border: none;
}

.iframeContainer{
  width: 100%;
}

.cardheader{
  overflow:hidden;
  text-overflow: ellipsis;
}

.dashBoardElement li {
  cursor:pointer
}

@media (max-width: 1200px){
  
  .dashBoardElement h3{
   font-size: 20px;
   /* background-color: red;  */
  }
}

@media (min-width: 780px){
  body{
    padding:0 !important;
      /* padding: 40px !important; */
      margin: 0;
  }

  .hasSideBar{
    padding-left: 115px;
  }
  /* #root{
    margin-left:45px !important;
    
  } */

  /* .giga-max-width{
    margin-left: 15px !important;
  } */
}

/* @media(max-){
  .menu-w{
    display: none;
} */

.widgets{
  text-align: center;
  min-height: 100vh;
  max-width: 200px;
}

.centerApp{
  overflow-y:scroll;
  max-height:100vh;
}

@media (max-width: 779px){
  .giga-max{
    padding: 15px;;
  }

  .widgets{
    text-align: center;
    min-height: initial !important;
    max-width: 100%;
  }

  
  .centerApp{
    /* overflow-y:scroll; */
    /* max-height:initial !important; */
  }
}
@media (max-width: 779px){
  
  .hasSideBar{
    padding-left: 10px;
    
    padding-right: 10px;
    min-height: 100%;
    max-height: 100vh;
    /* overflow-y: hidden;*/
    overflow-x: hidden; 
  }

  .menu-w{
    display: none !important;
  }

  body{
      padding: 0px !important;
  }
  #root{
    margin-left:0 !important;
  }

  .giga-max-width{
    margin-left: 0 !important;
  }
}


/* body{
  padding:40px !important;
  /* background-color: #262626 !important;  */
  /* color: #efefef !important; */
/*} */
hr{
  border-top: 1px solid #dedede !important;
}

.top-right{
  z-index:1000;
  cursor: pointer;
  position: absolute;
  top:15px;
  right:15px;
}


a.nav-link:hover{
  color:#ddd !Important;
}

.top-right-5{
  position: absolute;
  top:5px;
  right:5px;
}

.bg-white{
  background: #dedede;
}

.lessPadding{
  margin-top: 5px;;
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.remove{
  position:absolute;
  /* top:5px; */
  right:5px;
  cursor: pointer;
}

.removeComposant{
  position:relative;
  top:-15px;
  /* right:5px; */
  float:right;
  cursor: pointer;
}

.neoGray{
  background-color: #2b2b2b !important;
}

/* #style-me-please{
  background-color:#502a7b !important;
} */

.card{
  color:#2b2b2b;
}

h1{
  color:#FFF ;
}

.AVENS{
  background: -webkit-gradient(linear, left top, left bottom, from(#212121), to(#121212)) fixed;
  background: #212121;
}

.avensRed{
  color:#ed1c24 
}

.humania .avensRed{
  color:#fff 
}
button.btn-outline-secondary.redButton:disabled{
  color:#efefef !important;
  background-color:#2b2b2b !important;
  border-color:#ed1c24 !important;
  opacity: 1;
}

.redButton{
  color:#ed1c24 !important;
  border-color:#ed1c24 !important;
}

.redButton:hover,
.redButton:active:not(:disabled)
{
  color:#efefef !important;
  border-color:#efefef!important;
  background-color:#ed1c24 !important;
  transition: 1.5s
}

.NEOLEGAL .avensRed{
  color:#0052BB 
}
/* .humania, */
/* .humania .card,
.humania .card legend span{
  background-color: #dfe8df;
} */

 .humania .avensRed{
  color:#000;
}

/* .humania button.btn-outline-secondary.redButton:disabled{
  color:#efefef !important;
  background-color:#2b2b2b !important;
  border-color:#82b0b8!important;
  opacity: 1;
} */

.humania .redButton{
  color:#000 !important;
  border-color:#000 !important;
  background-color: #fff !important;
} */

/* .humania .avensRed{
  color:#502a7b
}

.humania button.btn-outline-secondary.redButton:disabled{
  color:#efefef !important;
  background-color:#2b2b2b !important;
  border-color:#502a7b!important;
  opacity: 1;
}

.humania .redButton{
  color:#502a7b !important;
  border-color:#502a7b !important;
  background-color: #fff !important;
} */

.NEOLEGAL button.btn-outline-secondary.redButton:disabled{
  color:#efefef !important;
  background-color:#2b2b2b !important;
  border-color:#0052BB !important;
  opacity: 1;
}

.humania .redButton:hover,
.humania .redButton:active:not(:disabled){
  color:#efefef !important;
  border-color:#efefef!important;
  background-color:#502a7b!important;
  transition: 1.5s
}

.NEOLEGAL .redButton:hover,
.NEOLEGAL .redButton:active:not(:disabled){
  color:#efefef !important;
  border-color:#efefef!important;
  background-color:#502a7b!important;
  transition: 1.5s
}

.NEOLEGAL .redButton{
  color:#0052BB !important;
  border-color:#0052BB !important;
}

.NEOLEGAL .redButton:hover,
.NEOLEGAL .redButton:active:not(:disabled)
{
  color:#efefef !important;
  border-color:#efefef!important;
  background-color:#0052BB !important;
  transition: 1.5s
}

.popover-header{color:#2b2b2b !important}

.legal-format .react-pdf__Page {
	width: 100% !important;
}

.legal-format .react-pdf__Page canvas {
	margin: auto;
}

.pdf-overflow-control {
	margin-top: 15px;
	text-align: center;
}

.form-height {
	max-height: calc(100vh - 70px);
}

#PDF-area {
	overflow: scroll !important;
	padding: 5px;
	background: #898989;
	text-align: center;
}

#PDF-area canvas {
	margin: 0 auto;
	padding: 5px;
}

#PDF-area.m-auto {
	max-width: calc( 50vw - 90px);
	max-height: calc(100vh - 70px);
}

.col-md-6 
#PDF-area.m-auto {
  
  width: calc( ( 100vw / 12 * 7 ) - 180px);
}

.col-md-3 
#PDF-area.m-auto {
  
  width: calc( ( 100vw / 12 * 4 ) - 180px);
}
.react-pdf__Page {
	text-align: center;
}

#PDF-area-public {
	overflow: scroll !important;
	padding: 5px;
	background: #898989;
	text-align: center;
}

#PDF-area-public canvas {
	margin: 0 auto;
	padding: 5px;
}

#PDF-area-public.m-auto {
	/* max-width: calc( 50vw - 90px); */
	max-height: calc(100vh - 200px);
}

.col-md-3 
#PDF-area-public.m-auto {
  
  width: calc( ( 100vw / 12 * 4 ) - 180px);
}
.react-pdf__Page {
	text-align: center;
}

.content-top {
	position: fixed;
	top: 85px;
	right: 15px;
}

.content-top3 {
	position: sticky;
	top:  0px;
	/* right: calc(100% -15px); */
  align-items: flex-start;
  float: right;
}

.content-top4 {
	position: fixed;
}

.content-top2 {
  position: sticky !important;
  top: 60px;
  right: 15px;
}

.neo-card {
  border-left: 2px solid #0052bb!important;
}  

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background-color: #5e72e4;
}

.cardheader {
  background-color: #efefff!important;
  color: #0052bb;
  padding: 15px!important;
}

.thankYou{
  border: #1c2bff 30px solid;
  border-radius:20px;
  padding: 30px;
  margin-top: 20px;
}

.f-left{
  float:left;

}
.f-right{
  float:right;

}

.MuiTableCell-root{
  text-align: left !important;
}

.nav-pills .nav-link {
  padding: 0.75rem 1rem;
  color: #5e72e4;
  font-weight: 500;
  font-size: .875rem;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  background-color: #fff;
  -webkit-transition: all .15s ease;
  transition: all .15s ease;
}
  /* Hide the default checkbox */
  .coloredCheck{
    display: inline-block;
    position: relative;
    /* padding-left: 45px; */
    /* margin-bottom: 15px; */
    cursor: pointer;
    font-size: 20px;
    margin-right:5px;
    vertical-align: middle;
  }

  span.coloredCheck input[type=checkbox] {
    visibility: hidden !important;;
    display: none !important;
}
        
/* Creating a custom checkbox
based on demand */
.geekmark {
    position: initial;
    display:inline-block;
    padding:0;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    
    border:2px solid #9ac8fc;;
    border-radius: 5px;
}
  
/* Specify the background color to be
shown when hovering over checkbox */
.coloredCheck:hover input ~ .geekmark {
    background-color: #1479fb;
}
  
/* Specify the background color to be
shown when checkbox is active */
.coloredCheck input:active ~ .geekmark {
    background-color: #d3e4f7;
}
  
/* Specify the background color to be
shown when checkbox is checked */
.coloredCheck input:checked ~ .geekmark {
    background-color: #9ac8fc;
}
  
/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.geekmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
.grayish{
  background-color: #f6f6f6 !important;
  border-radius: 15px;
}


.grayish .userTitle{
  color: #333;
  font-weight: bolder;
}

.grayish ul{
  padding-inline-start: 10px;
}

.grayish li{
  color: #333;
  
  font-weight:500;
}

.neo-card  .neo-card {
  border: none !important;
}

/* Display checkmark when checked */
.coloredCheck input:checked ~ .geekmark:after {
    display: block;
}
  
/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and 
showing only two border to make it look
like a tickmark */
.coloredCheck .geekmark:after {
    left: 10px;
    bottom: 11px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 4px 4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media (min-width: 1600px){
  .content-top3 {
    position: sticky;
    top:  0px;
    right: 25px;
    align-items: flex-start;
    float: right;
  }

}

@media (max-width: 767px){
  .content-top3{
    position:absolute !important;
	  top:  15px;
    right:15px;
  }

  .col-md-6 #PDF-area.m-auto {
    width: 100%;
  } 
  .col-md-3 
  #PDF-area.m-auto {
  
    width: 100%;
  }

}

.modal.fade.show{
  z-index:99999999;
}

.inlineCheckbox{
  display: none;
  position: relative !important;
  margin: -2px 0 2px 2px !important;
  padding: 0  !important;
  height: 18px !important;
  
  width: 18px !important;
  vertical-align: middle;
  right: 5px;
}

/* .custom-checkbox :checked~::before{
  /* padding: 3px;
   background-color: #212121;
} */

.pdf-options {
	position: absolute;
	top: 5px;
	right: 21px;
	text-align: center;
	z-index: 999;
}

#PDF-preview {
  z-index:999999;
	text-align: right;
}

.display-none {
	display: none;
}

.text-align-center {
	text-align: center;
}

.navbar {
	width: auto;
}

.re-init {
	position: sticky;
	top: 0;
	width: 100%;
}

.avatar {
	background-color: transparent;
}

.form-padding {
	padding: 15px 0;
}

@media (max-width: 768px) {
	#PDF-area.m-auto {
		width: auto;
		max-width: none;
		max-height: none;
		display: block;
		position: initial;
	}

	.form-height {
		max-height: none;
	}

	.weirdScroll {
		height: auto;
	}
}

body{
  margin: 0 auto;
  padding:0;
}
#root{
  padding-left: 15px;
  padding-right: 15px;
}


.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.high-z-index{
  z-index: 999;
}

.nav-link{
  color:white
}

.spmed{margin-top: 3.0rem;}


.rowmbtm{margin-bottom: 10px;}
.zendcomment{
  /* margin-bottom: 20px; */
  /* border-top: 1px solid black; */
    border-collapse: collapse;padding-top: 10px;margin-bottom: 30px;}
.pvcomment {
        background-color: #FFF6D9;
        border: 1px solid #EFDAA3;
        padding: 12px 16px 14px;
        margin-bottom: 16px;
        border-radius: 3px;
        color: #2F3941;
} 
/* .rich_text .comment_input:not(.is-public) .zendesk-editor--rich-text-container {
  background: #FFF6D9;
  border: 1px solid #EFDAA3;
} */
.rich_textTA {
  background: #FFF6D9!important;
  border: 1px solid #EFDAA3!important;
}
.zendesk-editor--rich-text-container {
  box-sizing: border-box;
  position: relative;
  overflow: auto;
  border: 1px solid #ddd;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: text;
}
.usernote-view{float: right;margin-top: -20px;margin-bottom: 20px;}





.neoHLMenu {
  /* margin: 5px; */
  background-color: rgba(231, 225, 225, 0.781);
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
}

.neoHLMenu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  color: #0052bb;
  /* background-color: #333333; */
  /* background-color: rgba(255, 255, 255, 0.781); rgba(255, 255, 255, 0.761);*/
  /* background-color: rgba(255, 255, 255, 0.651); */
  background-color: rgba(247, 247, 247, 0.74);
  /* 0.681   */
}

.neoHLMenu li {
  float: left; 
}

.neoHLli{
  padding: 10px; vertical-align:middle;
}

.neoHLli0{
  padding: 15px; 
}
.neoHLli0x {
  font-size: 1.25rem; font-weight: bolder;
}

.neoHLMenu li a {
  display: block;
  /* color: white; */
  color: #0052bb;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

.neoBtnMrg1{
  margin: 5px; 
}


.multisteps-form__progress {
  margin-top: 30px;
  /* background-image: url(./../img/step-bg.png); */
  background-repeat: repeat-x;
  padding: 80px 110px;
  /* display: flex;; */
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
}

.wizard-part-title {
  padding: 140px;
  padding-bottom: 0;
}

.multisteps-form__progress-btn {
  margin-right: 15px;
  position: relative;
  color: rgba(108, 117, 125, 0.7);
  border: none;
  background-color: transparent;
  outline: none !important;
  cursor: pointer;
  font-size: 21px;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: 32px;
}
.multisteps-form__progress-btn:first-child {
  margin-left: 0;
}
.multisteps-form__progress-btn:before {
  content: "";
  width: 50px;
  height: 50px;
  font-weight: 700;
  left: 0px;
  top: 0px;
  margin-right: 15px;
  line-height: 50px;
  position: relative;
  text-align: center;
  display: inline-block;
  border: 2px solid #e3e3e3;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 3;
}
.multisteps-form__progress-btn:after {
  content: "";
  position: absolute;
  top: 26px;
  right: -40px;
  display: block;
  width: 20px;
  height: 4px;
  background-color: currentColor;
  z-index: 1;
}
.multisteps-form__progress-btn:last-child:after {
  display: none;
}
.multisteps-form__progress-btn.js-active {
  color: #6b4cf9;
}
.multisteps-form__progress-btn.js-active:before {
  border-color: #6b4cf9;
  font-size: 20px;
  color: #6b4cf9;
  content: "";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
}

@media screen and (max-width: 1440px) {
  .wrapper {
    padding: 90px 0px;
    margin: 0;
    -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
            transform: scale(0.9);
  }

  .multisteps-form__progress {
    padding: 80px 0px;
  }

  .wizard-part-title {
    padding-top: 60px;
  }

  .multisteps-form__progress-btn {
    margin-left: 18px;
  }

  .wizard > .steps li {
    padding-right: 260px;
  }

  /* .neotooltip {
    left: 9.2% !important;
  } */
}
@media screen and (max-width: 1300px) {
  .wrapper {
    padding: 60px 0px;
    margin: 0;
  }

  .wizard > .steps li {
    margin-bottom: 30px;
  }

  .wizard-content-item p {
    font-size: 24px;
  }

  .wizard-topper .wizard-progress {
    width: 375px;
  }

  .multisteps-form__progress {
    padding: 30px 0px;
    background: none;
  }

  .wizard-part-title {
    padding: 60px;
  }

  .multisteps-form__progress-btn {
    font-size: 18px;
  }

  .wizard-part-title h3 {
    font-size: 45px;
  }

  .wizard-topper {
    top: -325px;
  }

  .wizard-content-form {
    padding: 0 50px;
  }
}
@media screen and (max-width: 991px) {
  .multisteps-form__progress {
    padding: 0;
  }

  .wizard-topper {
    position: absolute;
    top: -40px;
    right: auto;
    width: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .wizard-topper .wizard-progress {
    width: 100%;
    float: none;
  }

  .multisteps-form__progress-btn:before {
    left: 100%;
  }

  .multisteps-form__progress-btn:after {
    display: none;
  }

  .multisteps-form__progress-btn {
    width: 65px;
    margin-left: 0;
  }

  .multisteps-form__progress-btn {
    font-size: 0;
  }

  .wizard-part-title {
    float: none !important;
    padding: 10px;
  }

  .wizard-progress {
    float: none;
    margin-top: 20px;
  }

  .inner {
    padding: 0 15px;
  }

  .wizard-imgbg {
    display: none;
  }

  .wizard-content-item {
    padding-top: 60px;
  }

  .actions {
    position: static;
    margin-top: 50px;
  }
}
@media screen and (max-width: 767px) {
  .pb-100 {
    padding-bottom: 30px;
  }

  .wrapper {
    padding: 40px 0px;
    overflow-x: hidden;
    margin: 0;
  }

  .wizard-topper {
    width: 70%;
  }

  .actions li span, .actions li button {
    width: 170px;
  }

  .wizard-part-title {
    width: 100%;
    margin-bottom: 20px;
  }

  .multisteps-form__progress-btn:before {
    left: 0%;
  }

  .steps li.current:after,
.steps li.checked:after,
.steps li:after {
    display: none;
  }

  .wizard-part-title h3 {
    font-size: 40px;
  }

  .wizard-content-item p {
    font-size: 26px;
  }

  .wizard-content-item {
    padding-top: 85px;
  }

  .wizard-option-list li {
    width: 100%;
  }

  .wizard-option-list .taxable-area label {
    margin-bottom: 30px;
  }

  .wizard-content-item h2 {
    font-size: 36px;
  }

  .wizard-content-item {
    padding-bottom: 30px;
  }

  .wizard-identity .wizard-identity-box {
    margin-bottom: 30px;
  }

  .wizard-identity {
    padding: 0px 30px;
  }

  .identity-option li {
    width: 100%;
    margin: 0 0px 20px 0;
  }

  .n-activity {
    margin-bottom: 20px;
  }

  .n-checked {
    margin-bottom: 20px;
  }

  .wizard-footer {
    min-height: auto;
  }

  .actions li:first-child span i, .actions li:first-child button i {
    left: -25px;
  }

  .actions li .js-btn-next i, .actions li button i {
    right: -25px !important;
  }
}
@media screen and (max-width: 460px) {
  .wizard-progress {
    width: 290px;
  }

  .wizard-part-title h3 {
    font-size: 25px;
  }

  .wizard-content-item h2 {
    font-size: 30px;
  }

  .wizard-content-form {
    padding: 0 10px;
  }

  .wizard-content-item p {
    font-size: 18px;
  }

  .actions li span, .actions li button {
    width: 170px;
    min-width: 100%;
  }

  .wizard-option-list .upload-araa .upload-text span {
    display: none;
  }

  .wizard > .content {
    margin-bottom: 15px;
  }

  .wizard-content-item {
    padding-top: 65px;
  }

  .wizard-form-field input, .wizard-form-field select {
    padding: 0 25px;
  }

  .has-float-label i {
    left: 20px;
  }

  .identity-option li {
    height: inherit;
    line-height: inherit;
  }

  .n-summary {
    height: inherit;
    line-height: inherit;
    display: inline-block;
    margin-bottom: 10px;
  }

  .n-summary label {
    float: none;
  }

  .n-checked label span {
    font-size: 15px;
  }

  .actions {
    position: static;
    margin-top: 70px;
  }

  .multisteps-form__progress-btn:after {
    display: none;
  }

  .multisteps-form__progress-btn {
    width: 65px;
    margin-left: 0;
  }
}


/* to fix and remove big margin on top */
.wrapper {
  padding: 0px 0px!important;
  margin: 0!important;
  transform: none!important;
}
.hasSideBar {
  /* padding-left: 115px; */
  padding-left: 0px!important;
}

@media screen and (max-width: 1300px){

.wrapper {
    padding: 0px 0px!important;
    margin: 0!important;
}

}

@media screen and (max-width: 1440px){

.wrapper {
    padding: 0px 0px!important;
    margin: 0!important;
    transform: none!important;
}

}

.top-barleads{
  margin-top: -10px;
}

.notes-attachments-title{
  margin-top: 1rem;
  font-weight: 500;
  color: #0076CF;
}

.notes-attachments{
  margin-top: 0.6rem;
  font-weight: 500;
  color: #0076CF;
  display: flex;
  align-items: center;
  gap: 0.5rem
}
