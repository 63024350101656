/* 
TABLE OF CONTENTS


1. Theme Variables
	1a. User Override Variables
2. Theme Mixins
3. Bootstrap Override Styles
  3a. Override default includes of bootstrap
  3b. Override bootstrap table styles
  3c. Override bootstrap form styles
  3d. Override bootstrap navigation styles
  3e. Override bootstrap button styles
  3f. Override bootstrap breadcrumbs styles
  3g. Override bootstrap typography styles
  3h. Override bootstrap modal styles
  3i. Override bootstrap alerts styles
4. Overrides for various plugins that are included with this template
5. Handy Animations 
6. User Profiles
7. Layout styles
8. Menu styles
9. Styles for all element blocks
10. Helpful Misc Styles
11. Icon Fonts
12. Chat
13. App - Email
14. App - Projects
15. App - Full Chat
16. Pricing Plans
17. Pipelines
18. OS Dropdowns
19. App - TODO
20. Fancy Selector
21. App - Rentals
22. App - Support
23. Onboarding
24. Top Bar styles
25. Search
26. Ecommerce
27. Customizer
##. Dark Color Scheme
##. Responsive Styles

*/
/* 1. Theme Variables */
/* 1a. User Override Variables */
/* 2. Theme Mixins */
/* 3. Bootstrap Override Styles */
/* 3a. Override default includes of bootstrap */
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
 #atlwdg-trigger{
  /* bottom:20px; */
  z-index:20;
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
 /* background-color: red !important; */
}
.dropdown-item:focus, .dropdown-item:hover{
  background-color: inherit !important;
}

.react-date-picker__wrapper {
  border-radius: 5px!important;
  padding: 5px!important;
  border: thin solid #ced4da!important;
}

.react-time-picker__wrapper {
  border-radius: 5px!important;
  padding: 5px!important;
  border: thin solid #ced4da!important;
}

.modal.fade.show{
  z-index:99999999;
}
.react-calendar{
  z-index:99999999
}
#atlwdg-trigger:after{
 content: "";
 position: absolute;
 bottom:-96px; right: -96px;
 /* opacity:0.4; */
 background-color:inherit;
 padding-bottom:50%; width:57.7%;
 z-index:-1;
 border-top:#6781C2 1px solid;
 
 -webkit-transform-origin:0 0;
 -ms-transform-origin:0 0;
 transform-origin:0 0;
 background:#13ABCF;
 width:210px;
 height: 44px;;
 -webkit-transform: rotate(-45deg);
 -ms-transform: rotate(-45deg);
 transform: rotate(-45deg);
}

#atlwdg-trigger,#atlwdg-trigger:after:hover{
  transition:1s;
  
 background:#01cffd !important;
}
 .card-deck .card{
  max-width: 480px;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.22);
}
.fill-default{
  fill:#F7F8FA;
}

.card-deck .card:hover{
  box-shadow: 0 25px 80px rgba(0,0,0,0.12), 0 3px 12px rgba(0,0,0,0.05);
  transition: 0.75s;
}

 @font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v50/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

:root {
  --blue: #047bf8;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #e65252;
  --orange: #fd7e14;
  --yellow: #fbe4a0;
  --green: #24b314;
  --teal: #20c997;
  --cyan: #5bc0de;
  --white: #fff;
  --gray: #636c72;
  --gray-dark: #343a40;
  --primary: #047bf8;
  --secondary: #292b2c;
  --success: #24b314;
  --info: #5bc0de;
  --warning: #fbe4a0;
  --danger: #e65252;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1250px;
  --breakpoint-xxl: 1450px;
  --breakpoint-xxxl: 1550px;
  --breakpoint-xxxxl: 1650px;
  --font-family-sans-serif: "Avenir Next W01", "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Avenir Next W01", "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3E4B5B;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #047bf8;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0356ad;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #adb5bd;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Avenir Next W01", "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  line-height: 1.2;
  color: #334152;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.125rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fbe4a0;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.125rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #636c72;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #636c72;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #292b2c;
  border-radius: 4px;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 500;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #292b2c;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1250px) {
  .container {
    max-width: 1190px;
  }
}

@media (min-width: 1450px) {
  .container {
    max-width: 1380px;
  }
}

@media (min-width: 1550px) {
  .container {
    max-width: 1580px;
  }
}

@media (min-width: 1650px) {
  .container {
    max-width: 1680px;
  }
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl, .container-xxxl, .container-xxxxl {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1250px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1190px;
  }
}

@media (min-width: 1450px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1380px;
  }
}

@media (min-width: 1550px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl, .container-xxxl {
    max-width: 1580px;
  }
}

@media (min-width: 1650px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl, .container-xxxl, .container-xxxxl {
    max-width: 1680px;
  }
}

.row {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto, .col-xxxl-1, .col-xxxl-2, .col-xxxl-3, .col-xxxl-4, .col-xxxl-5, .col-xxxl-6, .col-xxxl-7, .col-xxxl-8, .col-xxxl-9, .col-xxxl-10, .col-xxxl-11, .col-xxxl-12, .col-xxxl,
.col-xxxl-auto, .col-xxxxl-1, .col-xxxxl-2, .col-xxxxl-3, .col-xxxxl-4, .col-xxxxl-5, .col-xxxxl-6, .col-xxxxl-7, .col-xxxxl-8, .col-xxxxl-9, .col-xxxxl-10, .col-xxxxl-11, .col-xxxxl-12, .col-xxxxl,
.col-xxxxl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
}

.col {
  flex-basis: 0;
  -webkit-box-flex: 1;
          flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  -webkit-box-flex: 0;
          flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  -webkit-box-flex: 0;
          flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  -webkit-box-flex: 0;
          flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  -webkit-box-flex: 0;
          flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  -webkit-box-flex: 0;
          flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  -webkit-box-flex: 0;
          flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  -webkit-box-flex: 0;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -webkit-box-flex: 0;
          flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  -webkit-box-flex: 0;
          flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  -webkit-box-flex: 0;
          flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
          flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  -webkit-box-flex: 0;
          flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  -webkit-box-flex: 0;
          flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
          flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  -webkit-box-flex: 0;
          flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  -webkit-box-flex: 0;
          flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
          flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  -webkit-box-flex: 0;
          flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  -webkit-box-flex: 0;
          flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
          order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
          order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
          order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
          order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
          order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
          order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
          order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
          order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
          order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
          order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
          order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
          order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
          order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
          order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
          order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
            order: -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
            order: 13;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
            order: 0;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
            order: -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
            order: 13;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
            order: 0;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
            order: -1;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
            order: 13;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
            order: 0;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1250px) {
  .col-xl {
    flex-basis: 0;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
            order: -1;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
            order: 13;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
            order: 0;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1450px) {
  .col-xxl {
    flex-basis: 0;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxl-1 > * {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxl-2 > * {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxl-3 > * {
    -webkit-box-flex: 0;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxl-5 > * {
    -webkit-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxl-6 > * {
    -webkit-box-flex: 0;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxl-auto {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    -webkit-box-flex: 0;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xxl-2 {
    -webkit-box-flex: 0;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxl-3 {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    -webkit-box-flex: 0;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xxl-5 {
    -webkit-box-flex: 0;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xxl-6 {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    -webkit-box-flex: 0;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xxl-8 {
    -webkit-box-flex: 0;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xxl-9 {
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    -webkit-box-flex: 0;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xxl-11 {
    -webkit-box-flex: 0;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xxl-12 {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    -webkit-box-ordinal-group: 0;
            order: -1;
  }
  .order-xxl-last {
    -webkit-box-ordinal-group: 14;
            order: 13;
  }
  .order-xxl-0 {
    -webkit-box-ordinal-group: 1;
            order: 0;
  }
  .order-xxl-1 {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .order-xxl-2 {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .order-xxl-3 {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .order-xxl-4 {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .order-xxl-5 {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .order-xxl-6 {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .order-xxl-7 {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .order-xxl-8 {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .order-xxl-9 {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .order-xxl-10 {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .order-xxl-11 {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .order-xxl-12 {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1550px) {
  .col-xxxl {
    flex-basis: 0;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxxl-1 > * {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxxl-2 > * {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxxl-3 > * {
    -webkit-box-flex: 0;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xxxl-4 > * {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxxl-5 > * {
    -webkit-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxxl-6 > * {
    -webkit-box-flex: 0;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxxl-auto {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxxl-1 {
    -webkit-box-flex: 0;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xxxl-2 {
    -webkit-box-flex: 0;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxxl-3 {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxxl-4 {
    -webkit-box-flex: 0;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xxxl-5 {
    -webkit-box-flex: 0;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xxxl-6 {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxxl-7 {
    -webkit-box-flex: 0;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xxxl-8 {
    -webkit-box-flex: 0;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xxxl-9 {
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxxl-10 {
    -webkit-box-flex: 0;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xxxl-11 {
    -webkit-box-flex: 0;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xxxl-12 {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxxl-first {
    -webkit-box-ordinal-group: 0;
            order: -1;
  }
  .order-xxxl-last {
    -webkit-box-ordinal-group: 14;
            order: 13;
  }
  .order-xxxl-0 {
    -webkit-box-ordinal-group: 1;
            order: 0;
  }
  .order-xxxl-1 {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .order-xxxl-2 {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .order-xxxl-3 {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .order-xxxl-4 {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .order-xxxl-5 {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .order-xxxl-6 {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .order-xxxl-7 {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .order-xxxl-8 {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .order-xxxl-9 {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .order-xxxl-10 {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .order-xxxl-11 {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .order-xxxl-12 {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .offset-xxxl-0 {
    margin-left: 0;
  }
  .offset-xxxl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xxxl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xxxl-3 {
    margin-left: 25%;
  }
  .offset-xxxl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xxxl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xxxl-6 {
    margin-left: 50%;
  }
  .offset-xxxl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xxxl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xxxl-9 {
    margin-left: 75%;
  }
  .offset-xxxl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xxxl-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1650px) {
  .col-xxxxl {
    flex-basis: 0;
    -webkit-box-flex: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxxxl-1 > * {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxxxl-2 > * {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxxxl-3 > * {
    -webkit-box-flex: 0;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xxxxl-4 > * {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxxxl-5 > * {
    -webkit-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxxxl-6 > * {
    -webkit-box-flex: 0;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxxxl-auto {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxxxl-1 {
    -webkit-box-flex: 0;
            flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xxxxl-2 {
    -webkit-box-flex: 0;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxxxl-3 {
    -webkit-box-flex: 0;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxxxl-4 {
    -webkit-box-flex: 0;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xxxxl-5 {
    -webkit-box-flex: 0;
            flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xxxxl-6 {
    -webkit-box-flex: 0;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxxxl-7 {
    -webkit-box-flex: 0;
            flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xxxxl-8 {
    -webkit-box-flex: 0;
            flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xxxxl-9 {
    -webkit-box-flex: 0;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxxxl-10 {
    -webkit-box-flex: 0;
            flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xxxxl-11 {
    -webkit-box-flex: 0;
            flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xxxxl-12 {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxxxl-first {
    -webkit-box-ordinal-group: 0;
            order: -1;
  }
  .order-xxxxl-last {
    -webkit-box-ordinal-group: 14;
            order: 13;
  }
  .order-xxxxl-0 {
    -webkit-box-ordinal-group: 1;
            order: 0;
  }
  .order-xxxxl-1 {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .order-xxxxl-2 {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .order-xxxxl-3 {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .order-xxxxl-4 {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .order-xxxxl-5 {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .order-xxxxl-6 {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .order-xxxxl-7 {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .order-xxxxl-8 {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .order-xxxxl-9 {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .order-xxxxl-10 {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .order-xxxxl-11 {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .order-xxxxl-12 {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .offset-xxxxl-0 {
    margin-left: 0;
  }
  .offset-xxxxl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xxxxl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xxxxl-3 {
    margin-left: 25%;
  }
  .offset-xxxxl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xxxxl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xxxxl-6 {
    margin-left: 50%;
  }
  .offset-xxxxl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xxxxl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xxxxl-9 {
    margin-left: 75%;
  }
  .offset-xxxxl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xxxxl-11 {
    margin-left: 91.6666666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #3E4B5B;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid rgba(83, 101, 140, 0.33);
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid rgba(83, 101, 140, 0.33);
}

.table tbody + tbody {
  border-top: 2px solid rgba(83, 101, 140, 0.33);
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid rgba(83, 101, 140, 0.33);
}

.table-bordered th,
.table-bordered td {
  border: 1px solid rgba(83, 101, 140, 0.33);
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(94, 130, 152, 0.05);
}

.table-hover tbody tr:hover {
  color: #3E4B5B;
  background-color: rgba(18, 95, 142, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b9dafd;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7cbafb;
}

.table-hover .table-primary:hover {
  background-color: #a0cdfc;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a0cdfc;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c3c4c4;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #909191;
}

.table-hover .table-secondary:hover {
  background-color: #b6b7b7;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #b6b7b7;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c2eabd;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8dd785;
}

.table-hover .table-success:hover {
  background-color: #b0e4aa;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b0e4aa;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d1edf6;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #aadeee;
}

.table-hover .table-info:hover {
  background-color: #bce5f2;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #bce5f2;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fef7e4;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fdf1ce;
}

.table-hover .table-warning:hover {
  background-color: #fdf0cb;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fdf0cb;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f8cfcf;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f2a5a5;
}

.table-hover .table-danger:hover {
  background-color: #f5b9b9;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f5b9b9;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(18, 95, 142, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(15, 80, 119, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(15, 80, 119, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #292b2c;
  border-color: #3b3e40;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: rgba(83, 101, 140, 0.33);
}

.table-dark {
  color: #fff;
  background-color: #292b2c;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #3b3e40;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1249.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1449.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1549.98px) {
  .table-responsive-xxxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxxl > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1649.98px) {
  .table-responsive-xxxxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxxxl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(calc(1.5em + 0.75rem) + 4px);
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #dde2ec;
  border-radius: 4px;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #047bf8;
  outline: 0;
  box-shadow: none;
}

.form-control::-webkit-input-placeholder {
  color: #636c72;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #636c72;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #636c72;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #636c72;
  opacity: 1;
}

.form-control::placeholder {
  color: #636c72;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 2px);
  padding-bottom: calc(0.375rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 2px);
  padding-bottom: calc(0.5rem + 2px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 2px);
  padding-bottom: calc(0.25rem + 2px);
  font-size: 0.8rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #3E4B5B;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.7rem + 4px);
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 4px;
}

.form-control-lg {
  height: calc(2.875rem + 4px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 6px;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #adb5bd;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #24b314;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.8rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(36, 179, 20, 0.9);
  border-radius: 4px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #24b314;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2324b314' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #24b314;
  box-shadow: 0 0 0 0.2rem rgba(36, 179, 20, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #24b314;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2324b314' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #24b314;
  box-shadow: 0 0 0 0.2rem rgba(36, 179, 20, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #24b314;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #24b314;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #24b314;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #2de119;
  background-color: #2de119;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(36, 179, 20, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #24b314;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #24b314;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #24b314;
  box-shadow: 0 0 0 0.2rem rgba(36, 179, 20, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e65252;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.8rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(230, 82, 82, 0.9);
  border-radius: 4px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #e65252;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e65252' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e65252' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #e65252;
  box-shadow: 0 0 0 0.2rem rgba(230, 82, 82, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #e65252;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e65252' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e65252' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #e65252;
  box-shadow: 0 0 0 0.2rem rgba(230, 82, 82, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e65252;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #e65252;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #e65252;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ec7f7f;
  background-color: #ec7f7f;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(230, 82, 82, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e65252;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e65252;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e65252;
  box-shadow: 0 0 0 0.2rem rgba(230, 82, 82, 0.25);
}

.form-inline {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-flow: row wrap;
  -webkit-box-align: center;
          align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row wrap;
    -webkit-box-align: center;
            align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn, .all-wrapper .fc-button {
  display: inline-block;
  font-weight: 400;
  color: #3E4B5B;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 4px;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn, .all-wrapper .fc-button {
    -webkit-transition: none;
    transition: none;
  }
}

.btn:hover, .all-wrapper .fc-button:hover {
  color: #3E4B5B;
  text-decoration: none;
}

.btn:focus, .all-wrapper .fc-button:focus, .btn.focus, .all-wrapper .focus.fc-button {
  outline: 0;
  box-shadow: none;
}

.btn.disabled, .all-wrapper .disabled.fc-button, .btn:disabled, .all-wrapper .fc-button:disabled {
  opacity: 0.65;
}

a.btn.disabled, .all-wrapper a.disabled.fc-button,
fieldset:disabled a.btn,
fieldset:disabled .all-wrapper a.fc-button,
.all-wrapper fieldset:disabled a.fc-button {
  pointer-events: none;
}

.btn-primary, .all-wrapper .fc-button.fc-state-active {
  color: #fff;
  background-color: #047bf8;
  border-color: #047bf8;
}

.btn-primary:hover, .all-wrapper .fc-button.fc-state-active:hover {
  color: #fff;
  background-color: #0368d2;
  border-color: #0362c6;
}

.btn-primary:focus, .all-wrapper .fc-button.fc-state-active:focus, .btn-primary.focus, .all-wrapper .focus.fc-button.fc-state-active {
  color: #fff;
  background-color: #0368d2;
  border-color: #0362c6;
  box-shadow: 0 0 0 0.2rem rgba(42, 143, 249, 0.5);
}

.btn-primary.disabled, .all-wrapper .disabled.fc-button.fc-state-active, .btn-primary:disabled, .all-wrapper .fc-button.fc-state-active:disabled {
  color: #fff;
  background-color: #047bf8;
  border-color: #047bf8;
}

.btn-primary:not(:disabled):not(.disabled):active, .all-wrapper .fc-button.fc-state-active:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .all-wrapper :not(:disabled):not(.disabled).active.fc-button.fc-state-active,
.show > .btn-primary.dropdown-toggle,
.all-wrapper .show > .dropdown-toggle.fc-button.fc-state-active {
  color: #fff;
  background-color: #0362c6;
  border-color: #035cb9;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .all-wrapper .fc-button.fc-state-active:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .all-wrapper :not(:disabled):not(.disabled).active.fc-button.fc-state-active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.all-wrapper .show > .dropdown-toggle.fc-button.fc-state-active:focus {
  box-shadow: 0 0 0 0.2rem rgba(42, 143, 249, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #292b2c;
  border-color: #292b2c;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #171818;
  border-color: #101112;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #171818;
  border-color: #101112;
  box-shadow: 0 0 0 0.2rem rgba(73, 75, 76, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #292b2c;
  border-color: #292b2c;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #101112;
  border-color: #0a0b0b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(73, 75, 76, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #24b314;
  border-color: #24b314;
}

.btn-success:hover {
  color: #fff;
  background-color: #1d9110;
  border-color: #1b850f;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #1d9110;
  border-color: #1b850f;
  box-shadow: 0 0 0 0.2rem rgba(69, 190, 55, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #24b314;
  border-color: #24b314;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1b850f;
  border-color: #187a0e;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 190, 55, 0.5);
}

.btn-info {
  color: #292b2c;
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-info:hover {
  color: #fff;
  background-color: #3bb4d8;
  border-color: #31b0d5;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #3bb4d8;
  border-color: #31b0d5;
  box-shadow: 0 0 0 0.2rem rgba(84, 170, 195, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #292b2c;
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  border-color: #2aaacf;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(84, 170, 195, 0.5);
}

.btn-warning {
  color: #292b2c;
  background-color: #fbe4a0;
  border-color: #fbe4a0;
}

.btn-warning:hover {
  color: #292b2c;
  background-color: #f9da7b;
  border-color: #f9d66f;
}

.btn-warning:focus, .btn-warning.focus {
  color: #292b2c;
  background-color: #f9da7b;
  border-color: #f9d66f;
  box-shadow: 0 0 0 0.2rem rgba(220, 200, 143, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #292b2c;
  background-color: #fbe4a0;
  border-color: #fbe4a0;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #292b2c;
  background-color: #f9d66f;
  border-color: #f8d363;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 200, 143, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #e65252;
  border-color: #e65252;
}

.btn-danger:hover {
  color: #fff;
  background-color: #e13131;
  border-color: #e02525;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #e13131;
  border-color: #e02525;
  box-shadow: 0 0 0 0.2rem rgba(234, 108, 108, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #e65252;
  border-color: #e65252;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #e02525;
  border-color: #d91f1f;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 108, 108, 0.5);
}

.btn-light {
  color: #292b2c;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #292b2c;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #292b2c;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(217, 218, 219, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #292b2c;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #292b2c;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 218, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #047bf8;
  border-color: #047bf8;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #047bf8;
  border-color: #047bf8;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(4, 123, 248, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #047bf8;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #047bf8;
  border-color: #047bf8;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(4, 123, 248, 0.5);
}

.btn-outline-secondary {
  color: #292b2c;
  border-color: #292b2c;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #292b2c;
  border-color: #292b2c;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(41, 43, 44, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #292b2c;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #292b2c;
  border-color: #292b2c;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(41, 43, 44, 0.5);
}

.btn-outline-success {
  color: #24b314;
  border-color: #24b314;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #24b314;
  border-color: #24b314;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(36, 179, 20, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #24b314;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #24b314;
  border-color: #24b314;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(36, 179, 20, 0.5);
}

.btn-outline-info {
  color: #5bc0de;
  border-color: #5bc0de;
}

.btn-outline-info:hover {
  color: #292b2c;
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(91, 192, 222, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #5bc0de;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #292b2c;
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(91, 192, 222, 0.5);
}

.btn-outline-warning {
  color: #fbe4a0;
  border-color: #fbe4a0;
}

.btn-outline-warning:hover {
  color: #292b2c;
  background-color: #fbe4a0;
  border-color: #fbe4a0;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 228, 160, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #fbe4a0;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #292b2c;
  background-color: #fbe4a0;
  border-color: #fbe4a0;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 228, 160, 0.5);
}

.btn-outline-danger {
  color: #e65252;
  border-color: #e65252;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #e65252;
  border-color: #e65252;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(230, 82, 82, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #e65252;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #e65252;
  border-color: #e65252;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(230, 82, 82, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #292b2c;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #292b2c;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #047bf8;
  text-decoration: none;
}

.btn-link:hover {
  color: #0356ad;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #636c72;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn, .all-wrapper .btn-group-lg > .fc-button {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 6px;
}

.btn-sm, .btn-group-sm > .btn, .all-wrapper .btn-group-sm > .fc-button {
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 4px;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.9rem;
  color: #3E4B5B;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1250px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1450px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1550px) {
  .dropdown-menu-xxxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxxl-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1650px) {
  .dropdown-menu-xxxxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxxxl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #292b2c;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #1d1e1f;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #047bf8;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #636c72;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.8rem;
  color: #636c72;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #292b2c;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn, .all-wrapper .btn-group > .fc-button,
.btn-group-vertical > .btn,
.all-wrapper .btn-group-vertical > .fc-button {
  position: relative;
  -webkit-box-flex: 1;
          flex: 1 1 auto;
}

.btn-group > .btn:hover, .all-wrapper .btn-group > .fc-button:hover,
.btn-group-vertical > .btn:hover,
.all-wrapper .btn-group-vertical > .fc-button:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .all-wrapper .btn-group > .fc-button:focus, .btn-group > .btn:active, .all-wrapper .btn-group > .fc-button:active, .btn-group > .btn.active, .all-wrapper .btn-group > .active.fc-button,
.btn-group-vertical > .btn:focus,
.all-wrapper .btn-group-vertical > .fc-button:focus,
.btn-group-vertical > .btn:active,
.all-wrapper .btn-group-vertical > .fc-button:active,
.btn-group-vertical > .btn.active,
.all-wrapper .btn-group-vertical > .active.fc-button {
  z-index: 1;
}

.btn-toolbar {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: start;
          justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child), .all-wrapper .btn-group > .fc-button:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -2px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .all-wrapper .btn-group > .fc-button:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.all-wrapper .btn-group > .btn-group:not(:last-child) > .fc-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child), .all-wrapper .btn-group > .fc-button:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.all-wrapper .btn-group > .btn-group:not(:first-child) > .fc-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .all-wrapper .btn-group-sm > .fc-button + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .all-wrapper .btn-group-lg > .fc-button + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
          justify-content: center;
}


.btn-group-vertical > .btn,
.all-wrapper .btn-group-vertical > .fc-button,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .all-wrapper .btn-group-vertical > .fc-button:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -2px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .all-wrapper .btn-group-vertical > .fc-button:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.all-wrapper .btn-group-vertical > .btn-group:not(:last-child) > .fc-button {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child), .all-wrapper .btn-group-vertical > .fc-button:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.all-wrapper .btn-group-vertical > .btn-group:not(:first-child) > .fc-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .all-wrapper .btn-group-toggle > .fc-button,
.btn-group-toggle > .btn-group > .btn,
.all-wrapper .btn-group-toggle > .btn-group > .fc-button {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"], .all-wrapper .btn-group-toggle > .fc-button input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.all-wrapper .btn-group-toggle > .fc-button input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.all-wrapper .btn-group-toggle > .btn-group > .fc-button input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"],
.all-wrapper .btn-group-toggle > .btn-group > .fc-button input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
          align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -webkit-box-flex: 1;
          flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -2px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: flex;
}

.input-group-prepend .btn, .input-group-prepend .all-wrapper .fc-button, .all-wrapper .input-group-prepend .fc-button,
.input-group-append .btn,
.input-group-append .all-wrapper .fc-button,
.all-wrapper .input-group-append .fc-button {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus, .input-group-prepend .all-wrapper .fc-button:focus, .all-wrapper .input-group-prepend .fc-button:focus,
.input-group-append .btn:focus,
.input-group-append .all-wrapper .fc-button:focus,
.all-wrapper .input-group-append .fc-button:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn, .input-group-prepend .all-wrapper .fc-button + .btn, .all-wrapper .input-group-prepend .fc-button + .btn, .input-group-prepend .all-wrapper .btn + .fc-button, .all-wrapper .input-group-prepend .btn + .fc-button, .input-group-prepend .all-wrapper .fc-button + .fc-button, .all-wrapper .input-group-prepend .fc-button + .fc-button,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .all-wrapper .fc-button + .input-group-text,
.all-wrapper .input-group-prepend .fc-button + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .all-wrapper .input-group-text + .fc-button,
.all-wrapper .input-group-prepend .input-group-text + .fc-button,
.input-group-append .btn + .btn,
.input-group-append .all-wrapper .fc-button + .btn,
.all-wrapper .input-group-append .fc-button + .btn,
.input-group-append .all-wrapper .btn + .fc-button,
.all-wrapper .input-group-append .btn + .fc-button,
.input-group-append .all-wrapper .fc-button + .fc-button,
.all-wrapper .input-group-append .fc-button + .fc-button,
.input-group-append .btn + .input-group-text,
.input-group-append .all-wrapper .fc-button + .input-group-text,
.all-wrapper .input-group-append .fc-button + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .all-wrapper .input-group-text + .fc-button,
.all-wrapper .input-group-append .input-group-text + .fc-button {
  margin-left: -2px;
}

.input-group-prepend {
  margin-right: -2px;
}

.input-group-append {
  margin-left: -2px;
}

.input-group-text {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 2px solid #dde2ec;
  border-radius: 4px;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(2.875rem + 4px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.all-wrapper .input-group-lg > .input-group-prepend > .fc-button,
.input-group-lg > .input-group-append > .btn,
.all-wrapper .input-group-lg > .input-group-append > .fc-button {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 6px;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.7rem + 4px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.all-wrapper .input-group-sm > .input-group-prepend > .fc-button,
.input-group-sm > .input-group-append > .btn,
.all-wrapper .input-group-sm > .input-group-append > .fc-button {
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 4px;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn, .all-wrapper .input-group > .input-group-prepend > .fc-button,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.all-wrapper .input-group > .input-group-append:not(:last-child) > .fc-button,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.all-wrapper .input-group > .input-group-append:last-child > .fc-button:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .all-wrapper .input-group > .input-group-append > .fc-button,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.all-wrapper .input-group > .input-group-prepend:not(:first-child) > .fc-button,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.all-wrapper .input-group > .input-group-prepend:first-child > .fc-button:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.35rem;
  padding-left: 2.5rem;
}

.custom-control-inline {
  display: -webkit-inline-box;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.175rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #047bf8;
  background-color: #047bf8;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, none;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #047bf8;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b1d6fe;
  border-color: #b1d6fe;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #636c72;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.175rem;
  left: -2.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #dee2e6;
  border: #adb5bd solid 2px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.175rem;
  left: -2.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 4px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #047bf8;
  background-color: #047bf8;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(4, 123, 248, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(4, 123, 248, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(4, 123, 248, 0.5);
}

.custom-switch {
  padding-left: 3.25rem;
}

.custom-switch .custom-control-label::before {
  left: -3.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.175rem + 4px);
  left: calc(-3.25rem + 4px);
  width: calc(1rem - 8px);
  height: calc(1rem - 8px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #dee2e6;
  -webkit-transform: translateX(0.75rem);
          transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(4, 123, 248, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(calc(1.5em + 0.75rem) + 4px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px;
  border: 2px solid #dde2ec;
  border-radius: 4px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.custom-select:focus {
  border-color: #047bf8;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(4, 123, 248, 0.5);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #636c72;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.7rem + 4px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 4px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(calc(1.5em + 0.75rem) + 4px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(calc(1.5em + 0.75rem) + 4px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #047bf8;
  box-shadow: none;
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(calc(1.5em + 0.75rem) + 4px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 2px solid #dde2ec;
  border-radius: 4px;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 4px 4px 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #047bf8;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b1d6fe;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #047bf8;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
       appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b1d6fe;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #047bf8;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b1d6fe;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    -webkit-transition: none;
    transition: none;
  }
}

.nav {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #636c72;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #636c72;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: transparent;
  border-color: #dee2e6 #dee2e6 transparent;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 4px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #047bf8;
}

.nav-fill .nav-item {
  -webkit-box-flex: 1;
          flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  -webkit-box-flex: 1;
          flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.breadcrumb {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 4px;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #636c72;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #636c72;
}

.pagination {
  display: -webkit-box;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 4px;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #047bf8;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0356ad;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #047bf8;
  border-color: #047bf8;
}

.page-item.disabled .page-link {
  color: #636c72;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 4px;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    -webkit-transition: none;
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge, .all-wrapper .fc-button .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #047bf8;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0362c6;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(4, 123, 248, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #292b2c;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #101112;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(41, 43, 44, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #24b314;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1b850f;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(36, 179, 20, 0.5);
}

.badge-info {
  color: #292b2c;
  background-color: #5bc0de;
}

a.badge-info:hover, a.badge-info:focus {
  color: #292b2c;
  background-color: #31b0d5;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(91, 192, 222, 0.5);
}

.badge-warning {
  color: #292b2c;
  background-color: #fbe4a0;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #292b2c;
  background-color: #f9d66f;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(251, 228, 160, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #e65252;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #e02525;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(230, 82, 82, 0.5);
}

.badge-light {
  color: #292b2c;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #292b2c;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 2px solid transparent;
  border-radius: 6px;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 500;
}

.alert-dismissible {
  padding-right: 3.85rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #02366d;
  background-color: #54a5fa;
  border-color: #1886f9;
}

.alert-primary hr {
  border-top-color: #0679f1;
}

.alert-primary .alert-link {
  color: #011d3b;
}

.alert-secondary {
  color: #121313;
  background-color: #6d6f70;
  border-color: #3a3c3d;
}

.alert-secondary hr {
  border-top-color: #2e2f30;
}

.alert-secondary .alert-link {
  color: black;
}

.alert-success {
  color: #104f09;
  background-color: #6acb5f;
  border-color: #36b927;
}

.alert-success hr {
  border-top-color: #30a423;
}

.alert-success .alert-link {
  color: #072104;
}

.alert-info {
  color: #285462;
  background-color: #8fd4e9;
  border-color: #68c5e1;
}

.alert-info hr {
  border-top-color: #53bddd;
}

.alert-info .alert-link {
  color: #19353e;
}

.alert-warning {
  color: #6e6446;
  background-color: #fcedbe;
  border-color: #fbe6a8;
}

.alert-warning hr {
  border-top-color: #fadf90;
}

.alert-warning .alert-link {
  color: #4f4832;
}

.alert-danger {
  color: #652424;
  background-color: #ee8989;
  border-color: #e86060;
}

.alert-danger hr {
  border-top-color: #e54a4a;
}

.alert-danger .alert-link {
  color: #3f1717;
}

.alert-light {
  color: #6d6e6e;
  background-color: #fafbfc;
  border-color: #f9f9fa;
}

.alert-light hr {
  border-top-color: #ebebee;
}

.alert-light .alert-link {
  color: #545454;
}

.alert-dark {
  color: #171a1c;
  background-color: #75797d;
  border-color: #444a4f;
}

.alert-dark hr {
  border-top-color: #383d41;
}

.alert-dark .alert-link {
  color: black;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -webkit-box;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.675rem;
  background-color: #e9ecef;
  border-radius: 4px;
}

.progress-bar {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #047bf8;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    -webkit-transition: none;
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
            animation: none;
  }
}

.close {
  float: right;
  font-size: 1.35rem;
  font-weight: 500;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
          transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
          transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: -webkit-box;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-pack: center;
          justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #235a94;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.3;
}

.modal-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
          justify-content: space-between;
  padding: 1rem;
  /* border-bottom: 0px solid #e9ecef; */
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
          flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: end;
          justify-content: flex-end;
  padding: 0.75rem;
  /* border-top: 0px solid #e9ecef; */
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 550px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 900px;
  }
}

@media (min-width: 1250px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Avenir Next W01", "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Avenir Next W01", "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 6px;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 0px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 0px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 0px);
  width: 0.5rem;
  height: 1rem;
  margin: 6px 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 0px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 0px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 0px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 0px solid #047bf8;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 0px);
  width: 0.5rem;
  height: 1rem;
  margin: 6px 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 0px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 12px 15px;
  margin-bottom: 0;
  font-size: 0.9rem;
  color: #fff;
  background-color: #047bf8;
  border-bottom: 0px solid #046fdf;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 15px 20px;
  color: #3E4B5B;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  -webkit-box-flex: 0;
          flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #047bf8 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0362c6 !important;
}

.bg-secondary {
  background-color: #292b2c !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #101112 !important;
}

.bg-success {
  background-color: #24b314 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1b850f !important;
}

.bg-info {
  background-color: #5bc0de !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #31b0d5 !important;
}

.bg-warning {
  background-color: #fbe4a0 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f9d66f !important;
}

.bg-danger {
  background-color: #e65252 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #e02525 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #047bf8 !important;
}

.border-secondary {
  border-color: #292b2c !important;
}

.border-success {
  border-color: #24b314 !important;
}

.border-info {
  border-color: #5bc0de !important;
}

.border-warning {
  border-color: #fbe4a0 !important;
}

.border-danger {
  border-color: #e65252 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 4px !important;
}

.rounded {
  border-radius: 4px !important;
}

.rounded-top {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.rounded-right {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.rounded-left {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.rounded-lg {
  border-radius: 6px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1250px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1450px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1550px) {
  .d-xxxl-none {
    display: none !important;
  }
  .d-xxxl-inline {
    display: inline !important;
  }
  .d-xxxl-inline-block {
    display: inline-block !important;
  }
  .d-xxxl-block {
    display: block !important;
  }
  .d-xxxl-table {
    display: table !important;
  }
  .d-xxxl-table-row {
    display: table-row !important;
  }
  .d-xxxl-table-cell {
    display: table-cell !important;
  }
  .d-xxxl-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-xxxl-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1650px) {
  .d-xxxxl-none {
    display: none !important;
  }
  .d-xxxxl-inline {
    display: inline !important;
  }
  .d-xxxxl-inline-block {
    display: inline-block !important;
  }
  .d-xxxxl-block {
    display: block !important;
  }
  .d-xxxxl-table {
    display: table !important;
  }
  .d-xxxxl-table-row {
    display: table-row !important;
  }
  .d-xxxxl-table-cell {
    display: table-cell !important;
  }
  .d-xxxxl-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-xxxxl-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-box !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
          flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
          flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
          flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
          flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
          flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
          flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
          flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
          justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
          justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
          justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
          justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
          align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
          align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
          align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
          align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
          align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
            flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
            align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
            flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
            align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
            flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
            align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1250px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
            flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
            align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1450px) {
  .flex-xxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }
  .flex-xxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    -webkit-box-flex: 1 !important;
            flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    -webkit-box-flex: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    -webkit-box-flex: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    -webkit-box-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    -webkit-box-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    -webkit-box-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-xxl-between {
    -webkit-box-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    -webkit-box-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-xxl-end {
    -webkit-box-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-xxl-center {
    -webkit-box-align: center !important;
            align-items: center !important;
  }
  .align-items-xxl-baseline {
    -webkit-box-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    -webkit-box-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1550px) {
  .flex-xxxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }
  .flex-xxxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important;
  }
  .flex-xxxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-xxxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-xxxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxxl-fill {
    -webkit-box-flex: 1 !important;
            flex: 1 1 auto !important;
  }
  .flex-xxxl-grow-0 {
    -webkit-box-flex: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-xxxl-grow-1 {
    -webkit-box-flex: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxxl-start {
    -webkit-box-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-xxxl-end {
    -webkit-box-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-xxxl-center {
    -webkit-box-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-xxxl-between {
    -webkit-box-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-xxxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxxl-start {
    -webkit-box-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-xxxl-end {
    -webkit-box-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-xxxl-center {
    -webkit-box-align: center !important;
            align-items: center !important;
  }
  .align-items-xxxl-baseline {
    -webkit-box-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-xxxl-stretch {
    -webkit-box-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-xxxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxxl-center {
    align-content: center !important;
  }
  .align-content-xxxl-between {
    align-content: space-between !important;
  }
  .align-content-xxxl-around {
    align-content: space-around !important;
  }
  .align-content-xxxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxxl-auto {
    align-self: auto !important;
  }
  .align-self-xxxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxxl-center {
    align-self: center !important;
  }
  .align-self-xxxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxxl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1650px) {
  .flex-xxxxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
            flex-direction: row !important;
  }
  .flex-xxxxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important;
  }
  .flex-xxxxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-xxxxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-xxxxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxxxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxxxl-fill {
    -webkit-box-flex: 1 !important;
            flex: 1 1 auto !important;
  }
  .flex-xxxxl-grow-0 {
    -webkit-box-flex: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-xxxxl-grow-1 {
    -webkit-box-flex: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-xxxxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxxxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxxxl-start {
    -webkit-box-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-xxxxl-end {
    -webkit-box-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-xxxxl-center {
    -webkit-box-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-xxxxl-between {
    -webkit-box-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-xxxxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxxxl-start {
    -webkit-box-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-xxxxl-end {
    -webkit-box-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-xxxxl-center {
    -webkit-box-align: center !important;
            align-items: center !important;
  }
  .align-items-xxxxl-baseline {
    -webkit-box-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-xxxxl-stretch {
    -webkit-box-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-xxxxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxxxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxxxl-center {
    align-content: center !important;
  }
  .align-content-xxxxl-between {
    align-content: space-between !important;
  }
  .align-content-xxxxl-around {
    align-content: space-around !important;
  }
  .align-content-xxxxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxxxl-auto {
    align-self: auto !important;
  }
  .align-self-xxxxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxxxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxxxl-center {
    align-self: center !important;
  }
  .align-self-xxxxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxxxl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1250px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1450px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}

@media (min-width: 1550px) {
  .float-xxxl-left {
    float: left !important;
  }
  .float-xxxl-right {
    float: right !important;
  }
  .float-xxxl-none {
    float: none !important;
  }
}

@media (min-width: 1650px) {
  .float-xxxxl-left {
    float: left !important;
  }
  .float-xxxxl-right {
    float: right !important;
  }
  .float-xxxxl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1250px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1450px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1550px) {
  .m-xxxl-0 {
    margin: 0 !important;
  }
  .mt-xxxl-0,
  .my-xxxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxxl-0,
  .mx-xxxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxxl-0,
  .my-xxxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxxl-0,
  .mx-xxxl-0 {
    margin-left: 0 !important;
  }
  .m-xxxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxxl-1,
  .my-xxxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxxl-1,
  .mx-xxxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxxl-1,
  .my-xxxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxxl-1,
  .mx-xxxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxxl-2,
  .my-xxxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxxl-2,
  .mx-xxxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxxl-2,
  .my-xxxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxxl-2,
  .mx-xxxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxxl-3 {
    margin: 1rem !important;
  }
  .mt-xxxl-3,
  .my-xxxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxxl-3,
  .mx-xxxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxxl-3,
  .my-xxxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxxl-3,
  .mx-xxxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxxl-4,
  .my-xxxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxxl-4,
  .mx-xxxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxxl-4,
  .my-xxxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxxl-4,
  .mx-xxxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxxl-5 {
    margin: 3rem !important;
  }
  .mt-xxxl-5,
  .my-xxxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxxl-5,
  .mx-xxxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxxl-5,
  .my-xxxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxxl-5,
  .mx-xxxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxxl-0 {
    padding: 0 !important;
  }
  .pt-xxxl-0,
  .py-xxxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxxl-0,
  .px-xxxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxxl-0,
  .py-xxxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxxl-0,
  .px-xxxl-0 {
    padding-left: 0 !important;
  }
  .p-xxxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxxl-1,
  .py-xxxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxxl-1,
  .px-xxxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxxl-1,
  .py-xxxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxxl-1,
  .px-xxxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxxl-2,
  .py-xxxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxxl-2,
  .px-xxxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxxl-2,
  .py-xxxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxxl-2,
  .px-xxxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxxl-3 {
    padding: 1rem !important;
  }
  .pt-xxxl-3,
  .py-xxxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxxl-3,
  .px-xxxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxxl-3,
  .py-xxxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxxl-3,
  .px-xxxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxxl-4,
  .py-xxxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxxl-4,
  .px-xxxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxxl-4,
  .py-xxxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxxl-4,
  .px-xxxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxxl-5 {
    padding: 3rem !important;
  }
  .pt-xxxl-5,
  .py-xxxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxxl-5,
  .px-xxxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxxl-5,
  .py-xxxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxxl-5,
  .px-xxxl-5 {
    padding-left: 3rem !important;
  }
  .m-xxxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxxl-n1,
  .my-xxxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxxl-n1,
  .mx-xxxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxxl-n1,
  .my-xxxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxxl-n1,
  .mx-xxxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxxl-n2,
  .my-xxxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxxl-n2,
  .mx-xxxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxxl-n2,
  .my-xxxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxxl-n2,
  .mx-xxxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxxl-n3,
  .my-xxxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxxl-n3,
  .mx-xxxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxxl-n3,
  .my-xxxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxxl-n3,
  .mx-xxxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxxl-n4,
  .my-xxxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxxl-n4,
  .mx-xxxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxxl-n4,
  .my-xxxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxxl-n4,
  .mx-xxxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxxl-n5,
  .my-xxxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxxl-n5,
  .mx-xxxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxxl-n5,
  .my-xxxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxxl-n5,
  .mx-xxxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxxl-auto {
    margin: auto !important;
  }
  .mt-xxxl-auto,
  .my-xxxl-auto {
    margin-top: auto !important;
  }
  .mr-xxxl-auto,
  .mx-xxxl-auto {
    margin-right: auto !important;
  }
  .mb-xxxl-auto,
  .my-xxxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxxl-auto,
  .mx-xxxl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1650px) {
  .m-xxxxl-0 {
    margin: 0 !important;
  }
  .mt-xxxxl-0,
  .my-xxxxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxxxl-0,
  .mx-xxxxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxxxl-0,
  .my-xxxxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxxxl-0,
  .mx-xxxxl-0 {
    margin-left: 0 !important;
  }
  .m-xxxxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxxxl-1,
  .my-xxxxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxxxl-1,
  .mx-xxxxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxxxl-1,
  .my-xxxxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxxxl-1,
  .mx-xxxxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxxxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxxxl-2,
  .my-xxxxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxxxl-2,
  .mx-xxxxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxxxl-2,
  .my-xxxxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxxxl-2,
  .mx-xxxxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxxxl-3 {
    margin: 1rem !important;
  }
  .mt-xxxxl-3,
  .my-xxxxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxxxl-3,
  .mx-xxxxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxxxl-3,
  .my-xxxxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxxxl-3,
  .mx-xxxxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxxxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxxxl-4,
  .my-xxxxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxxxl-4,
  .mx-xxxxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxxxl-4,
  .my-xxxxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxxxl-4,
  .mx-xxxxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxxxl-5 {
    margin: 3rem !important;
  }
  .mt-xxxxl-5,
  .my-xxxxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxxxl-5,
  .mx-xxxxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxxxl-5,
  .my-xxxxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxxxl-5,
  .mx-xxxxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxxxl-0 {
    padding: 0 !important;
  }
  .pt-xxxxl-0,
  .py-xxxxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxxxl-0,
  .px-xxxxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxxxl-0,
  .py-xxxxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxxxl-0,
  .px-xxxxl-0 {
    padding-left: 0 !important;
  }
  .p-xxxxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxxxl-1,
  .py-xxxxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxxxl-1,
  .px-xxxxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxxxl-1,
  .py-xxxxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxxxl-1,
  .px-xxxxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxxxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxxxl-2,
  .py-xxxxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxxxl-2,
  .px-xxxxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxxxl-2,
  .py-xxxxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxxxl-2,
  .px-xxxxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxxxl-3 {
    padding: 1rem !important;
  }
  .pt-xxxxl-3,
  .py-xxxxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxxxl-3,
  .px-xxxxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxxxl-3,
  .py-xxxxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxxxl-3,
  .px-xxxxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxxxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxxxl-4,
  .py-xxxxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxxxl-4,
  .px-xxxxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxxxl-4,
  .py-xxxxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxxxl-4,
  .px-xxxxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxxxl-5 {
    padding: 3rem !important;
  }
  .pt-xxxxl-5,
  .py-xxxxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxxxl-5,
  .px-xxxxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxxxl-5,
  .py-xxxxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxxxl-5,
  .px-xxxxl-5 {
    padding-left: 3rem !important;
  }
  .m-xxxxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxxxl-n1,
  .my-xxxxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxxxl-n1,
  .mx-xxxxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxxxl-n1,
  .my-xxxxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxxxl-n1,
  .mx-xxxxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxxxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxxxl-n2,
  .my-xxxxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxxxl-n2,
  .mx-xxxxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxxxl-n2,
  .my-xxxxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxxxl-n2,
  .mx-xxxxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxxxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxxxl-n3,
  .my-xxxxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxxxl-n3,
  .mx-xxxxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxxxl-n3,
  .my-xxxxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxxxl-n3,
  .mx-xxxxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxxxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxxxl-n4,
  .my-xxxxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxxxl-n4,
  .mx-xxxxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxxxl-n4,
  .my-xxxxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxxxl-n4,
  .mx-xxxxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxxxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxxxl-n5,
  .my-xxxxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxxxl-n5,
  .mx-xxxxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxxxl-n5,
  .my-xxxxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxxxl-n5,
  .mx-xxxxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxxxl-auto {
    margin: auto !important;
  }
  .mt-xxxxl-auto,
  .my-xxxxl-auto {
    margin-top: auto !important;
  }
  .mr-xxxxl-auto,
  .mx-xxxxl-auto {
    margin-right: auto !important;
  }
  .mb-xxxxl-auto,
  .my-xxxxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxxxl-auto,
  .mx-xxxxl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1250px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1450px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1550px) {
  .text-xxxl-left {
    text-align: left !important;
  }
  .text-xxxl-right {
    text-align: right !important;
  }
  .text-xxxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1650px) {
  .text-xxxxl-left {
    text-align: left !important;
  }
  .text-xxxxl-right {
    text-align: right !important;
  }
  .text-xxxxl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #047bf8 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0356ad !important;
}

.text-secondary {
  color: #292b2c !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #040404 !important;
}

.text-success {
  color: #24b314 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #166e0c !important;
}

.text-info {
  color: #5bc0de !important;
}

a.text-info:hover, a.text-info:focus {
  color: #28a1c5 !important;
}

.text-warning {
  color: #fbe4a0 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #f8cf57 !important;
}

.text-danger {
  color: #e65252 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #ce1e1e !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #3E4B5B !important;
}

.text-muted, .invoice-body .invoice-desc .desc-value {
  color: #adb5bd !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: rgba(83, 101, 140, 0.33);
  }
  .table .thead-dark th {
    color: inherit;
    border-color: rgba(83, 101, 140, 0.33);
  }
}

/* 3b. Override bootstrap table styles */
.table .user-with-avatar {
  white-space: nowrap;
}

.table .user-with-avatar img {
  width: 35px;
  height: 35px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
}

.table .user-with-avatar span {
  display: inline-block;
  vertical-align: middle;
}

.table .user-with-avatar img + span {
  margin-left: 10px;
}

.table .icon-separator {
  margin: 0px 4px;
  opacity: 0.6;
}

.table th {
  font-weight: 500;
}

.table .smaller, .table.smaller {
  font-size: 0.72rem;
}

.table .lighter {
  color: rgba(90, 99, 126, 0.49);
}

.table.table-v-compact td {
  padding: 0.3rem 0.75rem;
}

.table.table-compact td {
  padding: 0.3rem 0.45rem;
}

.table.table-editable td:hover {
  background-color: #fff;
  box-shadow: inset 0px 0px 0px 2px #047bf8;
}

.table.table-lightborder td {
  border-color: rgba(83, 101, 140, 0.08);
}

.table.table-clean tr:first-child td {
  border-top: none;
}

.table.table-clean td {
  padding-left: 0px;
  padding-right: 0px;
  border-top-color: rgba(0, 0, 0, 0.05);
}

.table.table-clean td .value {
  font-size: 0.9rem;
  line-height: 1.2;
}

.table.table-clean td .sub-value {
  font-size: 0.72rem;
  color: rgba(90, 99, 126, 0.49);
}

.table.table-lightfont td {
  font-weight: 300;
}

.table.table-bordered thead th {
  border-top: 1px solid rgba(83, 101, 140, 0.33);
}

.table th,
.table td {
  vertical-align: middle;
}

.table th img, .table td img {
  max-width: 100%;
}

.table thead th {
  border-bottom: 1px solid #999;
}

.table tfoot th {
  border-top: 1px solid #999;
}

.table tfoot th,
.table thead th {
  font-size: 0.63rem;
  text-transform: uppercase;
  border-top: none;
}

.table tbody + tbody {
  border-top: 1px solid rgba(83, 101, 140, 0.33);
}

.table td.nowrap {
  white-space: nowrap;
}

.table .row-actions {
  text-align: center;
}

.table .row-actions .os-icon {
  font-size: 16px;
}

.table .row-actions a {
  margin-right: 0.8rem;
  color: #3E4B5B;
}

.table .row-actions a.danger {
  color: #9d1818;
}

.table .row-actions a:last-child {
  margin-right: 0px;
}

.table .cell-image-list {
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

.table .cell-image-list .cell-img {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-size: cover;
  background-position: center center;
  border-radius: 2px;
  box-shadow: 0px 0px 0px 2px #fff, 1px 1px 5px rgba(0, 0, 0, 0.8);
  vertical-align: middle;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  -webkit-transform: scale(1);
          transform: scale(1);
  position: relative;
  cursor: pointer;
}

.table .cell-image-list .cell-img:nth-child(1) {
  z-index: 5;
}

.table .cell-image-list .cell-img:nth-child(2) {
  z-index: 4;
}

.table .cell-image-list .cell-img:nth-child(3) {
  z-index: 3;
}

.table .cell-image-list .cell-img:nth-child(4) {
  z-index: 2;
}

.table .cell-image-list .cell-img:nth-child(5) {
  z-index: 1;
}

.table .cell-image-list .cell-img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.table .cell-image-list .cell-img + .cell-img {
  margin-left: -15px;
}

.table .cell-image-list .cell-img + .cell-img:hover {
  -webkit-transform: translateX(5px) scale(1.1);
          transform: translateX(5px) scale(1.1);
}

.table .cell-image-list .cell-img-more {
  font-size: 0.63rem;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: -70%;
  background-color: #fff;
  padding: 3px 5px;
  border-radius: 4px;
  z-index: 7;
  white-space: nowrap;
}

@media (min-width: 1100px) {
  .table-responsive {
    overflow: visible;
  }
}

.table-lg td {
  padding: 1.2rem 1.5rem;
}

.table.table-v2 thead tr th, .table.table-v2 tfoot tr th {
  text-align: center;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  background-color: rgba(0, 0, 0, 0.05);
}

.table.table-v2 thead tr th:first-child, .table.table-v2 tfoot tr th:first-child {
  border-left: 1px solid #999;
}

.table.table-v2 thead tr th:last-child, .table.table-v2 tfoot tr th:last-child {
  border-right: 1px solid #999;
}

.table.table-v2 tbody tr td {
  border-color: #d1d8e6;
}

.controls-above-table {
  margin-bottom: 1rem;
}

.controls-above-table .btn, .controls-above-table .all-wrapper .fc-button, .all-wrapper .controls-above-table .fc-button {
  margin-right: 0.5rem;
}

.controls-above-table .btn + .btn, .controls-above-table .all-wrapper .fc-button + .btn, .all-wrapper .controls-above-table .fc-button + .btn, .controls-above-table .all-wrapper .btn + .fc-button, .all-wrapper .controls-above-table .btn + .fc-button, .controls-above-table .all-wrapper .fc-button + .fc-button, .all-wrapper .controls-above-table .fc-button + .fc-button {
  margin-left: 0px !important;
}

.controls-above-table .form-control {
  margin-right: 1rem;
}

.controls-above-table .form-control:last-child {
  margin-right: 0px;
}

.controls-below-table {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  font-size: 0.81rem;
}

.controls-below-table .table-records-info {
  color: rgba(0, 0, 0, 0.5);
}

.controls-below-table .table-records-pages ul {
  list-style: none;
}

.controls-below-table .table-records-pages ul li {
  display: inline-block;
  margin: 0px 10px;
}

.controls-below-table .table-records-pages ul li a.current {
  color: #3E4B5B;
}

.all-wrapper table.dataTable {
  border-collapse: collapse !important;
}

.table.table-padded {
  border-collapse: separate;
  border-spacing: 0 5px;
}

.table.table-padded thead tr th {
  border: none;
  font-size: 0.81rem;
  color: rgba(90, 99, 126, 0.49);
  letter-spacing: 1px;
  padding: 0.3rem 1.1rem;
}

.table.table-padded tbody tr {
  border-radius: 4px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.table.table-padded tbody tr:hover {
  box-shadow: 0px 2px 5px rgba(69, 101, 173, 0.1);
  -webkit-transform: translateY(-1px) scale(1.01);
          transform: translateY(-1px) scale(1.01);
}

.table.table-padded tbody td {
  padding: 0.9rem 1.1rem;
  background-color: #fff;
  border: none;
  border-right: 1px solid rgba(0, 0, 0, 0.03);
}

.table.table-padded tbody td.bolder {
  font-weight: 500;
  font-size: 0.99rem;
}

.table.table-padded tbody td img {
  display: inline-block;
  vertical-align: middle;
}

.table.table-padded tbody td img + span {
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}

.table.table-padded tbody td span + span {
  margin-left: 5px;
}

.table.table-padded tbody td .status-pill + span {
  margin-left: 10px;
}

.table.table-padded tbody td:first-child {
  border-radius: 4px 0px 0px 4px;
}

.table.table-padded tbody td:last-child {
  border-radius: 0px 4px 4px 0px;
  border-right: none;
}

.element-box .table:last-child, .invoice-w .table:last-child, .big-error-w .table:last-child {
  margin-bottom: 0;
}

/* 3c. Override bootstrap form styles */
label {
  margin-bottom: 4px;
}

button, input, optgroup, select, textarea {
  font-family: "Avenir Next W01", "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
}

.form-control.rounded {
  border-radius: 30px !important;
}

select.form-control.rounded {
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 40px;
  padding-left: 15px;
  background-position: right 5px top 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
}

.form-text {
  font-size: 0.81rem;
}

.form-text.with-errors {
  color: #e65252;
}

.has-danger .form-control-feedback.text-muted, .has-danger .invoice-body .invoice-desc .form-control-feedback.desc-value, .invoice-body .invoice-desc .has-danger .form-control-feedback.desc-value {
  color: #e65252 !important;
  font-weight: 400;
}

.has-danger .form-control {
  border-color: #e65252;
}

.form-control {
  font-family: "Avenir Next W01", "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.form-control.form-control-faded {
  background-color: #dde2ec;
}

.form-control.bright {
  border-color: #e2e4ef;
}

.form-control[type="checkbox"] {
  width: auto;
  display: inline-block;
}

.form-group .date-input {
  position: relative;
}

.form-group .date-input:before {
  content: "\e972";
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  color: #047bf8;
  position: absolute;
  top: 50%;
  left: 10px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.form-group .date-input .form-control {
  padding-left: 35px;
}

.form-control::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.form-control::-moz-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.form-control:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.form-control::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.form-control::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.form-check-input {
  margin-right: 0.5rem;
}

.form-check.stick-right span {
  margin-right: 5px;
}

.form-buttons-w {
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.form-buttons-w.compact {
  margin-top: 0.5rem;
}

.form-buttons-w .btn + .btn, .form-buttons-w .all-wrapper .fc-button + .btn, .all-wrapper .form-buttons-w .fc-button + .btn, .form-buttons-w .all-wrapper .btn + .fc-button, .all-wrapper .form-buttons-w .btn + .fc-button, .form-buttons-w .all-wrapper .fc-button + .fc-button, .all-wrapper .form-buttons-w .fc-button + .fc-button {
  margin-left: 10px;
}

label.bigger {
  font-size: 1.08rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

label.lighter {
  color: rgba(90, 99, 126, 0.49);
}

label.smaller {
  font-size: 0.8rem;
  line-height: 1.5;
}

fieldset {
  margin-top: 2rem;
}

legend {
  font-size: 0.99rem;
  display: block;
  margin-bottom: 1.5rem;
  position: relative;
  color: #047bf8;
}

legend span {
  padding: 0px 0.5rem 0 0;
  background-color: #fff;
  display: inline-block;
  z-index: 2;
  position: relative;
}

legend:before {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  height: 1px;
  top: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.form-header {
  margin-bottom: 1rem;
  padding-top: 0.5rem;
  display: block;
}

.form-desc {
  color: #262626;
  margin-bottom: 1.5rem;
  font-weight: 300;
  font-size: 0.9rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: block;
}

.os-toggler-w {
  display: inline-block;
  margin-right: 10px;
}

.os-toggler-w .os-toggler-i {
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  height: 20px;
  width: 35px;
  position: relative;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.os-toggler-w .os-toggler-i .os-toggler-pill {
  background-color: #fff;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 3px;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.os-toggler-w.on .os-toggler-i {
  background-color: #E1E6F2;
}

.os-toggler-w.on .os-toggler-i .os-toggler-pill {
  -webkit-transform: translate(15px, -50%);
          transform: translate(15px, -50%);
  background-color: #047bf8;
}

/* 3d. Override bootstrap navigation styles */
.nav.smaller {
  font-size: 0.72rem;
}

.nav.smaller.nav-tabs .nav-link {
  padding: 0.7em 1.1em;
}

.nav.smaller.nav-pills .nav-link {
  padding: 0.2em 1.1em;
}

.nav.bigger {
  font-size: 1.25rem;
  font-family: "Avenir Next W01", "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
}

.nav.bigger.nav-tabs .nav-link.active:after,
.nav.bigger.nav-tabs .nav-item.show .nav-link:after {
  height: 6px;
  bottom: -3px;
  border-radius: 2px;
}

.nav.bigger.nav-tabs .nav-link {
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 2rem;
}

.nav.upper {
  font-size: 0.9rem;
  font-family: "Avenir Next W01", "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.nav.upper.nav-tabs .nav-link.active:after,
.nav.upper.nav-tabs .nav-item.show .nav-link:after {
  height: 3px;
  bottom: -2px;
  border-radius: 2px;
}

.nav.upper.nav-tabs .nav-link {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 15px;
  margin-right: 2rem;
}

.nav.upper.centered.nav-tabs .nav-link {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 15px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.nav.upper.centered.nav-tabs .nav-item {
  margin-right: 0px;
}

.nav.centered {
  text-align: center;
  justify-content: space-around;
}

.nav-link i {
  display: inline-block;
  color: #b0c4f3;
  font-size: 26px;
  margin-bottom: 5px;
}

.nav-link span {
  display: block;
  font-size: 0.72rem;
}

.nav-link.active i {
  color: #047bf8;
}

.nav-tabs .nav-item {
  margin-bottom: 0px;
  margin-right: 1rem;
}

.nav-tabs .nav-link {
  border: none;
  color: rgba(0, 0, 0, 0.3);
}

.nav-tabs .nav-link.disabled {
  color: #636c72;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link,
.nav-tabs .nav-item .nav-link {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  position: relative;
}

.nav-tabs .nav-link:after,
.nav-tabs .nav-item .nav-link:after {
  content: "";
  width: 0%;
  height: 3px;
  background-color: #047bf8;
  position: absolute;
  bottom: -2px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-item:hover .nav-link {
  color: #495057;
  background-color: transparent;
  border-color: #dee2e6 #dee2e6 transparent;
  position: relative;
}

.nav-tabs .nav-link.active:after,
.nav-tabs .nav-item.show .nav-link:after,
.nav-tabs .nav-link:hover:after,
.nav-tabs .nav-item:hover .nav-link:after {
  width: 100%;
}

.nav-pills .nav-link {
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.4);
}

.nav-pills .nav-link.active,
.nav-pills .nav-item.show .nav-link {
  color: #fff;
  cursor: default;
  background-color: #047bf8;
}

/* 3e. Override bootstrap button styles */
.toggled-buttons .btn-toggled {
  border: 2px solid #047bf8;
  border: 2px solid transparent;
  border-radius: 6px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 0.72rem;
  padding: 4px 8px;
  color: rgba(0, 0, 0, 0.3);
  margin: 5px 0px;
}

.toggled-buttons .btn-toggled.on, .toggled-buttons .btn-toggled:hover {
  border-color: #047bf8;
  color: #047bf8;
}

.toggled-buttons .btn-toggled + .btn-toggled {
  margin-left: 10px;
}

.toggled-buttons.solid .btn-toggled {
  background-color: rgba(0, 0, 0, 0.07);
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.81rem;
}

.toggled-buttons.solid .btn-toggled.on, .toggled-buttons.solid .btn-toggled:hover {
  background-color: #047bf8;
  color: #fff;
}

.btn-sm, .btn-group-sm > .btn, .all-wrapper .btn-group-sm > .fc-button {
  padding: 0.25rem 0.5rem;
  font-size: 0.775rem;
  line-height: 1.5;
  border-radius: 4px;
}

.btn-white, .all-wrapper .fc-button {
  color: #292b2c;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:hover, .all-wrapper .fc-button:hover {
  color: #292b2c;
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-white:focus, .all-wrapper .fc-button:focus, .btn-white.focus, .all-wrapper .focus.fc-button {
  color: #292b2c;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(223, 223, 223, 0.5);
}

.btn-white.disabled, .all-wrapper .disabled.fc-button, .btn-white:disabled, .all-wrapper .fc-button:disabled {
  color: #292b2c;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:not(:disabled):not(.disabled):active, .all-wrapper .fc-button:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active, .all-wrapper :not(:disabled):not(.disabled).active.fc-button,
.show > .btn-white.dropdown-toggle,
.all-wrapper .show > .dropdown-toggle.fc-button {
  color: #292b2c;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-white:not(:disabled):not(.disabled):active:focus, .all-wrapper .fc-button:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus, .all-wrapper :not(:disabled):not(.disabled).active.fc-button:focus,
.show > .btn-white.dropdown-toggle:focus,
.all-wrapper .show > .dropdown-toggle.fc-button:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 223, 223, 0.5);
}

.btn-grey {
  color: #292b2c;
  background-color: #dde2ec;
  border-color: #dde2ec;
}

.btn-grey:hover {
  color: #292b2c;
  background-color: #c4cdde;
  border-color: #bcc6da;
}

.btn-grey:focus, .btn-grey.focus {
  color: #292b2c;
  background-color: #c4cdde;
  border-color: #bcc6da;
  box-shadow: 0 0 0 0.2rem rgba(194, 199, 207, 0.5);
}

.btn-grey.disabled, .btn-grey:disabled {
  color: #292b2c;
  background-color: #dde2ec;
  border-color: #dde2ec;
}

.btn-grey:not(:disabled):not(.disabled):active, .btn-grey:not(:disabled):not(.disabled).active,
.show > .btn-grey.dropdown-toggle {
  color: #292b2c;
  background-color: #bcc6da;
  border-color: #b4bfd5;
}

.btn-grey:not(:disabled):not(.disabled):active:focus, .btn-grey:not(:disabled):not(.disabled).active:focus,
.show > .btn-grey.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(194, 199, 207, 0.5);
}

.btn-white-gold {
  color: #292b2c;
  background-color: #fff;
  border-color: #fff;
  color: #CF8D0D;
}

.btn-white-gold:hover {
  color: #292b2c;
  background-color: #ececec;
  border-color: #e6e6e6;
}

.btn-white-gold:focus, .btn-white-gold.focus {
  color: #292b2c;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 0.2rem rgba(223, 223, 223, 0.5);
}

.btn-white-gold.disabled, .btn-white-gold:disabled {
  color: #292b2c;
  background-color: #fff;
  border-color: #fff;
}

.btn-white-gold:not(:disabled):not(.disabled):active, .btn-white-gold:not(:disabled):not(.disabled).active,
.show > .btn-white-gold.dropdown-toggle {
  color: #292b2c;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}

.btn-white-gold:not(:disabled):not(.disabled):active:focus, .btn-white-gold:not(:disabled):not(.disabled).active:focus,
.show > .btn-white-gold.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 223, 223, 0.5);
}

.btn-teal {
  color: #292b2c;
  background-color: #fff;
  border-color: #31C37F;
}

.btn-teal:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #279a64;
}

.btn-teal:focus, .btn-teal.focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #279a64;
  box-shadow: 0 0 0 0.2rem rgba(48, 172, 115, 0.5);
}

.btn-teal.disabled, .btn-teal:disabled {
  color: #292b2c;
  background-color: #fff;
  border-color: #31C37F;
}

.btn-teal:not(:disabled):not(.disabled):active, .btn-teal:not(:disabled):not(.disabled).active,
.show > .btn-teal.dropdown-toggle {
  color: #292b2c;
  background-color: #e6e6e6;
  border-color: #24905e;
}

.btn-teal:not(:disabled):not(.disabled):active:focus, .btn-teal:not(:disabled):not(.disabled).active:focus,
.show > .btn-teal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(48, 172, 115, 0.5);
}

.btn-link.btn-danger {
  color: #e65252;
}

.btn, .all-wrapper .fc-button {
  font-family: "Avenir Next W01", "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  outline: none;
  line-height: 1;
}

.btn.btn-secondary:focus, .all-wrapper .btn-secondary.fc-button:focus, .btn.btn-secondary.focus, .all-wrapper .btn-secondary.focus.fc-button {
  box-shadow: none;
}

.btn .os-icon, .all-wrapper .fc-button .os-icon {
  font-size: 0.9rem;
  display: inline-block;
  vertical-align: middle;
}

.btn .os-icon + span, .all-wrapper .fc-button .os-icon + span {
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
}

.btn span + .os-icon, .all-wrapper .fc-button span + .os-icon {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.btn.btn-sm .os-icon, .btn-group-sm > .btn .os-icon, .all-wrapper .btn-group-sm > .fc-button .os-icon, .all-wrapper .btn-sm.fc-button .os-icon {
  font-size: 14px;
}

.btn.btn-sm .os-icon + span, .btn-group-sm > .btn .os-icon + span, .all-wrapper .btn-group-sm > .fc-button .os-icon + span, .all-wrapper .btn-sm.fc-button .os-icon + span {
  margin-left: 0.5rem;
}

.btn.btn-sm span + .os-icon, .btn-group-sm > .btn span + .os-icon, .all-wrapper .btn-group-sm > .fc-button span + .os-icon, .all-wrapper .btn-sm.fc-button span + .os-icon {
  margin-left: 0.5rem;
}

.btn.btn-rounded, .all-wrapper .btn-rounded.fc-button {
  border-radius: 40px;
}

.btn.btn-upper, .all-wrapper .btn-upper.fc-button {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.btn.btn-underlined, .all-wrapper .btn-underlined.fc-button {
  background-color: transparent;
  padding-left: 0px;
  padding-right: 0px;
  text-decoration: none;
}

.btn.btn-underlined span, .all-wrapper .btn-underlined.fc-button span {
  border-bottom: 2px solid #047bf8;
}

.btn.btn-underlined i, .all-wrapper .btn-underlined.fc-button i {
  font-size: 0.63rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.btn.btn-underlined:hover, .all-wrapper .btn-underlined.fc-button:hover {
  color: #0362c6;
}

.btn.btn-underlined:hover span, .all-wrapper .btn-underlined.fc-button:hover span {
  border-color: #0362c6;
}

.btn.btn-underlined:hover i, .all-wrapper .btn-underlined.fc-button:hover i {
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}

.btn.btn-underlined.btn-gold, .all-wrapper .btn-underlined.btn-gold.fc-button {
  color: #BE8B1C;
}

.btn.btn-underlined.btn-gold span, .all-wrapper .btn-underlined.btn-gold.fc-button span {
  border-color: #BE8B1C;
}

.btn.btn-underlined.btn-gold:hover, .all-wrapper .btn-underlined.btn-gold.fc-button:hover {
  color: #926a15;
}

.btn.btn-underlined.btn-gold:hover span, .all-wrapper .btn-underlined.btn-gold.fc-button:hover span {
  border-color: #926a15;
}

/* 3f. Override bootstrap breadcrumbs styles */
.breadcrumb {
  list-style: none;
  margin: 0px;
  padding: 10px 30px 10px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  background-color: transparent;
  letter-spacing: 0.7px;
}

.breadcrumb li {
  margin-bottom: 0px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.585rem;
  font-family: "Avenir Next W01", "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.breadcrumb li a {
  color: #3E4B5B;
}

.breadcrumb li span {
  color: rgba(0, 0, 0, 0.4);
}

.breadcrumbs + .content-box {
  padding-top: 0px;
}

/* 3g. Override bootstrap typography styles */
.text-muted, .invoice-body .invoice-desc .desc-value {
  font-weight: 300;
}

/* 3h. Override bootstrap modal styles */
.modal-content {
  box-shadow: 0 25px 65px rgba(15, 24, 33, 0.29);
}

.modal-footer.buttons-on-left {
  -webkit-box-pack: start;
          justify-content: flex-start;
}

.modal-header.faded {
  background-color: rgba(0, 0, 0, 0.05);
}

.modal-header.smaller {
  font-size: 0.99rem;
}

.modal-header span, .modal-header strong, .modal-header .avatar {
  display: inline-block;
  vertical-align: middle;
}

.modal-header span {
  color: #999;
  margin-right: 5px;
}

.modal-header .avatar {
  border-radius: 50%;
  width: 40px;
  height: auto;
}

.modal-header .avatar + span {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 15px;
  margin-left: 15px;
}

/* 3h. Override bootstrap popover styles */
.popover {
  font-size: 0.99rem;
  box-shadow: 0 5px 50px rgba(4, 123, 248, 0.3);
}

.popover-header {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.9rem;
  color: #fff;
}

/* 3i. Override bootstrap alerts styles */
.alert {
  font-weight: 300;
}

.alert p:last-child {
  margin-bottom: 0px;
}

.alert.alert-warning .alert-heading {
  color: #3E3221;
}

.alert.borderless {
  border: none;
}

.alert.compact {
  padding: 15px 20px;
  font-size: 0.72rem;
}

/* 3i. Override bootstrap badges styles */
.badge {
  font-size: 0.81rem;
}

.badge-default-inverted {
  background-color: #b4bbc3;
  border: 1px solid #101112;
  color: #040404;
  font-weight: 300;
}

.badge-default-inverted[href]:hover, .badge-default-inverted[href]:focus {
  background-color: #98a2ac;
  border: 1px solid black;
  color: #040404;
  font-weight: 300;
}

.badge-primary-inverted {
  background-color: #cce4ff;
  border: 1px solid #0362c6;
  color: #0356ad;
  font-weight: 300;
}

.badge-primary-inverted[href]:hover, .badge-primary-inverted[href]:focus {
  background-color: #99caff;
  border: 1px solid #024994;
  color: #0356ad;
  font-weight: 300;
}

.badge-success-inverted {
  background-color: #c5f0c0;
  border: 1px solid #1b850f;
  color: #166e0c;
  font-weight: 300;
}

.badge-success-inverted[href]:hover, .badge-success-inverted[href]:focus {
  background-color: #9fe697;
  border: 1px solid #12570a;
  color: #166e0c;
  font-weight: 300;
}

.badge-info-inverted {
  background-color: #ecf9fd;
  border: 1px solid #31b0d5;
  color: #28a1c5;
  font-weight: 300;
}

.badge-info-inverted[href]:hover, .badge-info-inverted[href]:focus {
  background-color: #bfeaf7;
  border: 1px solid #2390b0;
  color: #28a1c5;
  font-weight: 300;
}

.badge-warning-inverted {
  background-color: white;
  border: 1px solid #f9d66f;
  color: #f8cf57;
  font-weight: 300;
}

.badge-warning-inverted[href]:hover, .badge-warning-inverted[href]:focus {
  background-color: #fff9e8;
  border: 1px solid #f7c83e;
  color: #f8cf57;
  font-weight: 300;
}

.badge-danger-inverted {
  background-color: #fdebeb;
  border: 1px solid #e02525;
  color: #ce1e1e;
  font-weight: 300;
}

.badge-danger-inverted[href]:hover, .badge-danger-inverted[href]:focus {
  background-color: #fabcbc;
  border: 1px solid #b71b1b;
  color: #ce1e1e;
  font-weight: 300;
}

/* 4. Overrides for various plugins that are included with this template */
/* STAR RATING */
.br-theme-osadmin .br-widget {
  height: 28px;
  white-space: nowrap;
}

.br-theme-osadmin .br-widget a {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  margin-right: 2px;
}

.br-theme-osadmin .br-widget a:after {
  content: '\e970';
  color: #d2d2d2;
}

.br-theme-osadmin .br-widget a.br-active:after {
  color: #EDB867;
}

.br-theme-osadmin .br-widget a.br-selected:after {
  color: #EDB867;
}

.br-theme-osadmin .br-widget .br-current-rating {
  display: none;
}

.br-theme-osadmin .br-readonly a {
  cursor: default;
}

@media print {
  .br-theme-osadmin .br-widget a:after {
    content: '\f006';
    color: black;
  }
  .br-theme-osadmin .br-widget a.br-active:after,
  .br-theme-osadmin .br-widget a.br-selected:after {
    content: '\e970';
    color: black;
  }
}

/* ION RANGE SLIDER */
.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge,
.irs-slider {
  background-color: #dddddd;
}

.irs {
  height: 40px;
}

.irs-with-grid {
  height: 60px;
}

.irs-line {
  height: 5px;
  top: 25px;
  border-radius: 2px;
}

.irs-line-left {
  height: 12px;
}

.irs-line-mid {
  height: 12px;
}

.irs-line-right {
  height: 12px;
}

.irs-bar {
  height: 5px;
  top: 25px;
  background-color: #98c9fd;
}

.irs-bar-edge {
  top: 25px;
  height: 12px;
  width: 9px;
}

.irs-shadow {
  height: 3px;
  top: 34px;
  background: #000;
  opacity: 0.25;
}

.lt-ie9 .irs-shadow {
  filter: alpha(opacity=25);
}

.irs-slider {
  width: 11px;
  height: 11px;
  top: 22px;
  background-color: #047bf8;
  box-shadow: 0px 0px 0px 2px #fff;
  border-radius: 20px;
  cursor: pointer;
}

.irs-slider:hover {
  background-color: #024994;
}

.irs-min, .irs-max {
  color: #999;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  top: 0;
  padding: 1px 3px;
  background: #e1e4e9;
  border-radius: 4px;
}

.irs-from, .irs-to, .irs-single {
  color: #fff;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background: #ed5565;
  border-radius: 4px;
}

.irs-from:after, .irs-to:after, .irs-single:after {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #ed5565;
}

.irs-grid-pol {
  background: #e1e4e9;
}

.irs-grid-text {
  color: #999;
}

/* DROPZONE */
.dropzone {
  border: 2px dashed #047bf8;
  border-radius: 6px;
}

/* SELECT 2 */
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
  border-color: #dde2ec;
  border-width: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e2ebff;
  border: 1px solid #4771d2;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #474a50;
  margin-right: 4px;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #047bf8;
  color: #fff;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-width: 2px;
  border-color: #047bf8;
}

.select2-dropdown {
  border-width: 2px;
  border-color: #047bf8;
}

.form-control {
  font-weight: 300;
}

.select2 {
  font-weight: 300;
}

/* DATERANGEPICKER */
body .daterangepicker {
  box-shadow: 3px 25px 50px rgba(77, 76, 132, 0.34);
  font-family: "Avenir Next W01", "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  border: none;
  padding: 15px;
  min-width: 540px;
}

body .daterangepicker::after {
  display: block;
  clear: both;
  content: "";
}

body .daterangepicker .calendar {
  float: left;
  max-width: 300px;
  margin: 10px;
}

body .daterangepicker .calendar td {
  cursor: pointer;
}

body .daterangepicker .calendar td:hover {
  background-color: #1a88fb;
  color: #fff;
}

body .daterangepicker .calendar td.in-range {
  background-color: #1a88fb;
  color: #fff;
  border-color: #fff;
}

body .daterangepicker .calendar td.active, body .daterangepicker .calendar td.active:hover {
  background-color: #046fdf;
  color: #fff;
  border-color: #fff;
}

body .daterangepicker .ranges {
  clear: left;
  padding: 5px 10px;
}

body .daterangepicker .ranges .btn, body .daterangepicker .ranges .all-wrapper .fc-button, .all-wrapper body .daterangepicker .ranges .fc-button {
  padding: 5px 10px;
  line-height: 1;
  vertical-align: middle;
  text-transform: none;
  font-size: 0.81rem;
}

body .daterangepicker th {
  font-weight: 500;
  text-align: center;
  padding: 5px 0px;
  font-size: 0.72rem;
}

body .daterangepicker th.prev {
  text-align: left;
  cursor: pointer;
}

body .daterangepicker th.next {
  text-align: right;
  cursor: pointer;
}

body .daterangepicker:before {
  border-bottom-color: #3E4B5B;
}

body .daterangepicker .calendar td {
  font-weight: 300;
  font-size: 0.81rem;
  text-align: right;
  font-size: 0.81rem;
  padding: 5px 8px;
  border: 1px solid #eee;
}

body .daterangepicker .calendar th.month {
  color: #047bf8;
}

body .daterangepicker .calendar-table {
  padding-top: 10px;
}

body .daterangepicker thead tr:first-child th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 5px;
}

body .daterangepicker thead tr:first-child + tr th {
  padding-top: 10px;
}

body .daterangepicker .daterangepicker_input {
  position: relative;
}

body .daterangepicker .daterangepicker_input input {
  width: 100%;
  display: block;
  padding-left: 35px;
}

body .daterangepicker .daterangepicker_input i {
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 18px;
  position: absolute;
}

body .daterangepicker .fa.fa-calendar.glyphicon.glyphicon-calendar:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e926";
}

body .daterangepicker .fa.fa-chevron-left.glyphicon.glyphicon-chevron-left:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e919";
  font-size: 10px;
}

body .daterangepicker .fa.fa-chevron-right.glyphicon.glyphicon-chevron-right:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e910";
  font-size: 10px;
}

/* DATATABLES */
.dataTables_length select {
  display: inline-block;
  width: 50px;
  margin: 0px 5px;
  vertical-align: middle;
}

.dataTables_filter input {
  display: inline-block;
  width: 130px;
  margin: 0px 5px;
  vertical-align: middle;
}

.dataTables_wrapper .row:first-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding-bottom: 0.5rem;
}

.dataTables_wrapper .row:last-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-top: 0.5rem;
}

/* CALENDAR  */
.fc-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.fc-header td {
  padding: 10px 0px;
}

.fc-header h2 {
  text-transform: uppercase;
  font-size: 18px;
}

.fc-content {
  color: #fff;
}

.fc-event {
  background-color: #3584ff;
  box-shadow: 5px 5px 10px 0px #bdd4ff;
  border: none;
  padding: 6px;
  padding-left: 9px;
  color: #fff;
  border-radius: 4px;
}

.fc-day-number {
  color: #6B6862;
}

.fc-day-header {
  font-weight: 300;
  color: #6B6862;
  text-transform: uppercase;
  font-size: 12px;
}

.fc-other-month {
  background-color: #eee;
}

.all-wrapper .fc-button {
  padding: 5px 10px;
  height: auto;
  margin: 0px 5px;
  background-image: none;
  box-shadow: none;
}

.all-wrapper .fc-button.fc-state-active {
  outline: none;
  text-shadow: none;
}

/* Datatables for Bootstrap 4 */
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
}

table.dataTable td, table.dataTable th {
  box-sizing: content-box;
}

table.dataTable td.dataTables_empty, table.dataTable th.dataTables_empty {
  text-align: center;
}

table.dataTable.nowrap th, table.dataTable.nowrap td {
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length select {
  width: 75px;
  display: inline-block;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}

div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}

div.dataTables_wrapper div.dataTables_info {
  padding-top: 0.85em;
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
  -webkit-box-pack: end;
          justify-content: flex-end;
}

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0;
}

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting {
  padding-right: 30px;
}

table.dataTable thead > tr > th:active, table.dataTable thead > tr > td:active {
  outline: none;
}

table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  content: "\2191";
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "\2193";
}

table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:after {
  opacity: 1;
}

table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0;
}

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table thead .sorting:after, div.dataTables_scrollBody table thead .sorting_asc:after, div.dataTables_scrollBody table thead .sorting_desc:after {
  display: none;
}

div.dataTables_scrollBody table tbody tr:first-child th, div.dataTables_scrollBody table tbody tr:first-child td {
  border-top: none;
}

div.dataTables_scrollFoot table {
  margin-top: 0 !important;
  border-top: none;
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length, div.dataTables_wrapper div.dataTables_filter, div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
}

table.dataTable.table-condensed > thead > tr > th {
  padding-right: 20px;
}

table.dataTable.table-condensed .sorting:after, table.dataTable.table-condensed .sorting_asc:after, table.dataTable.table-condensed .sorting_desc:after {
  top: 6px;
  right: 6px;
}

table.table-bordered.dataTable th, table.table-bordered.dataTable td {
  border-left-width: 0;
}

table.table-bordered.dataTable th:last-child, table.table-bordered.dataTable th:last-child, table.table-bordered.dataTable td:last-child, table.table-bordered.dataTable td:last-child {
  border-right-width: 0;
}

table.table-bordered.dataTable tbody th, table.table-bordered.dataTable tbody td {
  border-bottom-width: 0;
}

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {
  padding-left: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:last-child {
  padding-right: 0;
}

/* 5. Handy Animations  */
@-webkit-keyframes fadeLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}
@keyframes fadeLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes fadeRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes fadeRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@-webkit-keyframes fadeUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@-webkit-keyframes jumpUp {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate(70px, 70px);
            transform: scale(0.5) translate(70px, 70px);
  }
  80% {
    opacity: 1;
    -webkit-transform: scale(1.1) translate(-10px, -10px);
            transform: scale(1.1) translate(-10px, -10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translate(0px);
            transform: scale(1) translate(0px);
  }
}

@keyframes jumpUp {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5) translate(70px, 70px);
            transform: scale(0.5) translate(70px, 70px);
  }
  80% {
    opacity: 1;
    -webkit-transform: scale(1.1) translate(-10px, -10px);
            transform: scale(1.1) translate(-10px, -10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translate(0px);
            transform: scale(1) translate(0px);
  }
}

.floated-chat-btn {
  -webkit-animation-name: jumpUp;
          animation-name: jumpUp;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}

.floated-colors-btn {
  -webkit-animation-name: jumpUp;
          animation-name: jumpUp;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.floated-customizer-btn {
  -webkit-animation-name: jumpUp;
          animation-name: jumpUp;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.element-box, .invoice-w, .big-error-w {
  -webkit-animation-name: fadeUp;
          animation-name: fadeUp;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.element-balances .balance {
  -webkit-animation-name: fadeRight;
          animation-name: fadeRight;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.element-balances .balance:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.element-balances .balance:nth-child(2) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

.element-balances .balance:nth-child(3) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.element-balances .balance:nth-child(4) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.element-balances .balance:nth-child(5) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.pipeline {
  -webkit-animation-name: fadeUp;
          animation-name: fadeUp;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.project-box {
  -webkit-animation-name: fadeUp;
          animation-name: fadeUp;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.aec-full-message-w {
  -webkit-animation-name: fadeUp;
          animation-name: fadeUp;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.user-profile {
  -webkit-animation-name: fadeUp;
          animation-name: fadeUp;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.content-panel {
  -webkit-animation-name: fadeLeft;
          animation-name: fadeLeft;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

/* 6. User Profile  */
.user-profile .up-head-w {
  background-size: cover;
  background-position: center center;
  position: relative;
  color: #fff;
  border-radius: 6px 6px 0px 0px;
}

.user-profile .up-head-w .decor {
  position: absolute;
  bottom: -1px;
  right: 0px;
  max-width: 100%;
  z-index: 3;
}

.user-profile .up-head-w .decor .decor-path {
  fill: #fff;
}

.user-profile .up-head-w:before {
  z-index: 1;
  content: '';
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), color-stop(rgba(0, 0, 0, 0.2)), color-stop(70%), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2), 70%, rgba(0, 0, 0, 0.5));
}

.user-profile .up-head-w .up-social {
  position: absolute;
  top: 15px;
  right: 25px;
  z-index: 5;
}

.user-profile .up-head-w .up-social a {
  color: rgba(255, 255, 255, 0.8);
  display: inline-block;
  font-size: 30px;
  margin-left: 10px;
}

.user-profile .up-head-w .up-social a:hover {
  color: white;
  text-decoration: none;
}

.user-profile .up-main-info {
  padding: 20% 5% 5% 5%;
  position: relative;
  z-index: 4;
}

.user-profile .up-main-info .user-avatar-w {
  margin-bottom: 1rem;
}

.user-profile .up-main-info .user-avatar-w .user-avatar {
  width: 60px;
  height: 60px;
  border-radius: 40px;
  border: 3px solid #fff;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

.user-profile .up-main-info .user-avatar-w .user-avatar img {
  max-width: 100%;
  height: auto;
}

.user-profile .up-header {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 3.75rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding-bottom: 10px;
  display: inline-block;
  margin-bottom: 10px;
}

.user-profile .up-sub-header {
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  color: rgba(255, 255, 255, 0.8);
}

.user-profile .up-controls {
  padding: 20px 5%;
}

.user-profile .btn + .btn, .user-profile .all-wrapper .fc-button + .btn, .all-wrapper .user-profile .fc-button + .btn, .user-profile .all-wrapper .btn + .fc-button, .all-wrapper .user-profile .btn + .fc-button, .user-profile .all-wrapper .fc-button + .fc-button, .all-wrapper .user-profile .fc-button + .fc-button {
  margin-left: 20px;
}

.user-profile .up-controls + .up-contents {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.user-profile .up-contents {
  padding: 20px 5%;
  padding-top: 3rem;
}

.user-profile.compact .up-contents {
  padding-top: 0px;
}

.user-profile.compact .up-main-info {
  padding-top: 35%;
}

.user-profile.compact .up-head-w .up-social a {
  font-size: 20px;
}

.user-profile.compact .up-header {
  font-size: 1.5rem;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.user-profile.compact .up-sub-header {
  font-size: 0.81rem;
}

.user-profile + .element-wrapper {
  margin-top: 2rem;
}

.value-pair {
  display: inline-block;
}

.value-pair .label {
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.63rem;
  text-transform: uppercase;
  display: inline-block;
}

.value-pair .value {
  display: inline-block;
  font-size: 0.63rem;
  text-transform: uppercase;
}

.value-pair + .value-pair {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

/* 7. Layout styles */
html {
  height: 100%;
}

body {
  min-height: 100%;
  position: relative;
  padding: 50px;
  overflow-x: hidden;
}

body:before {
  content: "";
  position: absolute;
  z-index: -1;
  /* background: -webkit-gradient(linear, left top, right bottom, from(#D7BBEA), to(#65A8F1));
  background: linear-gradient(to bottom right, #D7BBEA, #65A8F1); */
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}

body.full-screen {
  padding: 0px;
}

body.full-screen .all-wrapper {
  max-width: initial;
  border-radius: 0px;
}

body.full-screen .all-wrapper > .top-bar {
  border-radius: 0px;
}

body.full-screen .menu-w,
body.full-screen .top-bar {
  border-radius: 0px !important;
}

body.full-screen .menu-position-side .logged-user-menu {
  left: 0px;
  right: -1px;
  border-radius: 0px;
}

b,
strong {
  font-weight: 500;
}

.all-wrapper {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  max-width: 1600px;
  margin: 0px auto;
  position: relative;
  min-height: 100%;
}

.all-wrapper.no-padding-content .content-box {
  padding: 0px;
}

.all-wrapper.white-bg-content .content-w {
  background-color: #fff;
  background-image: none;
}

.all-wrapper.solid-bg-all {
  background-color: #f2f4f8;
}

.all-wrapper.solid-bg-all .content-w {
  background-image: none;
}

.auth-wrapper .all-wrapper {
  padding: 100px;
}

.section-heading {
  padding: 5% 10%;
  font-size: 1.26rem;
  color: rgba(0, 0, 0, 0.5);
}

.section-heading.centered {
  text-align: center;
}

.section-heading h1 {
  position: relative;
  margin-bottom: 40px;
}

.section-heading h1:after {
  position: absolute;
  bottom: -25px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  content: "";
  background-color: #047bf8;
  height: 5px;
  width: 40px;
  border-radius: 6px;
}

/* Layout settings based on menu position */
/* MENU ON A SIDE */
body.menu-position-side .layout-w {
  display: -webkit-box;
  display: flex;
}

body.menu-position-side .content-w {
  border-radius: 0px 6px 6px 0px;
  -webkit-box-flex: 1;
          flex: 1;
}

/* MENU ON TOP */
body.menu-position-top .content-w {
  border-radius: 0px 0px 6px 6px;
}

body.menu-position-top .top-bar + .layout-w .menu-w {
  border-radius: 0px;
}

body.menu-side-right .layout-w {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
          flex-direction: row-reverse;
}

.content-w {
  background-color: #262626;
  background-repeat: no-repeat;
  background-position: 20px 50px;
  vertical-align: top;
}

.content-box {
  vertical-align: top;
  padding: 2rem 2.5rem;
  -webkit-box-flex: 1;
          flex: 1;
}

.content-box > .element-wrapper:first-child {
  padding-top: 0px !important;
}

.content-panel {
  vertical-align: top;
  -webkit-box-flex: 0;
          flex: 0 0 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  padding: 2rem 2.5rem;
  background-color: transparent;
}

.content-panel.compact {
  padding: 2rem 1.5rem;
  -webkit-box-flex: 0;
          flex: 0 0 320px;
}

.content-panel .content-panel-close {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 14px;
  color: #047bf8;
  z-index: 999;
  cursor: pointer;
}

.content-panel.color-scheme-dark {
  background-color: #2d3756;
}

.content-panel-toggler {
  background-color: #047bf8;
  padding: 4px 10px;
  border-radius: 6px;
  color: #fff;
  font-size: 0.72rem;
  text-transform: uppercase;
  display: none;
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 4;
  cursor: pointer;
}

.content-panel-toggler:hover {
  background-color: #1a88fb;
}

.content-panel-toggler i {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
}

.content-panel-toggler span {
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  letter-spacing: 1px;
}

/*
 SIDE PANEL IS ACTIVE - ADD STYLES
*/
.with-side-panel .content-i {
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 1;
          flex: 1;
}

.with-side-panel .menu-mobile .mm-logo-buttons-w .content-panel-open {
  display: block;
}

/* ICONS LIST FOR DEMO */
.demo-icons-list {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
}

.demo-icons-list li {
  width: 80px;
  text-align: center;
  display: inline-block;
  font-size: 24px;
  vertical-align: middle;
  padding: 20px 15px;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.demo-icons-list li a {
  position: relative;
  color: #333;
}

.demo-icons-list li a i {
  font-style: normal;
}

.demo-icons-list li a span {
  display: inline-block;
  position: absolute;
  background-color: #047bf8;
  color: #fff;
  padding: 4px 7px;
  border-radius: 4px;
  font-size: 0.81rem;
  white-space: nowrap;
  top: -30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: none;
}

.demo-icons-list li a:hover {
  text-decoration: none;
}

.demo-icons-list li a:hover span {
  display: block;
}

/* 8. Menu styles */
/*
  STYLE NAVIGATION
  
#1. Logged User Box
#2. Shared styles between Top Bar & Main Menu
#3. Desktop Menu
#4. Mobile Menu

*/
/* 
#1. Logged User Box
*/
.logged-user-w {
  text-align: center;
  padding: 1rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.logged-user-w .avatar-w {
  padding: 5px;
  border: 1px solid #262626;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
}

.logged-user-w .avatar-w img {
  width: 50px;
  height: auto;
  border-radius: 50%;
}

.logged-user-w .logged-user-role {
  display: block;
  font-size: 0.63rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 1px;
}

.logged-user-w .logged-user-toggler-arrow {
  padding: 3px;
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.logged-user-w.avatar-inline {
  text-align: left;
  position: relative;
}

.logged-user-w.avatar-inline .logged-user-i {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  padding-left: 0.7rem;
}

.logged-user-w.avatar-inline .logged-user-i .logged-user-info-w {
  padding-left: 20px;
  text-align: left;
}

.logged-user-w.avatar-inline .logged-user-toggler-arrow {
  padding: 3px;
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.logged-user-w.avatar-inline .logged-user-avatar-info {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
}

.logged-user-w.avatar-inline .logged-user-avatar-info .logged-user-info-w {
  margin-bottom: 0px;
  padding: 10px 10px 10px 20px;
}

/*
#2. Shared styles between Top Bar & Main Menu
*/
.menu-w .logo-w,
.top-bar .logo-w {
  padding: 10px 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: start;
          justify-content: flex-start;
}

.menu-w .logo-w .filters-toggler,
.top-bar .logo-w .filters-toggler {
  color: rgba(255, 255, 255, 0.7);
  font-size: 20px;
  line-height: 1;
  cursor: pointer;
}

.menu-w .logo-w .filters-toggler .os-icon,
.top-bar .logo-w .filters-toggler .os-icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

.menu-w .logo-w .filters-toggler:hover,
.top-bar .logo-w .filters-toggler:hover {
  color: #fff;
}

.menu-w .logo-w .logo,
.top-bar .logo-w .logo {
  display: inline-block;
  text-decoration: none;
}

.menu-w .logo-w .logo-element,
.top-bar .logo-w .logo-element {
  content: "";
  width: 26px;
  height: 26px;
  border-radius: 15px;
  position: relative;
  background-color: #98c9fd;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-w .logo-w .logo-element:after,
.top-bar .logo-w .logo-element:after {
  content: "";
  width: 26px;
  height: 26px;
  background-color: #fff;
  border-radius: 15px;
  right: -20px;
  position: absolute;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-w .logo-w .logo:hover .logo-element,
.top-bar .logo-w .logo:hover .logo-element {
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}

.menu-w .logo-w .logo:hover .logo-element:after,
.top-bar .logo-w .logo:hover .logo-element:after {
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
}

.menu-w .logo-w .logo:hover .logo-label:after,
.top-bar .logo-w .logo:hover .logo-label:after {
  width: 100%;
  background-color: #fff;
}

.menu-w .logo-w .logo-label,
.top-bar .logo-w .logo-label {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.81rem;
  position: relative;
  margin-left: 10px;
}

.menu-w .logo-w .logo-label:after,
.top-bar .logo-w .logo-label:after {
  height: 2px;
  position: absolute;
  width: 0%;
  left: 0px;
  bottom: -5px;
  background-color: #fff;
  content: "";
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

/*
  #3. Desktop Menu
*/
.menu-w {
  position: relative;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
  -webkit-box-flex: 0;
          flex: 0 0 260px;
  border-right: 0px solid rgba(0, 0, 0, 0.05);
  /* Styles for sub menu */
}

.menu-w .menu-page-header {
  display: none;
}

.menu-w .logged-user-w {
  position: relative;
  padding: 1rem;
}

.menu-w .logged-user-w .logged-user-i:hover {
  cursor: pointer;
}

.menu-w .logged-user-w .logged-user-i:hover .logged-user-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.menu-w .logged-user-w + .main-menu {
  padding-top: 0px;
}

.menu-w .logged-user-menu {
  background: #323c58;
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -1px;
  left: -10px;
  overflow: hidden;
  padding: 1rem;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 6px;
}

.menu-w .logged-user-menu .avatar-w {
  border-color: #fff;
}

.menu-w .logged-user-menu .logged-user-avatar-info {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.menu-w .logged-user-menu .logged-user-info-w .logged-user-name {
  color: #fff;
}

.menu-w .logged-user-menu .logged-user-info-w .logged-user-role {
  color: rgba(255, 255, 255, 0.6);
}

.menu-w .logged-user-menu ul {
  list-style: none;
  text-align: left;
  margin: 0px;
  padding: 0px 30px;
  padding-bottom: 20px;
}

.menu-w .logged-user-menu ul li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-w .logged-user-menu ul li a {
  display: block;
  padding: 10px;
  color: #fff;
}

.menu-w .logged-user-menu ul li a i {
  vertical-align: middle;
  margin-right: 15px;
  font-size: 20px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: inline-block;
}

.menu-w .logged-user-menu ul li a span {
  vertical-align: middle;
  font-size: 0.9rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: inline-block;
}

.menu-w .logged-user-menu ul li a:hover {
  text-decoration: none;
}

.menu-w .logged-user-menu ul li a:hover i {
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}

.menu-w .logged-user-menu ul li a:hover span {
  -webkit-transform: translateX(8px);
          transform: translateX(8px);
}

.menu-w .logged-user-menu ul li:last-child {
  border-bottom: none;
}

.menu-w .logged-user-menu .bg-icon {
  font-size: 100px;
  color: rgba(255, 255, 255, 0.1);
  position: absolute;
  bottom: -40px;
  right: -20px;
}

.menu-w .logged-user-menu.color-style-light {
  background-color: #fff;
}

.menu-w .logged-user-menu.color-style-light .logged-user-avatar-info {
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.menu-w .logged-user-menu.color-style-light .logged-user-info-w .logged-user-name {
  color: #334152;
}

.menu-w .logged-user-menu.color-style-light .logged-user-info-w .logged-user-role {
  color: #adb5bd;
}

.menu-w .logged-user-menu.color-style-light .avatar-w {
  border-color: #111;
}

.menu-w .logged-user-menu.color-style-light ul li {
  border-bottom-color: rgba(0, 0, 0, 0.05);
}

.menu-w .logged-user-menu.color-style-light ul li a {
  color: #3E4B5B;
}

.menu-w .logged-user-menu.color-style-light ul li a i {
  color: #047bf8;
}

.menu-w .logged-user-menu.color-style-dark {
  background-color: #323c58;
}

.menu-w .logged-user-menu.color-style-bright {
  background-color: #1b55e2;
}

.menu-w .logged-user-menu.color-style-bright-alt {
  background-color: #5e00da;
}

.menu-w .side-menu-magic {
  background-image: linear-gradient(-154deg, #1643A3 8%, #2E1170 90%);
  border-radius: 6px;
  padding: 40px;
  text-align: center;
  margin: 20px;
}

.menu-w .side-menu-magic h1, .menu-w .side-menu-magic h2, .menu-w .side-menu-magic h3, .menu-w .side-menu-magic h4, .menu-w .side-menu-magic h5 {
  color: #fff;
  margin-bottom: 5px;
}

.menu-w .side-menu-magic p {
  color: rgba(255, 255, 255, 0.6);
}

.menu-w .side-menu-magic .btn-w {
  margin-top: 15px;
}

.menu-w .side-menu-magic .btn-white, .menu-w .side-menu-magic .all-wrapper .fc-button, .all-wrapper .menu-w .side-menu-magic .fc-button {
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}

.menu-w .logo-w {
  text-align: center;
  padding: 14px 1rem 14px 1.7rem;
}

.menu-w .logo-w a {
  display: inline-block;
}

.menu-w .logo-w .logo {
  display: inline-block;
}

.menu-w .logo-w img {
  width: 40px;
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

.menu-w .logo-w span {
  vertical-align: middle;
  display: inline-block;
  color: #334152;
  margin-left: 1rem;
}

.menu-w ul.main-menu {
  list-style: none;
  padding: 2rem 1rem;
  margin-bottom: 0px;
}

.menu-w ul.main-menu > li {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-w ul.main-menu > li:last-child {
  border-bottom: none;
}

.menu-w ul.main-menu > li.has-sub-menu > a:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e91c";
  font-size: 7px;
  color: rgba(0, 0, 0, 0.5);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 5px;
}

.menu-w ul.main-menu > li.has-sub-menu.active .sub-menu {
  display: block;
}

.menu-w ul.main-menu > li.sub-header {
  text-transform: uppercase;
  color: #448eef;
  font-size: 0.72rem;
  letter-spacing: 1px;
  padding-top: 20px;
  padding-bottom: 0px;
  border-bottom: none;
}

.menu-w ul.main-menu > li.sub-header:first-child {
  padding-top: 0px;
}

.menu-w ul.main-menu > li.sub-header:first-child span {
  padding-top: 0px;
}

.menu-w ul.main-menu > li > a {
  color: #3E4B5B;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  position: relative;
  font-size: 0.99rem;
}

.menu-w ul.main-menu > li > a:focus {
  text-decoration: none;
}

.menu-w ul.main-menu > li > a:hover {
  text-decoration: none;
}

@media (min-width: 1025px) {
  .menu-w ul.main-menu > li > a:hover .icon-w {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  .menu-w ul.main-menu > li > a:hover span {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}

.menu-w ul.main-menu > li .icon-w {
  color: #0073ff;
  font-size: 27px;
  display: block;
  padding: 1rem 1rem 1rem 0rem;
  width: 70px;
  text-align: center;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-w ul.main-menu > li span {
  padding: 1rem;
  padding-left: 0.8rem;
  display: block;
  -webkit-box-flex: 1;
          flex: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-w ul.main-menu > li .icon-w + span {
  padding-left: 0px;
}

.menu-w ul.sub-menu {
  padding: 0px;
  padding-left: 55px;
  display: none;
  list-style: none;
}

.menu-w ul.sub-menu:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding-top: 1rem;
}

.menu-w ul.sub-menu li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-w ul.sub-menu li a {
  padding: 0.4rem 10px 0.4rem 10px;
  display: block;
  position: relative;
  font-size: 0.81rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-w ul.sub-menu li a:before {
  content: "";
  width: 5px;
  height: 5px;
  border: 1px solid #047bf8;
  position: absolute;
  left: -10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #fff;
  display: block;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: 6px;
}

.menu-w ul.sub-menu li a:hover {
  text-decoration: none;
  cursor: pointer;
  -webkit-transform: translateX(-5px);
          transform: translateX(-5px);
}

.menu-w ul.sub-menu li a:hover:before {
  -webkit-transform: translate(-5px, -50%);
          transform: translate(-5px, -50%);
  border-radius: 6px;
  background-color: #047bf8;
  border-color: #047bf8;
}

.menu-w.color-scheme-light .messages-notifications,
.menu-w.color-scheme-light .top-icon {
  color: #047bf8;
}

.menu-w.color-scheme-light .logo-w {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-w.color-scheme-light .logo-w .logo-label {
  color: #3E4B5B;
}

.menu-w.color-scheme-light .logo-w .logo-element {
  background-color: #0a7cf8;
}

.menu-w.color-scheme-light .logo-w .logo-element:after {
  background-color: #bfd6f9;
}

.menu-w.color-scheme-light .logo-w .logo:hover .logo-label {
  color: #047bf8;
}

.menu-w.color-scheme-light .logo-w .logo:hover .logo-label:after {
  background-color: #047bf8;
}

.menu-w.color-scheme-dark {
  background-color: #293145;
  background-image: none;
  color: rgba(255, 255, 255, 0.9);
  border-right-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 5px 15px rgba(22, 22, 35, 0.1);
}

.menu-w.color-scheme-dark .messages-notifications,
.menu-w.color-scheme-dark .top-icon {
  color: #fff;
}

.menu-w.color-scheme-dark .logged-user-menu {
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.2);
}

.menu-w.color-scheme-dark .side-menu-magic {
  background-image: linear-gradient(-154deg, #6d16a3 8%, #5211e6 90%);
  box-shadow: 0px 5px 10px rgba(19, 19, 19, 0.17);
}

.menu-w.color-scheme-dark ul.sub-menu li {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-w.color-scheme-dark ul.sub-menu li a {
  color: #fff;
}

.menu-w.color-scheme-dark.color-style-bright ul.main-menu .icon-w {
  color: #95acff;
}

.menu-w.color-scheme-dark ul.main-menu .icon-w {
  color: #babed0;
}

.menu-w.color-scheme-dark ul.main-menu > li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-w.color-scheme-dark ul.main-menu > li > a {
  color: #fff;
}

.menu-w.color-scheme-dark ul.main-menu > li > a:hover > .icon-w {
  color: #fff;
}

.menu-w.color-scheme-dark ul.main-menu > li > a:before {
  color: #fff;
}

.menu-w.color-scheme-dark .sub-menu-w {
  box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.2);
}

.menu-w.color-scheme-dark .logo-w {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-w.color-scheme-dark .logo-w span {
  color: #fff;
}

.menu-w.color-scheme-dark .logged-user-w {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-w.color-scheme-dark .logged-user-w .avatar-w {
  border-color: #fff;
}

.menu-w.color-scheme-dark .logged-user-w .logged-user-role {
  color: rgba(255, 255, 255, 0.4);
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w {
  background-color: transparent;
  box-shadow: none;
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w .sub-menu-header {
  border-bottom-color: rgba(255, 255, 255, 0.05);
  color: #fff;
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w ul.sub-menu:first-child {
  border-top-color: rgba(255, 255, 255, 0.05);
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w ul.sub-menu li {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w ul.sub-menu li a {
  color: #ccd9e8;
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w ul.sub-menu li a:before {
  border-color: #fff;
  opacity: 0.7;
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w ul.sub-menu li a:hover {
  color: #fff;
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w ul.sub-menu li a:hover:before {
  background-color: #fff;
  opacity: 1;
}

.menu-w.color-scheme-dark.sub-menu-style-inside .sub-menu-w ul.sub-menu + ul.sub-menu {
  border-left-color: rgba(255, 255, 255, 0.05);
}

.menu-w.color-style-transparent {
  background: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  border-right-width: 1px;
}

.menu-w.color-style-bright {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1c4cc3), to(#0e369a));
  background-image: linear-gradient(to bottom, #1c4cc3 0%, #0e369a 100%);
  background-repeat: repeat-x;
}

.menu-w.sub-menu-style-inside .sub-menu-header {
  display: none;
}

.menu-w.sub-menu-style-inside .sub-menu-icon {
  display: none;
}

.menu-w.sub-menu-style-inside ul.sub-menu {
  padding-left: 45px;
}

.menu-w.sub-menu-style-inside ul.sub-menu:last-child {
  padding-bottom: 1rem;
}

.menu-w.sub-menu-style-inside ul.sub-menu:last-child li:last-child {
  border-bottom: none;
}

.menu-w.sub-menu-style-inside.sub-menu-color-bright ul.main-menu > li.active > a:before, .menu-w.sub-menu-style-inside.sub-menu-color-dark ul.main-menu > li.active > a:before {
  color: #fff;
}

.menu-w.sub-menu-style-inside.sub-menu-color-bright ul.main-menu > li.active > a span, .menu-w.sub-menu-style-inside.sub-menu-color-dark ul.main-menu > li.active > a span {
  color: #fff;
}

.menu-w.sub-menu-style-inside.sub-menu-color-bright ul.main-menu > li.active > a .icon-w, .menu-w.sub-menu-style-inside.sub-menu-color-dark ul.main-menu > li.active > a .icon-w {
  color: #fff;
}

.menu-w.sub-menu-style-flyout .main-menu > li.has-sub-menu {
  position: relative;
}

.menu-w.sub-menu-style-flyout .main-menu > li.has-sub-menu > a:before {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-w.sub-menu-style-flyout .main-menu > li.has-sub-menu.active > a:before {
  -webkit-transform: translateY(-50%) rotate(-90deg);
          transform: translateY(-50%) rotate(-90deg);
}

.menu-w.sub-menu-style-flyout .main-menu > li.has-sub-menu.active .sub-menu-w {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(100%, -50%);
          transform: translate(100%, -50%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.menu-w.sub-menu-style-flyout .sub-menu-w {
  position: absolute;
  background-color: #fff;
  padding: 10px 20px 20px;
  border-radius: 6px;
  right: 0px;
  top: 50%;
  -webkit-transform: translate(110%, -50%);
          transform: translate(110%, -50%);
  z-index: 10000;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.menu-w.sub-menu-style-flyout .sub-menu-header {
  font-size: 1.35rem;
  font-weight: 500;
  color: #047bf8;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.menu-w.sub-menu-style-flyout .sub-menu-icon {
  display: none;
}

.menu-w.sub-menu-style-flyout .sub-menu-i {
  display: -webkit-box;
  display: flex;
}

.menu-w.sub-menu-style-flyout ul.sub-menu {
  display: block;
  padding-left: 0px;
  border: none;
  -webkit-box-flex: 0;
          flex: 0 0 180px;
  padding: 0px 5px;
}

.menu-w.sub-menu-style-flyout ul.sub-menu li {
  min-width: 200px;
}

.menu-w.sub-menu-style-flyout ul.sub-menu li:last-child {
  border-bottom: none;
}

.menu-w.sub-menu-style-flyout ul.sub-menu li a {
  color: #3E4B5B;
  white-space: nowrap;
  font-size: 0.9rem;
  padding: 8px 12px;
}

.menu-w.sub-menu-style-flyout ul.sub-menu li a:before {
  opacity: 0;
  -webkit-transform: translate(5px, -50%);
          transform: translate(5px, -50%);
  background-color: #047bf8;
}

.menu-w.sub-menu-style-flyout ul.sub-menu li a:hover {
  color: #047bf8;
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}

.menu-w.sub-menu-style-flyout ul.sub-menu li a:hover:before {
  opacity: 1;
  -webkit-transform: translate(12px, -50%);
          transform: translate(12px, -50%);
}

.menu-w.sub-menu-style-flyout ul.sub-menu li .badge {
  font-size: 0.72rem;
  padding: 2px 5px;
  margin-left: 5px;
}

.menu-w.sub-menu-style-flyout ul.sub-menu + ul.sub-menu {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-w.sub-menu-style-flyout.sub-menu-color-light .sub-menu-w {
  background-color: #fff;
}

.menu-w.sub-menu-style-flyout.sub-menu-color-light .sub-menu-w ul.sub-menu li {
  border-bottom-color: rgba(0, 0, 0, 0.03);
}

.menu-w.sub-menu-style-flyout.sub-menu-color-dark .sub-menu-w, .menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w {
  background-color: #323c58;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.15);
}

.menu-w.sub-menu-style-flyout.sub-menu-color-dark .sub-menu-w .sub-menu-header, .menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w .sub-menu-header {
  border-bottom-color: rgba(255, 255, 255, 0.05);
  color: #fff;
}

.menu-w.sub-menu-style-flyout.sub-menu-color-dark .sub-menu-w ul.sub-menu li, .menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w ul.sub-menu li {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-w.sub-menu-style-flyout.sub-menu-color-dark .sub-menu-w ul.sub-menu li a, .menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w ul.sub-menu li a {
  color: #ccd9e8;
}

.menu-w.sub-menu-style-flyout.sub-menu-color-dark .sub-menu-w ul.sub-menu li a:before, .menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w ul.sub-menu li a:before {
  background-color: #fff;
  border-color: #fff;
}

.menu-w.sub-menu-style-flyout.sub-menu-color-dark .sub-menu-w ul.sub-menu li a:hover, .menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w ul.sub-menu li a:hover {
  color: #fff;
}

.menu-w.sub-menu-style-flyout.sub-menu-color-dark .sub-menu-w ul.sub-menu + ul.sub-menu, .menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w ul.sub-menu + ul.sub-menu {
  border-left-color: rgba(255, 255, 255, 0.05);
}

.menu-w.sub-menu-style-flyout.sub-menu-color-bright .sub-menu-w {
  background-color: #1b55e2;
}

.menu-w.sub-menu-style-over ul.main-menu > li {
  position: relative;
}

.menu-w.sub-menu-style-over ul.main-menu > li > a:hover .icon-w {
  -webkit-transform: none;
          transform: none;
}

.menu-w.sub-menu-style-over ul.main-menu > li:hover {
  border-right-color: #1b55e2;
  border-bottom-color: #1b55e2;
}

.menu-w.sub-menu-style-over ul.main-menu > li:hover > a {
  background-color: #1b55e2;
  color: #fff;
}

.menu-w.sub-menu-style-over ul.main-menu > li:hover > a:before {
  color: #fff;
  -webkit-transform: translateY(-50%) rotate(-90deg);
          transform: translateY(-50%) rotate(-90deg);
}

.menu-w.sub-menu-style-over ul.main-menu > li:hover > a .icon-w {
  color: #fff;
}

.menu-w.sub-menu-style-over ul.main-menu > li:hover .sub-menu-w {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateX(0px) scale(1);
          transform: translateX(0px) scale(1);
}

.menu-w.sub-menu-style-over .sub-menu-w {
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateX(40px) scale(0.95);
          transform: translateX(40px) scale(0.95);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  position: absolute;
  background-color: #1b55e2;
  color: #fff;
  left: 100%;
  top: -100px;
  overflow: hidden;
  text-align: left;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.2);
  padding-bottom: 20px;
}

.menu-w.sub-menu-style-over .sub-menu-w .sub-menu-header {
  font-size: 2.4rem;
  color: rgba(255, 255, 255, 0.2);
  padding: 5px 45px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 15px;
  letter-spacing: -0.5px;
  white-space: nowrap;
  overflow: hidden;
  font-family: "Avenir Next W01", "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
}

.menu-w.sub-menu-style-over .sub-menu-w .sub-menu-icon {
  position: absolute;
  font-size: 120px;
  color: rgba(255, 255, 255, 0.1);
  bottom: -50px;
  right: -10px;
  display: block;
}

.menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i {
  display: -webkit-box;
  display: flex;
}

.menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i .sub-menu + .sub-menu {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.menu-w.sub-menu-style-over .sub-menu-w ul.sub-menu:first-child {
  border-top: none;
}

.menu-w.sub-menu-style-over .sub-menu-w > ul, .menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i > ul {
  list-style: none;
  padding: 0px;
  min-width: 250px;
}

.menu-w.sub-menu-style-over .sub-menu-w > ul > li, .menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i > ul > li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.menu-w.sub-menu-style-over .sub-menu-w > ul > li:last-child, .menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i > ul > li:last-child {
  border-bottom: none;
}

.menu-w.sub-menu-style-over .sub-menu-w > ul > li > a, .menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i > ul > li > a {
  color: #fff;
  display: block;
  padding: 12px 50px;
  font-size: 0.99rem;
  position: relative;
  white-space: nowrap;
}

.menu-w.sub-menu-style-over .sub-menu-w > ul > li > a:before, .menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i > ul > li > a:before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: #FBB463;
  border-color: #FBB463;
  position: absolute;
  left: 28px;
  top: 50%;
  -webkit-transform: translate(-10px, -50%);
          transform: translate(-10px, -50%);
  opacity: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-w.sub-menu-style-over .sub-menu-w > ul > li > a:hover:before, .menu-w.sub-menu-style-over .sub-menu-w .sub-menu-i > ul > li > a:hover:before {
  opacity: 1;
  -webkit-transform: translate(0px, -50%);
          transform: translate(0px, -50%);
}

.menu-w.sub-menu-style-over.sub-menu-color-light .sub-menu-w {
  background-color: #fff;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.15);
}

.menu-w.sub-menu-style-over.sub-menu-color-light .sub-menu-w .sub-menu-header {
  color: rgba(0, 0, 0, 0.1);
  border-bottom-color: rgba(0, 0, 0, 0.05);
}

.menu-w.sub-menu-style-over.sub-menu-color-light .sub-menu-w ul.sub-menu li {
  border-bottom-color: rgba(0, 0, 0, 0.03);
}

.menu-w.sub-menu-style-over.sub-menu-color-light .sub-menu-w ul.sub-menu li a {
  color: #3E4B5B;
}

.menu-w.sub-menu-style-over.sub-menu-color-light .sub-menu-w .sub-menu-i .sub-menu + .sub-menu {
  border-left-color: rgba(0, 0, 0, 0.04);
}

.menu-w.sub-menu-style-over.sub-menu-color-light .sub-menu-w .sub-menu-icon {
  color: rgba(0, 0, 0, 0.03);
}

.menu-w.sub-menu-style-over.sub-menu-color-light ul.main-menu > li:hover {
  border-right-color: #fff;
  border-bottom-color: #fff;
}

.menu-w.sub-menu-style-over.sub-menu-color-light ul.main-menu > li:hover > a {
  background-color: #fff;
  color: #3E4B5B;
}

.menu-w.sub-menu-style-over.sub-menu-color-light ul.main-menu > li:hover > a .icon-w {
  color: #047bf8;
}

.menu-w.sub-menu-style-over.sub-menu-color-light.color-scheme-light ul.main-menu > li:hover > a:before {
  color: #3E4B5B;
}

.menu-w.sub-menu-style-over.sub-menu-color-dark .sub-menu-w {
  background-color: #323c58;
}

.menu-w.sub-menu-style-over.sub-menu-color-dark ul.main-menu > li:hover {
  border-right-color: #323c58;
  border-bottom-color: #323c58;
}

.menu-w.sub-menu-style-over.sub-menu-color-dark ul.main-menu > li:hover > a {
  background-color: #323c58;
}

.menu-w.sub-menu-style-over.sub-menu-color-bright ul.main-menu > li:hover > a {
  background-color: #1b55e2;
}

.menu-w.menu-layout-mini {
  -webkit-box-flex: 0;
          flex: 0 0 70px;
}

.menu-w.menu-layout-mini .logged-user-toggler-arrow {
  display: none;
}

.menu-w.menu-layout-mini .logo-w {
  padding: 17px 15px;
  text-align: center;
}

.menu-w.menu-layout-mini .logo-w .logo-label {
  display: none;
}

.menu-w.menu-layout-mini ul.main-menu > li span {
  display: none;
}

.menu-w.menu-layout-mini .side-menu-magic {
  display: none;
}

.menu-w.menu-layout-mini ul.main-menu > li .icon-w {
  padding: 18px 5px;
  font-size: 30px;
  width: auto;
}

.menu-w.menu-layout-mini ul.main-menu {
  padding: 20px 0px;
}

.menu-w.menu-layout-mini ul.main-menu > li > a {
  -webkit-box-pack: center;
          justify-content: center;
}

.menu-w.menu-layout-mini .main-menu > li.has-sub-menu > a:before {
  display: none;
}

.menu-w.menu-layout-mini ul.main-menu > li.sub-header {
  display: none;
}

.menu-w.menu-layout-mini .logged-user-w.avatar-inline .logged-user-i > .logged-user-info-w {
  display: none;
}

.menu-w.menu-layout-mini .logged-user-w.avatar-inline .logged-user-i {
  padding-left: 0px;
}

.menu-w.menu-layout-mini .logged-user-w {
  text-align: center;
}

.menu-w.menu-layout-mini .logged-user-w {
  padding: 15px;
}

.menu-w.menu-layout-mini .logged-user-menu {
  width: 280px;
}

.menu-w.menu-layout-mini .logged-user-w .logged-user-i > .avatar-w img {
  max-width: 100%;
  height: auto;
}

.menu-w.menu-layout-compact {
  -webkit-box-flex: 0;
          flex: 0 0 260px;
}

.menu-w.menu-layout-compact .logged-user-w .avatar-w {
  padding: 4px;
}

.menu-w.menu-layout-compact .logged-user-w .avatar-w img {
  width: 35px;
}

.menu-w.menu-layout-compact .logged-user-w.avatar-inline .logged-user-i .logged-user-info-w {
  padding-left: 15px;
}

.menu-w.menu-layout-compact ul.main-menu {
  padding: 1rem 1.5rem;
  margin-bottom: 0px;
}

.menu-w.menu-layout-compact ul.main-menu > li {
  border-bottom: none;
}

.menu-w.menu-layout-compact ul.main-menu > li.has-sub-menu > a:before {
  content: "\e91c";
  font-size: 7px;
  right: 5px;
}

.menu-w.menu-layout-compact ul.main-menu > li.sub-header {
  text-transform: uppercase;
  font-size: 0.72rem;
  letter-spacing: 1px;
  padding-top: 10px;
  padding-bottom: 0px;
}

.menu-w.menu-layout-compact ul.main-menu > li > a {
  font-size: 0.9rem;
}

.menu-w.menu-layout-compact ul.main-menu > li > a:focus {
  text-decoration: none;
}

@media (min-width: 1025px) {
  .menu-w.menu-layout-compact ul.main-menu > li > a:hover .icon-w {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  .menu-w.menu-layout-compact ul.main-menu > li > a:hover span {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}

.menu-w.menu-layout-compact ul.main-menu > li .icon-w {
  font-size: 18px;
  padding: 7px 10px 7px 0px;
  width: 40px;
}

.menu-w.menu-layout-compact ul.main-menu > li span {
  padding: 7px;
  padding-left: 5px;
}

.menu-w.menu-layout-compact ul.main-menu > li .icon-w + span {
  padding-left: 0px;
}

.menu-w.menu-layout-compact.sub-menu-style-inside ul.sub-menu {
  padding-left: 30px;
}

.menu-w.menu-layout-compact.sub-menu-style-inside ul.sub-menu:first-child {
  padding-top: 10px;
}

.menu-w.menu-layout-compact.sub-menu-style-inside ul.sub-menu li {
  border-bottom: none;
}

.menu-w.menu-layout-compact.sub-menu-style-inside ul.sub-menu li a {
  padding: 4px 0px 4px 10px;
}

.menu-w.menu-layout-compact.sub-menu-style-inside ul.sub-menu li a .badge {
  font-size: 0.72rem;
  padding: 2px 3px;
  border-radius: 2px;
  margin-left: 3px;
}

.menu-position-side.menu-side-right .top-bar {
  border-radius: 6px 0px 0px 0px;
}

.menu-position-side.menu-side-right .content-w {
  border-radius: 6px 0px 0px 6px;
}

.menu-position-side.menu-w {
  border-radius: 0px 0px 0px 0px;
}

.menu-position-side.menu-w.menu-side-left .logo-w:first-child {
  border-radius: 0px 0px 0px 0px;
}

.menu-position-side.menu-w.menu-side-right {
  border-radius: 0px 6px 6px 0px;
}

.menu-position-side.menu-w.menu-side-right .logo-w:first-child {
  border-radius: 0px 6px 0px 0px;
}

.menu-position-side.menu-w.menu-side-right.sub-menu-style-flyout .sub-menu-w, .menu-position-side.menu-w.menu-side-right.sub-menu-style-over .sub-menu-w {
  left: auto;
  right: 100%;
}

.menu-position-side.menu-w.menu-side-right.sub-menu-style-flyout .sub-menu-w {
  -webkit-transform: translate(-10%, -50%);
          transform: translate(-10%, -50%);
}

.menu-position-side.menu-w.menu-side-right.sub-menu-style-flyout .main-menu > li.has-sub-menu:hover .sub-menu-w {
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
}

.menu-position-side.menu-w.menu-side-right.color-style-transparent {
  border-right: none;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-side.menu-w.menu-side-right.color-style-transparent.color-scheme-dark {
  border-left-color: rgba(255, 255, 255, 0.05);
}

.menu-position-side.menu-w .menu-actions {
  -webkit-box-ordinal-group: 4;
          order: 3;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  padding: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-position-side.menu-w .menu-actions .messages-notifications, .menu-position-side.menu-w .menu-actions .top-icon {
  margin: 5px 0px;
}

.menu-position-side.menu-w .messages-notifications {
  margin: 0px 1rem;
  position: relative;
  font-size: 24px;
}

.menu-position-side.menu-w .messages-notifications .new-messages-count {
  background-color: #e65252;
  color: #fff;
  border-radius: 6px;
  font-weight: 500;
  position: absolute;
  top: -5px;
  right: -12px;
  padding: 4px 4px;
  vertical-align: middle;
  font-size: 0.72rem;
  line-height: 1;
}

.menu-position-side.menu-w .messages-notifications i {
  vertical-align: middle;
}

.menu-position-side.menu-w .top-icon {
  margin: 0px 1rem;
  font-size: 26px;
}

.menu-position-side.menu-w .top-icon i {
  vertical-align: middle;
}

.menu-position-side.menu-w .element-search {
  position: relative;
  margin: 0px 1rem;
  -webkit-box-ordinal-group: 2;
          order: 1;
}

.menu-position-side.menu-w .element-search:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 16px;
  content: "\e92c";
  color: rgba(0, 0, 0, 0.4);
}

.menu-position-side.menu-w .element-search input {
  border: none;
  box-shadow: none;
  border-radius: 30px;
  padding: 8px 15px 8px 40px;
  display: block;
  width: 100%;
  outline: none;
  border: none;
  box-shadow: none;
  background-color: rgba(91, 109, 136, 0.1);
}

.menu-position-side.menu-w .element-search input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-side.menu-w .element-search input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-side.menu-w .element-search input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-side.menu-w .element-search input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-side.menu-w .element-search input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-side.menu-w.menu-layout-full .menu-actions {
  -webkit-box-pack: space-evenly;
          justify-content: space-evenly;
  padding: 10px 0px;
}

.menu-position-side.menu-w.menu-layout-full .element-search {
  padding: 15px 0px;
}

.menu-position-side.menu-w.menu-layout-full .element-search,
.menu-position-side.menu-w.menu-layout-full .menu-actions {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-position-side.menu-w.menu-layout-full .element-search + ul.main-menu,
.menu-position-side.menu-w.menu-layout-full .menu-actions + ul.main-menu {
  padding-top: 0px;
}

.menu-position-side.menu-w.menu-layout-full .element-search + ul.main-menu > .sub-header:first-child,
.menu-position-side.menu-w.menu-layout-full .menu-actions + ul.main-menu > .sub-header:first-child {
  padding-top: 0px;
}

.menu-position-side.menu-w.menu-layout-compact .menu-actions {
  -webkit-box-pack: space-evenly;
          justify-content: space-evenly;
  padding: 10px 0px;
}

.menu-position-side.menu-w.menu-layout-compact .element-search {
  padding: 15px 0px;
}

.menu-position-side.menu-w.menu-layout-compact .element-search,
.menu-position-side.menu-w.menu-layout-compact .menu-actions {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-position-side.menu-w.menu-layout-compact .element-search + ul.main-menu,
.menu-position-side.menu-w.menu-layout-compact .menu-actions + ul.main-menu {
  padding-top: 20px;
}

.menu-position-side.menu-w.menu-layout-compact .element-search + ul.main-menu > .sub-header:first-child,
.menu-position-side.menu-w.menu-layout-compact .menu-actions + ul.main-menu > .sub-header:first-child {
  padding-top: 0px;
}

.menu-position-side.menu-w.menu-layout-mini {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}

.menu-position-side.menu-w.menu-layout-mini .logo-w {
  -webkit-box-pack: center;
          justify-content: center;
}

.menu-position-side.menu-w.menu-layout-mini .menu-actions {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  -webkit-box-ordinal-group: 2;
          order: 1;
}

.menu-position-side.menu-w.menu-layout-mini .element-search {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  padding: 10px 0px;
  margin: 0px;
  -webkit-box-ordinal-group: 3;
          order: 2;
}

.menu-position-side.menu-w.menu-layout-mini .element-search:before {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
  font-size: 22px;
  color: #fff;
}

.menu-position-side.menu-w.menu-layout-mini .element-search input {
  opacity: 0;
  background-color: transparent;
  width: 100%;
  color: transparent;
  cursor: pointer;
}

.menu-position-side.menu-w.menu-layout-mini .element-search input::-webkit-input-placeholder {
  color: transparent;
}

.menu-position-side.menu-w.menu-layout-mini .element-search input::-moz-placeholder {
  color: transparent;
}

.menu-position-side.menu-w.menu-layout-mini .element-search input:-ms-input-placeholder {
  color: transparent;
}

.menu-position-side.menu-w.menu-layout-mini .element-search input::-ms-input-placeholder {
  color: transparent;
}

.menu-position-side.menu-w.menu-layout-mini .element-search input::placeholder {
  color: transparent;
}

.menu-position-side.menu-w.menu-layout-mini .menu-actions {
  margin: 0px 10px;
  border-radius: 50px;
  border: none;
}

.menu-position-side.menu-w.menu-layout-mini .menu-actions + .element-search {
  margin-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-position-side.menu-w.menu-layout-mini.color-scheme-light .menu-actions {
  background-color: rgba(0, 0, 0, 0.03);
}

.menu-position-side.menu-w.menu-layout-mini.color-scheme-light .menu-actions + .element-search {
  border-top-color: rgba(0, 0, 0, 0.05);
}

.menu-position-side.menu-w.menu-layout-mini.color-scheme-light .element-search:before {
  color: #047bf8;
}

.menu-position-side.menu-w.menu-layout-mini.color-scheme-dark .menu-actions {
  background-color: rgba(0, 0, 0, 0.1);
}

.menu-position-side.menu-w.color-scheme-dark .logo-w {
  background-color: rgba(0, 0, 0, 0.15);
}

.menu-position-side.menu-w.color-scheme-dark .element-search:before {
  color: rgba(255, 255, 255, 0.4);
}

.menu-position-side.menu-w.color-scheme-dark .element-search input {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.menu-position-side.menu-w.color-scheme-dark .element-search input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.menu-position-side.menu-w.color-scheme-dark .element-search input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.menu-position-side.menu-w.color-scheme-dark .element-search input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.menu-position-side.menu-w.color-scheme-dark .element-search input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.menu-position-side.menu-w.color-scheme-dark .element-search input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.menu-position-side.menu-w.color-scheme-light.menu-layout-full .element-search,
.menu-position-side.menu-w.color-scheme-light.menu-layout-full .menu-actions, .menu-position-side.menu-w.color-scheme-light.menu-layout-mini .element-search,
.menu-position-side.menu-w.color-scheme-light.menu-layout-mini .menu-actions, .menu-position-side.menu-w.color-scheme-light.menu-layout-compact .element-search,
.menu-position-side.menu-w.color-scheme-light.menu-layout-compact .menu-actions {
  border-bottom-color: rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w {
  -webkit-box-flex: 0;
          flex: 0 0 100%;
  border-radius: 6px 6px 0px 0px;
  border-bottom: 0px solid rgba(0, 0, 0, 0.05);
  border-right: none;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
}

.menu-position-top.menu-w .logged-user-toggler-arrow {
  display: none;
}

.menu-position-top.menu-w.menu-has-selected-link {
  margin-bottom: 50px;
}

.menu-position-top.menu-w ul.main-menu > li > a:hover .icon-w {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.menu-position-top.menu-w .menu-actions {
  -webkit-box-ordinal-group: 4;
          order: 3;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}

.menu-position-top.menu-w .messages-notifications {
  margin: 0px 1rem;
  position: relative;
  font-size: 24px;
  -webkit-box-ordinal-group: 4;
          order: 3;
}

.menu-position-top.menu-w .messages-notifications .new-messages-count {
  background-color: #e65252;
  color: #fff;
  border-radius: 6px;
  font-weight: 500;
  position: absolute;
  top: -5px;
  right: -12px;
  padding: 4px 4px;
  vertical-align: middle;
  font-size: 0.72rem;
  line-height: 1;
}

.menu-position-top.menu-w .messages-notifications i {
  vertical-align: middle;
}

.menu-position-top.menu-w .top-icon {
  margin: 0px 1rem;
  font-size: 26px;
  -webkit-box-ordinal-group: 3;
          order: 2;
}

.menu-position-top.menu-w .top-icon i {
  vertical-align: middle;
}

.menu-position-top.menu-w ul.main-menu {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
          align-items: flex-start;
  margin-right: auto;
}

.menu-position-top.menu-w ul.main-menu > li {
  border-bottom: none;
}

.menu-position-top.menu-w .logged-user-w {
  -webkit-box-ordinal-group: 5;
          order: 4;
  border-bottom: none;
  padding: 5px 15px;
}

.menu-position-top.menu-w .logged-user-w .avatar-w {
  padding: 0px;
  border: none;
}

.menu-position-top.menu-w .logged-user-w .avatar-w img {
  width: 35px;
}

.menu-position-top.menu-w .logged-user-w .logged-user-i .logged-user-menu {
  left: auto;
  right: 0;
  top: -10px;
  width: auto;
  min-width: 230px;
}

.menu-position-top.menu-w .logged-user-w .logged-user-i .logged-user-menu ul {
  padding: 0px 10px;
}

.menu-position-top.menu-w .logged-user-w .logged-user-i .logged-user-menu ul a {
  white-space: nowrap;
}

.menu-position-top.menu-w .logged-user-w .logged-user-i .logged-user-menu .logged-user-avatar-info {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.menu-position-top.menu-w .logged-user-w .logged-user-i .logged-user-menu .logged-user-avatar-info .logged-user-info-w {
  padding: 5px 5px 5px 20px;
}

.menu-position-top.menu-w .logged-user-w .logged-user-i:hover .logged-user-menu {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}

.menu-position-top.menu-w .logo-w {
  border-bottom: none;
}

.menu-position-top.menu-w .element-search {
  position: relative;
  margin: 0px 1rem;
  -webkit-box-ordinal-group: 2;
          order: 1;
}

.menu-position-top.menu-w .element-search:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 16px;
  content: "\e92c";
  color: rgba(0, 0, 0, 0.4);
}

.menu-position-top.menu-w .element-search input {
  border: none;
  box-shadow: none;
  background-color: transparent;
  border-radius: 30px;
  padding: 8px 15px 8px 40px;
  display: block;
  width: 220px;
  outline: none;
  border: none;
  box-shadow: none;
}

.menu-position-top.menu-w .element-search input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-top.menu-w .element-search input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-top.menu-w .element-search input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-top.menu-w .element-search input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-top.menu-w .element-search input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.menu-position-top.menu-w.color-scheme-dark .element-search:before {
  color: rgba(255, 255, 255, 0.4);
}

.menu-position-top.menu-w.color-scheme-dark .element-search input {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.menu-position-top.menu-w.color-scheme-dark .element-search input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.menu-position-top.menu-w.color-scheme-dark .element-search input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.menu-position-top.menu-w.color-scheme-dark .element-search input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.menu-position-top.menu-w.color-scheme-dark .element-search input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.menu-position-top.menu-w.color-scheme-dark .element-search input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.menu-position-top.menu-w.color-scheme-dark .top-icon,
.menu-position-top.menu-w.color-scheme-dark .messages-notifications {
  color: rgba(255, 255, 255, 0.8);
}

.menu-position-top.menu-w.color-style-bright {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1c4cc3), to(#1c4cc3));
  background-image: linear-gradient(to bottom, #1c4cc3 0%, #1c4cc3 100%);
  background-repeat: repeat-x;
  box-shadow: none;
}

.menu-position-top.menu-w.color-style-transparent {
  border-bottom-width: 1px;
}

.menu-position-top.menu-w.color-scheme-light .element-search input {
  background-color: rgba(121, 138, 185, 0.07);
}

.menu-position-top.menu-w.menu-layout-mini .logo-w {
  padding: 10px 15px;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w.menu-layout-mini ul.main-menu {
  padding: 0px;
  -webkit-box-align: center;
          align-items: center;
}

.menu-position-top.menu-w.menu-layout-mini ul.main-menu > li {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w.menu-layout-mini ul.main-menu > li > a {
  padding: 15px 20px;
}

.menu-position-top.menu-w.menu-layout-mini ul.main-menu > li > a .icon-w {
  padding: 0px;
  font-size: 28px;
}

.menu-position-top.menu-w.menu-layout-full.sub-menu-style-flyout .sub-menu-w {
  padding-top: 20px;
}

.menu-position-top.menu-w.menu-layout-full.sub-menu-style-flyout .sub-menu-header {
  display: none;
}

.menu-position-top.menu-w.menu-layout-full > .logged-user-w > .logged-user-i > .logged-user-info-w {
  display: none !important;
}

.menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-inside ul.main-menu > li.active > a:before, .menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-over ul.main-menu > li.active > a:before, .menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-flyout ul.main-menu > li.active > a:before {
  color: #047bf8;
}

.menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-inside ul.main-menu > li.active, .menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-over ul.main-menu > li.active, .menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-flyout ul.main-menu > li.active {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-inside ul.main-menu > li.active span, .menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-over ul.main-menu > li.active span, .menu-position-top.menu-w.menu-layout-full.color-scheme-light.sub-menu-color-light.sub-menu-style-flyout ul.main-menu > li.active span {
  color: #047bf8;
}

.menu-position-top.menu-w.menu-layout-full.color-scheme-dark.sub-menu-color-light.sub-menu-style-inside ul.main-menu > li.active > a:before, .menu-position-top.menu-w.menu-layout-full.color-scheme-dark.sub-menu-color-light.sub-menu-style-over ul.main-menu > li.active > a:before, .menu-position-top.menu-w.menu-layout-full.color-scheme-dark.sub-menu-color-light.sub-menu-style-flyout ul.main-menu > li.active > a:before {
  color: #3E4B5B;
}

.menu-position-top.menu-w.menu-layout-full .side-menu-magic {
  display: none;
}

.menu-position-top.menu-w.menu-layout-full .logo-w {
  border-right: 1px solid rgba(255, 255, 255, 0.05);
  margin-right: 20px;
  padding: 10px 20px;
}

.menu-position-top.menu-w.menu-layout-full .logo-w .logo {
  white-space: nowrap;
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu {
  padding: 0px;
  -webkit-box-align: center;
          align-items: center;
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu .sub-header {
  display: none;
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li > a {
  padding: 15px 20px;
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li > a .icon-w {
  display: none;
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li > a span {
  font-size: 0.81rem;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 2px;
  padding: 0px;
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li > a:hover:before {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li > a:hover span {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li.active > a:before {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li.active > a span {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li.has-sub-menu > a {
  padding-right: 30px;
}

.menu-position-top.menu-w.menu-layout-full ul.main-menu > li.has-sub-menu > a:before {
  right: 13px;
  font-size: 7px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-position-top.menu-w.menu-layout-compact .logged-user-w {
  border: none !important;
}

.menu-position-top.menu-w.menu-layout-compact .logged-user-w .logged-user-info-i > .logged-user-info-w {
  display: none !important;
}

.menu-position-top.menu-w.menu-layout-compact .logged-user-w .avatar-w {
  vertical-align: middle;
}

.menu-position-top.menu-w.menu-layout-compact .logo-w {
  padding: 10px;
  padding-right: 20px;
}

.menu-position-top.menu-w.menu-layout-compact .logo-w .logo {
  white-space: nowrap;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu {
  padding: 0px 10px;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu .sub-header {
  display: none;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li.has-sub-menu > a {
  padding-right: 25px;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li.has-sub-menu > a:before {
  right: 15px;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li .sub-menu-w .sub-menu-header {
  display: none;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li > a {
  padding: 8px 12px;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li > a:hover .icon-w {
  -webkit-transform: none;
          transform: none;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li > a:hover span {
  -webkit-transform: none;
          transform: none;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li > a:hover:before {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li.active a:before {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li .icon-w {
  width: auto;
}

.menu-position-top.menu-w.menu-layout-compact ul.main-menu > li span {
  white-space: nowrap;
}

.menu-position-top.menu-w.menu-layout-compact .side-menu-magic {
  display: none;
}

.menu-position-top.menu-w.menu-layout-compact.sub-menu-style-flyout ul.main-menu > li .sub-menu-w, .menu-position-top.menu-w.menu-layout-compact.sub-menu-style-over ul.main-menu > li .sub-menu-w {
  padding-top: 20px;
}

.menu-position-top.menu-w.menu-layout-compact.color-scheme-dark ul.main-menu > li, .menu-position-top.menu-w.menu-layout-compact.color-scheme-bright ul.main-menu > li {
  border-right-color: rgba(255, 255, 255, 0.1);
}

.menu-position-top.menu-w ul.main-menu > li.selected {
  position: static;
  background-color: #fff;
}

.menu-position-top.menu-w ul.main-menu > li.selected .icon-w {
  color: #047bf8;
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w {
  -webkit-transform: none !important;
          transform: none !important;
  border-radius: 0px !important;
  position: absolute;
  top: 100%;
  left: 0px;
  right: 0px;
  padding: 0px 10px !important;
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-header,
.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-icon {
  display: none;
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-i {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: start;
          justify-content: flex-start;
  overflow-x: auto;
  -webkit-box-align: center;
          align-items: center;
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
          align-items: center;
  margin: 0px;
  padding: 0px;
  min-width: auto;
  border: none;
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu li {
  border-bottom: none;
  min-width: auto;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu li a {
  white-space: nowrap;
  padding: 15px 15px;
  font-size: 0.9rem;
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu li a:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.menu-position-top.menu-w ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu li a:before {
  display: none;
}

.menu-position-top.menu-w.selected-menu-color-light ul.main-menu > li.selected {
  border-right-color: rgba(0, 0, 0, 0.05) !important;
  background-color: transparent !important;
}

.menu-position-top.menu-w.selected-menu-color-light ul.main-menu > li.selected > a {
  background-color: #fff;
}

.menu-position-top.menu-w.selected-menu-color-light ul.main-menu > li.selected > a:before {
  color: #3E4B5B;
}

.menu-position-top.menu-w.selected-menu-color-light ul.main-menu > li.selected > a .icon-w {
  color: #047bf8 !important;
}

.menu-position-top.menu-w.selected-menu-color-light ul.main-menu > li.selected > a span {
  color: #3E4B5B;
}

.menu-position-top.menu-w.selected-menu-color-light ul.main-menu > li.selected .sub-menu-w {
  background-color: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.03);
}

.menu-position-top.menu-w.selected-menu-color-light ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu li a {
  color: #3E4B5B;
}

.menu-position-top.menu-w.selected-menu-color-light.color-scheme-light .sub-menu-w {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w.selected-menu-color-dark ul.main-menu > li.selected {
  border-right-color: #323c58;
}

.menu-position-top.menu-w.selected-menu-color-dark ul.main-menu > li.selected > a {
  background-color: #323c58;
}

.menu-position-top.menu-w.selected-menu-color-dark ul.main-menu > li.selected > a .icon-w {
  color: #fff !important;
}

.menu-position-top.menu-w.selected-menu-color-dark ul.main-menu > li.selected > a span {
  color: #fff;
}

.menu-position-top.menu-w.selected-menu-color-dark ul.main-menu > li.selected > a:before {
  color: #fff;
}

.menu-position-top.menu-w.selected-menu-color-dark ul.main-menu > li.selected .sub-menu-w {
  background-color: #323c58;
  box-shadow: none;
}

.menu-position-top.menu-w.selected-menu-color-dark ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu li a {
  color: #fff;
}

.menu-position-top.menu-w.selected-menu-color-bright ul.main-menu > li.selected {
  border-right-color: #1b55e2;
}

.menu-position-top.menu-w.selected-menu-color-bright ul.main-menu > li.selected > a {
  background-color: #1b55e2;
}

.menu-position-top.menu-w.selected-menu-color-bright ul.main-menu > li.selected > a .icon-w {
  color: #fff !important;
}

.menu-position-top.menu-w.selected-menu-color-bright ul.main-menu > li.selected > a span {
  color: #fff;
}

.menu-position-top.menu-w.selected-menu-color-bright ul.main-menu > li.selected > a:before {
  color: #fff;
}

.menu-position-top.menu-w.selected-menu-color-bright ul.main-menu > li.selected .sub-menu-w {
  background-color: #1b55e2;
  box-shadow: none;
}

.menu-position-top.menu-w.selected-menu-color-bright ul.main-menu > li.selected .sub-menu-w .sub-menu-i ul.sub-menu li a {
  color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-over .sub-menu-w {
  left: 0px;
  top: 100%;
}

.menu-position-top.menu-w.sub-menu-style-over.sub-menu-color-light ul.main-menu > li.active {
  border-right-color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-over.sub-menu-color-dark ul.main-menu > li.active {
  border-right-color: #323c58;
}

.menu-position-top.menu-w.sub-menu-style-over.sub-menu-color-bright ul.main-menu > li.active {
  border-right-color: #1b55e2;
}

.menu-position-top.menu-w.sub-menu-style-inside .sub-menu-w {
  position: absolute;
  top: 100%;
  left: 0px;
  right: 0px;
  padding: 0px 10px;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
}

.menu-position-top.menu-w.sub-menu-style-inside .sub-menu-w .sub-menu-i {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: start;
          justify-content: flex-start;
  overflow-x: auto;
  -webkit-box-align: center;
          align-items: center;
}

.menu-position-top.menu-w.sub-menu-style-inside .sub-menu-w .sub-menu-i ul.sub-menu {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
          align-items: center;
  margin: 0px;
  padding: 0px;
  border: none;
}

.menu-position-top.menu-w.sub-menu-style-inside .sub-menu-w .sub-menu-i ul.sub-menu li {
  border-bottom: none;
}

.menu-position-top.menu-w.sub-menu-style-inside .sub-menu-w .sub-menu-i ul.sub-menu li a {
  white-space: nowrap;
  padding: 15px 15px;
  font-size: 0.9rem;
}

.menu-position-top.menu-w.sub-menu-style-inside .sub-menu-w .sub-menu-i ul.sub-menu li a:hover {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.menu-position-top.menu-w.sub-menu-style-inside .sub-menu-w .sub-menu-i ul.sub-menu li a:before {
  display: none;
}

.menu-position-top.menu-w.sub-menu-style-inside ul.main-menu > li.active {
  background-color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-inside ul.main-menu > li.active .icon-w {
  color: #047bf8;
}

.menu-position-top.menu-w.sub-menu-style-inside ul.main-menu > li.active .sub-menu-w {
  visibility: visible;
  opacity: 1;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-light ul.main-menu > li.active {
  background-color: #fff;
  border-right-color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-light ul.main-menu > li.active .icon-w {
  color: #047bf8;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-light ul.main-menu > li.active span {
  color: #3E4B5B;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-light .sub-menu-w {
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-light .sub-menu-w ul.sub-menu li {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-light .sub-menu-w ul.sub-menu li a {
  color: #3E4B5B;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-dark ul.main-menu > li.active {
  background-color: #323c58;
  border-right-color: #323c58;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-dark ul.main-menu > li.active .icon-w {
  color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-dark .sub-menu-w {
  background-color: #323c58;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-dark .sub-menu-w ul.sub-menu li {
  border-right: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-dark .sub-menu-w ul.sub-menu li a {
  color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-bright ul.main-menu > li.active {
  background-color: #1b55e2;
  border-right-color: #1b55e2;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-bright ul.main-menu > li.active .icon-w {
  color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-bright .sub-menu-w {
  background-color: #1b55e2;
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-bright .sub-menu-w ul.sub-menu li {
  border-right: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-position-top.menu-w.sub-menu-style-inside.sub-menu-color-bright .sub-menu-w ul.sub-menu li a {
  color: #fff;
}

.menu-position-top.menu-w.sub-menu-style-flyout .main-menu > li.has-sub-menu.active .sub-menu-w {
  -webkit-transform: translate(50%, 0);
          transform: translate(50%, 0);
}

.menu-position-top.menu-w.sub-menu-style-flyout .sub-menu-w {
  right: 50%;
  top: 100%;
  -webkit-transform: translate(50%, 20%);
          transform: translate(50%, 20%);
}

.menu-position-top.menu-w.menu-with-image {
  background-color: transparent;
  background-size: cover;
  background-position: center center;
  flex-wrap: wrap;
  padding-top: 5px;
  border-bottom: none;
  box-shadow: none;
}

.menu-position-top.menu-w.menu-with-image .top-icon > .os-icon,
.menu-position-top.menu-w.menu-with-image .messages-notifications > .os-icon {
  color: #fff;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.menu-position-top.menu-w.menu-with-image > .element-search {
  margin-left: auto;
}

.menu-position-top.menu-w.menu-with-image .logo-w {
  padding-left: 20px;
}

.menu-position-top.menu-w.menu-with-image .logged-user-w {
  -webkit-box-ordinal-group: 5;
          order: 4;
}

.menu-position-top.menu-w.menu-with-image .logged-user-w > .logged-user-i > .avatar-w {
  border: 1px solid rgba(255, 255, 255, 0.7);
  padding: 4px;
}

.menu-position-top.menu-w.menu-with-image ul.main-menu {
  -webkit-box-ordinal-group: 7;
          order: 6;
  margin-top: 20px;
  -webkit-box-flex: 0;
          flex: 0 0 100%;
}

.menu-position-top.menu-w.menu-with-image ul.main-menu .icon-w {
  color: #fff;
}

.menu-position-top.menu-w.menu-with-image .menu-page-header {
  display: block;
  -webkit-box-flex: 0;
          flex: 0 0 100%;
  padding: 30px 20px;
  margin-bottom: 0px;
  -webkit-box-ordinal-group: 6;
          order: 5;
  margin-top: 30px;
  color: #fff;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.menu-position-top.menu-w.menu-with-image h1.menu-page-header {
  font-size: 3.25rem;
}

.menu-position-top.menu-w.menu-with-image.color-scheme-light .top-icon > .os-icon,
.menu-position-top.menu-w.menu-with-image.color-scheme-light .messages-notifications > .os-icon {
  color: #047bf8;
  text-shadow: none;
}

.menu-position-top.menu-w.menu-with-image.color-scheme-light .logo-w .logo-label {
  color: #fff;
}

.menu-position-top.menu-w.menu-with-image.color-scheme-light ul.main-menu .icon-w {
  color: #047bf8;
}

/*
  #4. Mobile Menu
*/
.menu-mobile {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
  position: relative;
  display: none;
  /* Styles for sub menu */
}

.menu-mobile .mm-logo-buttons-w {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
  padding: 1rem;
}

.menu-mobile .mm-logo-buttons-w .mm-logo {
  vertical-align: middle;
  text-align: left;
  text-decoration: none;
}

.menu-mobile .mm-logo-buttons-w .mm-logo img {
  width: 40px;
  display: inline-block;
}

.menu-mobile .mm-logo-buttons-w .mm-logo span {
  display: inline-block;
  color: #3E4B5B;
  margin-left: 1rem;
}

.menu-mobile .mm-logo-buttons-w .mm-logo:hover {
  text-decoration: none;
}

.menu-mobile .mm-logo-buttons-w .mm-buttons {
  display: -webkit-box;
  display: flex;
}

.menu-mobile .mm-logo-buttons-w .content-panel-open {
  display: none;
  font-size: 18px;
  cursor: pointer;
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  color: #047bf8;
}

.menu-mobile .mm-logo-buttons-w .mobile-menu-trigger {
  vertical-align: middle;
  text-align: right;
  font-size: 18px;
  cursor: pointer;
  color: #047bf8;
}

.menu-mobile .menu-and-user {
  display: none;
  padding-bottom: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.menu-mobile .logged-user-w {
  text-align: left;
  padding: 1rem;
  padding-left: 35px;
}

.menu-mobile .logged-user-w .avatar-w {
  vertical-align: middle;
}

.menu-mobile .logged-user-w .avatar-w img {
  width: 40px;
}

.menu-mobile .logged-user-w .logged-user-info-w {
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
}

.menu-mobile .mobile-menu-magic {
  background-image: linear-gradient(-154deg, #1643A3 8%, #2E1170 90%);
  border-radius: 6px;
  padding: 40px;
  text-align: center;
  margin: 10px 20px;
}

.menu-mobile .mobile-menu-magic:last-child {
  margin-bottom: 0px;
}

.menu-mobile .mobile-menu-magic h1, .menu-mobile .mobile-menu-magic h2, .menu-mobile .mobile-menu-magic h3, .menu-mobile .mobile-menu-magic h4, .menu-mobile .mobile-menu-magic h5 {
  color: #fff;
  margin-bottom: 5px;
}

.menu-mobile .mobile-menu-magic p {
  color: rgba(255, 255, 255, 0.6);
}

.menu-mobile .mobile-menu-magic .btn-w {
  margin-top: 15px;
}

.menu-mobile .mobile-menu-magic .btn-white, .menu-mobile .mobile-menu-magic .all-wrapper .fc-button, .all-wrapper .menu-mobile .mobile-menu-magic .fc-button {
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
}

.menu-mobile ul.main-menu {
  list-style: none;
  padding: 10px 1rem;
  margin-bottom: 0px;
}

.menu-mobile ul.main-menu > li {
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-mobile ul.main-menu > li:last-child {
  border-bottom: none;
}

.menu-mobile ul.main-menu > li.has-sub-menu > a:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e91c";
  font-size: 7px;
  color: rgba(0, 0, 0, 0.5);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 10px;
}

.menu-mobile ul.main-menu > li.has-sub-menu.active .sub-menu {
  display: block;
}

.menu-mobile ul.main-menu > li > a {
  color: #3E4B5B;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  position: relative;
}

.menu-mobile ul.main-menu > li > a:focus {
  text-decoration: none;
}

.menu-mobile ul.main-menu > li > a:hover {
  text-decoration: none;
}

@media (min-width: 1025px) {
  .menu-mobile ul.main-menu > li > a:hover .icon-w {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  .menu-mobile ul.main-menu > li > a:hover span {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}

.menu-mobile ul.main-menu > li .icon-w {
  color: #0073ff;
  font-size: 27px;
  display: block;
  padding: 1rem;
  width: 80px;
  text-align: center;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-mobile ul.main-menu > li span {
  padding: 1rem;
  display: block;
  -webkit-box-flex: 1;
          flex: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu-mobile ul.main-menu > li .icon-w + span {
  padding-left: 0px;
}

.menu-mobile ul.sub-menu {
  padding: 1rem 0px;
  padding-left: 55px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  display: none;
}

.menu-mobile ul.sub-menu li {
  padding: 0.4rem 10px 0.4rem 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.menu-mobile ul.sub-menu li:last-child {
  border-bottom: none;
}

.menu-mobile ul.sub-menu li a {
  font-size: 0.81rem;
}

.menu-mobile.color-scheme-dark {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#3D4D75), to(#31395B));
  background-image: linear-gradient(to bottom, #3D4D75 0%, #31395B 100%);
  background-repeat: repeat-x;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1c4cc3), to(#1c2e7b));
  background-image: linear-gradient(to bottom, #1c4cc3 0%, #1c2e7b 100%);
  background-repeat: repeat-x;
  color: rgba(255, 255, 255, 0.9);
}

.menu-mobile.color-scheme-dark .side-menu-magic {
  background-image: linear-gradient(-154deg, #6d16a3 8%, #5211e6 90%);
}

.menu-mobile.color-scheme-dark ul.sub-menu li {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-mobile.color-scheme-dark ul.sub-menu li a {
  color: #fff;
}

.menu-mobile.color-scheme-dark ul.main-menu .icon-w {
  color: #babed0;
}

.menu-mobile.color-scheme-dark ul.main-menu > li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.menu-mobile.color-scheme-dark ul.main-menu > li > a {
  color: #fff;
}

.menu-mobile.color-scheme-dark ul.main-menu > li > a:before {
  color: #fff;
}

.menu-mobile.color-scheme-dark .sub-menu-w {
  box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.2);
}

.menu-mobile.color-scheme-dark .mm-logo-buttons-w {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-mobile.color-scheme-dark .mm-logo-buttons-w span {
  color: #fff;
}

.menu-mobile.color-scheme-dark .mm-logo-buttons-w .content-panel-open {
  border-right-color: rgba(255, 255, 255, 0.1);
}

.menu-mobile.color-scheme-dark .mm-logo-buttons-w .content-panel-open,
.menu-mobile.color-scheme-dark .mm-logo-buttons-w .mobile-menu-trigger {
  color: #fff;
}

.menu-mobile.color-scheme-dark .logged-user-w {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.menu-mobile.color-scheme-dark .logged-user-w .avatar-w {
  border-color: #fff;
}

.menu-mobile.color-scheme-dark .logged-user-w .logged-user-role {
  color: rgba(255, 255, 255, 0.4);
}

.menu-mobile.color-scheme-dark .mobile-menu-magic {
  background-image: linear-gradient(-154deg, #6d16a3 8%, #5211e6 90%);
}

/* 9. Styles for all element blocks */
/* 

  # -- STYLES NAVIGATION:

  #1. Element Wrapper Styles
  #2. Tablo Element
  #3. Charts & Legends
  #4. Profile Tiles
  #5. Users list element
  #6. Tabs Controls
  #7. Activity Boxes
  #8. Note box
  #9. Steps
  #10. Invoice
  #11. Error Page
  #12. Authorization styles
  #13. Timed Activities Element
  #14. Progress Bar Element
  #15. Balances
  #16. Call to action box
  #17. Post Box
  #18. Todo List Widget


*/
/*
 #1. Element Wrapper Styles
*/
.element-wrapper {
  padding-bottom: 3rem;
}

.element-wrapper .btn + .btn, .element-wrapper .all-wrapper .fc-button + .btn, .all-wrapper .element-wrapper .fc-button + .btn, .element-wrapper .all-wrapper .btn + .fc-button, .all-wrapper .element-wrapper .btn + .fc-button, .element-wrapper .all-wrapper .fc-button + .fc-button, .all-wrapper .element-wrapper .fc-button + .fc-button {
  margin-left: 1rem;
}

.element-wrapper.compact {
  padding-bottom: 2rem;
}

.element-wrapper.folded {
  padding-bottom: 2rem;
}

.element-wrapper.folded .element-header {
  margin-bottom: 0px;
}

.element-wrapper .element-info {
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.element-wrapper .element-info .element-info-with-icon {
  margin-bottom: 0px;
}

.element-wrapper .element-info-with-icon {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  margin-bottom: 2rem;
}

.element-wrapper .element-info-with-icon .element-info-icon {
  -webkit-box-flex: 0;
          flex: 0 0 70px;
  color: #047bf8;
  font-size: 30px;
}

.element-wrapper .element-info-with-icon.smaller .element-info-icon {
  -webkit-box-flex: 0;
          flex: 0 0 50px;
  font-size: 20px;
}

.element-wrapper .element-info-with-icon.smaller .element-info-text .element-inner-header {
  margin-bottom: 0px;
}

.element-wrapper .element-actions {
  float: right;
  position: relative;
  z-index: 2;
  margin-top: -0.2rem;
}

.element-wrapper .element-actions select.form-control-sm,
.element-wrapper .element-actions input.form-control-sm {
  height: 1.75rem;
}

.element-wrapper .element-actions .form-control + .form-control {
  margin-left: 10px;
}

.element-wrapper .element-actions .btn + .btn, .element-wrapper .element-actions .all-wrapper .fc-button + .btn, .all-wrapper .element-wrapper .element-actions .fc-button + .btn, .element-wrapper .element-actions .all-wrapper .btn + .fc-button, .all-wrapper .element-wrapper .element-actions .btn + .fc-button, .element-wrapper .element-actions .all-wrapper .fc-button + .fc-button, .all-wrapper .element-wrapper .element-actions .fc-button + .fc-button {
  margin-left: 10px;
}

.element-wrapper .element-actions label {
  margin-right: 7px;
  color: rgba(90, 99, 126, 0.49);
}

.element-wrapper .element-actions.actions-only {
  margin-top: 0px;
}

.element-wrapper .element-actions .element-action {
  text-decoration: none;
  color: rgba(90, 99, 126, 0.49);
}

.element-wrapper .element-actions .element-action:hover {
  color: #3E4B5B;
}

.element-wrapper .element-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  position: relative;
  z-index: 1;
}

.element-wrapper .element-header:after {
  content: "";
  background-color: #047bf8;
  width: 25px;
  height: 4px;
  border-radius: 0px;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0px;
}

.element-wrapper .element-inner-header {
  margin-bottom: 0.5rem;
  margin-top: 0px;
  display: block;
}

.element-wrapper .element-inner-desc {
  color: #999;
  font-weight: 300;
  font-size: 0.81rem;
  display: block;
}

.element-wrapper .element-search {
  position: relative;
}

.element-wrapper .element-search:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 15px;
  top: 48%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 20px;
  content: "\e92c";
  color: rgba(0, 0, 0, 0.2);
}

.element-wrapper .element-search input {
  border: none;
  box-shadow: none;
  background-color: #f1f1f1;
  border-radius: 30px;
  padding: 10px 15px 10px 50px;
  display: block;
  width: 100%;
  outline: none;
}

.element-wrapper .element-search input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.element-wrapper .element-search input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.element-wrapper .element-search input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.element-wrapper .element-search input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.element-wrapper .element-search input::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.box-style, .user-profile, .element-box, .invoice-w, .big-error-w, .activity-boxes-w .activity-box, .post-box, .projects-list .project-box, .order-box, .ecommerce-customer-info {
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
}

.element-box, .invoice-w, .big-error-w {
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
}

.element-box.less-padding, .less-padding.invoice-w, .less-padding.big-error-w {
  padding: 1rem;
}

.element-box .os-tabs-controls, .invoice-w .os-tabs-controls, .big-error-w .os-tabs-controls {
  margin-left: -2rem;
  margin-right: -2rem;
}

.element-box .os-tabs-controls .nav, .invoice-w .os-tabs-controls .nav, .big-error-w .os-tabs-controls .nav {
  padding-left: 2rem;
  padding-right: 2rem;
}

.element-box .centered-header, .invoice-w .centered-header, .big-error-w .centered-header {
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  margin-bottom: 1rem;
}

.element-box .element-box-header, .invoice-w .element-box-header, .big-error-w .element-box-header {
  color: #046fdf;
  margin-bottom: 1rem;
}

.element-box-content + .form-header {
  margin-top: 2rem;
}

.element-box + .element-box, .invoice-w + .element-box, .big-error-w + .element-box, .element-box + .invoice-w, .invoice-w + .invoice-w, .big-error-w + .invoice-w, .element-box + .big-error-w, .invoice-w + .big-error-w, .big-error-w + .big-error-w {
  margin-top: 2rem;
}

.element-box-tp .input-search-w,
.element-box .input-search-w,
.invoice-w .input-search-w,
.big-error-w .input-search-w {
  margin-bottom: 1rem;
}

/*
 #2. Tablo Element
*/
.el-tablo {
  display: block;
}

.el-tablo:not(.centered) {
  padding-right: 5px;
}

.el-tablo .label {
  display: block;
  font-size: 0.63rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 1px;
}

.el-tablo .value {
  font-size: 2.43rem;
  font-weight: 500;
  font-family: "Avenir Next W01", "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  letter-spacing: 1px;
  line-height: 1.2;
  display: inline-block;
  vertical-align: middle;
}

.el-tablo .trending {
  padding: 3px 10px;
  border-radius: 30px;
  display: inline-block;
  font-size: 0.81rem;
  vertical-align: middle;
  margin-left: 10px;
}

.el-tablo .trending .os-icon {
  margin-left: 2px;
  vertical-align: middle;
  font-size: 14px;
}

.el-tablo .trending span {
  display: inline-block;
  vertical-align: middle;
}

.el-tablo .trending-up {
  color: #fff;
  background-color: #24b314;
}

.el-tablo .trending-down {
  color: #fff;
  background-color: #e65252;
}

.el-tablo .trending-up-basic {
  color: #24b314;
  padding: 0px;
}

.el-tablo .trending-down-basic {
  color: #e65252;
  padding: 0px;
}

.el-tablo.trend-in-corner {
  position: relative;
}

.el-tablo.trend-in-corner .trending {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 2px 4px;
  border-radius: 4px;
  line-height: 1.2;
  font-size: 0.63rem;
}

.el-tablo.trend-in-corner .trending i {
  font-size: 12px;
}

.el-tablo.centered {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.el-tablo.centered.padded {
  padding-left: 10px;
  padding-right: 10px;
}

.el-tablo.padded {
  padding: 2rem;
}

.el-tablo.bigger .value {
  font-size: 3.33rem;
}

.el-tablo.bigger .label {
  font-size: 0.9rem;
}

.el-tablo.smaller .value {
  font-size: 1.71rem;
}

.el-tablo.smaller .label {
  font-size: 0.63rem;
  letter-spacing: 2px;
}

.el-tablo.smaller.trend-in-corner .trending {
  top: 3px;
  right: 3px;
}

.el-tablo.highlight .value {
  color: #047bf8;
}

.el-tablo.bold-label .label {
  text-transform: none;
  font-size: 0.99rem;
  letter-spacing: 0px;
}

a.el-tablo {
  text-decoration: none;
  display: block;
  color: #3E4B5B;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

a.el-tablo .value {
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

a.el-tablo .label {
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

a.el-tablo:hover {
  -webkit-transform: translateY(-5px) scale(1.02);
          transform: translateY(-5px) scale(1.02);
  box-shadow: 0px 5px 12px rgba(126, 142, 177, 0.2);
}

a.el-tablo:hover .value {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  color: #047bf8;
}

a.el-tablo:hover .label {
  color: #3395fc;
}

a.el-tablo:hover.centered .value {
  -webkit-transform: scale(1.1) translateY(-3px);
          transform: scale(1.1) translateY(-3px);
}

a.el-tablo:hover .label {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.el-buttons-list .btn, .el-buttons-list .all-wrapper .fc-button, .all-wrapper .el-buttons-list .fc-button {
  margin: 0px;
  margin-bottom: 0.5rem;
}

.el-buttons-list .btn + .btn, .el-buttons-list .all-wrapper .fc-button + .btn, .all-wrapper .el-buttons-list .fc-button + .btn, .el-buttons-list .all-wrapper .btn + .fc-button, .all-wrapper .el-buttons-list .btn + .fc-button, .el-buttons-list .all-wrapper .fc-button + .fc-button, .all-wrapper .el-buttons-list .fc-button + .fc-button {
  margin-left: 0px;
}

.el-buttons-list.full-width .btn, .el-buttons-list.full-width .all-wrapper .fc-button, .all-wrapper .el-buttons-list.full-width .fc-button {
  display: block;
}

.el-tablo + .el-chart-w {
  margin-top: 1rem;
}

/*
 #3. Charts & Legend
*/
.el-chart-w {
  position: relative;
  text-align: center;
}

.el-chart-w .inside-donut-chart-label {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  font-size: 0.63rem;
  text-transform: uppercase;
  z-index: 1;
}

.el-chart-w .inside-donut-chart-label strong {
  display: block;
  font-size: 1.98rem;
  line-height: 1.1;
}

.el-chart-w .inside-donut-chart-label span {
  display: block;
  color: rgba(0, 0, 0, 0.4);
}

.el-chart-w canvas {
  position: relative;
  z-index: 2;
  max-width: 100%;
}

.el-chart-w + .el-legend {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 1.5rem;
  margin-top: 2rem;
}

.el-legend .legend-value-w {
  margin-bottom: 0.5rem;
}

.el-legend.condensed {
  margin-top: 15px;
}

.el-legend.condensed .legend-value-w {
  white-space: nowrap;
}

.el-legend.condensed .legend-value-w .legend-pin {
  vertical-align: top;
  margin-top: 3px;
  margin-right: 2px;
}

.el-legend .legend-pin {
  width: 10px;
  height: 10px;
  border-radius: 6px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.7rem;
}

.el-legend .legend-pin.legend-pin-squared {
  border-radius: 0px;
  width: 7px;
  height: 7px;
}

.el-legend .legend-value {
  display: inline-block;
  vertical-align: middle;
}

.el-legend .legend-value span {
  display: block;
  line-height: 1;
}

.el-legend .legend-value .legend-sub-value {
  color: rgba(90, 99, 126, 0.49);
  font-size: 0.63rem;
}

/*
 #4. Profile Tile
*/
.profile-tile {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.profile-tile .profile-tile-box {
  width: 110px;
  text-align: center;
  border-radius: 6px;
  padding: 1.2rem 0.5rem 0.5rem;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
  text-decoration: none;
  color: #3E4B5B;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  display: block;
}

.profile-tile .profile-tile-box:hover {
  -webkit-transform: translateY(-5px) scale(1.02);
          transform: translateY(-5px) scale(1.02);
  box-shadow: 0px 5px 12px rgba(126, 142, 177, 0.2);
}

.profile-tile .profile-tile-box.faded {
  background-color: #E9ECF3;
  border: 1px dashed #ACBEDA;
  box-shadow: none;
}

.profile-tile .pt-avatar-w {
  display: inline-block;
  border-radius: 50px;
  overflow: hidden;
}

.profile-tile .pt-avatar-w img {
  width: 60px;
  height: auto;
}

.profile-tile .pt-user-name {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.63rem;
  text-transform: uppercase;
}

.profile-tile .pt-new-icon {
  background-color: #047bf8;
  color: #fff;
  vertical-align: middle;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  border-radius: 50%;
  line-height: 1;
  height: 35px;
  width: 35px;
  margin-bottom: 10px;
}

.profile-tile .pt-new-icon i {
  line-height: 35px;
  display: inline-block;
  vertical-align: middle;
}

.profile-tile .profile-tile-meta {
  -webkit-box-flex: 1;
          flex: 1;
  padding-left: 30px;
}

.profile-tile .profile-tile-meta ul {
  list-style: none;
  font-size: 0.63rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
  margin: 0px;
  padding: 0px;
}

.profile-tile .profile-tile-meta ul li {
  margin-bottom: 3px;
}

.profile-tile .profile-tile-meta ul strong {
  color: #3E4B5B;
  margin-left: 5px;
}

.profile-tile .profile-tile-meta ul strong a {
  color: #3E4B5B;
  text-decoration: none;
  border-bottom: 1px solid #3E4B5B;
}

.profile-tile .pt-btn {
  margin-top: 10px;
}

.inline-profile-tiles .profile-tile {
  -webkit-box-flex: 0;
          flex: 0 1 130px;
  margin-bottom: 10px;
  padding-bottom: 0px;
  border-bottom: none;
  padding: 0px;
}

.inline-profile-tiles .profile-tile .pt-avatar-w img {
  width: 40px;
  height: auto;
}

.inline-profile-tiles .profile-tile .profile-tile-box {
  display: block;
  width: 100%;
  padding: 15px;
  padding-bottom: 10px;
}

.inline-profile-tiles .profile-tile .pt-user-name {
  border-top: none;
  padding-top: 0px;
  text-transform: none;
  font-size: 0.9rem;
  line-height: 1.2;
  margin-top: 5px;
}

.status-pill {
  width: 12px;
  height: 12px;
  border-radius: 30px;
  background-color: #eee;
  display: inline-block;
  vertical-align: middle;
}

.status-pill.yellow {
  background-color: #f8bc34;
}

.status-pill.red {
  background-color: #c21a1a;
}

.status-pill.green {
  background-color: #71c21a;
}

.status-pill.smaller {
  width: 8px;
  height: 8px;
}

.status-pill + span {
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
}

/*
 #5. Users list element
*/
.users-list-w .user-w {
  display: -webkit-box;
  display: flex;
  padding: 12px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

.users-list-w .user-w .user-avatar-w {
  width: 50px;
}

.users-list-w .user-w .user-avatar-w .user-avatar {
  border-radius: 40px;
  overflow: hidden;
}

.users-list-w .user-w .user-avatar-w .user-avatar img {
  max-width: 100%;
  height: auto;
}

.users-list-w .user-w.with-status .user-avatar-w {
  position: relative;
}

.users-list-w .user-w.with-status .user-avatar-w:before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 2px;
  right: 2px;
  border-radius: 10px;
  box-shadow: 0px 0px 0px 3px #fff;
}

.users-list-w .user-w.with-status.status-green .user-avatar-w:before {
  background-color: #24b314;
}

.users-list-w .user-w.with-status.status-red .user-avatar-w:before {
  background-color: #e65252;
}

.users-list-w .user-w .user-name {
  padding-left: 20px;
  -webkit-box-flex: 1;
          flex: 1;
}

.users-list-w .user-w .user-title {
  margin-bottom: 4px;
}

.users-list-w .user-w .user-role {
  font-size: 0.63rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
}

.users-list-w .user-w .user-action {
  width: 50px;
  color: #047bf8;
  font-size: 18px;
}

/*
 #6. Tabs Controls
*/
.os-tabs-controls {
  margin-bottom: 2rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

.os-tabs-controls .nav-pills {
  -webkit-box-align: center;
          align-items: center;
  border-bottom: 1px solid #dee2e6;
}

.os-tabs-controls .nav-tabs {
  -webkit-box-flex: 1;
          flex: 1;
}

.os-tabs-controls.os-tabs-complex .nav-tabs {
  -webkit-box-align: stretch;
          align-items: stretch;
}

.os-tabs-controls.os-tabs-complex .nav-item {
  white-space: nowrap;
  margin-right: 0px;
}

.os-tabs-controls.os-tabs-complex .nav-item.nav-actions {
  margin-left: auto;
}

.os-tabs-controls.os-tabs-complex .nav-item .btn, .os-tabs-controls.os-tabs-complex .nav-item .all-wrapper .fc-button, .all-wrapper .os-tabs-controls.os-tabs-complex .nav-item .fc-button {
  margin-left: 10px;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link {
  color: #3E4B5B;
  opacity: 0.6;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0px;
  padding: 8px 20px;
  margin-right: 0px;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link span {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.9rem;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link span.badge {
  font-size: 0.72rem;
  padding: 3px 5px;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link span.badge span {
  font-size: 0.72rem;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link.active {
  opacity: 1;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link.active .tab-label {
  font-size: 1.26rem;
  line-height: 1.25;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link .tab-label {
  display: inline-block;
  margin-right: 10px;
  text-transform: none;
  letter-spacing: normal;
  font-size: 1.08rem;
  vertical-align: middle;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link .tab-value {
  display: inline-block;
  color: #047bf8;
  margin-right: 10px;
  text-transform: none;
  letter-spacing: normal;
  vertical-align: middle;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link .badge {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.72rem;
  letter-spacing: normal;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link .badge i.os-icon {
  color: #fff;
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0px;
  margin-right: 3px;
}

.os-tabs-controls.os-tabs-complex .nav-item .nav-link .badge span {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.72rem;
}

/*
 #7. Activity Boxes
*/
.activity-boxes-w .activity-box-w {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  padding: 0.8rem 0px;
  position: relative;
}

.activity-boxes-w .activity-box-w:before {
  content: "";
  display: block;
  width: 3px;
  background-color: #c0cadd;
  top: 0px;
  bottom: 0px;
  left: 75px;
  position: absolute;
}

.activity-boxes-w .activity-box-w:after {
  content: "";
  display: block;
  width: 20px;
  background-color: #c0cadd;
  top: 50%;
  height: 1px;
  left: 80px;
  position: absolute;
  z-index: 1;
}

.activity-boxes-w .activity-box-w:first-child:before {
  top: 50%;
}

.activity-boxes-w .activity-box-w:last-child:before {
  bottom: 50%;
}

.activity-boxes-w .activity-time {
  -webkit-box-flex: 0;
          flex: 0 0 100px;
  font-size: 0.63rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
  text-align: right;
  padding-right: 40px;
}

.activity-boxes-w .activity-box {
  padding: 0.8rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 1;
          flex: 1;
  position: relative;
  -webkit-box-align: center;
          align-items: center;
}

.activity-boxes-w .activity-box:before {
  position: absolute;
  top: 50%;
  left: -30px;
  content: "";
  width: 12px;
  height: 12px;
  border: 2px solid #60769f;
  background-color: #f2f4f8;
  border-radius: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 2;
}

.activity-boxes-w .activity-avatar {
  width: 50px;
  border-radius: 50px;
  overflow: hidden;
  margin-right: 1.1rem;
}

.activity-boxes-w .activity-avatar img {
  max-width: 100%;
  height: auto;
}

.activity-boxes-w .activity-info {
  -webkit-box-flex: 1;
          flex: 1;
}

.activity-boxes-w .activity-role {
  font-size: 0.63rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 0.2rem;
}

.activity-boxes-w .activity-title {
  font-size: 0.63rem;
  text-transform: uppercase;
  display: block;
}

/*
 #8. Note box
*/
.note-box {
  padding: 15%;
  background-color: #F8ECC7;
  margin-bottom: 2rem;
}

.note-box .note-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.note-box .note-content {
  font-weight: 300;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.81rem;
}

/*
 #9. Steps
*/
.step-contents .step-content {
  padding: 0px 5%;
  display: none;
}

.step-contents .step-content.active {
  display: block;
}

.step-triggers {
  display: -webkit-box;
  display: flex;
  margin-bottom: 2.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.step-triggers .step-trigger {
  padding-bottom: 1rem;
  -webkit-box-flex: 1;
          flex: 1;
  text-align: center;
  font-size: 1.08rem;
  color: rgba(0, 0, 0, 0.3);
  position: relative;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.step-triggers .step-trigger:focus {
  text-decoration: none;
}

.step-triggers .step-trigger:hover {
  text-decoration: none;
  color: #3E4B5B;
  cursor: pointer;
}

.step-triggers .step-trigger:before {
  position: absolute;
  content: "";
  height: 4px;
  background-color: #047bf8;
  left: 0px;
  right: 100%;
  bottom: -2px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.step-triggers .step-trigger.complete:before {
  right: 0px;
}

.step-triggers .step-trigger.active {
  color: #3E4B5B;
}

.step-triggers .step-trigger.active:before {
  right: 0px;
}

/*
 #10. Invoice
*/
.invoice-w {
  font-family: "Avenir Next W01", "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  max-width: 800px;
  position: relative;
  overflow: hidden;
  padding: 100px;
  padding-bottom: 20px;
}

.invoice-w:before {
  width: 140%;
  height: 450px;
  background-color: #faf9f3;
  position: absolute;
  top: -15%;
  left: -24%;
  -webkit-transform: rotate(-27deg);
          transform: rotate(-27deg);
  content: "";
  z-index: 1;
}

.invoice-w .infos {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

.invoice-w .infos .info-1 {
  font-size: 1.08rem;
}

.invoice-w .infos .info-1 .company-name {
  font-size: 2.25rem;
  margin-bottom: 0.5rem;
  margin-top: 10px;
}

.invoice-w .infos .info-1 .company-extra {
  font-size: 0.81rem;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 1rem;
}

.invoice-w .infos .info-2 {
  padding-top: 140px;
  text-align: right;
}

.invoice-w .infos .info-2 .company-name {
  margin-bottom: 1rem;
  font-size: 1.26rem;
}

.invoice-w .infos .info-2 .company-address {
  color: rgba(0, 0, 0, 0.6);
}

.invoice-w .terms {
  font-size: 0.81rem;
  margin-top: 2.5rem;
}

.invoice-w .terms .terms-header {
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.invoice-w .terms .terms-content {
  color: rgba(0, 0, 0, 0.4);
}

.invoice-table thead th {
  border-bottom: 2px solid #333;
}

.invoice-table tbody tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.invoice-table tbody tr:last-child td {
  padding-bottom: 40px;
}

.invoice-table tfoot tr td {
  border-top: 3px solid #333;
  font-size: 1.26rem;
}

.invoice-heading {
  margin-bottom: 4rem;
  margin-top: 7rem;
  position: relative;
  z-index: 2;
}

.invoice-heading h3 {
  margin-bottom: 0px;
}

.invoice-footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  margin-top: 6rem;
}

.invoice-footer .invoice-logo img {
  vertical-align: middle;
  height: 20px;
  width: auto;
  display: inline-block;
}

.invoice-footer .invoice-logo span {
  vertical-align: middle;
  margin-left: 10px;
  display: inline-block;
}

.invoice-footer .invoice-info span {
  display: inline-block;
}

.invoice-footer .invoice-info span + span {
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.invoice-body {
  display: -webkit-box;
  display: flex;
}

.invoice-body .invoice-desc {
  -webkit-box-flex: 0;
          flex: 0 1 250px;
  font-size: 1.17rem;
}

/*
 #11. Error Page
*/
.big-error-w {
  width: 450px;
  text-align: center;
  padding: 50px;
  margin: 0px auto;
  margin-top: 100px;
}

.big-error-w h1 {
  font-size: 4.75rem;
  margin-bottom: 0px;
}

.big-error-w h5 {
  color: #047bf8;
  margin-bottom: 3rem;
}

.big-error-w h4 {
  margin-bottom: 0px;
}

.big-error-w form {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 1.5rem;
  margin-top: 1.5rem;
}

/*
 #12. Authorization styles
*/
.auth-box-w {
  max-width: 450px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
}

.auth-box-w.wider {
  max-width: 500px;
}

.auth-box-w .logged-user-w {
  padding-top: 0px;
  padding-bottom: 10px;
}

.auth-box-w .logo-w {
  text-align: center;
  padding: 20%;
}

.auth-box-w .auth-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  position: relative;
  z-index: 1;
  padding-left: 80px;
}

.auth-box-w .auth-header:after {
  content: "";
  background-color: #047bf8;
  width: 32px;
  height: 7px;
  border-radius: 2px;
  display: block;
  position: absolute;
  bottom: -4px;
  left: 80px;
}

.auth-box-w form {
  padding: 20px 80px;
  padding-bottom: 60px;
}

.auth-box-w form .form-check-inline {
  margin-left: 1rem;
}

.auth-box-w form .form-group {
  position: relative;
}

.auth-box-w form .form-group .pre-icon {
  position: absolute;
  bottom: 5px;
  font-size: 24px;
  color: #047bf8;
  left: -38px;
}

.auth-box-w .buttons-w {
  padding-top: 1rem;
  margin-top: 0.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.auth-box-w.centered {
  text-align: center;
}

.auth-box-w.centered .auth-header {
  padding-left: 0px;
}

.auth-box-w.centered .auth-header:after {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 50%;
}

/*
 #13. Timed Activities Element
*/
.timed-activities {
  margin-bottom: 1rem;
}

.timed-activity {
  display: -webkit-box;
  display: flex;
  margin-bottom: 2rem;
  font-size: 1.08rem;
}

.timed-activity .ta-date {
  -webkit-box-flex: 0;
          flex: 0 0 130px;
  color: rgba(0, 0, 0, 0.4);
  position: relative;
  text-align: center;
}

.timed-activity .ta-date span {
  position: relative;
  display: inline-block;
}

.timed-activity .ta-date span:after {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: -30px;
  width: 16px;
  height: 16px;
  border: 3px solid #C76DC8;
  border-radius: 8px;
  background-color: #fff;
}

.timed-activity .ta-date:before {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: -20px;
  width: 1px;
  top: 70px;
  background-color: rgba(0, 0, 0, 0.1);
}

.timed-activity .ta-record-w {
  padding-left: 40px;
}

.timed-activity .ta-record {
  display: -webkit-box;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-align: start;
          align-items: flex-start;
}

.timed-activity .ta-timestamp {
  position: relative;
  padding-right: 40px;
  color: rgba(0, 0, 0, 0.4);
  width: 110px;
  white-space: nowrap;
}

.timed-activity .ta-timestamp strong {
  color: #3E4B5B;
}

.timed-activity .ta-timestamp:after {
  content: '\e910';
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.3);
  font-size: 10px;
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-40%);
          transform: translateY(-40%);
  display: block;
}

.timed-activity .ta-activity a {
  display: inline-block;
  padding-bottom: 1px;
  border-bottom: 1px solid #047bf8;
}

.timed-activity .ta-activity a:hover {
  text-decoration: none;
  color: #024994;
  border-bottom-color: #024994;
}

.timed-activities.compact .timed-activity {
  display: block;
  padding-left: 30px;
  position: relative;
}

.timed-activities.compact .timed-activity:before {
  position: absolute;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 0px;
  top: 45px;
  content: "";
  left: 8px;
}

.timed-activities.compact .timed-activity .ta-date {
  -webkit-box-flex: 0;
          flex: 0;
  text-align: left;
  margin-bottom: 1.5rem;
}

.timed-activities.compact .timed-activity .ta-date:before {
  display: none;
}

.timed-activities.compact .timed-activity .ta-date span:after {
  left: -30px;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.timed-activities.compact .timed-activity .ta-record-w {
  padding-left: 20px;
}

.timed-activities.compact .timed-activity .ta-record {
  display: block;
}

.timed-activities.compact .timed-activity .ta-timestamp {
  font-size: 0.81rem;
  margin-bottom: 5px;
}

.timed-activities.compact .timed-activity .ta-timestamp:after {
  right: auto;
  left: -20px;
  font-size: 8px;
}

.timed-activities.compact .timed-activity .ta-activity {
  font-size: 0.9rem;
  margin-bottom: 10px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

/*
 #14. Progress Bar Element
*/
.os-progress-bar {
  margin-bottom: 1rem;
}

.os-progress-bar .bar-labels {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  margin-bottom: 5px;
}

.os-progress-bar .bar-labels span {
  font-size: 0.72rem;
}

.os-progress-bar .bar-labels span.bigger {
  font-size: 0.99rem;
}

.os-progress-bar .bar-label-left span {
  margin-right: 5px;
}

.os-progress-bar .bar-label-left span.positive {
  color: #619B2E;
}

.os-progress-bar .bar-label-left span.negative {
  color: #D83536;
}

.os-progress-bar .bar-label-right span {
  margin-left: 5px;
}

.os-progress-bar .bar-label-right span.info {
  color: #456CF9;
}

.os-progress-bar .bar-level-1,
.os-progress-bar .bar-level-2,
.os-progress-bar .bar-level-3 {
  border-radius: 12px;
  height: 6px;
}

.os-progress-bar.blue .bar-level-1, .os-progress-bar.primary .bar-level-1 {
  background-color: #F2F2F2;
}

.os-progress-bar.blue .bar-level-2, .os-progress-bar.primary .bar-level-2 {
  background-color: #65affd;
}

.os-progress-bar.blue .bar-level-3, .os-progress-bar.primary .bar-level-3 {
  background-color: #0362c6;
}

.os-progress-bar.warning .bar-level-1 {
  background-color: #F2F2F2;
}

.os-progress-bar.warning .bar-level-2 {
  background-color: #fcebb8;
}

.os-progress-bar.warning .bar-level-3 {
  background-color: #f9d66f;
}

.os-progress-bar.danger .bar-level-1 {
  background-color: #F2F2F2;
}

.os-progress-bar.danger .bar-level-2 {
  background-color: #f7acac;
}

.os-progress-bar.danger .bar-level-3 {
  background-color: #f16666;
}

.os-progress-bar.success .bar-level-1 {
  background-color: #F2F2F2;
}

.os-progress-bar.success .bar-level-2 {
  background-color: #c2f2ae;
}

.os-progress-bar.success .bar-level-3 {
  background-color: #90e86c;
}

.os-progress-bar.info .bar-level-1 {
  background-color: #F2F2F2;
}

.os-progress-bar.info .bar-level-2 {
  background-color: #9fd9f7;
}

.os-progress-bar.info .bar-level-3 {
  background-color: #58bef1;
}

.os-progress-bar.floated {
  float: right;
  width: 220px;
}

/*
  #15. Balances
*/
.element-balances {
  display: -webkit-box;
  display: flex;
  padding: 20px 0px;
  justify-content: space-around;
}

.element-balances .balance {
  padding: 10px 20px;
}

.element-balances .balance + .balance {
  border-left: 1px solid rgba(0, 0, 0, 0.03);
}

.element-balances .balance-title {
  color: rgba(90, 99, 126, 0.49);
  font-size: 1.08rem;
}

.element-balances .balance-value {
  font-size: 2.07rem;
  font-weight: 500;
  color: #303a46;
  white-space: nowrap;
}

.element-balances .balance-value.danger {
  color: #b71b1b;
}

.element-balances .balance-value span {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.1;
}

.element-balances .balance-value i {
  line-height: 1.1;
}

.element-balances .balance-value .balance-value-success {
  color: #24b314;
}

.element-balances span.trending {
  padding: 3px 10px;
  border-radius: 30px;
  display: inline-block;
  font-size: 0.99rem;
  vertical-align: middle;
  margin-left: 10px;
}

.element-balances span.trending .os-icon {
  margin-left: 5px;
  vertical-align: middle;
  font-size: 14px;
}

.element-balances span.trending span {
  display: inline-block;
  vertical-align: middle;
}

.element-balances span.trending-up {
  color: #fff;
  background-color: #24b314;
}

.element-balances span.trending-down {
  color: #fff;
  background-color: #e65252;
}

.element-balances span.trending-up-basic {
  color: #24b314;
  padding: 0px;
}

.element-balances span.trending-down-basic {
  color: #e65252;
  padding: 0px;
}

.element-balances .balance.balance-v2 {
  padding-top: 0px;
}

.element-balances .balance.balance-v2:first-child {
  padding-left: 0px;
}

.element-balances .balance.balance-v2 .balance-title {
  color: #047bf8;
}

.element-balances .balance.balance-v2 .balance-value {
  font-size: 2.88rem;
  line-height: 1;
}

/* 
#16. Call to action box
*/
.cta-w {
  border-radius: 6px;
  position: relative;
}

.cta-w.orange .highlight-header {
  background-color: #2283FF;
}

.cta-w .highlight-header {
  background-color: #a42cef;
  color: #fff;
  padding: 2px 5px;
  line-height: 1.2;
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  margin-bottom: 10px;
  display: inline-block;
}

.cta-w .cta-header {
  color: #fff;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.cta-w .cta-content {
  padding: 12% 8%;
}

.cta-w .cta-content.extra-padded {
  padding: 24% 9%;
}

.cta-w .cta-content .store-google-btn img {
  max-width: 150px;
  height: auto;
}

.cta-w.cta-with-media .cta-content {
  padding-right: 0px;
  width: 65%;
}

.cta-w.cta-with-media .cta-media {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 25%;
}

.cta-w.cta-with-media .cta-media img {
  max-width: 100%;
  height: auto;
}

.cta-w .newsletter-field-w {
  position: relative;
}

.cta-w .newsletter-field-w input {
  background-color: #fff;
  border: none;
  display: block;
  width: 100%;
  border-radius: 6px;
  padding: 7px 15px;
}

.cta-w .newsletter-field-w .btn, .cta-w .newsletter-field-w .all-wrapper .fc-button, .all-wrapper .cta-w .newsletter-field-w .fc-button {
  position: absolute;
  top: 50%;
  right: 5px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

/*
#17. Post Box
*/
.post-box {
  margin-bottom: 15px;
  padding: 10px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: stretch;
          align-items: stretch;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.post-box:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  box-shadow: 0px 5px 12px rgba(126, 142, 177, 0.2);
}

.post-box .post-media {
  -webkit-box-flex: 0;
          flex: 0 0 30%;
  background-size: cover;
  background-position: center center;
}

.post-box .post-content {
  -webkit-box-flex: 1;
          flex: 1;
  padding: 10px;
  padding-left: 20px;
}

.post-box .post-text {
  color: rgba(90, 99, 126, 0.49);
  margin-bottom: 10px;
  font-size: 0.81rem;
}

.post-box .post-foot {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
}

.post-box .post-foot .post-link {
  font-size: 0.81rem;
}

.post-box .post-foot .post-link i {
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
}

.post-box .post-foot .post-link span {
  display: inline-block;
  vertical-align: middle;
}

/* 
#18. Todo List Widget
*/
.todo-list .todo-item {
  text-decoration: none;
  display: -webkit-box;
  display: flex;
  border: 1px solid #047bf8;
  -webkit-box-align: center;
          align-items: center;
  padding: 10px 20px;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

.todo-list .todo-item .ti-header {
  font-weight: 500;
  color: #047bf8;
}

.todo-list .todo-item .ti-sub-header {
  font-size: 0.8rem;
  color: rgba(90, 99, 126, 0.49);
  line-height: 1.2;
}

.todo-list .todo-item .ti-icon {
  margin-left: auto;
  font-size: 20px;
  color: #047bf8;
}

.todo-list .todo-item.complete {
  opacity: 0.7;
  border-color: #24b314;
}

.todo-list .todo-item.complete .ti-icon {
  color: #24b314;
}

.todo-list .todo-item.complete .ti-header {
  color: #1b850f;
}

/* 
#19. Fancy Progress With Label
*/
.fancy-progress-with-label {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  margin-bottom: 2rem;
}

.fancy-progress-with-label .fpl-label {
  font-size: 1.35rem;
  font-weight: 500;
  padding-right: 20px;
}

.fancy-progress-with-label .fpl-progress-w {
  -webkit-box-flex: 1;
          flex: 1;
  border-radius: 20px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.05);
}

.fancy-progress-with-label .fpl-progress-w .fpl-progress-i {
  height: 20px;
  background-color: #28EF58;
  background-image: -webkit-gradient(linear, left top, right top, from(#28EF58), color-stop(98%, #3386EF));
  background-image: linear-gradient(-270deg, #28EF58 0%, #3386EF 98%);
  border-radius: 20px;
}

/* 10. Helpful little styles */
.relative {
  position: relative;
}

.padded-v {
  padding: 1rem 10px;
}

.padded-v-big {
  padding: 2rem 10px;
}

.padded {
  padding: 1rem 2rem;
}

.b-l {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.b-r {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.b-t {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.b-b {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.m-t {
  margin-top: 1rem;
}

.m-b {
  margin-bottom: 1rem;
}

@media (min-width: 992px) {
  .padded-lg {
    padding: 1rem 2rem;
  }
  .b-l-lg {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
  .b-r-lg {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  .b-t-lg {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .b-b-lg {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

@media (min-width: 1200px) {
  .padded-xl {
    padding: 1rem 2rem;
  }
  .b-l-xl {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
  .b-r-xl {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  .b-t-xl {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .b-b-xl {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.example-column {
  padding: 12px 15px;
  background: #EEF5FF;
  border: 1px solid #AEC8FF;
  margin-bottom: 15px;
  text-align: center;
}

.example-column + .example-column {
  border-left: none;
}

.example-content .progress + .progress {
  margin-top: 1rem;
}

.with-avatar {
  margin-right: 5px;
}

.with-avatar img {
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  width: 30px;
  height: auto;
  margin-right: 10px;
}

.with-avatar span {
  display: inline-block;
  vertical-align: middle;
}

a.with-avatar span {
  border-bottom: 1px solid #047bf8;
}

.add-agent-btn i {
  margin-right: 15px;
  padding-left: 5px;
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
}

.add-agent-btn span {
  display: inline-block;
  vertical-align: middle;
  border-bottom: 1px solid #047bf8;
}

.centered-load-more-link {
  display: block;
  padding: 10px;
  padding-bottom: 15px;
  text-align: center;
  cursor: pointer;
}

.centered-load-more-link i {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  margin-right: 5px;
}

.centered-load-more-link span {
  display: inline-block;
  vertical-align: middle;
  border-bottom: 1px solid #047bf8;
}

.centered-load-more-link:hover span {
  border-bottom-color: #0362c6;
}

.centered-load-more-link.smaller {
  font-size: 0.8rem;
}

.text-faded {
  color: rgba(90, 99, 126, 0.49);
}

.text-bright {
  color: #1D1F28;
}

/* 11. Icon Fonts */
@font-face {
  font-family: 'osfont';
  font-weight: normal;
  font-style: normal;
}

.os-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.os-icon-activity:before {
  content: "\e985";
}

.os-icon-airplay:before {
  content: "\e988";
}

.os-icon-alert-circle:before {
  content: "\e989";
}

.os-icon-alert-octagon:before {
  content: "\e98a";
}

.os-icon-alert-triangle:before {
  content: "\e98b";
}

.os-icon-align-center:before {
  content: "\e98c";
}

.os-icon-align-justify:before {
  content: "\e98d";
}

.os-icon-align-left:before {
  content: "\e98e";
}

.os-icon-align-right:before {
  content: "\e98f";
}

.os-icon-anchor:before {
  content: "\e990";
}

.os-icon-aperture:before {
  content: "\e991";
}

.os-icon-arrow-down6:before {
  content: "\e992";
}

.os-icon-arrow-down-circle:before {
  content: "\e993";
}

.os-icon-arrow-down-left:before {
  content: "\e994";
}

.os-icon-arrow-down-right:before {
  content: "\e995";
}

.os-icon-arrow-left6:before {
  content: "\e996";
}

.os-icon-arrow-left-circle:before {
  content: "\e997";
}

.os-icon-arrow-right7:before {
  content: "\e998";
}

.os-icon-arrow-right-circle:before {
  content: "\e999";
}

.os-icon-arrow-up6:before {
  content: "\e99a";
}

.os-icon-arrow-up-circle:before {
  content: "\e99b";
}

.os-icon-arrow-up-left:before {
  content: "\e99c";
}

.os-icon-arrow-up-right:before {
  content: "\e99d";
}

.os-icon-at-sign:before {
  content: "\e99e";
}

.os-icon-award:before {
  content: "\e99f";
}

.os-icon-bar-chart:before {
  content: "\e9a0";
}

.os-icon-bar-chart-2:before {
  content: "\e9a1";
}

.os-icon-battery:before {
  content: "\e9a2";
}

.os-icon-battery-charging:before {
  content: "\e9a3";
}

.os-icon-bell:before {
  content: "\e9a4";
}

.os-icon-bell-off:before {
  content: "\e9a5";
}

.os-icon-bluetooth:before {
  content: "\e9a6";
}

.os-icon-bold:before {
  content: "\e9a7";
}

.os-icon-book:before {
  content: "\e9a8";
}

.os-icon-book-open:before {
  content: "\e9a9";
}

.os-icon-bookmark:before {
  content: "\e9aa";
}

.os-icon-box:before {
  content: "\e9ab";
}

.os-icon-briefcase:before {
  content: "\e9ac";
}

.os-icon-calendar:before {
  content: "\e9ad";
}

.os-icon-camera:before {
  content: "\e9ae";
}

.os-icon-camera-off:before {
  content: "\e9af";
}

.os-icon-cast:before {
  content: "\e9b0";
}

.os-icon-check:before {
  content: "\e9b1";
}

.os-icon-check-circle:before {
  content: "\e9b2";
}

.os-icon-check-square:before {
  content: "\e9b3";
}

.os-icon-chevron-down:before {
  content: "\e9b4";
}

.os-icon-chevron-left:before {
  content: "\e9b5";
}

.os-icon-chevron-right:before {
  content: "\e9b6";
}

.os-icon-chevron-up:before {
  content: "\e9b7";
}

.os-icon-chevrons-down:before {
  content: "\e9b8";
}

.os-icon-chevrons-left:before {
  content: "\e9b9";
}

.os-icon-chevrons-right:before {
  content: "\e9ba";
}

.os-icon-chevrons-up:before {
  content: "\e9bb";
}

.os-icon-chrome:before {
  content: "\e9bc";
}

.os-icon-circle:before {
  content: "\e9bd";
}

.os-icon-clipboard:before {
  content: "\e9be";
}

.os-icon-clock:before {
  content: "\e9bf";
}

.os-icon-cloud:before {
  content: "\e9c0";
}

.os-icon-cloud-drizzle:before {
  content: "\e9c1";
}

.os-icon-cloud-lightning:before {
  content: "\e9c2";
}

.os-icon-cloud-off:before {
  content: "\e9c3";
}

.os-icon-cloud-rain:before {
  content: "\e9c4";
}

.os-icon-cloud-snow:before {
  content: "\e9c5";
}

.os-icon-code:before {
  content: "\e9c6";
}

.os-icon-codepen:before {
  content: "\e9c7";
}

.os-icon-command:before {
  content: "\e9c8";
}

.os-icon-compass:before {
  content: "\e9c9";
}

.os-icon-copy:before {
  content: "\e9ca";
}

.os-icon-corner-down-left:before {
  content: "\e9cb";
}

.os-icon-corner-down-right:before {
  content: "\e9cc";
}

.os-icon-corner-left-down:before {
  content: "\e9cd";
}

.os-icon-corner-left-up:before {
  content: "\e9ce";
}

.os-icon-corner-right-down:before {
  content: "\e9cf";
}

.os-icon-corner-right-up:before {
  content: "\e9d0";
}

.os-icon-corner-up-left:before {
  content: "\e9d1";
}

.os-icon-corner-up-right:before {
  content: "\e9d2";
}

.os-icon-cpu:before {
  content: "\e9d3";
}

.os-icon-credit-card:before {
  content: "\e9d4";
}

.os-icon-crop:before {
  content: "\e9d5";
}

.os-icon-crosshair:before {
  content: "\e9d6";
}

.os-icon-database:before {
  content: "\e9d7";
}

.os-icon-delete:before {
  content: "\e9d8";
}

.os-icon-disc:before {
  content: "\e9d9";
}

.os-icon-dollar-sign:before {
  content: "\e9da";
}

.os-icon-download:before {
  content: "\e9db";
}

.os-icon-download-cloud:before {
  content: "\e9dc";
}

.os-icon-droplet:before {
  content: "\e9dd";
}

.os-icon-edit:before {
  content: "\e9de";
}

.os-icon-edit-2:before {
  content: "\e9df";
}

.os-icon-edit-32:before {
  content: "\e9e0";
}

.os-icon-external-link:before {
  content: "\e9e1";
}

.os-icon-eye:before {
  content: "\e9e2";
}

.os-icon-eye-off:before {
  content: "\e9e3";
}

.os-icon-facebook2:before {
  content: "\e9e4";
}

.os-icon-fast-forward:before {
  content: "\e9e5";
}

.os-icon-feather:before {
  content: "\e9e6";
}

.os-icon-file:before {
  content: "\e9e7";
}

.os-icon-file-minus:before {
  content: "\e9e8";
}

.os-icon-file-plus:before {
  content: "\e9e9";
}

.os-icon-file-text:before {
  content: "\e9ea";
}

.os-icon-film:before {
  content: "\e9eb";
}

.os-icon-filter:before {
  content: "\e9ec";
}

.os-icon-flag:before {
  content: "\e9ed";
}

.os-icon-folder:before {
  content: "\e9ee";
}

.os-icon-folder-minus:before {
  content: "\e9ef";
}

.os-icon-folder-plus:before {
  content: "\e9f0";
}

.os-icon-git-branch:before {
  content: "\e9f1";
}

.os-icon-git-commit:before {
  content: "\e9f2";
}

.os-icon-git-merge:before {
  content: "\e9f3";
}

.os-icon-git-pull-request:before {
  content: "\e9f4";
}

.os-icon-github:before {
  content: "\e9f5";
}

.os-icon-gitlab:before {
  content: "\e9f6";
}

.os-icon-globe:before {
  content: "\e9f7";
}

.os-icon-grid:before {
  content: "\e9f8";
}

.os-icon-hard-drive:before {
  content: "\e9f9";
}

.os-icon-hash:before {
  content: "\e9fa";
}

.os-icon-headphones:before {
  content: "\e9fb";
}

.os-icon-heart:before {
  content: "\e9fc";
}

.os-icon-help-circle:before {
  content: "\e9fd";
}

.os-icon-home:before {
  content: "\e9fe";
}

.os-icon-image:before {
  content: "\e9ff";
}

.os-icon-inbox:before {
  content: "\ea00";
}

.os-icon-info:before {
  content: "\ea01";
}

.os-icon-instagram:before {
  content: "\ea02";
}

.os-icon-italic:before {
  content: "\ea03";
}

.os-icon-layers:before {
  content: "\ea04";
}

.os-icon-layout:before {
  content: "\ea05";
}

.os-icon-life-buoy:before {
  content: "\ea06";
}

.os-icon-link:before {
  content: "\ea07";
}

.os-icon-link-2:before {
  content: "\ea08";
}

.os-icon-linkedin:before {
  content: "\ea09";
}

.os-icon-list:before {
  content: "\ea0a";
}

.os-icon-loader:before {
  content: "\ea0b";
}

.os-icon-lock:before {
  content: "\ea0c";
}

.os-icon-log-in:before {
  content: "\ea0d";
}

.os-icon-log-out:before {
  content: "\ea0e";
}

.os-icon-mail:before {
  content: "\ea0f";
}

.os-icon-map:before {
  content: "\ea10";
}

.os-icon-map-pin:before {
  content: "\ea11";
}

.os-icon-maximize:before {
  content: "\ea12";
}

.os-icon-maximize-2:before {
  content: "\ea13";
}

.os-icon-menu:before {
  content: "\ea14";
}

.os-icon-message-circle:before {
  content: "\ea15";
}

.os-icon-message-square:before {
  content: "\ea16";
}

.os-icon-mic:before {
  content: "\ea17";
}

.os-icon-mic-off:before {
  content: "\ea18";
}

.os-icon-minimize:before {
  content: "\ea19";
}

.os-icon-minimize-2:before {
  content: "\ea1a";
}

.os-icon-minus2:before {
  content: "\ea1b";
}

.os-icon-minus-circle:before {
  content: "\ea1c";
}

.os-icon-minus-square:before {
  content: "\ea1d";
}

.os-icon-monitor:before {
  content: "\ea1e";
}

.os-icon-moon:before {
  content: "\ea1f";
}

.os-icon-more-horizontal:before {
  content: "\ea20";
}

.os-icon-more-vertical:before {
  content: "\ea21";
}

.os-icon-move:before {
  content: "\ea22";
}

.os-icon-music:before {
  content: "\ea23";
}

.os-icon-navigation:before {
  content: "\ea24";
}

.os-icon-navigation-2:before {
  content: "\ea25";
}

.os-icon-octagon:before {
  content: "\ea26";
}

.os-icon-package:before {
  content: "\ea27";
}

.os-icon-paperclip:before {
  content: "\ea28";
}

.os-icon-pause:before {
  content: "\ea29";
}

.os-icon-pause-circle:before {
  content: "\ea2a";
}

.os-icon-percent:before {
  content: "\ea2b";
}

.os-icon-phone:before {
  content: "\ea2c";
}

.os-icon-phone-call:before {
  content: "\ea2d";
}

.os-icon-phone-forwarded:before {
  content: "\ea2e";
}

.os-icon-phone-incoming:before {
  content: "\ea2f";
}

.os-icon-phone-missed:before {
  content: "\ea30";
}

.os-icon-phone-off:before {
  content: "\ea31";
}

.os-icon-phone-outgoing:before {
  content: "\ea32";
}

.os-icon-pie-chart:before {
  content: "\ea33";
}

.os-icon-play:before {
  content: "\ea34";
}

.os-icon-play-circle:before {
  content: "\ea35";
}

.os-icon-plus:before {
  content: "\ea36";
}

.os-icon-plus-circle:before {
  content: "\ea37";
}

.os-icon-plus-square:before {
  content: "\ea38";
}

.os-icon-pocket:before {
  content: "\ea39";
}

.os-icon-power:before {
  content: "\ea3a";
}

.os-icon-printer:before {
  content: "\ea3b";
}

.os-icon-radio:before {
  content: "\ea3c";
}

.os-icon-refresh-ccw:before {
  content: "\ea3d";
}

.os-icon-refresh-cw:before {
  content: "\ea3e";
}

.os-icon-repeat:before {
  content: "\ea3f";
}

.os-icon-rewind:before {
  content: "\ea40";
}

.os-icon-rotate-ccw:before {
  content: "\ea41";
}

.os-icon-rotate-cw:before {
  content: "\ea42";
}

.os-icon-rss:before {
  content: "\ea43";
}

.os-icon-save:before {
  content: "\ea44";
}

.os-icon-scissors:before {
  content: "\ea45";
}

.os-icon-search2:before {
  content: "\ea46";
}

.os-icon-send:before {
  content: "\ea47";
}

.os-icon-server:before {
  content: "\ea48";
}

.os-icon-settings:before {
  content: "\ea49";
}

.os-icon-share:before {
  content: "\ea4a";
}

.os-icon-share-2:before {
  content: "\ea4b";
}

.os-icon-shield:before {
  content: "\ea4c";
}

.os-icon-shield-off:before {
  content: "\ea4d";
}

.os-icon-shopping-bag:before {
  content: "\ea4e";
}

.os-icon-shopping-cart:before {
  content: "\ea4f";
}

.os-icon-shuffle:before {
  content: "\ea50";
}

.os-icon-sidebar:before {
  content: "\ea51";
}

.os-icon-skip-back:before {
  content: "\ea52";
}

.os-icon-skip-forward:before {
  content: "\ea53";
}

.os-icon-slack:before {
  content: "\ea54";
}

.os-icon-slash:before {
  content: "\ea55";
}

.os-icon-sliders:before {
  content: "\ea56";
}

.os-icon-smartphone:before {
  content: "\ea57";
}

.os-icon-speaker:before {
  content: "\ea58";
}

.os-icon-square:before {
  content: "\ea59";
}

.os-icon-star:before {
  content: "\ea5a";
}

.os-icon-stop-circle:before {
  content: "\ea5b";
}

.os-icon-sun:before {
  content: "\ea5c";
}

.os-icon-sunrise:before {
  content: "\ea5d";
}

.os-icon-sunset:before {
  content: "\ea5e";
}

.os-icon-tablet:before {
  content: "\ea5f";
}

.os-icon-tag:before {
  content: "\ea60";
}

.os-icon-target:before {
  content: "\ea61";
}

.os-icon-terminal:before {
  content: "\ea62";
}

.os-icon-thermometer:before {
  content: "\ea63";
}

.os-icon-thumbs-down:before {
  content: "\ea64";
}

.os-icon-thumbs-up:before {
  content: "\ea65";
}

.os-icon-toggle-left:before {
  content: "\ea66";
}

.os-icon-toggle-right:before {
  content: "\ea67";
}

.os-icon-trash:before {
  content: "\ea68";
}

.os-icon-trash-2:before {
  content: "\ea69";
}

.os-icon-trending-down:before {
  content: "\ea6a";
}

.os-icon-trending-up:before {
  content: "\ea6b";
}

.os-icon-triangle:before {
  content: "\ea6c";
}

.os-icon-truck:before {
  content: "\ea6d";
}

.os-icon-tv:before {
  content: "\ea6e";
}

.os-icon-twitter2:before {
  content: "\ea6f";
}

.os-icon-type:before {
  content: "\ea70";
}

.os-icon-umbrella:before {
  content: "\ea71";
}

.os-icon-underline:before {
  content: "\ea72";
}

.os-icon-unlock:before {
  content: "\ea73";
}

.os-icon-upload:before {
  content: "\ea74";
}

.os-icon-upload-cloud:before {
  content: "\ea75";
}

.os-icon-user:before {
  content: "\ea76";
}

.os-icon-user-check:before {
  content: "\ea77";
}

.os-icon-user-minus:before {
  content: "\ea78";
}

.os-icon-user-plus:before {
  content: "\ea79";
}

.os-icon-user-x:before {
  content: "\ea7a";
}

.os-icon-users:before {
  content: "\ea7b";
}

.os-icon-video:before {
  content: "\ea7c";
}

.os-icon-video-off:before {
  content: "\ea7d";
}

.os-icon-voicemail:before {
  content: "\ea7e";
}

.os-icon-volume:before {
  content: "\ea7f";
}

.os-icon-volume-1:before {
  content: "\ea80";
}

.os-icon-volume-2:before {
  content: "\ea81";
}

.os-icon-volume-x:before {
  content: "\ea82";
}

.os-icon-watch:before {
  content: "\ea83";
}

.os-icon-wifi:before {
  content: "\ea84";
}

.os-icon-wifi-off:before {
  content: "\ea85";
}

.os-icon-wind:before {
  content: "\ea86";
}

.os-icon-x:before {
  content: "\ea87";
}

.os-icon-x-circle:before {
  content: "\ea88";
}

.os-icon-x-square:before {
  content: "\ea89";
}

.os-icon-zap:before {
  content: "\ea8a";
}

.os-icon-zap-off:before {
  content: "\ea8b";
}

.os-icon-zoom-in:before {
  content: "\ea8c";
}

.os-icon-zoom-out:before {
  content: "\ea8d";
}

.os-icon-star-full:before {
  content: "\e970";
}

.os-icon-arrow-right6:before {
  content: "\e986";
}

.os-icon-arrow-left7:before {
  content: "\e987";
}

.os-icon-arrow-2-right:before {
  content: "\e971";
}

.os-icon-minus:before {
  content: "\e96f";
}

.os-icon-arrow-right:before {
  content: "\e90e";
}

.os-icon-arrow-right2:before {
  content: "\e90f";
}

.os-icon-arrow-right3:before {
  content: "\e910";
}

.os-icon-arrow-right4:before {
  content: "\e911";
}

.os-icon-arrow-right5:before {
  content: "\e912";
}

.os-icon-arrow-left:before {
  content: "\e913";
}

.os-icon-arrow-left2:before {
  content: "\e914";
}

.os-icon-arrow-left3:before {
  content: "\e915";
}

.os-icon-arrow-left4:before {
  content: "\e916";
}

.os-icon-arrow-up:before {
  content: "\e917";
}

.os-icon-arrow-down:before {
  content: "\e918";
}

.os-icon-arrow-left5:before {
  content: "\e919";
}

.os-icon-arrow-down2:before {
  content: "\e91a";
}

.os-icon-arrow-down3:before {
  content: "\e91b";
}

.os-icon-arrow-down4:before {
  content: "\e91c";
}

.os-icon-arrow-up2:before {
  content: "\e91d";
}

.os-icon-arrow-up3:before {
  content: "\e91e";
}

.os-icon-arrow-down5:before {
  content: "\e91f";
}

.os-icon-arrow-up4:before {
  content: "\e920";
}

.os-icon-arrow-up5:before {
  content: "\e921";
}

.os-icon-search:before {
  content: "\e92c";
}

.os-icon-ui-34:before {
  content: "\e984";
}

.os-icon-ui-21:before {
  content: "\e983";
}

.os-icon-documents-15:before {
  content: "\e97f";
}

.os-icon-documents-17:before {
  content: "\e980";
}

.os-icon-documents-11:before {
  content: "\e981";
}

.os-icon-documents-13:before {
  content: "\e982";
}

.os-icon-ui-23:before {
  content: "\e97e";
}

.os-icon-home-11:before {
  content: "\e97a";
}

.os-icon-ui-09:before {
  content: "\e97b";
}

.os-icon-old-tv-2:before {
  content: "\e97c";
}

.os-icon-fire:before {
  content: "\e97d";
}

.os-icon-home-10:before {
  content: "\e976";
}

.os-icon-home-09:before {
  content: "\e977";
}

.os-icon-home-13:before {
  content: "\e978";
}

.os-icon-home-34:before {
  content: "\e979";
}

.os-icon-ui-90:before {
  content: "\e975";
}

.os-icon-ui-03:before {
  content: "\e974";
}

.os-icon-ui-83:before {
  content: "\e972";
}

.os-icon-ui-74:before {
  content: "\e973";
}

.os-icon-pencil-12:before {
  content: "\e96e";
}

.os-icon-ui-33:before {
  content: "\e96c";
}

.os-icon-ui-49:before {
  content: "\e96d";
}

.os-icon-grid-10:before {
  content: "\e96b";
}

.os-icon-common-03:before {
  content: "\e968";
}

.os-icon-ui-22:before {
  content: "\e969";
}

.os-icon-ui-46:before {
  content: "\e96a";
}

.os-icon-basic-1-138-quotes:before {
  content: "\e966";
  color: #474a56;
}

.os-icon-ui-07:before {
  content: "\e962";
}

.os-icon-social-09:before {
  content: "\e963";
}

.os-icon-finance-28:before {
  content: "\e964";
}

.os-icon-finance-29:before {
  content: "\e965";
}

.os-icon-checkmark:before {
  content: "\e961";
}

.os-icon-ui-93:before {
  content: "\e95d";
}

.os-icon-mail-14:before {
  content: "\e95e";
}

.os-icon-phone-15:before {
  content: "\e95f";
}

.os-icon-phone-18:before {
  content: "\e960";
}

.os-icon-ui-55:before {
  content: "\e95c";
}

.os-icon-mail-19:before {
  content: "\e95a";
}

.os-icon-mail-18:before {
  content: "\e95b";
}

.os-icon-grid-18:before {
  content: "\e950";
}

.os-icon-ui-02:before {
  content: "\e951";
}

.os-icon-ui-37:before {
  content: "\e952";
}

.os-icon-common-07:before {
  content: "\e953";
}

.os-icon-ui-54:before {
  content: "\e954";
}

.os-icon-ui-44:before {
  content: "\e955";
}

.os-icon-ui-15:before {
  content: "\e956";
}

.os-icon-documents-03:before {
  content: "\e957";
}

.os-icon-ui-92:before {
  content: "\e958";
}

.os-icon-phone-21:before {
  content: "\e959";
}

.os-icon-documents-07:before {
  content: "\e94c";
}

.os-icon-others-29:before {
  content: "\e94d";
}

.os-icon-ui-65:before {
  content: "\e94e";
}

.os-icon-ui-51:before {
  content: "\e94f";
}

.os-icon-mail-07:before {
  content: "\e94b";
}

.os-icon-mail-01:before {
  content: "\e949";
}

.os-icon-others-43:before {
  content: "\e94a";
}

.os-icon-mail-12:before {
  content: "\e967";
}

.os-icon-signs-11:before {
  content: "\e946";
}

.os-icon-coins-4:before {
  content: "\e947";
}

.os-icon-user-male-circle2:before {
  content: "\e948";
}

.os-icon-emoticon-smile:before {
  content: "\e943";
}

.os-icon-robot-2:before {
  content: "\e944";
}

.os-icon-robot-1:before {
  content: "\e945";
}

.os-icon-crown:before {
  content: "\e942";
}

.os-icon-cancel-circle:before {
  content: "\e93f";
}

.os-icon-cancel-square:before {
  content: "\e940";
}

.os-icon-close:before {
  content: "\e941";
}

.os-icon-grid-circles:before {
  content: "\e93c";
}

.os-icon-grid-squares-22:before {
  content: "\e93d";
}

.os-icon-grid-squares2:before {
  content: "\e93e";
}

.os-icon-tasks-checked:before {
  content: "\e93a";
}

.os-icon-hierarchy-structure-2:before {
  content: "\e93b";
}

.os-icon-agenda-1:before {
  content: "\e935";
}

.os-icon-cv-2:before {
  content: "\e936";
}

.os-icon-grid-squares-2:before {
  content: "\e937";
}

.os-icon-grid-squares:before {
  content: "\e938";
}

.os-icon-calendar-time:before {
  content: "\e939";
}

.os-icon-twitter:before {
  content: "\e933";
}

.os-icon-facebook:before {
  content: "\e934";
}

.os-icon-pie-chart-2:before {
  content: "\e92d";
}

.os-icon-pie-chart-1:before {
  content: "\e92e";
}

.os-icon-pie-chart-3:before {
  content: "\e92f";
}

.os-icon-donut-chart-1:before {
  content: "\e930";
}

.os-icon-bar-chart-up:before {
  content: "\e931";
}

.os-icon-bar-chart-stats-up:before {
  content: "\e932";
}

.os-icon-hamburger-menu-2:before {
  content: "\e92a";
}

.os-icon-hamburger-menu-1:before {
  content: "\e92b";
}

.os-icon-email-2-at:before {
  content: "\e928";
}

.os-icon-email-2-at2:before {
  content: "\e929";
}

.os-icon-fingerprint:before {
  content: "\e927";
}

.os-icon-basic-2-259-calendar:before {
  content: "\e926";
  color: #474a56;
}

.os-icon-arrow-2-up:before {
  content: "\e924";
}

.os-icon-arrow-2-down:before {
  content: "\e925";
}

.os-icon-bar-chart-down:before {
  content: "\e922";
}

.os-icon-graph-down:before {
  content: "\e923";
}

.os-icon-pencil-1:before {
  content: "\e90b";
}

.os-icon-edit-3:before {
  content: "\e90c";
}

.os-icon-edit-1:before {
  content: "\e90d";
}

.os-icon-database-remove:before {
  content: "\e908";
}

.os-icon-pencil-2:before {
  content: "\e909";
}

.os-icon-link-3:before {
  content: "\e90a";
}

.os-icon-email-forward:before {
  content: "\e907";
}

.os-icon-delivery-box-2:before {
  content: "\e900";
}

.os-icon-wallet-loaded:before {
  content: "\e901";
}

.os-icon-newspaper:before {
  content: "\e902";
}

.os-icon-window-content:before {
  content: "\e903";
}

.os-icon-donut-chart-2:before {
  content: "\e904";
}

.os-icon-text-input:before {
  content: "\e905";
}

.os-icon-user-male-circle:before {
  content: "\e906";
}

/* 12. Chat */
/* 

Chat Styles 

#1. Popup chat on a side

*/
/*
#1. Popup chat on a side
*/
.floated-chat-btn {
  z-index: 9999;
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: #097CFF;
  box-shadow: 0 2px 20px 0 rgba(46, 130, 255, 0.75);
  border-radius: 75px;
  color: #fff;
  padding: 12px 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  vertical-align: middle;
  font-size: 1.08rem;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.floated-chat-btn i {
  vertical-align: middle;
  display: inline-block;
  font-size: 24px;
}

.floated-chat-btn span {
  vertical-align: middle;
  display: inline-block;
  font-weight: 500;
}

.floated-chat-btn i + span {
  margin-left: 15px;
}

.floated-chat-btn:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  background-color: #0064d5;
  box-shadow: 0 2px 30px 0 rgba(46, 130, 255, 0.8);
}

.floated-chat-w {
  z-index: 9999;
  position: fixed;
  bottom: 70px;
  right: 10px;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.floated-chat-w.active {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.floated-chat-w .floated-chat-i {
  background-color: #fff;
  /* Rectangle: */
  box-shadow: 0 2px 40px 0 rgba(43, 132, 210, 0.41);
  border-radius: 10px;
  width: 320px;
  position: relative;
}

.floated-chat-w .floated-chat-i .chat-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 10px;
  cursor: pointer;
}

.floated-chat-w .chat-head {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.floated-chat-w .chat-head .user-w {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

.floated-chat-w .chat-head .user-w .user-avatar-w {
  width: 50px;
}

.floated-chat-w .chat-head .user-w .user-avatar-w .user-avatar {
  border-radius: 40px;
  overflow: hidden;
}

.floated-chat-w .chat-head .user-w .user-avatar-w .user-avatar img {
  max-width: 100%;
  height: auto;
}

.floated-chat-w .chat-head .user-w.with-status .user-avatar-w {
  position: relative;
}

.floated-chat-w .chat-head .user-w.with-status .user-avatar-w:before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 2px;
  right: 2px;
  border-radius: 10px;
  box-shadow: 0px 0px 0px 3px #fff;
}

.floated-chat-w .chat-head .user-w.with-status.status-green .user-avatar-w:before {
  background-color: #24b314;
}

.floated-chat-w .chat-head .user-w.with-status.status-red .user-avatar-w:before {
  background-color: #e65252;
}

.floated-chat-w .chat-head .user-w .user-name {
  padding-left: 20px;
  -webkit-box-flex: 1;
          flex: 1;
}

.floated-chat-w .chat-head .user-w .user-title {
  margin-bottom: 2px;
  color: #047bf8;
}

.floated-chat-w .chat-head .user-w .user-role {
  font-weight: 500;
  font-size: 0.81rem;
}

.floated-chat-w .chat-head .user-w .user-action {
  width: 50px;
  color: #047bf8;
  font-size: 18px;
}

.floated-chat-w .chat-messages {
  padding: 20px;
  height: 300px;
  position: relative;
  overflow: hidden;
}

.floated-chat-w .chat-messages .message {
  margin-bottom: 12px;
}

.floated-chat-w .chat-messages .message .message-content {
  color: #594939;
  padding: 10px 20px;
  background-color: #fcf6ee;
  border-radius: 20px 20px 20px 0px;
  max-width: 80%;
  display: inline-block;
  text-align: left;
}

.floated-chat-w .chat-messages .message.self {
  text-align: right;
}

.floated-chat-w .chat-messages .message.self .message-content {
  border-radius: 20px 20px 0px 20px;
  background-color: #e2efff;
  color: #2A4E7F;
}

.floated-chat-w .chat-messages .date-break {
  text-align: center;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.4);
}

.floated-chat-w .chat-controls {
  padding: 10px;
  padding-top: 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.floated-chat-w .chat-controls .message-input {
  border: 1px solid transparent;
  background-color: #fff;
  padding: 10px;
  width: 100%;
  display: block;
  border-radius: 0px;
}

.floated-chat-w .chat-controls .message-input:focus {
  outline: none;
  border-bottom: 1px solid #047bf8;
}

.floated-chat-w .chat-controls .chat-extra {
  text-align: left;
  padding-left: 0px;
  padding-top: 10px;
}

.floated-chat-w .chat-controls .chat-extra a {
  display: inline-block;
  margin-left: 10px;
  font-size: 16px;
  position: relative;
}

.floated-chat-w .chat-controls .chat-extra a .extra-tooltip {
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  font-weight: 500;
  font-size: 0.63rem;
  text-transform: uppercase;
  display: inline-block;
  padding: 2px 7px;
  border-radius: 4px;
  position: absolute;
  top: -20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  white-space: nowrap;
  display: none;
}

.floated-chat-w .chat-controls .chat-extra a:hover {
  text-decoration: none;
}

.floated-chat-w .chat-controls .chat-extra a:hover .extra-tooltip {
  display: block;
}

/* 13. App - Email */
/* Email App styles 
#1. EMAIL APP WRAPPER 
#2. LEFT MENU 
#3. MESSAGES LIST 
#4. CONTENT BOX 
#5. MESSAGE BOX 
#6. REPLY
#7. FOLDED STYLES
#8. DARK SCHEME
*/
/* #1. EMAIL APP WRAPPER */
.app-email-w a:focus, .app-email-w a:hover {
  text-decoration: none;
}

.app-email-i {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: stretch;
          align-items: stretch;
  background-color: #fff;
  border-radius: 6px;
}

/* #2. LEFT MENU */
.ae-side-menu {
  -webkit-box-flex: 0;
          flex: 0 0 170px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #ebeef3;
}

.ae-side-menu .aem-head {
  padding: 10px 20px;
  height: 50px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 10px;
}

.ae-side-menu .ae-main-menu {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.ae-side-menu .ae-main-menu li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
}

.ae-side-menu .ae-main-menu li a {
  display: block;
  padding: 20px;
}

.ae-side-menu .ae-main-menu li a i {
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
  color: #047bf8;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.ae-side-menu .ae-main-menu li a span {
  margin-left: 20px;
  display: inline-block;
  vertical-align: middle;
  color: #3E4B5B;
  font-weight: 500;
  text-transform: uppercase;
}

.ae-side-menu .ae-main-menu li:after {
  content: "";
  position: absolute;
  right: 0px;
  top: -1px;
  bottom: -1px;
  width: 5px;
  opacity: 0;
  background-color: #047bf8;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.ae-side-menu .ae-main-menu li:hover a i {
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}

.ae-side-menu .ae-main-menu li:hover:after, .ae-side-menu .ae-main-menu li.active:after {
  opacity: 1;
}

.ae-side-menu .ae-labels {
  margin-top: 20px;
}

.ae-side-menu .ae-labels .ae-labels-header {
  padding: 20px;
}

.ae-side-menu .ae-labels .ae-labels-header i {
  color: #047bf8;
  font-size: 20px;
  vertical-align: middle;
  display: inline-block;
}

.ae-side-menu .ae-labels .ae-labels-header span {
  margin-left: 20px;
  text-transform: uppercase;
  font-weight: 500;
  vertical-align: middle;
  display: inline-block;
}

.ae-side-menu .ae-labels .ae-label {
  display: block;
  padding: 10px;
  padding-left: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  color: #3E4B5B;
  white-space: nowrap;
}

.ae-side-menu .ae-labels .ae-label .label-pin {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: #047bf8;
  vertical-align: middle;
}

.ae-side-menu .ae-labels .ae-label .label-value {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.ae-side-menu .ae-labels .ae-label:before {
  content: "";
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.ae-side-menu .ae-labels .ae-label.ae-label-red .label-pin {
  background-color: #e65252;
}

.ae-side-menu .ae-labels .ae-label.ae-label-green .label-pin {
  background-color: #24b314;
}

.ae-side-menu .ae-labels .ae-label.ae-label-yellow .label-pin {
  background-color: #fbe4a0;
}

/* #3. MESSAGES LIST */
.ae-list-w {
  -webkit-box-flex: 0;
          flex: 0 0 370px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.ae-list-w .ael-head {
  padding: 10px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.ae-list-w .ael-head a {
  display: inline-block;
  vertical-align: middle;
}

.ae-list-w .ael-head a i {
  color: #047bf8;
  font-size: 20px;
}

.ae-list-w .ael-head a:hover {
  text-decoration: none;
}

.ae-list-w .ael-head a + a {
  margin-left: 15px;
}

.ae-list-w .ae-list {
  height: 1000px;
  overflow: hidden;
  position: relative;
}

.ae-list-w .ae-list .ae-item {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  cursor: pointer;
}

.ae-list-w .ae-list .ae-item.with-status .user-avatar-w {
  position: relative;
}

.ae-list-w .ae-list .ae-item.with-status .user-avatar-w:before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 2px;
  right: 2px;
  border-radius: 10px;
  box-shadow: 0px 0px 0px 3px #fff;
}

.ae-list-w .ae-list .ae-item.with-status.status-green .user-avatar-w:before {
  background-color: #24b314;
}

.ae-list-w .ae-list .ae-item.with-status.status-red .user-avatar-w:before {
  background-color: #e65252;
}

.ae-list-w .ae-list .ae-item.with-status.status-blue .user-avatar-w:before {
  background-color: #047bf8;
}

.ae-list-w .ae-list .ae-item.with-status.status-yellow .user-avatar-w:before {
  background-color: #fbe4a0;
}

.ae-list-w .ae-list .ae-item.active {
  background-color: #047bf8;
  color: #fff;
}

.ae-list-w .ae-list .ae-item.active .aei-content .aei-title {
  color: #fff;
}

.ae-list-w .ae-list .ae-item.active .aei-content .aei-sub-title {
  color: #fff;
}

.ae-list-w .ae-list .ae-item.active .aei-content .aei-text {
  color: rgba(255, 255, 255, 0.5);
}

.ae-list-w .ae-list .ae-item.active .aei-timestamp {
  color: rgba(255, 255, 255, 0.5);
}

.ae-list-w .ae-list .ae-item.active .user-avatar-w {
  box-shadow: 0px 0px 0px 3px #fff;
  border-radius: 50%;
}

.ae-list-w .ae-list .ae-item:hover {
  background-color: #f9f9f9;
}

.ae-list-w .ae-list .ae-item:hover.active {
  background-color: #047bf8;
}

.ae-list-w .ae-list .aei-image {
  margin-right: 20px;
}

.ae-list-w .ae-list .aei-image .user-avatar-w {
  width: 50px;
}

.ae-list-w .ae-list .aei-image .user-avatar-w img {
  border-radius: 50px;
  display: block;
  width: 50px;
  height: auto;
}

.ae-list-w .ae-list .aei-content {
  position: relative;
}

.ae-list-w .ae-list .aei-content .aei-timestamp {
  position: absolute;
  top: -10px;
  right: 0px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 0.63rem;
}

.ae-list-w .ae-list .aei-content .aei-title {
  margin-bottom: 4px;
}

.ae-list-w .ae-list .aei-content .aei-sub-title {
  color: #a7aeb5;
  text-transform: uppercase;
  font-size: 0.72rem;
  font-weight: 500;
}

.ae-list-w .ae-list .aei-content .aei-text {
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.81rem;
}

.ae-list-w .ae-load-more {
  display: block;
  padding: 10px;
  padding-bottom: 15px;
  text-align: center;
  cursor: pointer;
}

.ae-list-w .ae-load-more i {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  margin-right: 5px;
}

.ae-list-w .ae-load-more span {
  display: inline-block;
  vertical-align: middle;
  border-bottom: 1px solid #047bf8;
}

.ae-list-w .ae-load-more:hover span {
  border-bottom-color: #0362c6;
}

/* #4. CONTENT BOX */
.ae-content-w {
  background-color: #f6f7f8;
  -webkit-box-flex: 1;
          flex: 1;
}

.ae-content-w .aec-head {
  height: 50px;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
  background-color: #fff;
}

.ae-content-w .aec-head .separate {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding-right: 15px;
  margin-right: 5px;
}

.ae-content-w .aec-head a {
  display: inline-block;
  vertical-align: middle;
}

.ae-content-w .aec-head a i {
  color: #047bf8;
  font-size: 20px;
}

.ae-content-w .aec-head a.highlight i {
  color: #CB9E48;
}

.ae-content-w .aec-head a:hover {
  text-decoration: none;
}

.ae-content-w .aec-head a + a {
  margin-left: 15px;
}

.ae-content-w .aec-head .actions-left {
  display: -webkit-box;
  display: flex;
}

.ae-content-w .aec-head .actions-right {
  display: -webkit-box;
  display: flex;
}

.ae-content-w .aec-head .user-avatar {
  margin-left: 40px;
  padding-left: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.ae-content-w .aec-head .user-avatar img {
  display: block;
  width: 30px;
  height: auto;
  border-radius: 40px;
}

.ae-content {
  padding: 40px;
}

/* #5. MESSAGE BOX */
.aec-full-message-w {
  position: relative;
  z-index: 2;
  margin-bottom: 40px;
}

.aec-full-message-w .more-messages {
  position: absolute;
  left: 50%;
  top: -55px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.72rem;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: inline-block;
  border-bottom: 1px solid transparent;
  padding-bottom: 1px;
}

.aec-full-message-w .more-messages:hover {
  cursor: pointer;
  color: #047bf8;
  border-bottom: 1px solid #047bf8;
}

.aec-full-message-w.show-pack {
  margin-top: 40px;
}

.aec-full-message-w.show-pack:before {
  z-index: -1;
  content: "";
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px;
  top: -30px;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.aec-full-message-w.show-pack:after {
  z-index: -1;
  content: "";
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
  top: -15px;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.aec-full-message-w .aec-full-message {
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.aec-full-message-w .message-head {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

.aec-full-message-w .message-head .user-w {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

.aec-full-message-w .message-head .user-w .user-avatar-w {
  width: 50px;
}

.aec-full-message-w .message-head .user-w .user-avatar-w .user-avatar {
  border-radius: 40px;
  overflow: hidden;
}

.aec-full-message-w .message-head .user-w .user-avatar-w .user-avatar img {
  max-width: 100%;
  height: auto;
}

.aec-full-message-w .message-head .user-w.with-status .user-avatar-w {
  position: relative;
}

.aec-full-message-w .message-head .user-w.with-status .user-avatar-w:before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 2px;
  right: 2px;
  border-radius: 10px;
  box-shadow: 0px 0px 0px 3px #fff;
}

.aec-full-message-w .message-head .user-w.with-status.status-green .user-avatar-w:before {
  background-color: #24b314;
}

.aec-full-message-w .message-head .user-w.with-status.status-red .user-avatar-w:before {
  background-color: #e65252;
}

.aec-full-message-w .message-head .user-w .user-name {
  padding-left: 20px;
  -webkit-box-flex: 1;
          flex: 1;
}

.aec-full-message-w .message-head .user-w .user-title {
  margin-bottom: 2px;
  color: #047bf8;
}

.aec-full-message-w .message-head .user-w .user-role {
  font-weight: 500;
  font-size: 0.81rem;
}

.aec-full-message-w .message-head .user-w .user-role span {
  display: inline-block;
  margin-left: 5px;
  color: rgba(0, 0, 0, 0.4);
}

.aec-full-message-w .message-head .user-w .user-action {
  width: 50px;
  color: #047bf8;
  font-size: 18px;
}

.aec-full-message-w .message-head .message-info {
  color: rgba(0, 0, 0, 0.3);
  font-size: 0.72rem;
  text-align: right;
}

.aec-full-message-w .message-content {
  padding: 6% 10% 8% 10%;
}

.aec-full-message-w .message-attachments {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  margin-top: 20px;
}

.aec-full-message-w .message-attachments .attachments-heading {
  text-transform: uppercase;
  font-size: 0.72rem;
  color: rgba(0, 0, 0, 0.4);
}

.aec-full-message-w .message-attachments .attachments-docs {
  margin-top: 15px;
}

.aec-full-message-w .message-attachments .attachments-docs a {
  display: inline-block;
}

.aec-full-message-w .message-attachments .attachments-docs a i {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.aec-full-message-w .message-attachments .attachments-docs a span {
  display: inline-block;
  vertical-align: middle;
}

.aec-full-message-w .message-attachments .attachments-docs a:hover {
  text-decoration: none;
}

.aec-full-message-w .message-attachments .attachments-docs a + a {
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.older-pack {
  display: none;
}

/* #6. REPLY */
.aec-reply {
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.aec-reply .reply-header {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 30px;
  margin-bottom: 0px;
}

.aec-reply .reply-header i {
  font-size: 130px;
  color: rgba(0, 86, 255, 0.05);
  position: absolute;
  top: -70px;
  left: -70px;
}

.aec-reply .reply-header h5 {
  margin: 0px;
  font-size: 1.08rem;
}

.aec-reply .reply-header h5 span {
  color: #047bf8;
  border-bottom: 1px dotted #047bf8;
  display: inline-block;
}

.aec-reply .buttons-w {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
}

.aec-reply .buttons-w .btn, .aec-reply .buttons-w .all-wrapper .fc-button, .all-wrapper .aec-reply .buttons-w .fc-button {
  text-transform: uppercase;
  padding: 5px 10px;
  font-size: 0.7rem;
}

.aec-reply .buttons-w .btn .os-icon, .aec-reply .buttons-w .all-wrapper .fc-button .os-icon, .all-wrapper .aec-reply .buttons-w .fc-button .os-icon {
  margin-right: 10px;
}

.aec-reply .cke_chrome {
  border: none;
}

.aec-reply .cke_bottom {
  border: none;
}

/* #7. FOLDED STYLES */
.app-email-w.compact-side-menu .ae-side-menu {
  -webkit-box-flex: 0;
          flex: 0 1 60px;
  text-align: center;
}

.app-email-w.compact-side-menu .ae-side-menu .aem-head {
  -webkit-box-pack: center;
          justify-content: center;
}

.app-email-w.compact-side-menu .ae-side-menu .ae-main-menu li a span {
  display: none;
}

.app-email-w.compact-side-menu .ae-side-menu .ae-labels .ae-label {
  padding-left: 10px;
}

.app-email-w.compact-side-menu .ae-side-menu .ae-labels .ae-label span.label-value {
  display: none;
}

.app-email-w.compact-side-menu .ae-side-menu .ae-labels-header span {
  display: none;
}

/* #8. DARK SCHEME */
.color-scheme-dark .ae-side-menu {
  background-color: #293148;
  border-right-color: #202637;
}

.color-scheme-dark .ae-side-menu .ae-main-menu li a span {
  color: #ccd9e8;
}

.color-scheme-dark .ae-side-menu .ae-main-menu li {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.color-scheme-dark .ae-side-menu .ae-labels .ae-label {
  border-bottom-color: rgba(255, 255, 255, 0.05);
  color: #ccd9e8;
}

.color-scheme-dark .aem-head {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.color-scheme-dark .ael-head {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.color-scheme-dark .ael-head select {
  background: #202738;
  color: #7f8696;
  border-color: #141927;
}

.color-scheme-dark .ae-content-w {
  background-color: #293148;
}

.color-scheme-dark .ae-content-w .aec-head {
  background-color: rgba(0, 0, 0, 0.1);
}

.color-scheme-dark .ae-content-w .aec-head .separate {
  border-right-color: rgba(255, 255, 255, 0.05);
}

.color-scheme-dark .ae-content-w .aec-head .user-avatar {
  border-left-color: rgba(255, 255, 255, 0.05);
}

.color-scheme-dark .ae-content-w .aec-full-message-w .aec-full-message, .color-scheme-dark .ae-content-w .aec-reply {
  background-color: #3b4768;
  box-shadow: 0px 5px 15px rgba(22, 22, 35, 0.1);
}

.color-scheme-dark .ae-content-w .aec-reply .buttons-w {
  border-top-color: rgba(255, 255, 255, 0.05);
}

.color-scheme-dark .ae-content-w .aec-reply .reply-header {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.color-scheme-dark .ae-content-w .aec-full-message-w.show-pack:after {
  background-color: rgba(59, 71, 104, 0.7);
}

.color-scheme-dark .ae-content-w .aec-full-message-w.show-pack:before {
  background-color: rgba(59, 71, 104, 0.4);
}

.color-scheme-dark .ae-content-w .aec-full-message-w .more-messages {
  color: #047bf8;
  border-bottom-color: #047bf8;
}

.color-scheme-dark .ae-content-w .aec-full-message-w .message-attachments .attachments-heading,
.color-scheme-dark .ae-content-w .aec-full-message-w .message-head .message-info,
.color-scheme-dark .ae-content-w .aec-full-message-w .message-head .user-w .user-role span {
  color: rgba(218, 226, 243, 0.4);
}

.color-scheme-dark .ae-content-w .aec-full-message-w .message-head {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.color-scheme-dark .ae-content-w .aec-full-message-w .message-attachments {
  border-top-color: rgba(255, 255, 255, 0.05);
}

.color-scheme-dark .ae-list-w {
  background-color: #323c58;
  border-right-color: #202637;
}

.color-scheme-dark .ae-list-w .ae-item:hover {
  background-color: #047bf8;
}

.color-scheme-dark .ae-list-w .ae-item:hover .aei-sub-title {
  color: #fff;
}

.color-scheme-dark .ae-list-w .ae-list .aei-content .aei-text {
  color: rgba(218, 226, 243, 0.4);
}

.color-scheme-dark .ae-list-w .ae-list .aei-content .aei-timestamp {
  color: rgba(218, 226, 243, 0.4);
}

.color-scheme-dark .ae-list-w .ae-list .ae-item.with-status .user-avatar-w:before {
  box-shadow: 0px 0px 0px 3px #323c58;
}

/* 14. App - Projects */
.projects-list {
  margin-bottom: 2rem;
}

.projects-list .project-head {
  padding: 20px 40px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.projects-list .project-head .project-title {
  text-transform: uppercase;
  letter-spacing: 2px;
}

.projects-list .project-head .project-title h5 {
  margin-bottom: 0;
}

.projects-list .project-head .project-users {
  -webkit-box-flex: 0;
          flex: 0 0 220px;
  text-align: right;
}

.projects-list .project-head .project-users .avatar {
  width: 35px;
  border-radius: 35px;
  box-shadow: 0px 0px 0px 5px #fff;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
}

.projects-list .project-head .project-users .avatar img {
  height: auto;
  width: 35px;
}

.projects-list .project-head .project-users .more {
  background-color: #047bf8;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  left: -10px;
  padding: 2px 7px;
  border-radius: 12px;
  color: #fff;
  font-size: 0.63rem;
}

.projects-list .project-info {
  padding: 20px 40px;
}

.projects-list .project-info .os-progress-bar {
  margin-bottom: 0px;
}

.projects-list .project-info .os-progress-bar + .os-progress-bar {
  margin-top: 1rem;
}

.projects-list .project-box + .project-box {
  margin-top: 2rem;
}

/* 15. App - Full Chat */
.full-chat-w {
  /*
    #3. Middle Section
  */
}

.full-chat-w .full-chat-i {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: stretch;
          align-items: stretch;
  background-color: #fff;
  padding: 0px;
}

.full-chat-w .full-chat-left {
  -webkit-box-flex: 0;
          flex: 0 0 340px;
  background-color: #f6f7f8;
  padding: 20px 0px;
}

.full-chat-w .full-chat-left .os-tabs-w .nav {
  padding: 0px 20px;
  flex-wrap: nowrap;
}

.full-chat-w .full-chat-left .nav-link i {
  margin-bottom: 10px;
}

.full-chat-w .full-chat-left .chat-search {
  padding: 20px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.full-chat-w .full-chat-left .element-search {
  position: relative;
}

.full-chat-w .full-chat-left .element-search:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 15px;
  top: 48%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 20px;
  content: "\e92c";
  color: rgba(0, 0, 0, 0.2);
}

.full-chat-w .full-chat-left .element-search input {
  border: none;
  box-shadow: none;
  background-color: #fff;
  border-radius: 30px;
  padding: 10px 15px 10px 50px;
  display: block;
  width: 100%;
  outline: none;
}

.full-chat-w .full-chat-left .element-search input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.full-chat-w .full-chat-left .element-search input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.full-chat-w .full-chat-left .element-search input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.full-chat-w .full-chat-left .element-search input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.full-chat-w .full-chat-left .element-search input::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.full-chat-w .full-chat-left .user-list .user-w {
  display: -webkit-box;
  display: flex;
  padding: 20px 30px;
  -webkit-box-align: center;
          align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.full-chat-w .full-chat-left .user-list .user-w .avatar {
  margin-right: 20px;
  -webkit-box-flex: 0;
          flex: 0 0 50px;
}

.full-chat-w .full-chat-left .user-list .user-w .avatar img {
  width: 50px;
  height: auto;
  border-radius: 50px;
  display: block;
}

.full-chat-w .full-chat-left .user-list .user-w .user-info {
  -webkit-box-flex: 1;
          flex: 1 1 auto;
}

.full-chat-w .full-chat-left .user-list .user-w .user-name {
  font-weight: 500;
  font-size: 0.99rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.full-chat-w .full-chat-left .user-list .user-w .last-message {
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.81rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.full-chat-w .full-chat-left .user-list .user-w .user-date {
  float: right;
  padding: 2px 7px;
  background-color: #fff;
  border-radius: 12px;
  font-size: 0.72rem;
  color: rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.full-chat-w .full-chat-left .user-list .user-w:hover {
  background-color: #047bf8;
  cursor: pointer;
}

.full-chat-w .full-chat-left .user-list .user-w:hover .user-name {
  color: #fff;
}

.full-chat-w .full-chat-left .user-list .user-w:hover .last-message {
  color: rgba(255, 255, 255, 0.5);
}

.full-chat-w .full-chat-left .user-list .user-w:hover .user-date {
  background-color: #046fdf;
  color: rgba(255, 255, 255, 0.3);
}

.full-chat-w .full-chat-middle {
  -webkit-box-flex: 1;
          flex: 1 1 auto;
  background-color: #fff;
}

.full-chat-w .full-chat-middle .chat-head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
}

.full-chat-w .full-chat-middle .user-info {
  font-size: 1.08rem;
}

.full-chat-w .full-chat-middle .user-info span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.full-chat-w .full-chat-middle .user-info a {
  display: inline-block;
  vertical-align: middle;
  border-bottom: 1px dotted #047bf8;
}

.full-chat-w .full-chat-middle .user-info a:hover {
  text-decoration: none;
}

.full-chat-w .full-chat-middle .user-actions a {
  margin-left: 1rem;
  font-size: 24px;
  display: inline-block;
  vertical-align: middle;
}

.full-chat-w .chat-content-w {
  height: 600px;
  overflow-y: scroll;
  position: relative;
}

.full-chat-w .chat-content-w .chat-content {
  padding: 50px;
  min-height: 600px;
}

.full-chat-w .chat-content-w .chat-date-separator {
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
  font-size: 0.81rem;
  position: relative;
  margin: 40px 0px;
}

.full-chat-w .chat-content-w .chat-date-separator:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
}

.full-chat-w .chat-content-w .chat-date-separator span {
  display: inline-block;
  background-color: #fff;
  padding: 0px 10px;
  position: relative;
}

.full-chat-w .chat-content-w .chat-message {
  margin-bottom: 20px;
}

.full-chat-w .chat-content-w .chat-message .chat-message-content {
  padding: 15px 35px;
  background-color: #fff9f0;
  color: #594939;
  max-width: 400px;
  display: inline-block;
  margin-bottom: -20px;
  margin-left: 20px;
  border-radius: 20px;
  text-align: left;
}

.full-chat-w .chat-content-w .chat-message .chat-message-avatar {
  display: inline-block;
  vertical-align: bottom;
}

.full-chat-w .chat-content-w .chat-message .chat-message-avatar img {
  width: 40px;
  height: auto;
  border-radius: 30px;
  display: inline-block;
  box-shadow: 0px 0px 0px 10px #fff;
}

.full-chat-w .chat-content-w .chat-message .chat-message-date {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 0.72rem;
  color: rgba(0, 0, 0, 0.3);
}

.full-chat-w .chat-content-w .chat-message.self {
  text-align: right;
}

.full-chat-w .chat-content-w .chat-message.self .chat-message-content {
  background-color: #f0f9ff;
  color: #2A4E7F;
  margin-right: 20px;
  margin-left: 0px;
}

.full-chat-w .chat-controls {
  padding: 20px;
  padding-top: 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.full-chat-w .chat-input input[type="text"] {
  padding: 20px 20px 20px 0px;
  border: none;
  display: block;
  width: 100%;
  outline: none;
}

.full-chat-w .chat-input-extra {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
}

.full-chat-w .chat-input-extra .chat-extra-actions a {
  margin-right: 10px;
  display: inline-block;
}

.full-chat-w .full-chat-right {
  -webkit-box-flex: 0;
          flex: 0 0 260px;
  background-color: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.full-chat-w .user-intro {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
}

.full-chat-w .user-intro .avatar {
  width: 90px;
  height: 90px;
  display: inline-block;
}

.full-chat-w .user-intro .avatar img {
  width: 90px;
  border-radius: 60px;
  height: auto;
}

.full-chat-w .user-intro .user-intro-info {
  margin-top: 1rem;
}

.full-chat-w .user-intro .user-intro-info .user-name {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #047bf8;
}

.full-chat-w .user-intro .user-intro-info .user-sub {
  color: rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.72rem;
  margin-top: 5px;
}

.full-chat-w .user-intro .user-intro-info .user-social {
  margin-top: 1rem;
}

.full-chat-w .user-intro .user-intro-info .user-social a {
  display: inline-block;
  margin: 0px 6px;
  font-size: 24px;
}

.full-chat-w .user-intro .user-intro-info .user-social a:hover {
  text-decoration: none;
}

.full-chat-w .user-intro .user-intro-info .user-social i.os-icon.os-icon-twitter {
  color: #31a7f3;
}

.full-chat-w .user-intro .user-intro-info .user-social i.os-icon.os-icon-facebook {
  color: #175dc5;
}

.full-chat-w .chat-info-section {
  padding: 20px;
}

.full-chat-w .chat-info-section .ci-header i {
  color: #047bf8;
  font-size: 20px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

.full-chat-w .chat-info-section .ci-header span {
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  letter-spacing: 2px;
  display: inline-block;
  vertical-align: middle;
}

.full-chat-w .chat-info-section .ci-content {
  padding: 20px;
}

.full-chat-w .chat-info-section .ci-content .ci-file-list ul {
  list-style-type: square;
  color: #98c9fd;
  margin-left: 0px;
  margin-bottom: 0px;
  padding-left: 10px;
}

.full-chat-w .chat-info-section .ci-content .ci-file-list ul li {
  margin: 5px;
}

.full-chat-w .chat-info-section .ci-content .ci-file-list ul li a {
  font-size: 0.81rem;
  border-bottom: 1px solid #047bf8;
}

.full-chat-w .chat-info-section .ci-content .ci-file-list ul li a:hover {
  text-decoration: none;
}

.full-chat-w .chat-info-section .ci-content .ci-photos-list {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: start;
          align-items: flex-start;
}

.full-chat-w .chat-info-section .ci-content .ci-photos-list img {
  margin: 2%;
  border-radius: 6px;
  width: 45%;
  display: inline-block;
  height: auto;
}

.full-chat-w .chat-info-section + .chat-info-section {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* 16. Pricing Plans */
/* 

Pricing Plans Styles

#1. 
#2. 
#3. 
#4. 
#5. 
#6. 


*/
/*

#1.

*/
.pricing-plans {
  background-color: #fff;
}

.pricing-plan + .pricing-plan {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.pricing-plan {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  padding-bottom: 20px;
  background-color: #f9f9f9;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.pricing-plan.with-hover-effect:hover, .pricing-plan.highlight {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
  box-shadow: 0 2px 30px 0 rgba(54, 88, 206, 0.2);
}

.pricing-plan.with-hover-effect:hover .plan-price-w .price-value, .pricing-plan.highlight .plan-price-w .price-value {
  color: #047bf8;
}

.pricing-plan.with-hover-effect:hover .plan-head, .pricing-plan.highlight .plan-head {
  box-shadow: inset 0px 5px 0px #047bf8;
}

.pricing-plan.with-hover-effect:hover .plan-name {
  color: #3E4B5B;
}

.pricing-plan.highlight {
  margin-top: -20px;
  margin-bottom: -20px;
}

.pricing-plan .plan-image img {
  width: 80px;
}

.pricing-plan .plan-head {
  background-color: #fff;
  padding-top: 40px;
}

.pricing-plan .plan-body {
  background-color: #fff;
}

.pricing-plan .plan-name {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.5rem;
  color: #047bf8;
  font-weight: 500;
  font-family: "Avenir Next W01", "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  padding-bottom: 40px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.pricing-plan .plan-image + .plan-name {
  margin-top: 20px;
}

.pricing-plan .plan-price-w {
  padding-top: 40px;
  margin-bottom: 40px;
}

.pricing-plan .plan-price-w .price-value {
  font-size: 3.6rem;
  line-height: 1;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.pricing-plan .plan-price-w .price-label {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.3);
  padding-top: 5px;
  margin-top: 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: inline-block;
}

.pricing-plan .plan-btn-w {
  padding-bottom: 40px;
}

.pricing-plan .plan-description {
  text-align: left;
  padding: 30px 15%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.5);
}

.pricing-plan .plan-description h6 {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 30px;
  font-size: 0.9rem;
}

.pricing-plan .plan-description ul {
  list-style: none;
  padding-left: 30px;
  margin-top: 20px;
}

.pricing-plan .plan-description ul li {
  position: relative;
  margin-bottom: 10px;
}

.pricing-plan .plan-description ul li:before {
  content: '\e961';
  color: #047bf8;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: -30px;
  top: 5px;
}

/*

#2.

*/
/*

#3.

*/
/*

#4.

*/
/*

#5.

*/
/* 17. Pipelines */
/* Pipeline Styles*/
.pipeline {
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 30px 0 rgba(16, 37, 133, 0.5);
  padding: 20px;
  margin-bottom: 20px;
}

.pipeline.blue {
  background-color: #1D54EF;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1D54EF), to(#4327DF));
  background-image: linear-gradient(-180deg, #1D54EF 0%, #4327DF 100%);
  box-shadow: 0 2px 30px 0 rgba(16, 37, 133, 0.5);
}

.pipeline.teal {
  background-color: #07B77F;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#07B77F), color-stop(98%, #2767DF));
  background-image: linear-gradient(-180deg, #07B77F 0%, #2767DF 98%);
  box-shadow: 0 2px 30px 0 rgba(7, 186, 171, 0.5);
}

.pipeline.purple {
  background-color: #28428c;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#28428c), to(#510d8c));
  background-image: linear-gradient(-180deg, #28428c 0%, #510d8c 100%);
  box-shadow: 0 2px 30px 0 rgba(42, 16, 133, 0.5);
}

.pipeline.green {
  background-color: #7BC10C;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#7BC10C), color-stop(95%, #057051));
  background-image: linear-gradient(-180deg, #7BC10C 0%, #057051 95%);
  box-shadow: 0 2px 30px 0 rgba(31, 219, 78, 0.5);
}

.pipeline.red {
  background-color: #960644;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#960644), color-stop(95%, #af760b));
  background-image: linear-gradient(-180deg, #960644 0%, #af760b 95%);
  box-shadow: 0 2px 30px 0 rgba(154, 7, 58, 0.5);
}

.pipeline.pink {
  background-color: #a0008f;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#a0008f), color-stop(98%, #2767DF));
  background-image: linear-gradient(-180deg, #a0008f 0%, #2767DF 98%);
  box-shadow: 0 2px 30px 0 rgba(7, 8, 186, 0.5);
}

.pipeline.white {
  background-color: #fff;
  box-shadow: 0 2px 30px 0 rgba(16, 37, 133, 0.1);
}

.pipeline.white .pipeline-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
}

.pipeline.white .pipeline-header .pipeline-name {
  color: #334152;
}

.pipeline.white .pipeline-header .pipeline-count {
  color: rgba(0, 0, 0, 0.5);
}

.pipeline.white .pipeline-header .pipeline-settings {
  color: rgba(0, 0, 0, 0.4);
}

.pipeline.white .pipeline-header .pipeline-value {
  color: #047bf8;
}

.pipeline.white .pipeline-item {
  box-shadow: none;
  border-radius: 4px;
  margin-bottom: 15px;
  border: 1px solid #d1d4e8;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  cursor: move;
}

.pipeline.white .pipeline-item:last-child {
  margin-bottom: 0px;
}

.pipeline.white .pipeline-item:hover {
  border: 1px solid #9ea4cf;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.pipeline.white .pipeline-item .pi-foot {
  border-radius: 0px 0px 4px 4px;
}

.pipeline.white.lined-primary {
  border-top: 5px solid #047bf8;
  border-radius: 0px 0px 4px 4px;
}

.pipeline.white.lined-success {
  border-top: 5px solid #24b314;
  border-radius: 0px 0px 4px 4px;
}

.pipeline.white.lined-danger {
  border-top: 5px solid #e65252;
  border-radius: 0px 0px 4px 4px;
}

.pipeline.white.lined-warning {
  border-top: 5px solid #fbe4a0;
  border-radius: 0px 0px 4px 4px;
}

/* Pipeline Header Styles */
.pipeline-header {
  position: relative;
  margin-bottom: 20px;
}

.pipeline-header .pipeline-header-numbers {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
}

.pipeline-header .pipeline-name {
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
  margin-bottom: 5px;
  line-height: 1;
  padding-right: 30px;
}

.pipeline-header .pipeline-value {
  color: #FFD038;
  letter-spacing: 3px;
  font-size: 1.17rem;
}

.pipeline-header .pipeline-count {
  font-size: 0.81rem;
  color: rgba(255, 255, 255, 0.7);
}

.pipeline-header .pipeline-settings {
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  position: absolute;
  top: 0px;
  right: 0px;
}

.pipeline-body {
  min-height: 100px;
}

.pipeline-body.empty {
  border: 1px dashed rgba(0, 0, 0, 0.2);
}

/* Pipeline Item Styles */
.pipeline-item {
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 4px;
  position: relative;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4);
}

.pipeline-item:last-child {
  margin-bottom: 0px;
}

.pipeline-item .pi-controls {
  position: absolute;
  top: 5px;
  right: 10px;
  line-height: 1;
}

.pipeline-item .pi-controls a {
  font-size: 16px;
  line-height: 14px;
  color: #047bf8;
  display: inline-block;
  vertical-align: middle;
}

.pipeline-item .pi-controls .pi-settings {
  display: inline-block;
  color: #047bf8;
  vertical-align: middle;
}

.pipeline-item .pi-controls .pi-settings > i {
  font-size: 15px;
}

.pipeline-item .pi-controls .status {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  width: 11px;
  height: 11px;
  border-radius: 10px;
  cursor: pointer;
}

.pipeline-item .pi-controls .status.status-green {
  background-color: #24b314;
}

.pipeline-item .pi-controls .status.status-red {
  background-color: #e65252;
}

.pipeline-item .pi-controls .status.status-yellow {
  background-color: #fbe4a0;
}

.pipeline-item .pi-body {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
          align-items: center;
  padding: 10px 15px;
}

.pipeline-item .pi-body .avatar {
  width: 50px;
  margin-right: 15px;
  -webkit-box-flex: 0;
          flex: 0 0 50px;
}

.pipeline-item .pi-body .avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.pipeline-item .pi-body .pi-name {
  margin-bottom: 0px;
}

.pipeline-item .pi-body .pi-sub {
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.81rem;
}

.pipeline-item .pi-foot {
  background-color: #F1F4F8;
  padding: 10px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
  border-radius: 0px 0px 4px 4px;
}

.pipeline-item .pi-foot .tags {
  line-height: 1;
}

.pipeline-item .pi-foot .tags .tag {
  padding: 3px 6px;
  line-height: 1;
  display: inline-block;
  background-color: #fff;
  border: 1px solid #047bf8;
  color: #047bf8;
  margin-right: 2px;
  font-size: 0.72rem;
  border-radius: 4px;
  margin-bottom: 2px;
  margin-top: 2px;
}

.pipeline-item .pi-foot .extra-info {
  margin-right: 5px;
  white-space: nowrap;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.63rem;
  letter-spacing: 1px;
}

.pipeline-item .pi-foot .extra-info i {
  margin-right: 5px;
  font-size: 16px;
  vertical-align: middle;
  color: #047bf8;
  display: inline-block;
}

.pipeline-item .pi-foot .extra-info span {
  vertical-align: middle;
  display: inline-block;
}

.control-header {
  padding-bottom: 10px;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.control-header select.form-control {
  font-weight: 400;
  border-color: rgba(0, 0, 0, 0.5);
}

.control-header label {
  text-transform: uppercase;
  font-size: 0.72rem;
  letter-spacing: 1px;
}

.control-header .form-group {
  margin-bottom: 0px;
}

.color-scheme-dark .control-header {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.color-scheme-dark .control-header select {
  background: #202738;
  color: #7f8696;
  border-color: #141927;
}

.color-scheme-dark .control-header label {
  color: rgba(218, 226, 243, 0.4);
}

.color-scheme-dark .pipeline.white {
  background-color: #323c58;
  box-shadow: 0px 5px 15px rgba(22, 22, 35, 0.1);
}

.color-scheme-dark .pipeline.white.lined-warning {
  border-top-color: #fbe4a0;
}

.color-scheme-dark .pipeline.white .pipeline-header .pipeline-name {
  color: #fff;
}

.color-scheme-dark .pipeline.white .pipeline-header .pipeline-settings {
  color: #fff;
}

.color-scheme-dark .pipeline.white .pipeline-header .pipeline-count {
  color: rgba(218, 226, 243, 0.4);
}

.color-scheme-dark .pipeline.white .pipeline-item {
  background-color: #384362;
  border-color: #293148;
}

.color-scheme-dark .pipeline.white .pipeline-item .pi-body .pi-sub {
  color: rgba(218, 226, 243, 0.4);
}

.color-scheme-dark .pipeline.white .pipeline-item .pi-foot {
  background-color: #2c354e;
}

.color-scheme-dark .pipeline.white .pipeline-item .pi-foot .extra-info {
  color: rgba(218, 226, 243, 0.4);
}

.color-scheme-dark .pipeline.white .pipeline-item .pi-foot .tags .tag {
  color: #fff;
  background-color: #422af5;
  border-color: #422af5;
}

/* 18. Dropdowns */
.os-dropdown-trigger {
  position: relative;
  cursor: pointer;
}

.os-dropdown-trigger.over .os-dropdown {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate3d(100%, 100%, 0);
          transform: translate3d(100%, 100%, 0);
}

.os-dropdown-trigger.os-dropdown-position-center .os-dropdown {
  right: 50%;
  -webkit-transform: translate3d(50%, 110%, 0);
          transform: translate3d(50%, 110%, 0);
}

.os-dropdown-trigger.os-dropdown-position-center.over .os-dropdown {
  -webkit-transform: translate3d(50%, 100%, 0);
          transform: translate3d(50%, 100%, 0);
}

.os-dropdown-trigger.os-dropdown-position-left .os-dropdown {
  right: 0px;
  -webkit-transform: translate3d(0%, 110%, 0);
          transform: translate3d(0%, 110%, 0);
}

.os-dropdown-trigger.os-dropdown-position-left.over .os-dropdown {
  -webkit-transform: translate3d(0%, 100%, 0);
          transform: translate3d(0%, 100%, 0);
}

.os-dropdown-trigger.os-dropdown-position-right .os-dropdown {
  right: 100%;
  -webkit-transform: translate3d(100%, 110%, 0);
          transform: translate3d(100%, 110%, 0);
}

.os-dropdown-trigger.os-dropdown-position-right.over .os-dropdown {
  -webkit-transform: translate3d(100%, 100%, 0);
          transform: translate3d(100%, 100%, 0);
}

.os-dropdown-trigger.os-dropdown-position-right-center .os-dropdown {
  right: 0%;
  bottom: 50%;
  -webkit-transform: translate3d(110%, 50%, 0);
          transform: translate3d(110%, 50%, 0);
}

.os-dropdown-trigger.os-dropdown-position-right-center.over .os-dropdown {
  -webkit-transform: translate3d(100%, 50%, 0);
          transform: translate3d(100%, 50%, 0);
}

.os-dropdown-trigger.os-dropdown-position-left-center .os-dropdown {
  right: 100%;
  bottom: 50%;
  -webkit-transform: translate3d(-10%, 50%, 0);
          transform: translate3d(-10%, 50%, 0);
}

.os-dropdown-trigger.os-dropdown-position-left-center.over .os-dropdown {
  -webkit-transform: translate3d(0%, 50%, 0);
          transform: translate3d(0%, 50%, 0);
}

.os-dropdown {
  background-color: #047bf8;
  color: #fff;
  padding: 20px 15px;
  position: absolute;
  z-index: 999;
  bottom: 5px;
  right: 0px;
  visibility: hidden;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transform: translate3d(100%, 110%, 0);
          transform: translate3d(100%, 110%, 0);
  opacity: 0;
  border-radius: 4px;
  box-shadow: 0px 10px 25px 0px rgba(26, 32, 39, 0.1);
  overflow: hidden;
  font-size: 0.9rem;
  text-align: left;
}

.os-dropdown > .icon-w {
  position: absolute;
  top: -30px;
  right: -30px;
  color: rgba(0, 0, 0, 0.08);
  font-size: 100px;
}

.os-dropdown ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.os-dropdown ul li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.os-dropdown ul li:last-child {
  border-bottom: none;
}

.os-dropdown ul li a {
  display: block;
  white-space: nowrap;
  padding: 10px 10px 10px 10px;
  line-height: 1;
  color: #fff;
  font-size: 0.9rem;
}

.os-dropdown ul li a:hover {
  text-decoration: none;
}

.os-dropdown ul li a i {
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  font-size: 22px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.os-dropdown ul li a span {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  font-size: 0.9rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.os-dropdown ul li a i + span {
  padding-right: 10px;
}

.os-dropdown ul li a:hover i {
  color: #fff;
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.os-dropdown ul li a:hover span {
  -webkit-transform: translateX(3px);
          transform: translateX(3px);
}

.os-dropdown.message-list {
  padding: 15px 10px;
}

.os-dropdown.message-list li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.os-dropdown.message-list li:last-child {
  border-bottom: none;
}

.os-dropdown.message-list li > a {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
          align-items: center;
  padding: 10px 15px;
}

.os-dropdown.message-list li > a:hover {
  background-color: rgba(124, 137, 234, 0.07);
}

.os-dropdown.message-list li > a:hover .user-avatar-w img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.os-dropdown.message-list li > a:hover .message-content {
  -webkit-transform: translateX(3px);
          transform: translateX(3px);
}

.os-dropdown.message-list li > a .user-avatar-w {
  -webkit-box-flex: 0;
          flex: 0 0 40px;
  margin-right: 15px;
}

.os-dropdown.message-list li > a .user-avatar-w img {
  width: 40px;
  border-radius: 30px;
  height: auto;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: block;
}

.os-dropdown.message-list li > a .message-content {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.os-dropdown.message-list li > a .message-content .message-from {
  color: #fff;
  margin-bottom: 5px;
}

.os-dropdown.message-list li > a .message-content .message-title {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.54rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 2px;
  margin-bottom: 0px;
}

.os-dropdown.light {
  background-color: #fff;
  box-shadow: 0px 5px 75px 0px rgba(12, 76, 140, 0.21), 0px 3px 7px 0px rgba(12, 76, 140, 0.14);
}

.os-dropdown.light.message-list > .icon-w {
  color: rgba(4, 123, 248, 0.1);
}

.os-dropdown.light.message-list li {
  border-bottom: 1px solid rgba(113, 133, 171, 0.09);
}

.os-dropdown.light.message-list li:last-child {
  border-bottom: none;
}

.os-dropdown.light.message-list li > a .message-content .message-from {
  color: #334152;
}

.os-dropdown.light.message-list li > a .message-content .message-title {
  color: #047bf8;
}

/* 19. App - TODO */
/* 

TODO Application

#1. Sidebar
#2. Main Content

*/
.todo-app-w {
  display: -webkit-box;
  display: flex;
  /*
  #1. Sidebar
  */
  /*
  #2. Main Content
  */
}

.todo-app-w .todo-sidebar {
  -webkit-box-flex: 0;
          flex: 0 0 350px;
  background-color: #F9F9F9;
  padding: 40px;
}

.todo-app-w .todo-sidebar .todo-sidebar-section {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.todo-app-w .todo-sidebar .todo-sidebar-section:first-child {
  padding-top: 0px;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-header {
  color: #047bf8;
  position: relative;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-header span {
  display: inline-block;
  vertical-align: middle;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-header span + .os-icon {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  font-size: 22px;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-header span + .os-icon.starred {
  color: #E7AD10;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-header span + .os-icon.fire {
  color: #ff1b1b;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-contents {
  margin-top: 20px;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-toggle {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: -30px;
  color: #047bf8;
  font-size: 16px;
  position: absolute;
  text-decoration: none;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-toggle:hover {
  color: #024994;
}

.todo-app-w .todo-sidebar ul {
  margin-left: 0px;
  padding-left: 30px;
}

.todo-app-w .todo-sidebar ul.projects-list {
  list-style: none;
  font-size: 0.99rem;
  margin-bottom: 0px;
}

.todo-app-w .todo-sidebar ul.projects-list li {
  position: relative;
  margin-bottom: 10px;
}

.todo-app-w .todo-sidebar ul.projects-list li:before {
  content: "\e981";
  color: #047bf8;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  position: absolute;
  left: -30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.todo-app-w .todo-sidebar ul.projects-list li a {
  color: #3E4B5B;
  display: block;
}

.todo-app-w .todo-sidebar ul.projects-list li.add-new-project {
  margin-top: 20px;
}

.todo-app-w .todo-sidebar ul.projects-list li.add-new-project:before {
  content: "\e969";
}

.todo-app-w .todo-sidebar ul.projects-list li.add-new-project a {
  color: #047bf8;
  border-bottom: 1px solid #047bf8;
  display: inline-block;
  font-size: 0.9rem;
}

.todo-app-w .todo-sidebar ul.projects-list li.add-new-project a:hover {
  text-decoration: none;
}

.todo-app-w .todo-sidebar ul.tasks-list {
  list-style: none;
  padding-left: 25px;
}

.todo-app-w .todo-sidebar ul.tasks-list li {
  position: relative;
  margin-bottom: 10px;
}

.todo-app-w .todo-sidebar ul.tasks-list li strong {
  color: #3E4B5B;
  display: block;
}

.todo-app-w .todo-sidebar ul.tasks-list li span {
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.72rem;
  display: inline-block;
}

.todo-app-w .todo-sidebar ul.tasks-list li:before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  left: -25px;
  top: 7px;
  border-radius: 10px;
}

.todo-app-w .todo-sidebar ul.tasks-list li a {
  color: #3E4B5B;
  display: block;
}

.todo-app-w .todo-sidebar ul.tasks-list li.danger:before {
  background-color: #f59595;
}

.todo-app-w .todo-sidebar ul.tasks-list li.warning:before {
  background-color: #fbe4a0;
}

.todo-app-w .todo-sidebar ul.tasks-list li.success:before {
  background-color: #b1ef98;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-sub-section {
  padding-left: 30px;
  position: relative;
  margin-bottom: 15px;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-sub-section .todo-sidebar-section-sub-section-toggler {
  position: absolute;
  z-index: 2;
  top: 5px;
  right: 0px;
  font-size: 14px;
  cursor: pointer;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-sub-section .todo-sidebar-section-sub-header {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 10px;
  padding-top: 10px;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-sub-section .todo-sidebar-section-sub-header i.os-icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: -30px;
  font-size: 18px;
  color: #047bf8;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-sub-section .todo-sidebar-section-sub-header h6 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.72rem;
  margin: 0px;
  color: #047bf8;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-sub-section .todo-sidebar-section-sub-section-content ul {
  list-style: none;
  padding: 10px 0px;
  margin: 0px;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-sub-section .todo-sidebar-section-sub-section-content ul li {
  padding: 3px 0px;
  margin: 0px;
}

.todo-app-w .todo-sidebar .todo-sidebar-section-sub-section .todo-sidebar-section-sub-section-content ul li a {
  color: #3E4B5B;
  display: inline-block;
  font-size: 0.9rem;
  line-height: 1.2;
  height: 1.08rem;
  overflow: hidden;
}

.todo-app-w .todo-content {
  background-color: #fff;
  -webkit-box-flex: 1;
          flex: 1;
  padding: 40px;
}

.todo-app-w .todo-content .todo-content-header {
  margin-bottom: 20px;
  color: #047bf8;
}

.todo-app-w .todo-content .todo-content-header i {
  margin-right: 10px;
  font-size: 22px;
  display: inline-block;
  vertical-align: middle;
}

.todo-app-w .todo-content .todo-content-header span {
  display: inline-block;
  vertical-align: middle;
}

.todo-app-w .todo-content .all-tasks-w {
  padding: 20px 30px;
}

.todo-app-w .todo-content .tasks-header-w {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 0px;
  position: relative;
  margin-bottom: 30px;
  margin-top: 20px;
}

.todo-app-w .todo-content .tasks-header-w .tasks-header-toggler {
  position: absolute;
  color: #047bf8;
  font-size: 18px;
  position: absolute;
  left: -30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-decoration: none;
}

.todo-app-w .todo-content .tasks-header-w .tasks-header-toggler:hover {
  color: #024994;
}

.todo-app-w .todo-content .tasks-header-w .tasks-header {
  display: inline-block;
  margin-bottom: 0px;
}

.todo-app-w .todo-content .tasks-header-w .tasks-sub-header {
  display: inline-block;
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.3);
  font-size: 0.81rem;
}

.todo-app-w .todo-content .tasks-header-w .add-task-btn {
  float: right;
}

.todo-app-w .todo-content .tasks-header-w .add-task-btn span, .todo-app-w .todo-content .tasks-header-w .add-task-btn i {
  display: inline-block;
  vertical-align: middle;
}

.todo-app-w .todo-content .tasks-header-w .add-task-btn span {
  border-bottom: 1px solid #047bf8;
}

.todo-app-w .todo-content .tasks-header-w .add-task-btn i.os-icon {
  margin-right: 5px;
  font-size: 20px;
}

.todo-app-w .todo-content .tasks-list-header {
  text-transform: uppercase;
  color: #047bf8;
  letter-spacing: 1px;
  font-size: 0.81rem;
  font-weight: 500;
  margin-bottom: 5px;
}

.todo-app-w .todo-content .tasks-list {
  font-size: 1.08rem;
  padding: 0px;
  list-style: none;
  border-radius: 4px;
  margin-left: -10px;
  padding: 5px 0px;
}

.todo-app-w .todo-content .tasks-list li.draggable-task {
  margin: 0px;
  padding: 6px 40px;
  position: relative;
  border: 1px solid transparent;
}

.todo-app-w .todo-content .tasks-list li.draggable-task .todo-task-drag {
  color: #111;
  position: absolute;
  top: 9px;
  left: -10px;
  font-size: 8px;
  cursor: move;
  display: none;
  padding: 5px;
}

.todo-app-w .todo-content .tasks-list li.draggable-task .todo-task-media {
  padding-top: 10px;
}

.todo-app-w .todo-content .tasks-list li.draggable-task .todo-task-media img {
  display: inline-block;
  border-radius: 4px;
  height: 30px;
  width: auto;
  margin-right: 5px;
}

.todo-app-w .todo-content .tasks-list li.draggable-task .todo-task-buttons {
  position: absolute;
  right: -10px;
  top: 50%;
  -webkit-transform: translate(100%, -50%);
          transform: translate(100%, -50%);
  display: none;
}

.todo-app-w .todo-content .tasks-list li.draggable-task .todo-task-buttons a {
  font-size: 18px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  text-decoration: none;
}

.todo-app-w .todo-content .tasks-list li.draggable-task .todo-task-buttons a span {
  position: absolute;
  top: -28px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  white-space: nowrap;
  padding: 2px 8px;
  border-radius: 4px;
  background-color: #111;
  color: #fff;
  font-weight: 500;
  font-size: 0.72rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: inline-block;
  vertical-align: middle;
}

.todo-app-w .todo-content .tasks-list li.draggable-task .todo-task-buttons a:hover {
  text-decoration: none;
}

.todo-app-w .todo-content .tasks-list li.draggable-task .todo-task-buttons a:hover span {
  -webkit-transform: translate(-50%, 8px);
          transform: translate(-50%, 8px);
  visibility: visible;
  opacity: 1;
}

.todo-app-w .todo-content .tasks-list li.draggable-task .todo-task-buttons a + a {
  margin-left: 8px;
}

.todo-app-w .todo-content .tasks-list li.draggable-task .todo-task-buttons .task-btn-done {
  color: #4d9121;
}

.todo-app-w .todo-content .tasks-list li.draggable-task .todo-task-buttons .task-btn-edit {
  color: #047bf8;
}

.todo-app-w .todo-content .tasks-list li.draggable-task .todo-task-buttons .task-btn-delete {
  color: #e65252;
}

.todo-app-w .todo-content .tasks-list li.draggable-task .todo-task-buttons .task-btn-star {
  color: #c89302;
}

.todo-app-w .todo-content .tasks-list li.draggable-task .todo-task {
  position: relative;
  display: inline-block;
}

.todo-app-w .todo-content .tasks-list li.draggable-task .todo-task span {
  outline: none;
}

.todo-app-w .todo-content .tasks-list li.draggable-task:hover .todo-task-drag, .todo-app-w .todo-content .tasks-list li.draggable-task:hover .todo-task-buttons {
  display: block;
}

.todo-app-w .todo-content .tasks-list li.draggable-task:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 10px;
  background-color: #e1e1e1;
  position: absolute;
  left: 20px;
  top: 15px;
}

.todo-app-w .todo-content .tasks-list li.draggable-task.favorite {
  background-color: #fffaea;
  border-radius: 6px;
}

.todo-app-w .todo-content .tasks-list li.draggable-task.gu-transit {
  opacity: 0.8;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.05);
}

.todo-app-w .todo-content .tasks-list li.draggable-task.pre-removed {
  background-color: #fff5f5;
  border-radius: 6px;
}

.todo-app-w .todo-content .tasks-list li.draggable-task.pre-removed .todo-task {
  opacity: 0.3;
}

.todo-app-w .todo-content .tasks-list li.draggable-task.pre-removed .task-btn-undelete {
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #e65252;
  color: #fff;
  font-weight: 500;
  font-size: 0.72rem;
  padding: 1px 10px;
  border-radius: 10px;
  text-decoration: none;
}

.todo-app-w .todo-content .tasks-list li.draggable-task.pre-removed .task-btn-undelete:hover {
  background-color: #111;
  text-decoration: none;
}

.todo-app-w .todo-content .tasks-list li.draggable-task.pre-removed .todo-task-drag, .todo-app-w .todo-content .tasks-list li.draggable-task.pre-removed .todo-task-buttons {
  display: none !important;
}

.todo-app-w .todo-content .tasks-list li.draggable-task.complete {
  color: #999;
}

.todo-app-w .todo-content .tasks-list li.draggable-task.complete .todo-task {
  text-decoration: line-through;
}

.todo-app-w .todo-content .tasks-list li.draggable-task.complete:before {
  background-color: #e1e1e1 !important;
}

.todo-app-w .todo-content .tasks-list li.draggable-task.danger:before {
  background-color: #e65252;
}

.todo-app-w .todo-content .tasks-list li.draggable-task.warning:before {
  background-color: #fbe4a0;
}

.todo-app-w .todo-content .tasks-list li.draggable-task.success:before {
  background-color: #24b314;
}

.draggable-task.gu-mirror {
  list-style: none;
  padding: 10px 40px;
  background-color: #fff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  margin: 0px;
  font-size: 1.08rem;
  position: relative;
  border-radius: 4px;
}

.draggable-task.gu-mirror .todo-task-drag {
  color: #111;
  position: absolute;
  top: 50%;
  left: -10px;
  font-size: 8px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: move;
  padding: 5px;
}

.draggable-task.gu-mirror .todo-task-buttons {
  display: none;
}

.draggable-task.gu-mirror .todo-task-media {
  padding-top: 10px;
}

.draggable-task.gu-mirror .todo-task-media img {
  display: inline-block;
  border-radius: 4px;
  height: 30px;
  width: auto;
  margin-right: 5px;
}

.attached-media-w img {
  display: inline-block;
  border-radius: 4px;
  height: 30px;
  width: auto;
  margin-right: 5px;
}

.attached-media-w .attach-media-btn {
  display: inline-block;
  margin-left: 10px;
}

.attached-media-w .attach-media-btn span, .attached-media-w .attach-media-btn i {
  display: inline-block;
  vertical-align: middle;
}

.attached-media-w .attach-media-btn span {
  border-bottom: 1px solid #047bf8;
}

.attached-media-w .attach-media-btn i.os-icon {
  margin-right: 5px;
  font-size: 18px;
}

.color-scheme-dark .todo-app-w .todo-sidebar {
  background-color: #293145;
}

.color-scheme-dark .todo-app-w .todo-sidebar ul.tasks-list li strong {
  color: #ccd9e8;
}

.color-scheme-dark .todo-app-w .todo-sidebar ul.tasks-list li span {
  color: rgba(218, 226, 243, 0.4);
}

.color-scheme-dark .todo-app-w .todo-sidebar .todo-sidebar-section {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.color-scheme-dark .todo-app-w .todo-sidebar ul.projects-list li a {
  color: #ccd9e8;
}

.color-scheme-dark .todo-app-w .todo-sidebar .todo-sidebar-section-sub-section .todo-sidebar-section-sub-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.color-scheme-dark .todo-app-w .todo-sidebar .todo-sidebar-section-sub-section .todo-sidebar-section-sub-section-content ul li a {
  color: #ccd9e8;
}

.color-scheme-dark .todo-app-w .todo-content .tasks-list li.draggable-task.pre-removed {
  background-color: #f59595;
  color: #a94442;
}

.color-scheme-dark .todo-app-w .todo-content .tasks-list li.draggable-task.pre-removed .todo-task {
  opacity: 0.7;
}

.color-scheme-dark .todo-app-w .todo-content .tasks-list li.draggable-task.pre-removed span {
  color: #a94442;
}

.color-scheme-dark .todo-app-w .todo-content {
  background-color: #323c58;
}

.color-scheme-dark .todo-app-w .todo-content .tasks-header-w {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.color-scheme-dark .todo-app-w .todo-content .tasks-header-w .tasks-sub-header {
  color: rgba(218, 226, 243, 0.4);
}

.color-scheme-dark .todo-app-w .todo-content .tasks-list li.draggable-task .todo-task-buttons .task-btn-done {
  color: #55bf10;
}

.color-scheme-dark .todo-app-w .todo-content .tasks-list li.draggable-task.favorite {
  background-color: #efe67b;
  color: #383314;
}

.color-scheme-dark .todo-app-w .todo-content .tasks-list li.draggable-task .todo-task-drag {
  color: #fff;
}

.color-scheme-dark .todo-app-w .todo-content .tasks-list li.draggable-task.complete {
  color: rgba(218, 226, 243, 0.4);
}

.color-scheme-dark .todo-app-w .todo-content .tasks-list li.draggable-task.complete:before {
  background-color: #445279 !important;
}

/* 20. Fancy Selector */
.fancy-selector-w {
  position: relative;
}

.fancy-selector-w .fancy-selector-current {
  position: relative;
  z-index: 2;
  height: 100%;
}

.fancy-selector-w .fancy-selector-current, .fancy-selector-w .fancy-selector-option {
  display: -webkit-box;
  display: flex;
  background-color: #1e62eb;
  color: #fff;
  text-align: left;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

.fancy-selector-w .fancy-selector-current .fs-img, .fancy-selector-w .fancy-selector-option .fs-img {
  padding: 10px 15px;
  vertical-align: middle;
}

.fancy-selector-w .fancy-selector-current .fs-img img, .fancy-selector-w .fancy-selector-option .fs-img img {
  display: inline-block;
  vertical-align: middle;
  height: 30px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.fancy-selector-w .fancy-selector-current .fs-img.shadowless img, .fancy-selector-w .fancy-selector-option .fs-img.shadowless img {
  box-shadow: none;
  height: auto !important;
  width: 40px;
}

.fancy-selector-w .fancy-selector-current .fs-main-info, .fancy-selector-w .fancy-selector-option .fs-main-info {
  padding: 8px 10px;
  padding-right: 20px;
}

.fancy-selector-w .fancy-selector-current .fs-main-info .fs-name, .fancy-selector-w .fancy-selector-option .fs-main-info .fs-name {
  font-size: 0.99rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1px;
  margin-bottom: 3px;
}

.fancy-selector-w .fancy-selector-current .fs-main-info .fs-name strong, .fancy-selector-w .fancy-selector-option .fs-main-info .fs-name strong {
  color: rgba(255, 255, 255, 0.4);
  margin-left: 5px;
}

.fancy-selector-w .fancy-selector-current .fs-main-info .fs-sub, .fancy-selector-w .fancy-selector-option .fs-main-info .fs-sub {
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 0.5px;
  font-size: 0.72rem;
}

.fancy-selector-w .fancy-selector-current .fs-main-info .fs-sub strong, .fancy-selector-w .fancy-selector-option .fs-main-info .fs-sub strong {
  color: #F6DB77;
  margin-left: 5px;
}

.fancy-selector-w .fancy-selector-current .fs-extra-info, .fancy-selector-w .fancy-selector-option .fs-extra-info {
  padding: 10px 20px;
  border-left: 1px solid rgba(255, 255, 255, 0.05);
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}

.fancy-selector-w .fancy-selector-current .fs-extra-info strong, .fancy-selector-w .fancy-selector-option .fs-extra-info strong {
  font-size: 0.99rem;
  margin-bottom: 5px;
  font-weight: 500;
  display: block;
  letter-spacing: 1px;
  line-height: 1;
}

.fancy-selector-w .fancy-selector-current .fs-extra-info span, .fancy-selector-w .fancy-selector-option .fs-extra-info span {
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.54rem;
  letter-spacing: 2px;
  line-height: 1;
  display: block;
}

.fancy-selector-w .fancy-selector-current .fs-selector-trigger, .fancy-selector-w .fancy-selector-option .fs-selector-trigger {
  background-color: #114dc5;
  padding: 10px 10px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  align-self: stretch;
  position: relative;
  width: 60px;
  cursor: pointer;
}

.fancy-selector-w .fancy-selector-current .fs-selector-trigger i, .fancy-selector-w .fancy-selector-option .fs-selector-trigger i {
  vertical-align: middle;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.fancy-selector-w .fancy-selector-current .fs-selector-trigger:hover, .fancy-selector-w .fancy-selector-option .fs-selector-trigger:hover {
  background-color: #0d3b96;
  color: #fff;
}

.fancy-selector-w .fancy-selector-current .fs-selector-trigger:hover i, .fancy-selector-w .fancy-selector-option .fs-selector-trigger:hover i {
  -webkit-transform: translate(-50%, -40%);
          transform: translate(-50%, -40%);
}

.fancy-selector-w .fancy-selector-options {
  background-color: #1456dc;
  position: absolute;
  left: 0px;
  min-width: 100%;
  padding: 15px 0px;
  padding-top: 25px;
  z-index: 9998;
  border-radius: 0px 0px 6px 6px;
  visibility: hidden;
  -webkit-transform: translateY(-30px) scale(1);
          transform: translateY(-30px) scale(1);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 0;
}

.fancy-selector-w .fancy-selector-options .fancy-selector-actions {
  padding: 15px 15px 0px 15px;
}

.fancy-selector-w .fancy-selector-options .fancy-selector-option {
  padding: 8px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  color: rgba(255, 255, 255, 0.7);
  position: relative;
  background-color: transparent;
  cursor: pointer;
  border-radius: 0px;
}

.fancy-selector-w .fancy-selector-options .fancy-selector-option .fs-extra-info {
  padding-right: 10px;
}

.fancy-selector-w .fancy-selector-options .fancy-selector-option .fs-img {
  padding-left: 20px;
}

.fancy-selector-w .fancy-selector-options .fancy-selector-option .fs-img img {
  height: 37px;
}

.fancy-selector-w .fancy-selector-options .fancy-selector-option .fs-main-info {
  -webkit-box-flex: 1;
          flex: 1;
}

.fancy-selector-w .fancy-selector-options .fancy-selector-option.active {
  color: white;
}

.fancy-selector-w .fancy-selector-options .fancy-selector-option.active:before {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #ffb900;
  position: absolute;
  top: 50%;
  left: 15px;
  border-radius: 6px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.fancy-selector-w .fancy-selector-options .fancy-selector-option:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #fff;
}

.fancy-selector-w.opened .fancy-selector-options {
  visibility: visible;
  -webkit-transform: translateY(-5px) scale(1);
          transform: translateY(-5px) scale(1);
  opacity: 1;
}

.fancy-selector-w.opened .fs-selector-trigger i {
  -webkit-transform: translate(-50%, -50%) rotate(180deg);
          transform: translate(-50%, -50%) rotate(180deg);
}

.fancy-selector-w.opened .fs-selector-trigger:hover i {
  -webkit-transform: translate(-50%, -60%) rotate(180deg);
          transform: translate(-50%, -60%) rotate(180deg);
}

/* 21. App - Rentals */
.top-bar-rentals {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: stretch;
          align-items: stretch;
}

.top-bar-rentals .logo-w {
  -webkit-box-flex: 0;
          flex: 0 0 400px;
  background-color: #4472fd;
  padding: 10px 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

.top-bar-rentals .logo-w .filters-toggler {
  color: rgba(255, 255, 255, 0.7);
  font-size: 20px;
  line-height: 1;
  cursor: pointer;
}

.top-bar-rentals .logo-w .filters-toggler .os-icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

.top-bar-rentals .logo-w .filters-toggler:hover {
  color: #fff;
}

.top-bar-rentals .logo-w .logo {
  display: inline-block;
  text-decoration: none;
}

.top-bar-rentals .logo-w .logo-element {
  content: "";
  width: 26px;
  height: 26px;
  border-radius: 15px;
  position: relative;
  background-color: #98c9fd;
  display: inline-block;
  vertical-align: middle;
  margin-right: 40px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.top-bar-rentals .logo-w .logo-element:after {
  content: "";
  width: 26px;
  height: 26px;
  background-color: #fff;
  border-radius: 15px;
  right: -20px;
  position: absolute;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.top-bar-rentals .logo-w .logo:hover .logo-element {
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}

.top-bar-rentals .logo-w .logo:hover .logo-element:after {
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
}

.top-bar-rentals .logo-w .logo:hover .logo-label:after {
  width: 100%;
  background-color: #fff;
}

.top-bar-rentals .logo-w .logo-label {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.08rem;
  position: relative;
}

.top-bar-rentals .logo-w .logo-label:after {
  height: 2px;
  position: absolute;
  width: 0%;
  left: 0px;
  bottom: -5px;
  background-color: #fff;
  content: "";
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.top-bar-rentals .filters {
  -webkit-box-flex: 1;
          flex: 1;
  background-color: #24293d;
  padding: 0px 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

.top-bar-rentals .filters .filters-header {
  padding-right: 20px;
}

.top-bar-rentals .filters .filters-header h4 {
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.9rem;
  margin: 0px;
}

.top-bar-rentals .filters .filter-w {
  padding: 15px 20px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.top-bar-rentals .filters .filter-w label {
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.4);
  display: inline-block;
  margin-right: 10px;
}

.top-bar-rentals .filters .filter-w input.form-control {
  background-color: #040407;
  border-color: #040407;
  color: #fff;
  font-weight: 500;
  letter-spacing: 1px;
}

.top-bar-rentals .filters .filter-w input.form-control.zip-width {
  width: 70px;
  padding-left: 5px;
}

.top-bar-rentals .filters .filter-w input.form-control.date-range-picker {
  width: 250px;
  padding-left: 5px;
}

.top-bar-rentals .filters .filter-w .input-group-prepend, .top-bar-rentals .filters .filter-w .input-group-text {
  background-color: #040407;
  border-color: #040407;
  color: #4472fd;
  font-size: 20px;
}

.top-bar-rentals .filters .buttons-w .btn, .top-bar-rentals .filters .buttons-w .all-wrapper .fc-button, .all-wrapper .top-bar-rentals .filters .buttons-w .fc-button {
  font-size: 0.81rem;
}

.top-bar-rentals .filters .buttons-w .btn i.os-icon, .top-bar-rentals .filters .buttons-w .all-wrapper .fc-button i.os-icon, .all-wrapper .top-bar-rentals .filters .buttons-w .fc-button i.os-icon {
  margin: 0px;
}

.top-bar-rentals .filters .buttons-w .btn i.os-icon + span, .top-bar-rentals .filters .buttons-w .all-wrapper .fc-button i.os-icon + span, .all-wrapper .top-bar-rentals .filters .buttons-w .fc-button i.os-icon + span {
  margin: 0px;
  margin-left: 10px;
}

.top-bar-rentals .filters .buttons-w .btn span + i.os-icon, .top-bar-rentals .filters .buttons-w .all-wrapper .fc-button span + i.os-icon, .all-wrapper .top-bar-rentals .filters .buttons-w .fc-button span + i.os-icon {
  margin: 0px;
  margin-left: 10px;
}

.rentals-list-w {
  display: -webkit-box;
  display: flex;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.rentals-list-w .filter-side {
  background-color: #f6f6f6;
  -webkit-box-flex: 0;
          flex: 0 0 400px;
}

.rentals-list-w .filter-side .filters-header {
  display: -webkit-box;
  display: flex;
  padding: 16px 20px;
  -webkit-box-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-box-align: center;
          align-items: center;
}

.rentals-list-w .filter-side .filters-header h4 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.08rem;
  margin-bottom: 0px;
}

.rentals-list-w .filter-side .filters-header .reset-filters {
  color: #BC8F8F;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}

.rentals-list-w .filter-side .filters-header .reset-filters i {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  font-size: 10px;
}

.rentals-list-w .filter-side .filters-header .reset-filters span {
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.63rem;
}

.rentals-list-w .filter-side .filter-w {
  position: relative;
}

.rentals-list-w .filter-side .filter-w .filter-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  padding: 4px 5px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.07);
  color: rgba(0, 0, 0, 0.4);
  font-size: 10px;
  line-height: 1;
  vertical-align: middle;
  cursor: pointer;
  z-index: 3;
}

.rentals-list-w .filter-side .filter-w .filter-toggle i {
  display: inline-block;
  vertical-align: middle;
}

.rentals-list-w .filter-side .filter-w .filter-toggle:hover {
  background-color: #111;
  color: #fff;
}

.rentals-list-w .filter-side .filter-w iframe {
  max-width: 100%;
}

.rentals-list-w .filter-side .filter-w.no-padding .filter-body {
  padding-left: 0px;
  padding-right: 0px;
}

.rentals-list-w .filter-side .filter-w.collapsed .filter-body {
  display: none;
}

.rentals-list-w .filter-side .filter-header {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.81rem;
  position: relative;
  padding: 40px;
  padding-bottom: 20px;
  padding-top: 20px;
  margin: 0px;
}

.rentals-list-w .filter-side .filter-header:before {
  content: "";
  background-color: #047bf8;
  width: 7px;
  height: 7px;
  left: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
}

.rentals-list-w .filter-side .filter-body {
  padding: 10px 40px 30px 40px;
}

.rentals-list-w .filter-side .filter-w + .filter-w {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.rentals-list-w .filter-side .select2 {
  display: block;
  width: 100%;
}

.rentals-list-w .filter-side .select2.select2-container--default .select2-selection--multiple {
  background-color: transparent;
  border-color: transparent;
}

.rentals-list-w .filter-side .select2.select2-container--default .select2-selection--multiple .select2-selection__choice {
  border: 2px solid #047bf8;
  border-radius: 16px;
  font-size: 0.63rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-color: transparent;
  font-weight: 500;
  padding: 3px 8px;
  color: #047bf8;
  margin-right: 7px;
}

.rentals-list-w .filter-side .select2.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #047bf8;
}

.rentals-list-w .rentals-list {
  -webkit-box-flex: 1;
          flex: 1;
}

.rentals-list-w .rentals-list .list-controls {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
}

.rentals-list-w .rentals-list .list-controls .list-info {
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 0.72rem;
}

.rentals-list-w .rentals-list .list-controls .list-order {
  margin-left: auto;
}

.rentals-list-w .rentals-list .list-controls .list-order label {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 0.72rem;
  margin-bottom: 0px;
}

.rentals-list-w .property-items.as-list .property-item {
  display: -webkit-box;
  display: flex;
}

.rentals-list-w .property-items.as-list .property-item .item-media-w {
  -webkit-box-flex: 0;
          flex: 0 0 40%;
}

.rentals-list-w .property-items.as-list .property-item .item-media-w .item-media {
  height: 100%;
}

.rentals-list-w .property-items.as-list .property-item .item-info {
  -webkit-box-flex: 1;
          flex: 1;
}

.rentals-list-w .property-items.as-grid {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
}

.rentals-list-w .property-items.as-grid .property-item {
  -webkit-box-flex: 0;
          flex: 0 0 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  padding: 20px;
}

.rentals-list-w .property-items.as-grid .property-item:nth-child(odd) {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.rentals-list-w .property-items.as-grid .property-item .item-media-w .item-media {
  padding-bottom: 65%;
}

.rentals-list-w .property-items.as-grid .property-item .item-info {
  padding: 30px 30px;
}

.rentals-list-w .property-item {
  -webkit-box-align: stretch;
          align-items: stretch;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.rentals-list-w .property-item .item-media-w {
  display: block;
  position: relative;
  overflow: hidden;
}

.rentals-list-w .property-item .item-media-w .item-media {
  background-size: cover;
  background-position: center center;
  z-index: 2;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.rentals-list-w .property-item .item-media-w:after {
  content: "";
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 3;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.rentals-list-w .property-item .item-media-w:hover .item-media {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.rentals-list-w .property-item .item-media-w:hover:after {
  background-color: rgba(0, 0, 0, 0.1);
}

.rentals-list-w .property-item .item-info {
  padding: 60px 50px;
}

.rentals-list-w .property-item .item-info .item-title a {
  color: #334152;
  text-decoration: none;
}

.rentals-list-w .property-item .item-info .item-title a:hover {
  color: #1f2833;
}

.rentals-list-w .property-item .item-price-buttons {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
  margin-top: 20px;
}

.rentals-list-w .property-item .item-reviews {
  display: -webkit-box;
  display: flex;
  margin-top: 15px;
  -webkit-box-align: center;
          align-items: center;
}

.rentals-list-w .property-item .item-reviews .reviews-count {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.4);
}

.rentals-list-w .property-item .item-price strong {
  color: #047bf8;
  font-size: 1.8rem;
}

.rentals-list-w .property-item .item-price span {
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: #999;
  margin-left: 5px;
}

.rentals-list-w .property-item .item-features {
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: #777;
  margin: 10px 0px;
}

.rentals-list-w .property-item .item-features .feature {
  margin-right: 17px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.rentals-list-w .property-item .item-features .feature + .feature:before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: -15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.rentals-list-w .property-item .item-buttons {
  padding-top: 20px;
}

.rentals-list-w .property-item .item-buttons .btn, .rentals-list-w .property-item .item-buttons .all-wrapper .fc-button, .all-wrapper .rentals-list-w .property-item .item-buttons .fc-button {
  border-width: 2px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 5px 14px;
  letter-spacing: 2px;
}

.rentals-list-w .property-item .item-buttons .btn span, .rentals-list-w .property-item .item-buttons .all-wrapper .fc-button span, .all-wrapper .rentals-list-w .property-item .item-buttons .fc-button span, .rentals-list-w .property-item .item-buttons .btn i, .rentals-list-w .property-item .item-buttons .all-wrapper .fc-button i, .all-wrapper .rentals-list-w .property-item .item-buttons .fc-button i {
  display: inline-block;
  vertical-align: middle;
}

.rentals-list-w .property-item .item-buttons .btn span + i, .rentals-list-w .property-item .item-buttons .all-wrapper .fc-button span + i, .all-wrapper .rentals-list-w .property-item .item-buttons .fc-button span + i {
  margin-left: 10px;
  font-size: 10px;
  margin-right: 0px;
}

.rentals-list-w .property-item .item-buttons .btn + .btn, .rentals-list-w .property-item .item-buttons .all-wrapper .fc-button + .btn, .all-wrapper .rentals-list-w .property-item .item-buttons .fc-button + .btn, .rentals-list-w .property-item .item-buttons .all-wrapper .btn + .fc-button, .all-wrapper .rentals-list-w .property-item .item-buttons .btn + .fc-button, .rentals-list-w .property-item .item-buttons .all-wrapper .fc-button + .fc-button, .all-wrapper .rentals-list-w .property-item .item-buttons .fc-button + .fc-button {
  margin-left: 10px;
}

.rentals-list-w .pagination-w {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
  padding: 10px 20px;
}

.rentals-list-w .pagination-w .pagination-info {
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.4);
}

.rentals-list-w .pagination-w .pagination-links .pagination {
  margin-bottom: 0;
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: #047bf8;
}

.rentals-list-w .pagination-w .pagination-links .pagination .page-link {
  border: none;
}

.rentals-list-w .pagination-w .pagination-links .pagination .disabled .page-link {
  color: rgba(0, 0, 0, 0.3);
}

.rentals-list-w.hide-filters .filter-side {
  display: none;
}

.rentals-list-w.hide-filters .property-items.as-grid .property-item {
  -webkit-box-flex: 0;
          flex: 0 0 33.3%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.rentals-list-w.hide-filters .property-items.as-grid .property-item:nth-child(3n) {
  border-right: none;
}

.property-single {
  background-color: #fff;
}

.property-single .property-media {
  height: 60vh;
  position: relative;
  background-size: cover;
  background-position: center center;
}

.property-single .property-media .media-buttons {
  position: absolute;
  left: 40px;
  bottom: 40px;
}

.property-single .property-media .media-buttons a {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  display: inline-block;
  color: #111;
}

.property-single .property-media .media-buttons a:hover {
  background-color: white;
}

.property-single .property-media .media-buttons a i.os-icon {
  font-size: 20px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

.property-single .property-media .media-buttons a span {
  display: inline-block;
  vertical-align: middle;
}

.property-single .property-media .media-buttons a + a {
  margin-left: 20px;
}

.property-single .property-info-w {
  max-width: 1200px;
  margin: 0px auto;
  padding: 0px 40px;
  display: -webkit-box;
  display: flex;
}

.property-single .property-info-w .property-info-main {
  -webkit-box-flex: 1;
          flex: 1;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding: 60px;
  position: relative;
}

.property-single .property-info-w .property-info-main .property-price {
  text-align: center;
  padding: 30px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 400px;
  background-color: #fff;
  top: 0px;
  right: 0px;
  -webkit-transform: translate(100%, -100%);
          transform: translate(100%, -100%);
}

.property-single .property-info-w .property-info-main .property-price strong {
  color: #047bf8;
  font-size: 2.5rem;
  display: block;
  line-height: 1;
}

.property-single .property-info-w .property-info-main .property-price span {
  display: inline-block;
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.81rem;
  letter-spacing: 1px;
}

.property-single .property-info-w .property-info-main h1 {
  font-size: 3rem;
}

.property-single .property-info-w .property-info-main .property-features-highlight {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 0px;
  margin-top: 30px;
}

.property-single .property-info-w .property-info-main .property-features-highlight .feature {
  -webkit-box-flex: 0;
          flex: 0 0 25%;
  text-align: center;
}

.property-single .property-info-w .property-info-main .property-features-highlight .feature i {
  display: inline-block;
  font-size: 30px;
  color: #047bf8;
}

.property-single .property-info-w .property-info-main .property-features-highlight .feature span {
  display: block;
  margin-top: 10px;
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 12px;
}

.property-single .property-info-w .property-info-main .property-description {
  font-size: 0.99rem;
  font-weight: 300;
  margin: 30px 0px;
}

.property-single .property-info-w .property-info-main .badge {
  border-radius: 0px;
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 5px 10px;
}

.property-single .property-info-w .property-info-main .badge.badge-red {
  background-color: #F70E2D;
  color: #fff;
}

.property-single .property-info-w .property-info-main .item-reviews {
  display: -webkit-box;
  display: flex;
  margin-top: 15px;
  -webkit-box-align: center;
          align-items: center;
}

.property-single .property-info-w .property-info-main .item-reviews .reviews-count {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.4);
}

.property-single .property-info-w .property-info-main .item-features {
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: #777;
  margin: 10px 0px;
  font-size: 0.99rem;
  position: relative;
}

.property-single .property-info-w .property-info-main .item-features:before {
  content: "";
  background-color: #047bf8;
  width: 7px;
  height: 7px;
  left: -63px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
}

.property-single .property-info-w .property-info-main .item-features .feature {
  margin-right: 17px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.property-single .property-info-w .property-info-main .item-features .feature + .feature:before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: -15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.property-single .property-info-w .property-info-side {
  -webkit-box-flex: 0;
          flex: 0 0 400px;
  background-color: #fff;
  padding: 0px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.property-single .property-info-w .property-info-side .side-action-form {
  padding: 30px 40px;
}

.property-single .property-info-w .property-info-side .side-action-form .input-group {
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.property-single .property-info-w .property-info-side .side-action-form .input-group .input-group-prepend .input-group-text {
  border: none;
  background-color: #fff;
  color: #111;
}

.property-single .property-info-w .property-info-side .side-action-form .input-group .form-control {
  border: none;
  font-weight: 500;
}

.property-single .property-info-w .property-info-side .side-action-form .form-buttons {
  text-align: center;
}

.property-single .property-info-w .property-info-side .side-action-form .btn, .property-single .property-info-w .property-info-side .side-action-form .all-wrapper .fc-button, .all-wrapper .property-single .property-info-w .property-info-side .side-action-form .fc-button {
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 12px 20px;
}

.property-single .property-info-w .property-info-side .side-action-form .btn span, .property-single .property-info-w .property-info-side .side-action-form .all-wrapper .fc-button span, .all-wrapper .property-single .property-info-w .property-info-side .side-action-form .fc-button span {
  margin-right: 10px;
}

.property-single .property-info-w .property-info-side .side-action-form .btn i.os-icon, .property-single .property-info-w .property-info-side .side-action-form .all-wrapper .fc-button i.os-icon, .all-wrapper .property-single .property-info-w .property-info-side .side-action-form .fc-button i.os-icon {
  font-size: 10px;
  margin-right: 0px;
}

.property-single .property-info-w .property-info-side .side-magic {
  padding: 15% 10%;
  position: relative;
  color: rgba(255, 255, 255, 0.8);
}

.property-single .property-info-w .property-info-side .side-magic .fader {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
}

.property-single .property-info-w .property-info-side .side-magic .side-magic-title {
  color: #fff;
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 1.75rem;
  position: relative;
  margin-bottom: 20px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.property-single .property-info-w .property-info-side .side-magic .side-magic-desc {
  position: relative;
  font-size: 1.08rem;
}

.property-single .property-info-w .property-info-side .side-magic .side-magic-btn {
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.7);
  padding: 7px 15px;
  color: #111;
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 1.08rem;
  display: inline-block;
  margin-top: 30px;
  text-decoration: none;
}

.property-single .property-info-w .property-info-side .side-section .side-section-header {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  position: relative;
  color: #111;
  text-align: center;
  font-size: 0.99rem;
}

.property-single .property-info-w .property-info-side .side-section .side-section-header:before {
  content: "";
  background-color: #047bf8;
  width: 7px;
  height: 7px;
  left: 50%;
  top: -4px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: absolute;
}

.property-single .property-info-w .property-info-side .side-section .side-section-content {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 0px;
}

.property-single .property-info-w .property-info-side .property-side-features .feature {
  padding: 15px 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
}

.property-single .property-info-w .property-info-side .property-side-features .feature i, .property-single .property-info-w .property-info-side .property-side-features .feature span, .property-single .property-info-w .property-info-side .property-side-features .feature strong {
  display: inline-block;
  vertical-align: middle;
}

.property-single .property-info-w .property-info-side .property-side-features .feature span {
  color: rgba(0, 0, 0, 0.6);
}

.property-single .property-info-w .property-info-side .property-side-features .feature strong {
  margin-right: 3px;
  color: #111;
}

.property-single .property-info-w .property-info-side .property-side-features .feature i {
  color: #047bf8;
  font-size: 30px;
  margin-right: 20px;
}

.property-single .property-info-w .property-info-side .property-side-features .feature:last-child {
  border-bottom: none;
}

.property-single .property-info-w .property-section .property-section-header {
  padding: 20px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  position: relative;
  color: #111;
  font-size: 0.99rem;
}

.property-single .property-info-w .property-section .property-section-header:before {
  content: "";
  background-color: #047bf8;
  width: 7px;
  height: 7px;
  left: -63px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
}

.property-single .property-info-w .property-section .property-section-header .filter-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  padding: 4px 5px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.07);
  color: rgba(0, 0, 0, 0.4);
  font-size: 10px;
  line-height: 1;
  vertical-align: middle;
  cursor: pointer;
  z-index: 3;
}

.property-single .property-info-w .property-section .property-section-header .filter-toggle i {
  display: inline-block;
  vertical-align: middle;
}

.property-single .property-info-w .property-section .property-section-header .filter-toggle:hover {
  background-color: #111;
  color: #fff;
}

.property-single .property-info-w .property-section .property-section-body {
  padding: 40px 0px;
  font-size: 0.99rem;
  font-weight: 300;
}

.property-single .property-info-w .property-section .property-section-body iframe {
  max-width: 100%;
}

.property-single .property-info-w .property-section .property-section-body ul li {
  margin-bottom: 10px;
}

.property-single .property-info-w .property-section .property-section-body ul li .os-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  font-size: 20px;
}

.property-single .property-info-w .property-section .property-section-body ul li span {
  display: inline-block;
  vertical-align: middle;
}

.property-single .property-info-w .property-section .property-section-body .property-note {
  padding: 20px;
  background-color: #FBF9F3;
  color: #383328;
  font-size: 0.81rem;
  margin: 20px 0px;
}

.property-single .property-info-w .property-section .property-section-body .property-note:last-child {
  margin-bottom: 0px;
}

.property-single .property-info-w .property-section .property-section-body .property-note h6 {
  font-size: 0.81rem;
}

.property-single .property-info-w .property-section .property-section-body .property-note p:last-child {
  margin-bottom: 0px;
}

.related-listings-w {
  margin-bottom: 100px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.related-listings-w .property-section-big-header {
  position: relative;
  text-align: center;
  padding: 30px;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.related-listings-w .property-section-big-header:before {
  content: "";
  background-color: #047bf8;
  width: 7px;
  height: 7px;
  left: 50%;
  top: -4px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: absolute;
}

.related-listings-w .related-listings {
  display: -webkit-box;
  display: flex;
}

.related-listings-w .property-item {
  -webkit-box-flex: 0;
          flex: 0 0 33.3%;
  -webkit-box-align: stretch;
          align-items: stretch;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.related-listings-w .property-item + .property-item {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.related-listings-w .property-item .item-media-w {
  display: block;
  position: relative;
  overflow: hidden;
}

.related-listings-w .property-item .item-media-w .item-media {
  background-size: cover;
  background-position: center center;
  z-index: 2;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding-bottom: 65%;
}

.related-listings-w .property-item .item-media-w:after {
  content: "";
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 3;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.related-listings-w .property-item .item-media-w:hover .item-media {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.related-listings-w .property-item .item-media-w:hover:after {
  background-color: rgba(0, 0, 0, 0.1);
}

.related-listings-w .property-item .item-info {
  padding: 60px 50px;
}

.related-listings-w .property-item .item-info .item-title a {
  color: #334152;
  text-decoration: none;
}

.related-listings-w .property-item .item-info .item-title a:hover {
  color: #1f2833;
}

.related-listings-w .property-item .item-price-buttons {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
  margin-top: 20px;
}

.related-listings-w .property-item .item-reviews {
  display: -webkit-box;
  display: flex;
  margin-top: 15px;
  -webkit-box-align: center;
          align-items: center;
}

.related-listings-w .property-item .item-reviews .reviews-count {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.4);
}

.related-listings-w .property-item .item-price strong {
  color: #047bf8;
  font-size: 1.8rem;
}

.related-listings-w .property-item .item-price span {
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: #999;
  margin-left: 5px;
}

.related-listings-w .property-item .item-features {
  font-size: 0.72rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: #777;
  margin: 10px 0px;
}

.related-listings-w .property-item .item-features .feature {
  margin-right: 17px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.related-listings-w .property-item .item-features .feature + .feature:before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: -15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.related-listings-w .property-item .item-buttons {
  padding-top: 20px;
}

.related-listings-w .property-item .item-buttons .btn, .related-listings-w .property-item .item-buttons .all-wrapper .fc-button, .all-wrapper .related-listings-w .property-item .item-buttons .fc-button {
  border-width: 2px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 5px 14px;
  letter-spacing: 2px;
}

.related-listings-w .property-item .item-buttons .btn span, .related-listings-w .property-item .item-buttons .all-wrapper .fc-button span, .all-wrapper .related-listings-w .property-item .item-buttons .fc-button span, .related-listings-w .property-item .item-buttons .btn i, .related-listings-w .property-item .item-buttons .all-wrapper .fc-button i, .all-wrapper .related-listings-w .property-item .item-buttons .fc-button i {
  display: inline-block;
  vertical-align: middle;
}

.related-listings-w .property-item .item-buttons .btn span + i, .related-listings-w .property-item .item-buttons .all-wrapper .fc-button span + i, .all-wrapper .related-listings-w .property-item .item-buttons .fc-button span + i {
  margin-left: 10px;
  font-size: 10px;
  margin-right: 0px;
}

.related-listings-w .property-item .item-buttons .btn + .btn, .related-listings-w .property-item .item-buttons .all-wrapper .fc-button + .btn, .all-wrapper .related-listings-w .property-item .item-buttons .fc-button + .btn, .related-listings-w .property-item .item-buttons .all-wrapper .btn + .fc-button, .all-wrapper .related-listings-w .property-item .item-buttons .btn + .fc-button, .related-listings-w .property-item .item-buttons .all-wrapper .fc-button + .fc-button, .all-wrapper .related-listings-w .property-item .item-buttons .fc-button + .fc-button {
  margin-left: 10px;
}

body.rentals-wrapper .all-wrapper {
  background-color: #fff;
}

body.rentals-wrapper .select2-container--default .select2-selection--single, body.rentals-wrapper .select2-container--default .select2-selection--multiple {
  cursor: pointer;
  border-width: 1px;
}

body.rentals-wrapper .select2-dropdown {
  background-color: #fff;
  border: none;
  border-radius: 6px;
  box-shadow: 0 5px 50px rgba(4, 123, 248, 0.3);
  padding: 10px 15px;
}

body.rentals-wrapper .select2-dropdown:before {
  content: "";
  border-width: 6px;
  border-style: solid;
  border-color: transparent;
  border-bottom-color: #fff;
  position: absolute;
  top: 0px;
  left: 10px;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

body.rentals-wrapper .select2-results__option {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 6px 15px;
  margin: 5px 0px;
  border-radius: 6px;
}

body.rentals-wrapper .select2-results__option:last-child {
  border-bottom: none;
}

body.rentals-wrapper .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #047bf8;
  color: #fff;
}

body.rentals-wrapper .select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #047bf8;
  color: #fff;
}

.footer-w {
  color: #868686;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
  background-image: linear-gradient(to bottom, #fff, #fff);
  font-weight: 300;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.footer-w .logo-element {
  content: "";
  width: 26px;
  height: 26px;
  border-radius: 15px;
  position: relative;
  margin-bottom: 30px;
  background-color: #98c9fd;
}

.footer-w .logo-element:after {
  content: "";
  width: 26px;
  height: 26px;
  background-color: #047bf8;
  border-radius: 15px;
  right: -20px;
  position: absolute;
}

.footer-w .footer-i {
  padding-top: 50px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer-w .footer-i .heading-big {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 1.75rem;
  letter-spacing: 3px;
}

.footer-w .footer-i .heading-small {
  color: #047bf8;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.72rem;
  margin-bottom: 20px;
}

.footer-w .footer-i ul {
  list-style-type: square;
  color: #047bf8;
}

.footer-w .footer-i ul li {
  color: #868686;
}

.footer-w .footer-i ul.social-links {
  margin: 0px;
  padding: 0px;
}

.footer-w .footer-i ul.social-links li {
  display: inline-block;
  margin-right: 15px;
}

.footer-w .footer-i ul.social-links li a {
  display: inline-block;
  vertical-align: middle;
}

.footer-w .footer-i ul.social-links li a:hover {
  text-decoration: none;
}

.footer-w .footer-i ul.social-links li a i {
  font-size: 50px;
}

.footer-w .footer-i ul.social-links li a .os-icon-facebook {
  color: #0d509a;
}

.footer-w .footer-i ul.social-links li a .os-icon-twitter {
  color: #2fafff;
}

.footer-w .deep-footer {
  text-align: center;
  padding: 20px;
  font-size: 0.72rem;
  margin-top: 50px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.call-to-action {
  text-align: center;
  max-width: 800px;
  margin: 0px auto;
  padding: 80px 20px;
}

.call-to-action .cta-header {
  font-size: 3rem;
}

.call-to-action .cta-desc {
  font-weight: 300;
  color: #868686;
  font-size: 1.17rem;
}

.call-to-action .cta-btn {
  margin-top: 40px;
}

.call-to-action .cta-btn .btn-lg, .call-to-action .cta-btn .btn-group-lg > .btn, .call-to-action .cta-btn .all-wrapper .btn-group-lg > .fc-button, .all-wrapper .call-to-action .cta-btn .btn-group-lg > .fc-button {
  font-size: 1.44rem;
  font-weight: 500;
  padding: 18px 40px;
  text-transform: uppercase;
  letter-spacing: 3px;
  padding-left: 50px;
}

.call-to-action .cta-btn span {
  display: inline-block;
  vertical-align: middle;
}

.call-to-action .cta-btn i {
  margin-right: 0px;
  font-size: 30px;
  display: inline-block;
  vertical-align: middle;
}

.call-to-action .cta-btn span + i {
  margin-left: 10px;
  opacity: 0.4;
}

.call-to-action .cta-btn i + i {
  margin-left: -18px;
}

/* #3.  Screen size > 1400px */
@media (min-width: 1650px) {
  .rentals-list-w .property-items.as-grid .property-item {
    -webkit-box-flex: 0;
            flex: 0 0 33.3%;
  }
  .rentals-list-w .property-items.as-grid .property-item {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  .rentals-list-w .property-items.as-grid .property-item:nth-child(3n) {
    border-right: none;
  }
}

/* 22. App - Support */
.support-index {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
          align-items: flex-start;
}

.support-index .support-tickets {
  -webkit-box-flex: 0;
          flex: 0 0 470px;
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.support-index .load-more-tickets {
  text-align: center;
  margin-top: 8px;
}

.support-index .load-more-tickets a {
  display: inline-block;
  padding: 10px;
  text-decoration: none;
}

.support-index .load-more-tickets a i {
  font-size: 20px;
  display: inline-block;
  vertical-align: middle;
}

.support-index .load-more-tickets a span {
  display: inline-block;
  vertical-align: middle;
  border-bottom: 1px solid #047bf8;
}

.support-index .load-more-tickets a i + span {
  margin-left: 10px;
}

.support-index .support-tickets-header h5 {
  margin-bottom: 0px;
}

.support-index .support-tickets-header .tickets-control {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}

.support-index .support-tickets-header .tickets-control .element-search {
  position: relative;
  margin-left: auto;
}

.support-index .support-tickets-header .tickets-control .element-search:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 10px;
  top: 48%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 16px;
  content: "\e92c";
  color: rgba(0, 0, 0, 0.2);
}

.support-index .support-tickets-header .tickets-control .element-search input {
  border: none;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 30px;
  padding: 4px 10px 4px 40px;
  display: block;
  width: 100%;
  font-size: 0.81rem;
  outline: none;
}

.support-index .support-tickets-header .tickets-control .element-search input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.support-index .support-tickets-header .tickets-control .element-search input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.support-index .support-tickets-header .tickets-control .element-search input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.support-index .support-tickets-header .tickets-control .element-search input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.support-index .support-tickets-header .tickets-control .element-search input::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.support-index .support-tickets-header .tickets-filter {
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding-bottom: 10px;
  display: -webkit-box;
  display: flex;
}

.support-index .support-tickets-header .tickets-filter .form-check {
  margin-bottom: 0px;
}

.support-index .support-tickets-header .tickets-filter .form-check label.form-check-label {
  vertical-align: middle;
  padding-left: 5px;
}

.support-index .support-tickets-header .tickets-filter .form-group {
  white-space: nowrap;
  margin-bottom: 0px;
}

.support-index .support-tickets-header .tickets-filter .form-group label {
  margin-bottom: 0px;
}

.support-index .support-tickets-header .tickets-filter select.form-control-sm {
  background-color: #fff;
}

.support-index .support-tickets-header .tickets-filter .stick-right {
  margin-left: auto;
}

.support-index .support-ticket {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
}

.support-index .support-ticket + .support-ticket {
  margin-top: 20px;
}

.support-index .support-ticket:hover, .support-index .support-ticket.active {
  box-shadow: 0px 5px 12px rgba(126, 142, 177, 0.2), 0px 0px 0px 2px #047bf8;
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  cursor: pointer;
}

.support-index .support-ticket:hover .ticket-title, .support-index .support-ticket.active .ticket-title {
  color: #047bf8;
}

.support-index .support-ticket.active {
  -webkit-transform: none;
          transform: none;
}

.support-index .st-meta {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 99;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}

.support-index .st-meta > div {
  margin-left: 10px;
}

.support-index .st-meta > i {
  margin-left: 10px;
  color: #EAA81D;
  font-size: 16px;
}

.support-index .st-meta .badge {
  font-size: 0.72rem;
  padding: 2px 5px;
}

.support-index .st-body {
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  padding: 20px;
  border-radius: 6px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}

.support-index .st-body .avatar {
  -webkit-box-flex: 0;
          flex: 0 0 50px;
  padding-right: 15px;
}

.support-index .st-body .avatar img {
  width: 50px;
  height: auto;
  border-radius: 50px;
}

.support-index .st-body .ticket-content .ticket-title {
  margin-bottom: 5px;
}

.support-index .st-body .ticket-content .ticket-description {
  color: #adb5bd;
  font-size: 0.81rem;
  font-weight: 300;
  height: 1.215rem;
  overflow: hidden;
}

.support-index .st-foot {
  padding: 10px 20px;
  font-size: 0.81rem;
}

.support-index .st-foot .label {
  color: #adb5bd;
  font-weight: 300;
  display: inline-block;
  vertical-align: middle;
}

.support-index .st-foot .value {
  vertical-align: middle;
  display: inline-block;
  color: #047bf8;
}

.support-index .st-foot a.value span {
  border-bottom: 1px solid #047bf8;
}

.support-index .st-foot a.value.with-avatar img {
  width: 25px;
}

.support-index .st-foot .label + .value {
  margin-left: 10px;
}

.support-index .st-foot .value + .label {
  margin-left: 20px;
}

.support-index .support-ticket-content-w {
  -webkit-box-flex: 1;
          flex: 1;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
  border-radius: 6px;
  display: -webkit-box;
  display: flex;
  position: relative;
}

.support-index .support-ticket-content-w .support-ticket-content {
  -webkit-box-flex: 1;
          flex: 1;
  padding: 10px 30px;
  padding-right: 60px;
  position: relative;
}

.support-index .support-ticket-content-w .support-ticket-content .support-ticket-content-header {
  display: -webkit-box;
  display: flex;
  margin-bottom: 30px;
  padding: 10px 0px;
}

.support-index .support-ticket-content-w .support-ticket-content .support-ticket-content-header .show-ticket-info {
  margin-left: auto;
  -webkit-box-flex: 0;
          flex: 0 0 auto;
  padding-left: 20px;
  padding-top: 5px;
}

.support-index .support-ticket-content-w .support-ticket-content .support-ticket-content-header h3, .support-index .support-ticket-content-w .support-ticket-content .support-ticket-content-header h4 {
  margin-bottom: 0px;
}

.support-index .support-ticket-content-w .support-ticket-content .show-ticket-info,
.support-index .support-ticket-content-w .support-ticket-content .back-to-index {
  display: none;
  text-decoration: none;
  white-space: nowrap;
}

.support-index .support-ticket-content-w .support-ticket-content .show-ticket-info span,
.support-index .support-ticket-content-w .support-ticket-content .back-to-index span {
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  color: #adb5bd;
  letter-spacing: 1px;
  font-size: 0.72rem;
  font-weight: 500;
}

.support-index .support-ticket-content-w .support-ticket-content .show-ticket-info i,
.support-index .support-ticket-content-w .support-ticket-content .back-to-index i {
  display: inline-block;
  vertical-align: middle;
  font-size: 19px;
}

.support-index .support-ticket-content-w .support-ticket-content .show-ticket-info span + i,
.support-index .support-ticket-content-w .support-ticket-content .back-to-index span + i {
  margin-left: 7px;
}

.support-index .support-ticket-content-w .support-ticket-content .show-ticket-info:hover span,
.support-index .support-ticket-content-w .support-ticket-content .back-to-index:hover span {
  color: #047bf8;
}

.support-index .support-ticket-content-w .support-ticket-content .back-to-index i {
  font-size: 12px;
}

.support-index .support-ticket-content-w .support-ticket-content .back-to-index span {
  margin-left: 10px;
}

.support-index .support-ticket-content-w .ticket-header {
  margin-bottom: 40px;
}

.support-index .support-ticket-content-w .ticket-reply {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 20px;
}

.support-index .support-ticket-content-w .ticket-reply:last-child {
  border-bottom: none;
}

.support-index .support-ticket-content-w .ticket-reply + .ticket-reply {
  margin-top: 20px;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-reply-info {
  padding: 10px 0px;
  margin-bottom: 5px;
  position: relative;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-reply-info .badge {
  vertical-align: middle;
  display: inline-block;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-reply-info .actions {
  position: absolute;
  top: 50%;
  right: 0px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 18px;
  color: #047bf8;
  text-decoration: none;
  cursor: pointer;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-reply-info .actions > i {
  vertical-align: middle;
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-reply-info .actions .actions-list {
  position: absolute;
  background-color: #0f2338;
  color: #fff;
  font-size: 0.9rem;
  padding: 12px 12px;
  border-radius: 6px;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  top: 0px;
  right: 0px;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-reply-info .actions .actions-list a {
  display: block;
  padding: 5px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-reply-info .actions .actions-list a:last-child {
  border-bottom: none;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-reply-info .actions .actions-list a i {
  font-size: 17px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  color: #fff;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-reply-info .actions .actions-list a span {
  color: rgba(255, 255, 255, 0.7);
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-reply-info .actions .actions-list a:hover span {
  color: #fff;
  -webkit-transform: translateX(-3px);
          transform: translateX(-3px);
}

.support-index .support-ticket-content-w .ticket-reply .ticket-reply-info .actions .actions-list a.danger i {
  color: #ff5b5b;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-reply-info .actions .actions-list a.danger span {
  color: #ff5b5b;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-reply-info .actions:hover > i {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.support-index .support-ticket-content-w .ticket-reply .ticket-reply-info .actions:hover .actions-list {
  visibility: visible;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  opacity: 1;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-reply-info .author {
  display: inline-block;
  vertical-align: middle;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-reply-info .info-data {
  display: inline-block;
  vertical-align: middle;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-reply-info .info-data .label {
  color: #adb5bd;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-reply-info .info-data .value {
  display: inline-block;
  vertical-align: middle;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-reply-info > span + span {
  margin-left: 10px;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-reply-content {
  padding: 20px 25px;
  margin-left: 15px;
  font-size: 0.99rem;
  background-color: #f3f3f7;
  border-radius: 6px;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-reply-content p:last-child {
  margin-bottom: 0px;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-attachments {
  text-align: right;
  margin-top: 10px;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-attachments .attachment {
  display: inline-block;
  padding: 5px 10px;
  padding-right: 20px;
  margin-bottom: 5px;
  background-color: #F6F7F8;
  color: #047bf8;
  border-radius: 6px;
  font-size: 0.72rem;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-attachments .attachment i {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  margin-right: 10px;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-attachments .attachment span {
  display: inline-block;
  vertical-align: middle;
}

.support-index .support-ticket-content-w .ticket-reply .ticket-attachments .attachment + .attachment {
  margin-left: 20px;
}

.support-index .support-ticket-content-w .ticket-reply.highlight .ticket-reply-info {
  border-bottom: none;
}

.support-index .support-ticket-content-w .ticket-reply.highlight .ticket-reply-content {
  background-color: #fff6d7;
}

.support-index .support-ticket-content-w .support-ticket-info {
  background-color: #F6F7F9;
  background: -webkit-gradient(linear, left top, left bottom, from(#eff1f7), to(#f9fafc));
  background: linear-gradient(to bottom, #eff1f7, #f9fafc);
  padding: 30px 20px;
  border-radius: 6px;
  -webkit-box-flex: 0;
          flex: 0 0 250px;
  position: relative;
}

.support-index .support-ticket-content-w .support-ticket-info .close-ticket-info {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #047bf8;
  text-decoration: none;
  font-size: 20px;
}

.support-index .support-ticket-content-w .support-ticket-info .info-header {
  color: #047bf8;
  text-align: center;
  margin-bottom: 15px;
}

.support-index .support-ticket-content-w .support-ticket-info .info-section {
  padding: 0px;
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.support-index .support-ticket-content-w .support-ticket-info .info-section .label {
  color: #adb5bd;
  display: block;
  margin-bottom: 5px;
}

.support-index .support-ticket-content-w .support-ticket-info .info-section .value {
  display: block;
}

.support-index .support-ticket-content-w .support-ticket-info .info-section .value + .label {
  margin-top: 10px;
}

.support-index .support-ticket-content-w .support-ticket-info .info-section:last-child {
  margin-bottom: 0px;
  border-bottom: none;
  padding-bottom: 0px;
}

.support-index .support-ticket-content-w .support-ticket-info ul.users-list {
  list-style: none;
  padding: 0px;
  margin: 0px;
  margin-bottom: 20px;
}

.support-index .support-ticket-content-w .support-ticket-info ul.users-list li {
  margin-bottom: 10px;
}

.support-index .support-ticket-content-w .support-ticket-info ul.users-list.as-tiles {
  display: inline-block;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
          justify-content: space-between;
}

.support-index .support-ticket-content-w .support-ticket-info ul.users-list.as-tiles li {
  text-align: center;
  -webkit-box-flex: 0;
          flex: 0 0 47%;
}

.support-index .support-ticket-content-w .support-ticket-info ul.users-list.as-tiles li a {
  display: block;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
  padding: 10px;
  border-radius: 6px;
  margin: 0px;
  text-decoration: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.support-index .support-ticket-content-w .support-ticket-info ul.users-list.as-tiles li a span {
  display: block;
  border-bottom: none;
  color: #3E4B5B;
  font-size: 0.72rem;
}

.support-index .support-ticket-content-w .support-ticket-info ul.users-list.as-tiles li a .avatar {
  margin: 0px;
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
  background-size: cover;
  display: inline-block;
  border-radius: 50px;
}

.support-index .support-ticket-content-w .support-ticket-info ul.users-list.as-tiles li a i {
  margin-right: 0px;
  margin-bottom: 10px;
}

.support-index .support-ticket-content-w .support-ticket-info ul.users-list.as-tiles li a:hover {
  box-shadow: 0px 5px 12px rgba(126, 142, 177, 0.2);
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

.support-index .support-ticket-content-w .support-ticket-info ul.users-list.as-tiles li a:hover span {
  color: #047bf8;
}

.support-index .support-ticket-content-w .support-ticket-info .customer {
  text-align: center;
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.support-index .support-ticket-content-w .support-ticket-info .customer .avatar {
  margin-bottom: 10px;
}

.support-index .support-ticket-content-w .support-ticket-info .customer .avatar img {
  border-radius: 50px;
  width: 100px;
  height: auto;
}

.support-index .support-ticket-content-w .support-ticket-info .customer .customer-name {
  margin-bottom: 5px;
}

.support-index .support-ticket-content-w .support-ticket-info .customer .customer-tickets {
  color: #adb5bd;
}

.support-index .support-ticket-content-w.folded-info .support-ticket-content {
  padding-right: 30px;
}

.support-index .support-ticket-content-w.folded-info .support-ticket-info {
  display: none;
}

.support-index .support-ticket-content-w.folded-info .show-ticket-info {
  display: inline-block;
}

.color-scheme-dark .support-index .st-meta .badge {
  border: rgba(0, 0, 0, 0.5) !important;
}

.color-scheme-dark .support-index .st-body .ticket-content .ticket-description {
  color: #ccd9e8;
}

.color-scheme-dark .support-index .st-foot {
  background-color: rgba(0, 0, 0, 0.05);
}

.color-scheme-dark .support-index .st-foot .label {
  color: rgba(218, 226, 243, 0.4);
}

.color-scheme-dark .support-index .support-tickets-header .tickets-control, .color-scheme-dark .support-index .support-tickets-header .tickets-filter {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.color-scheme-dark .support-index .support-ticket {
  background-color: #323c58;
  box-shadow: 0px 5px 15px rgba(22, 22, 35, 0.1);
}

.color-scheme-dark .support-index .support-ticket.active, .color-scheme-dark .support-index .support-ticket:hover {
  background-color: #047bf8;
}

.color-scheme-dark .support-index .support-ticket.active .ticket-title, .color-scheme-dark .support-index .support-ticket:hover .ticket-title {
  color: #fff;
}

.color-scheme-dark .support-index .support-ticket.active .st-foot a.value, .color-scheme-dark .support-index .support-ticket.active .st-foot .value, .color-scheme-dark .support-index .support-ticket:hover .st-foot a.value, .color-scheme-dark .support-index .support-ticket:hover .st-foot .value {
  color: #fff;
}

.color-scheme-dark .support-index .support-ticket.active .label, .color-scheme-dark .support-index .support-ticket:hover .label {
  color: rgba(255, 255, 255, 0.6);
}

.color-scheme-dark .support-index .support-tickets-header .tickets-filter label {
  color: rgba(218, 226, 243, 0.4);
}

.color-scheme-dark .support-index .support-tickets-header .tickets-filter select {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.4);
  color: #ccd9e8;
}

.color-scheme-dark .support-index .support-tickets-header .tickets-control .element-search input {
  background-color: rgba(0, 0, 0, 0.1);
}

.color-scheme-dark .support-index .support-tickets-header .tickets-control .element-search:before {
  color: rgba(218, 226, 243, 0.4);
}

.color-scheme-dark .support-index .support-tickets-header .tickets-control .element-search input::-webkit-input-placeholder {
  color: rgba(218, 226, 243, 0.4);
}

.color-scheme-dark .support-index .support-tickets-header .tickets-control .element-search input::-moz-placeholder {
  color: rgba(218, 226, 243, 0.4);
}

.color-scheme-dark .support-index .support-tickets-header .tickets-control .element-search input:-ms-input-placeholder {
  color: rgba(218, 226, 243, 0.4);
}

.color-scheme-dark .support-index .support-tickets-header .tickets-control .element-search input::-ms-input-placeholder {
  color: rgba(218, 226, 243, 0.4);
}

.color-scheme-dark .support-index .support-tickets-header .tickets-control .element-search input::placeholder {
  color: rgba(218, 226, 243, 0.4);
}

.color-scheme-dark .support-index .support-ticket-content-w {
  background-color: #323c58;
  box-shadow: 0px 5px 15px rgba(22, 22, 35, 0.1);
}

.color-scheme-dark .support-index .support-ticket-content-w .ticket-reply {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.color-scheme-dark .support-index .support-ticket-content-w .ticket-reply .ticket-reply-info .info-data .label {
  color: rgba(218, 226, 243, 0.4);
}

.color-scheme-dark .support-index .support-ticket-content-w .ticket-reply .ticket-attachments .attachment {
  background-color: #293148;
}

.color-scheme-dark .support-index .support-ticket-content-w .support-ticket-info {
  background-image: none;
  background-color: #293148;
}

.color-scheme-dark .support-index .support-ticket-content-w .support-ticket-info ul.users-list.as-tiles li a {
  background-color: #3b4768;
  box-shadow: 0px 5px 15px rgba(22, 22, 35, 0.1);
}

.color-scheme-dark .support-index .support-ticket-content-w .support-ticket-info ul.users-list.as-tiles li a span {
  color: #ccd9e8;
}

.color-scheme-dark .support-index .support-ticket-content-w .support-ticket-info .info-section {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.color-scheme-dark .support-index .support-ticket-content-w .support-ticket-info .info-section .label {
  color: rgba(218, 226, 243, 0.4);
}

.color-scheme-dark .support-index .support-ticket-content-w .support-ticket-info .customer {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.color-scheme-dark .support-index .support-ticket-content-w .support-ticket-info .customer .customer-tickets {
  color: rgba(218, 226, 243, 0.4);
}

.color-scheme-dark .support-index .support-ticket-content-w .ticket-reply .ticket-reply-content {
  background-color: #3b4768;
}

/* 23. Onboarding */
.onboarding-modal .onboarding-media {
  padding-top: 20px;
  text-align: center;
}

.onboarding-modal .onboarding-media img {
  margin: 0px auto;
}

.onboarding-modal button.close {
  position: absolute;
  top: 15px;
  right: 20px;
  float: none;
  z-index: 999;
  color: #111;
  opacity: 1;
  text-shadow: none;
}

.onboarding-modal button.close .os-icon {
  font-size: 12px;
}

.onboarding-modal button.close span {
  display: inline-block;
  vertical-align: middle;
}

.onboarding-modal button.close .close-label {
  margin-right: 10px;
  color: #C26C6C;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 0.81rem;
}

.onboarding-modal .onboarding-content {
  padding: 40px 50px;
}

.onboarding-modal .onboarding-content.with-gradient {
  padding: 70px 50px 40px;
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
}

.onboarding-modal .onboarding-content h1, .onboarding-modal .onboarding-content h2, .onboarding-modal .onboarding-content h3, .onboarding-modal .onboarding-content h4 {
  color: #047bf8;
  margin-bottom: 1.5rem;
}

.onboarding-modal .onboarding-content .onboarding-text {
  color: rgba(69, 76, 96, 0.49);
  font-weight: 300;
  font-size: 0.99rem;
  margin-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.onboarding-modal .onboarding-content .onboarding-text:last-child {
  margin-bottom: 10px;
}

.onboarding-modal .onboarding-content .features-list {
  text-align: left;
  padding-left: 20px;
}

.onboarding-modal .onboarding-content .features-list li {
  margin-bottom: 10px;
  list-style: none;
  position: relative;
}

.onboarding-modal .onboarding-content .features-list li:before {
  content: "\e961";
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  color: #047bf8;
  position: absolute;
  left: -20px;
  top: 45%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.onboarding-modal .onboarding-side-by-side {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
}

.onboarding-modal .onboarding-side-by-side .onboarding-media {
  -webkit-box-flex: 300px;
          flex: 300px 0 0;
  padding: 40px 0px;
}

.onboarding-modal .onboarding-side-by-side .onboarding-content {
  padding: 70px 70px 60px;
}

.onboarding-modal .onboarding-side-by-side .onboarding-content.with-gradient {
  padding: 70px 70px 60px;
  background-size: auto 100%;
  background-position: center left;
  background-repeat: no-repeat;
}

.onboarding-modal .onboarding-slide .onboarding-side-by-side .onboarding-media {
  padding-bottom: 60px;
}

.onboarding-modal .onboarding-slide .onboarding-side-by-side .onboarding-content {
  padding-bottom: 80px;
}

.onboarding-modal .onboarding-slide .onboarding-side-by-side .onboarding-content.with-gradient {
  padding-bottom: 80px;
}

.onboarding-modal form {
  text-align: left;
}

.onboarding-modal .onboarding-text + form {
  margin-top: 30px;
}

.onboarding-modal .onboarding-slider-w .onboarding-slide {
  display: none;
}

.onboarding-modal .onboarding-slider-w .onboarding-slide:first-child {
  display: block;
}

.onboarding-modal .onboarding-slider-w.slick-initialized .onboarding-slide {
  display: block;
}

.onboarding-modal.animated .onboarding-media {
  opacity: 0;
  -webkit-transform: translateY(80px) scale(0.8);
          transform: translateY(80px) scale(0.8);
  -webkit-transition: all 0.5s cubic-bezier(0.25, 1.1, 0.5, 1.35);
  transition: all 0.5s cubic-bezier(0.25, 1.1, 0.5, 1.35);
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}

.onboarding-modal.animated .onboarding-content {
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
}

.onboarding-modal.animated .onboarding-title {
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  -webkit-transition: all 0.5s cubic-bezier(0.25, 1.1, 0.5, 1.35);
  transition: all 0.5s cubic-bezier(0.25, 1.1, 0.5, 1.35);
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
  opacity: 0;
}

.onboarding-modal.animated .onboarding-text {
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  -webkit-transition: all 0.5s cubic-bezier(0.25, 1.1, 0.5, 1.35);
  transition: all 0.5s cubic-bezier(0.25, 1.1, 0.5, 1.35);
  -webkit-transition-delay: 0.6s;
          transition-delay: 0.6s;
  opacity: 0;
}

.onboarding-modal.animated .features-list, .onboarding-modal.animated form {
  -webkit-transform: translateY(40px);
          transform: translateY(40px);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transition-delay: 0.7s;
          transition-delay: 0.7s;
  opacity: 0;
}

.onboarding-modal.animated .onboarding-side-by-side .onboarding-content {
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
}

.onboarding-modal.show .onboarding-media {
  opacity: 1;
  -webkit-transform: translateY(0) scale(1);
          transform: translateY(0) scale(1);
}

.onboarding-modal.show .onboarding-content {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.onboarding-modal.show .onboarding-title {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.onboarding-modal.show .onboarding-text {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.onboarding-modal.show .features-list, .onboarding-modal.show form {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.onboarding-modal.show .onboarding-side-by-side .onboarding-content {
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
}

.onboarding-modal .slick-next, .onboarding-modal .slick-prev {
  border: none;
  background-color: transparent;
  font-weight: 500;
  font-size: 0.9rem;
  color: #046fdf;
  bottom: 25px;
  position: absolute;
  z-index: 999;
  cursor: pointer;
  letter-spacing: 1px;
}

.onboarding-modal .slick-next.slick-disabled, .onboarding-modal .slick-prev.slick-disabled {
  opacity: 0;
}

.onboarding-modal .slick-next:focus, .onboarding-modal .slick-prev:focus {
  outline: none;
}

.onboarding-modal .slick-next:before, .onboarding-modal .slick-prev:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  color: #047bf8;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.onboarding-modal .slick-next {
  right: 50px;
}

.onboarding-modal .slick-next:before {
  content: "\e986";
  right: -20px;
}

.onboarding-modal .slick-prev {
  left: 50px;
}

.onboarding-modal .slick-prev:before {
  content: "\e987";
  left: -20px;
}

.onboarding-modal .slick-dots {
  position: absolute;
  bottom: 30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 0px;
  margin: 0px;
}

.onboarding-modal .slick-dots li {
  list-style: none;
  display: inline-block;
  margin: 0px 5px;
}

.onboarding-modal .slick-dots li button {
  font-size: 0px;
  color: transparent;
  width: 10px;
  height: 10px;
  background-color: #047bf8;
  opacity: 0.2;
  border: none;
  box-shadow: none;
  padding: 0px;
  border-radius: 10px;
  cursor: pointer;
}

.onboarding-modal .slick-dots li button:hover {
  opacity: 1;
}

.onboarding-modal .slick-dots li button:focus {
  outline: none;
}

.onboarding-modal .slick-dots li.slick-active button {
  background-color: #047bf8;
  opacity: 1;
}

.onboarding-modal .onboarding-slide:focus {
  outline: none;
}

.onboarding-modal .onboarding-slide .onboarding-content {
  padding-bottom: 80px;
}

/* 24. Top Bar styles */
/*
  Top Bar Styles
*/
.all-wrapper > .top-bar {
  border-radius: 6px 6px 0px 0px;
}

.content-w > .top-bar {
  padding-left: 0px;
}

.top-bar {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
  padding: 0px 20px 0px 0px;
  text-align: center;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
  position: relative;
  border-radius: 0px 6px 0px 0px;
}

.top-bar > ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  text-transform: uppercase;
  color: #3E4B5B;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 0.99rem;
}

.top-bar > ul li {
  display: inline-block;
}

.top-bar > ul li a {
  display: inline-block;
  padding: 20px 25px;
  color: rgba(0, 0, 0, 0.25);
  position: relative;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.top-bar > ul li a:after {
  content: "";
  background-color: #047bf8;
  position: absolute;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 0px;
  height: 5px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.top-bar > ul li.active a, .top-bar > ul li:hover a {
  color: #3E4B5B;
  text-decoration: none;
}

.top-bar > ul li.active a:after, .top-bar > ul li:hover a:after {
  width: 100%;
}

.top-bar .fancy-selector-w {
  margin-right: auto;
  align-self: stretch;
}

.top-bar .logo-w.menu-size + .fancy-selector-w {
  margin-left: 0px;
}

.top-bar .logo-w {
  text-align: left;
}

.top-bar .logo-w a {
  display: inline-block;
}

.top-bar .logo-w img {
  height: 25px;
  width: auto;
  display: inline-block;
  vertical-align: middle;
}

.top-bar .logo-w span {
  display: inline-block;
  vertical-align: middle;
}

.top-bar .logo-w img + span {
  margin-left: 20px;
  color: #3E4B5B;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.81rem;
  font-weight: 500;
}

.top-bar .logo-w.menu-size {
  width: 260px;
}

.top-bar .top-menu-controls {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
          align-items: center;
  margin-left: auto;
  padding: 7px 0px;
}

.top-bar .top-menu-controls .element-search {
  position: relative;
  margin: 0px 1rem;
}

.top-bar .top-menu-controls .element-search:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 16px;
  content: "\e92c";
  color: rgba(0, 0, 0, 0.4);
}

.top-bar .top-menu-controls .element-search input {
  border: none;
  box-shadow: none;
  background-color: transparent;
  border-radius: 30px;
  padding: 8px 15px 8px 40px;
  display: block;
  width: 220px;
  outline: none;
  border: none;
  box-shadow: none;
  background: #eee;
}

.top-bar .top-menu-controls .element-search input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.top-bar .top-menu-controls .element-search input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.top-bar .top-menu-controls .element-search input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.top-bar .top-menu-controls .element-search input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.top-bar .top-menu-controls .element-search input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.top-bar .messages-notifications {
  margin: 0px 1rem;
  position: relative;
  font-size: 24px;
  color: #047bf8;
}

.top-bar .messages-notifications .new-messages-count {
  background-color: #e65252;
  color: #fff;
  border-radius: 6px;
  font-weight: 500;
  position: absolute;
  top: -5px;
  right: -12px;
  padding: 4px 4px;
  vertical-align: middle;
  font-size: 0.72rem;
  line-height: 1;
}

.top-bar .messages-notifications i {
  vertical-align: middle;
}

.top-bar .top-icon {
  margin: 0px 1rem;
  color: #047bf8;
  font-size: 26px;
}

.top-bar .top-icon i {
  vertical-align: middle;
}

.top-bar .logged-user-w {
  position: relative;
  padding: 0px 1rem;
  border-bottom: none;
}

.top-bar .logged-user-w .avatar-w {
  padding: 0px;
  border: none;
  vertical-align: middle;
}

.top-bar .logged-user-w .avatar-w img {
  width: 40px;
}

.top-bar .logged-user-w .logged-user-i {
  display: inline-block;
}

.top-bar .logged-user-w .logged-user-i:hover {
  cursor: pointer;
}

.top-bar .logged-user-w .logged-user-i:hover .logged-user-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}

.top-bar .logged-user-w .logged-user-menu {
  background: #1b55e2;
  box-shadow: 0 10px 30px 0 rgba(27, 30, 35, 0.27);
  position: absolute;
  top: -10px;
  right: -20px;
  overflow: hidden;
  padding: 1rem;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-align: left;
  border-radius: 6px;
}

.top-bar .logged-user-w .logged-user-menu .logged-user-avatar-info {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: start;
          justify-content: flex-start;
  margin-bottom: 10px;
  padding: 0px 30px 10px 13px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.top-bar .logged-user-w .logged-user-menu .avatar-w {
  border-color: #fff;
  vertical-align: middle;
  margin-right: 20px;
}

.top-bar .logged-user-w .logged-user-menu .logged-user-info-w {
  vertical-align: middle;
}

.top-bar .logged-user-w .logged-user-menu .logged-user-info-w .logged-user-name {
  color: #fff;
  white-space: nowrap;
}

.top-bar .logged-user-w .logged-user-menu .logged-user-info-w .logged-user-role {
  color: rgba(255, 255, 255, 0.6);
  white-space: nowrap;
}

.top-bar .logged-user-w .logged-user-menu ul {
  list-style: none;
  text-align: left;
  margin: 0px;
  padding: 0px;
  padding-bottom: 0px;
}

.top-bar .logged-user-w .logged-user-menu ul li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.top-bar .logged-user-w .logged-user-menu ul li a {
  display: block;
  padding: 10px 20px;
  color: #fff;
}

.top-bar .logged-user-w .logged-user-menu ul li a i {
  vertical-align: middle;
  margin-right: 15px;
  font-size: 20px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: inline-block;
}

.top-bar .logged-user-w .logged-user-menu ul li a span {
  vertical-align: middle;
  font-size: 0.9rem;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: inline-block;
}

.top-bar .logged-user-w .logged-user-menu ul li a:hover {
  text-decoration: none;
}

.top-bar .logged-user-w .logged-user-menu ul li a:hover i {
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}

.top-bar .logged-user-w .logged-user-menu ul li a:hover span {
  -webkit-transform: translateX(8px);
          transform: translateX(8px);
}

.top-bar .logged-user-w .logged-user-menu ul li:last-child {
  border-bottom: none;
}

.top-bar .logged-user-w .logged-user-menu .bg-icon {
  font-size: 100px;
  color: rgba(255, 255, 255, 0.1);
  position: absolute;
  bottom: -40px;
  right: -20px;
}

.top-bar.color-scheme-transparent {
  background-color: transparent;
  box-shadow: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.top-bar.color-scheme-light .top-menu-controls .element-search input, .top-bar.color-scheme-transparent .top-menu-controls .element-search input {
  background-color: rgba(121, 138, 185, 0.07);
}

.top-bar.color-scheme-light .logo-w .logo-label, .top-bar.color-scheme-transparent .logo-w .logo-label {
  color: #3E4B5B;
}

.top-bar.color-scheme-light .logo-w .logo-element, .top-bar.color-scheme-transparent .logo-w .logo-element {
  background-color: #0a7cf8;
}

.top-bar.color-scheme-light .logo-w .logo-element:after, .top-bar.color-scheme-transparent .logo-w .logo-element:after {
  background-color: #bfd6f9;
}

.top-bar.color-scheme-light .logo-w .logo:hover .logo-label, .top-bar.color-scheme-transparent .logo-w .logo:hover .logo-label {
  color: #047bf8;
}

.top-bar.color-scheme-light .logo-w .logo:hover .logo-label:after, .top-bar.color-scheme-transparent .logo-w .logo:hover .logo-label:after {
  background-color: #047bf8;
}

.top-bar.color-scheme-dark, .top-bar.color-scheme-bright {
  box-shadow: none;
}

.top-bar.color-scheme-dark .top-menu-controls .element-search input, .top-bar.color-scheme-bright .top-menu-controls .element-search input {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.top-bar.color-scheme-dark .top-menu-controls .element-search input::-webkit-input-placeholder, .top-bar.color-scheme-bright .top-menu-controls .element-search input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.top-bar.color-scheme-dark .top-menu-controls .element-search input::-moz-placeholder, .top-bar.color-scheme-bright .top-menu-controls .element-search input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.top-bar.color-scheme-dark .top-menu-controls .element-search input:-ms-input-placeholder, .top-bar.color-scheme-bright .top-menu-controls .element-search input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.top-bar.color-scheme-dark .top-menu-controls .element-search input::-ms-input-placeholder, .top-bar.color-scheme-bright .top-menu-controls .element-search input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.top-bar.color-scheme-dark .top-menu-controls .element-search input::placeholder, .top-bar.color-scheme-bright .top-menu-controls .element-search input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.top-bar.color-scheme-dark .top-menu-controls .element-search:before, .top-bar.color-scheme-bright .top-menu-controls .element-search:before {
  color: rgba(255, 255, 255, 0.7);
}

.top-bar.color-scheme-dark .messages-notifications,
.top-bar.color-scheme-dark .top-icon, .top-bar.color-scheme-bright .messages-notifications,
.top-bar.color-scheme-bright .top-icon {
  color: #fff;
}

.top-bar.color-scheme-dark > ul li a, .top-bar.color-scheme-bright > ul li a {
  color: rgba(255, 255, 255, 0.4);
}

.top-bar.color-scheme-dark > ul li a:after, .top-bar.color-scheme-bright > ul li a:after {
  background-color: #FBB463;
  border-color: #FBB463;
}

.top-bar.color-scheme-dark > ul li.active a, .top-bar.color-scheme-dark > ul li:hover a, .top-bar.color-scheme-bright > ul li.active a, .top-bar.color-scheme-bright > ul li:hover a {
  color: #fff;
}

.top-bar.color-scheme-dark .logo-w img + span, .top-bar.color-scheme-bright .logo-w img + span {
  color: #fff;
}

.top-bar.color-scheme-dark .fancy-selector-w .fancy-selector-current .fs-selector-trigger, .top-bar.color-scheme-bright .fancy-selector-w .fancy-selector-current .fs-selector-trigger {
  background-color: #3572ed;
}

.top-bar.color-scheme-dark {
  background-color: #323c58;
}

.top-bar.color-scheme-bright {
  background-color: #1b55e2;
}

/* 25. Search */
.search-with-suggestions-w {
  display: none;
}

.search-with-suggestions-w.active {
  display: block;
}

.search-with-suggestions-w .search-with-suggestions-modal {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  padding: 30px;
  position: relative;
}

.search-with-suggestions-w .search-with-suggestions-modal .close-search-suggestions {
  font-size: 14px;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  right: 0px;
  cursor: pointer;
  padding: 2px;
  background-color: #e65252;
  color: #fff;
  line-height: 0.9;
  vertical-align: middle;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.search-with-suggestions-w .search-with-suggestions-modal .close-search-suggestions i {
  vertical-align: middle;
}

.search-with-suggestions-w .search-with-suggestions-modal .close-search-suggestions:hover {
  background-color: #b71b1b;
  color: #fff;
}

.search-with-suggestions-w .search-with-suggestions-modal .element-search {
  position: relative;
  margin: 0px;
  margin-bottom: 30px;
}

.search-with-suggestions-w .search-with-suggestions-modal .element-search:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'osfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  left: 6px;
  top: 48%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 16px;
  content: "\e92c";
  color: rgba(0, 0, 0, 0.3);
}

.search-with-suggestions-w .search-with-suggestions-modal .element-search input {
  border: none;
  box-shadow: none;
  border-radius: 0px;
  padding: 8px 15px 8px 31px;
  display: block;
  width: 100%;
  outline: none;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #111;
}

.search-with-suggestions-w .search-with-suggestions-modal .element-search input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.search-with-suggestions-w .search-with-suggestions-modal .element-search input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.search-with-suggestions-w .search-with-suggestions-modal .element-search input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.search-with-suggestions-w .search-with-suggestions-modal .element-search input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.search-with-suggestions-w .search-with-suggestions-modal .element-search input::placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.search-with-suggestions-w .search-suggestions-group + .search-suggestions-group {
  margin-top: 20px;
}

.search-with-suggestions-w .search-suggestions-group:last-child .ssg-items {
  margin-bottom: 0px;
}

.search-with-suggestions-w .search-suggestions-group .ssg-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
          align-items: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0.07);
  margin-bottom: 15px;
  padding-bottom: 5px;
}

.search-with-suggestions-w .search-suggestions-group .ssg-header .ssg-icon {
  color: #047bf8;
  font-size: 20px;
  margin-right: 15px;
}

.search-with-suggestions-w .search-suggestions-group .ssg-header .ssg-name {
  color: #047bf8;
  font-family: "Avenir Next W01", "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
  font-size: 0.99rem;
}

.search-with-suggestions-w .search-suggestions-group .ssg-header .ssg-info {
  margin-left: auto;
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.8rem;
}

.search-with-suggestions-w .ssg-content .ssg-nothing-found {
  color: rgba(0, 0, 0, 0.4);
  padding: 0px 20px;
  text-align: center;
  font-size: 0.8rem;
  display: none;
}

.search-with-suggestions-w .ssg-content .ssg-nothing-found .icon-w {
  margin-bottom: 10px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.2);
}

.search-with-suggestions-w .ssg-items {
  margin-bottom: 25px;
}

.search-with-suggestions-w .ssg-items .ssg-item .item-name span {
  background-color: #FFF0B7;
}

.search-with-suggestions-w .ssg-items.ssg-items-blocks {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
}

.search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item {
  -webkit-box-flex: 0;
          flex: 0 0 50%;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  text-align: center;
}

.search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item:hover {
  text-decoration: none;
}

.search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item:hover .item-icon,
.search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item:hover .item-media {
  -webkit-transform: rotate(-15deg);
          transform: rotate(-15deg);
}

.search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item:hover .item-name {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  color: #047bf8;
}

.search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item:nth-child(even) {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item:last-child, .search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item:nth-last-child(2) {
  border-bottom: none;
}

.search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item .item-icon {
  font-size: 20px;
  margin-bottom: 5px;
  color: #047bf8;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item .item-media {
  height: 30px;
  width: 30px;
  margin: 0px auto;
  background-size: cover;
  margin-bottom: 10px;
  border-radius: 50%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item .item-name {
  font-size: 0.8rem;
  text-decoration: underline;
  color: #3E4B5B;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.search-with-suggestions-w .ssg-items.ssg-items-list .ssg-item {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  padding: 0px 10px 7px 0px;
  margin-bottom: 7px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.search-with-suggestions-w .ssg-items.ssg-items-list .ssg-item:hover {
  text-decoration: none;
}

.search-with-suggestions-w .ssg-items.ssg-items-list .ssg-item:hover .item-media {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.search-with-suggestions-w .ssg-items.ssg-items-list .ssg-item:hover .item-name {
  -webkit-transform: translateX(3px);
          transform: translateX(3px);
  color: #047bf8;
}

.search-with-suggestions-w .ssg-items.ssg-items-list .ssg-item:last-child {
  border-bottom: none;
}

.search-with-suggestions-w .ssg-items.ssg-items-list .ssg-item .item-media {
  -webkit-box-flex: 0;
          flex: 0 0 30px;
  height: 30px;
  background-size: cover;
  margin-right: 15px;
  border-radius: 50%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.search-with-suggestions-w .ssg-items.ssg-items-list .ssg-item .item-name {
  -webkit-box-flex: 1;
          flex: 1;
  text-align: left;
  color: #3E4B5B;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.search-with-suggestions-w .ssg-items.ssg-items-boxed .ssg-item {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  padding: 6px 8px;
  border: 1px solid #C4D1E1;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.search-with-suggestions-w .ssg-items.ssg-items-boxed .ssg-item:hover {
  text-decoration: none;
  border: 1px solid #a2b7d0;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.12);
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

.search-with-suggestions-w .ssg-items.ssg-items-boxed .ssg-item:hover .item-name {
  color: #047bf8;
}

.search-with-suggestions-w .ssg-items.ssg-items-boxed .ssg-item + .ssg-item {
  margin-top: 8px;
}

.search-with-suggestions-w .ssg-items.ssg-items-boxed .ssg-item .item-media {
  -webkit-box-flex: 0;
          flex: 0 0 30px;
  height: 30px;
  background-size: cover;
  margin-right: 15px;
}

.search-with-suggestions-w .ssg-items.ssg-items-boxed .ssg-item .item-name {
  -webkit-box-flex: 1;
          flex: 1;
  text-align: left;
  color: #3E4B5B;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.search-with-suggestions-w.over-search-field {
  position: absolute;
  width: 280px;
  z-index: 999;
  -webkit-transform: translate(-21px, -5px);
          transform: translate(-21px, -5px);
}

.search-with-suggestions-w.over-search-field .search-with-suggestions-modal {
  padding-top: 5px;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.4);
}

.search-with-suggestions-w.screen-center {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.search-with-suggestions-w.screen-center .search-with-suggestions-modal {
  top: 15%;
  margin: 0px auto;
  width: 350px;
  position: relative;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
}

/* 26. Ecommerce */
.order-box {
  padding: 30px;
}

.order-box .order-details-box {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  margin-bottom: 20px;
  -webkit-box-align: center;
          align-items: center;
}

.order-box .order-details-box .order-main-info span {
  display: block;
  color: #adb5bd;
  line-height: 1.3;
}

.order-box .order-details-box .order-main-info strong {
  display: block;
  font-size: 1.5rem;
  line-height: 1.3;
}

.order-box .order-details-box .order-sub-info span {
  display: block;
  color: #adb5bd;
  line-height: 1.3;
  font-size: 0.775rem;
}

.order-box .order-details-box .order-sub-info strong {
  display: block;
  font-size: 0.9rem;
  line-height: 1.3;
}

.order-box .order-controls {
  background-color: #FFF7EA;
  border: 1px solid #E9D9C1;
  padding: 10px;
  margin-bottom: 20px;
}

.order-box .order-controls .form-group {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.order-box .order-controls .form-group label {
  margin-right: 5px;
}

.order-box .order-controls .form-group:last-child {
  border-right: none;
  margin-right: 0px;
  padding-right: 0px;
  margin-left: auto;
}

.order-box .order-items-table {
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.order-box .order-items-table .product-image {
  width: 70px;
  height: 70px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.order-box .order-items-table .product-name {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.3;
}

.order-box .order-items-table .product-remove-btn {
  color: #E08989;
  font-size: 16px;
}

.order-box .order-items-table .product-details {
  color: #adb5bd;
  font-size: 0.8rem;
}

.order-box .order-items-table .product-details strong {
  color: #3E4B5B;
}

.order-box .order-items-table .product-details .color-box {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: 5px;
  margin-right: 10px;
}

.order-box .order-items-table .product-price {
  font-weight: 500;
  font-size: 1.25rem;
}

.order-box .order-items-table .quantity-input .input-group-text {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.order-box .order-items-table .quantity-input .form-control {
  -webkit-box-flex: 0;
          flex: 0 0 45px;
  text-align: center;
  font-weight: 500;
}

.order-box .order-section-heading {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.ecommerce-customer-info {
  padding: 30px;
}

.ecommerce-customer-info .ecommerce-customer-main-info {
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ecommerce-customer-info .ecommerce-customer-main-info .ecc-avatar {
  width: 90px;
  height: 90px;
  background-size: cover;
  background-position: center center;
  border-radius: 50%;
  background-color: #fff;
  margin: 0px auto;
  box-shadow: 0px 0px 0px 10px #fff;
}

.ecommerce-customer-info .ecommerce-customer-main-info .ecc-name {
  margin-top: 10px;
  font-weight: 500;
  font-size: 1.25rem;
}

.ecommerce-customer-info .ecommerce-customer-sub-info {
  margin-bottom: 30px;
}

.ecommerce-customer-info .ecc-sub-info-row {
  margin-bottom: 10px;
}

.ecommerce-customer-info .ecc-sub-info-row + .ecc-sub-info-row {
  padding-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.ecommerce-customer-info .ecc-sub-info-row .sub-info-label {
  display: block;
  color: #adb5bd;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8rem;
  margin-bottom: 4px;
}

.ecommerce-customer-info .ecc-sub-info-row .sub-info-value {
  display: block;
}

.ecommerce-customer-info .ecc-sub-info-row .sub-info-value img {
  margin-right: 5px;
}

.ecommerce-customer-info .os-tabs-controls .nav {
  flex-wrap: nowrap;
}

.ecommerce-customer-info .os-tabs-controls .nav-link {
  white-space: nowrap;
  padding: 10px 0px;
}

.order-summary-row {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
}

.order-summary-row.as-total .order-summary-label {
  font-weight: 500;
  font-size: 1.25rem;
  color: #3E4B5B;
}

.order-summary-row.as-total .order-summary-value {
  font-weight: 500;
  font-size: 1.5rem;
}

.order-summary-row .order-summary-label {
  color: #adb5bd;
}

.order-summary-row .order-summary-label strong {
  display: block;
  color: #3E4B5B;
  font-size: 0.8rem;
}

.order-summary-row .order-summary-value {
  font-weight: 500;
}

.order-summary-row + .order-summary-row {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding-top: 5px;
  margin-top: 5px;
}

.order-summary-row + .order-summary-row.as-total {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 3px solid #222;
}

/* 27. Customizer */
.floated-customizer-btn {
  z-index: 9999;
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-image: linear-gradient(-154deg, #b237ff 8%, #5211e6 90%);
  box-shadow: 0px 5px 10px rgba(19, 19, 19, 0.17);
  border-radius: 75px;
  color: #fff;
  padding: 12px 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  vertical-align: middle;
  font-size: 1.08rem;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.floated-customizer-btn.third-floated-btn {
  right: 400px;
}

.floated-customizer-btn > .icon-w {
  line-height: 1;
  vertical-align: middle;
  display: inline-block;
  -webkit-animation: os-rotating 2s linear infinite;
          animation: os-rotating 2s linear infinite;
}

.floated-customizer-btn > .icon-w i {
  font-size: 24px;
}

.floated-customizer-btn > span {
  margin-left: 10px;
  vertical-align: middle;
  display: inline-block;
  font-weight: 500;
}

.floated-customizer-btn > span + span {
  margin-left: 5px;
}

.floated-customizer-btn:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  background-color: #0f1115;
}

@-webkit-keyframes os-rotating {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    color: #f7ff0c;
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    color: #fff;
  }
}

@keyframes os-rotating {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    color: #f7ff0c;
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    color: #fff;
  }
}

.floated-customizer-panel {
  background-color: #fff;
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 320px;
  padding: 25px 35px;
  box-shadow: 0px 15px 125px rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  color: #3E4B5B;
  bottom: 70px;
  z-index: 999;
  right: 395px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transform: translate3d(20px, 40px, 0) scale(0.9);
          transform: translate3d(20px, 40px, 0) scale(0.9);
}

.floated-customizer-panel.active {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate3d(0px, 0px, 0) scale(1);
          transform: translate3d(0px, 0px, 0) scale(1);
}

.floated-customizer-panel .close-customizer-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 14px;
  color: #111;
  cursor: pointer;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.floated-customizer-panel .close-customizer-btn:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.floated-customizer-panel .fcp-header {
  font-weight: 500;
  font-family: "Avenir Next W01", "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  padding: 10px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  color: #334152;
  font-size: 1.08rem;
}

.floated-customizer-panel .fcp-header span {
  display: inline-block;
  vertical-align: middle;
}

.floated-customizer-panel .fcp-header .icon-w {
  display: inline-block;
  vertical-align: middle;
  color: #047bf8;
  font-size: 24px;
  margin-right: 10px;
}

.floated-customizer-panel .fcp-header .icon-w i {
  display: inline-block;
  vertical-align: middle;
}

.floated-customizer-panel .fcp-content .fcp-group + .fcp-group {
  margin-top: 20px;
}

.floated-customizer-panel .fcp-content .fcp-group-header {
  color: #047bf8;
  font-weight: 500;
  font-family: "Avenir Next W01", "Lato", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 0.8rem;
  margin-bottom: 15px;
}

.floated-customizer-panel .fcp-content .fcp-group-contents .fcp-field {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 7px;
  margin-bottom: 7px;
}

.floated-customizer-panel .fcp-content .fcp-group-contents .fcp-field:last-child {
  border-bottom: none;
  margin-bottom: 0px;
}

.floated-customizer-panel .fcp-content .fcp-group-contents .fcp-field label {
  font-size: 0.9rem;
  -webkit-box-flex: 0;
          flex: 0 0 54%;
  padding-right: 10px;
  margin: 0px;
}

.floated-customizer-panel .fcp-content .fcp-group-contents .fcp-field select {
  margin-left: auto;
  border: 2px solid #dde2ec;
  font-weight: 400;
  padding: 4px 15px;
  padding-right: 30px;
  background-color: #fff;
  line-height: 1.1;
  font-size: 0.9rem;
  border-radius: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 5px top 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
  outline: none;
  -webkit-box-flex: 1;
          flex: 1;
}

.floated-customizer-panel .fcp-content .fcp-group-contents .fcp-field .fcp-colors {
  margin-left: auto;
}

.floated-customizer-panel .fcp-content .fcp-group-contents .fcp-field .fcp-colors .color-selector {
  display: inline-block;
  vertical-align: middle;
  height: 14px;
  width: 14px;
  border-radius: 10px;
  margin: 0px 5px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  cursor: pointer;
}

.floated-customizer-panel .fcp-content .fcp-group-contents .fcp-field .fcp-colors .color-selector.selected, .floated-customizer-panel .fcp-content .fcp-group-contents .fcp-field .fcp-colors .color-selector:hover {
  box-shadow: 0px 0px 0px 2px #fff, 0px 0px 0px 4px #88b8ff;
}

.floated-customizer-panel .fcp-content .fcp-group-contents .fcp-field .fcp-colors .color-bright {
  background-color: #047bf8;
  border: 1px solid #047bf8;
}

.floated-customizer-panel .fcp-content .fcp-group-contents .fcp-field .fcp-colors .color-dark {
  background-color: #252a32;
  border: 1px solid #252a32;
}

.floated-customizer-panel .fcp-content .fcp-group-contents .fcp-field .fcp-colors .color-light {
  background-color: #fff;
  border: 1px solid #aaa;
}

.floated-customizer-panel .fcp-content .fcp-group-contents .fcp-field .fcp-colors .color-transparent {
  background-color: #fff;
  border: 1px solid #aaa;
  position: relative;
  overflow: hidden;
}

.floated-customizer-panel .fcp-content .fcp-group-contents .fcp-field .fcp-colors .color-transparent:before {
  content: "";
  width: 4px;
  height: 4px;
  position: absolute;
  top: 6px;
  left: 6px;
  background-color: #ccc;
}

.floated-customizer-panel .fcp-content .fcp-group-contents .fcp-field .fcp-colors .color-transparent:after {
  content: "";
  width: 4px;
  height: 4px;
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: #ccc;
}

/* ##. Dark Color Scheme */
.floated-colors-btn {
  z-index: 9999;
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: #252A32;
  border-radius: 75px;
  color: #fff;
  padding: 12px 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  vertical-align: middle;
  font-size: 1.08rem;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.floated-colors-btn.second-floated-btn {
  right: 200px;
}

.floated-colors-btn span {
  vertical-align: middle;
  display: inline-block;
  font-weight: 500;
}

.floated-colors-btn span + span {
  margin-left: 5px;
}

.floated-colors-btn:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  background-color: #0f1115;
}

.box-style-dark, .color-scheme-dark .ae-content-w .aec-full-message-w .aec-full-message, .color-scheme-dark .ae-content-w .aec-reply, body.color-scheme-dark .box-style, body.color-scheme-dark .user-profile, body.color-scheme-dark .element-box, body.color-scheme-dark .invoice-w, body.color-scheme-dark .big-error-w, body.color-scheme-dark .activity-boxes-w .activity-box, .activity-boxes-w body.color-scheme-dark .activity-box, body.color-scheme-dark .post-box, body.color-scheme-dark .projects-list .project-box, .projects-list body.color-scheme-dark .project-box, body.color-scheme-dark .order-box, body.color-scheme-dark .ecommerce-customer-info,
body.color-scheme-dark .profile-tile .profile-tile-box, .content-panel.color-scheme-dark .box-style, .content-panel.color-scheme-dark .user-profile, .content-panel.color-scheme-dark .element-box, .content-panel.color-scheme-dark .invoice-w, .content-panel.color-scheme-dark .big-error-w, .content-panel.color-scheme-dark .activity-boxes-w .activity-box, .activity-boxes-w .content-panel.color-scheme-dark .activity-box, .content-panel.color-scheme-dark .post-box, .content-panel.color-scheme-dark .projects-list .project-box, .projects-list .content-panel.color-scheme-dark .project-box, .content-panel.color-scheme-dark .order-box, .content-panel.color-scheme-dark .ecommerce-customer-info,
.content-panel.color-scheme-dark .profile-tile .profile-tile-box {
  background-color: #323c58;
  box-shadow: 0px 5px 15px rgba(22, 22, 35, 0.1);
  background-image: -webkit-gradient(linear, left top, left bottom, from(#323c58), to(#303950));
  background-image: linear-gradient(to bottom, #323c58 0%, #303950 100%);
  background-repeat: repeat-x;
}

body.color-scheme-dark, .content-panel.color-scheme-dark {
  background-color: #293145;
  color: #ccd9e8;
}

body.color-scheme-dark .post-box .post-text, .content-panel.color-scheme-dark .post-box .post-text {
  color: rgba(255, 255, 255, 0.49);
}

body.color-scheme-dark .content-panel-close, .content-panel.color-scheme-dark .content-panel-close {
  color: #fff;
}

body.color-scheme-dark .centered-load-more-link, .content-panel.color-scheme-dark .centered-load-more-link {
  color: #717BAA;
}

body.color-scheme-dark .centered-load-more-link span, .content-panel.color-scheme-dark .centered-load-more-link span {
  border-color: #717BAA;
}

body.color-scheme-dark .element-wrapper .element-actions .element-action, .content-panel.color-scheme-dark .element-wrapper .element-actions .element-action {
  color: #6C739C;
}

body.color-scheme-dark .element-wrapper .element-actions .element-action:hover, .content-panel.color-scheme-dark .element-wrapper .element-actions .element-action:hover {
  color: #fff;
}

body.color-scheme-dark .text-faded, .content-panel.color-scheme-dark .text-faded {
  color: #B7BAC6;
}

body.color-scheme-dark .text-bright, .content-panel.color-scheme-dark .text-bright {
  color: #fff;
}

body.color-scheme-dark .floated-colors-btn, .content-panel.color-scheme-dark .floated-colors-btn {
  background-color: #fff;
  box-shadow: 0px 5px 15px rgba(22, 22, 35, 0.1);
}

body.color-scheme-dark .floated-colors-btn span, .content-panel.color-scheme-dark .floated-colors-btn span {
  color: #3E4B5B;
}

body.color-scheme-dark:before, .content-panel.color-scheme-dark:before {
  background: -webkit-gradient(linear, left top, right bottom, from(#633d7d), to(#2867ab));
  background: linear-gradient(to bottom right, #633d7d, #2867ab);
}

body.color-scheme-dark .fancy-progress-with-label .fpl-progress-w, .content-panel.color-scheme-dark .fancy-progress-with-label .fpl-progress-w {
  background-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .todo-list .todo-item, .content-panel.color-scheme-dark .todo-list .todo-item {
  border-color: #A3A6B3;
}

body.color-scheme-dark .todo-list .todo-item .ti-header, .content-panel.color-scheme-dark .todo-list .todo-item .ti-header {
  color: #fff;
}

body.color-scheme-dark .todo-list .todo-item .ti-sub-header, .content-panel.color-scheme-dark .todo-list .todo-item .ti-sub-header {
  color: #979DA8;
}

body.color-scheme-dark .todo-list .todo-item .ti-icon, .content-panel.color-scheme-dark .todo-list .todo-item .ti-icon {
  color: #fff;
}

body.color-scheme-dark .todo-list .todo-item.complete, .content-panel.color-scheme-dark .todo-list .todo-item.complete {
  border-color: #166e0c;
}

body.color-scheme-dark .todo-list .todo-item.complete .ti-icon, .content-panel.color-scheme-dark .todo-list .todo-item.complete .ti-icon {
  color: #24b314;
}

body.color-scheme-dark .todo-list .todo-item.complete .ti-sub-header, .content-panel.color-scheme-dark .todo-list .todo-item.complete .ti-sub-header {
  color: #71AA91;
}

body.color-scheme-dark .todo-list .todo-item.complete .ti-header, .content-panel.color-scheme-dark .todo-list .todo-item.complete .ti-header {
  color: #F2F4F8;
}

body.color-scheme-dark h1, body.color-scheme-dark h2, body.color-scheme-dark h3, body.color-scheme-dark h4, body.color-scheme-dark h5, body.color-scheme-dark h6,
body.color-scheme-dark .h1, body.color-scheme-dark .h2, body.color-scheme-dark .h3, body.color-scheme-dark .h4, body.color-scheme-dark .h5, body.color-scheme-dark .h6, .content-panel.color-scheme-dark h1, .content-panel.color-scheme-dark h2, .content-panel.color-scheme-dark h3, .content-panel.color-scheme-dark h4, .content-panel.color-scheme-dark h5, .content-panel.color-scheme-dark h6,
.content-panel.color-scheme-dark .h1, .content-panel.color-scheme-dark .h2, .content-panel.color-scheme-dark .h3, .content-panel.color-scheme-dark .h4, .content-panel.color-scheme-dark .h5, .content-panel.color-scheme-dark .h6 {
  color: #fff;
}

body.color-scheme-dark .text-muted, body.color-scheme-dark .invoice-body .invoice-desc .desc-value, .invoice-body .invoice-desc body.color-scheme-dark .desc-value, body.color-scheme-dark .invoice-body .invoice-desc .desc-value, .content-panel.color-scheme-dark .text-muted, .content-panel.color-scheme-dark .invoice-body .invoice-desc .desc-value, .invoice-body .invoice-desc .content-panel.color-scheme-dark .desc-value, .content-panel.color-scheme-dark .invoice-body .invoice-desc .desc-value {
  color: rgba(198, 209, 230, 0.4) !important;
}

body.color-scheme-dark .content-w, .content-panel.color-scheme-dark .content-w {
  background-color: #293145;
  background-image: none;
}

body.color-scheme-dark .all-wrapper.solid-bg-all, .content-panel.color-scheme-dark .all-wrapper.solid-bg-all {
  background-color: #293145;
}

body.color-scheme-dark code, .content-panel.color-scheme-dark code {
  color: #7e9fea;
  background-color: #1c2738;
}

body.color-scheme-dark .demo-icons-list li, .content-panel.color-scheme-dark .demo-icons-list li {
  border-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .demo-icons-list li a, .content-panel.color-scheme-dark .demo-icons-list li a {
  color: #b1b8ca;
}

body.color-scheme-dark .content-panel, .content-panel.color-scheme-dark .content-panel {
  border-left-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .breadcrumb, .content-panel.color-scheme-dark .breadcrumb {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .breadcrumb li a, .content-panel.color-scheme-dark .breadcrumb li a {
  color: #b3bcde;
}

body.color-scheme-dark .breadcrumb li span, .content-panel.color-scheme-dark .breadcrumb li span {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .alert, .content-panel.color-scheme-dark .alert {
  border-color: transparent;
}

body.color-scheme-dark .alert h1, body.color-scheme-dark .alert h2, body.color-scheme-dark .alert h3, body.color-scheme-dark .alert h4, body.color-scheme-dark .alert h5, .content-panel.color-scheme-dark .alert h1, .content-panel.color-scheme-dark .alert h2, .content-panel.color-scheme-dark .alert h3, .content-panel.color-scheme-dark .alert h4, .content-panel.color-scheme-dark .alert h5 {
  color: #3E4B5B;
}

body.color-scheme-dark .b-r,
body.color-scheme-dark .b-b,
body.color-scheme-dark .b-t,
body.color-scheme-dark .b-l, .content-panel.color-scheme-dark .b-r,
.content-panel.color-scheme-dark .b-b,
.content-panel.color-scheme-dark .b-t,
.content-panel.color-scheme-dark .b-l {
  border-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .progress, .content-panel.color-scheme-dark .progress {
  background-color: #252c3f;
}

body.color-scheme-dark .popover, .content-panel.color-scheme-dark .popover {
  box-shadow: 0px 5px 15px rgba(22, 22, 35, 0.1);
  color: #3E4B5B;
}

body.color-scheme-dark .example-column, .content-panel.color-scheme-dark .example-column {
  background-color: #293145;
  border-color: #090d19;
}

body.color-scheme-dark .top-bar, .content-panel.color-scheme-dark .top-bar {
  box-shadow: 0px 5px 15px rgba(22, 22, 35, 0.1);
}

body.color-scheme-dark .menu-position-top.menu-w.color-style-transparent, .content-panel.color-scheme-dark .menu-position-top.menu-w.color-style-transparent {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .os-dropdown,
body.color-scheme-dark .top-bar .logged-user-w .logged-user-menu, .content-panel.color-scheme-dark .os-dropdown,
.content-panel.color-scheme-dark .top-bar .logged-user-w .logged-user-menu {
  box-shadow: 0px 5px 15px rgba(22, 22, 35, 0.1);
}

body.color-scheme-dark .menu-mobile, .content-panel.color-scheme-dark .menu-mobile {
  box-shadow: 0px 5px 15px rgba(22, 22, 35, 0.1);
}

body.color-scheme-dark .top-bar.color-scheme-transparent, .content-panel.color-scheme-dark .top-bar.color-scheme-transparent {
  box-shadow: none;
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .top-bar.color-scheme-transparent .logo-w .logo-label, .content-panel.color-scheme-dark .top-bar.color-scheme-transparent .logo-w .logo-label {
  color: #fff;
}

body.color-scheme-dark .top-bar.color-scheme-transparent .top-menu-controls .element-search:before, .content-panel.color-scheme-dark .top-bar.color-scheme-transparent .top-menu-controls .element-search:before {
  color: rgba(255, 255, 255, 0.4);
}

body.color-scheme-dark .top-bar.color-scheme-transparent .top-menu-controls .element-search input, .content-panel.color-scheme-dark .top-bar.color-scheme-transparent .top-menu-controls .element-search input {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

body.color-scheme-dark .top-bar.color-scheme-transparent .top-menu-controls .element-search input::-webkit-input-placeholder, .content-panel.color-scheme-dark .top-bar.color-scheme-transparent .top-menu-controls .element-search input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

body.color-scheme-dark .top-bar.color-scheme-transparent .top-menu-controls .element-search input::-moz-placeholder, .content-panel.color-scheme-dark .top-bar.color-scheme-transparent .top-menu-controls .element-search input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

body.color-scheme-dark .top-bar.color-scheme-transparent .top-menu-controls .element-search input:-ms-input-placeholder, .content-panel.color-scheme-dark .top-bar.color-scheme-transparent .top-menu-controls .element-search input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

body.color-scheme-dark .top-bar.color-scheme-transparent .top-menu-controls .element-search input::-ms-input-placeholder, .content-panel.color-scheme-dark .top-bar.color-scheme-transparent .top-menu-controls .element-search input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}

body.color-scheme-dark .top-bar.color-scheme-transparent .top-menu-controls .element-search input::placeholder, .content-panel.color-scheme-dark .top-bar.color-scheme-transparent .top-menu-controls .element-search input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

body.color-scheme-dark .modal-content, .content-panel.color-scheme-dark .modal-content {
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.3);
  background-color: #323c58;
}

body.color-scheme-dark .onboarding-modal button.close, .content-panel.color-scheme-dark .onboarding-modal button.close {
  color: #ccd9e8;
}

body.color-scheme-dark .onboarding-modal .onboarding-content .onboarding-title, .content-panel.color-scheme-dark .onboarding-modal .onboarding-content .onboarding-title {
  color: #fff;
}

body.color-scheme-dark .onboarding-modal .onboarding-content .onboarding-text, .content-panel.color-scheme-dark .onboarding-modal .onboarding-content .onboarding-text {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .onboarding-modal .slick-dots li button, .content-panel.color-scheme-dark .onboarding-modal .slick-dots li button {
  background-color: #fff;
}

body.color-scheme-dark .onboarding-modal .slick-next:before, body.color-scheme-dark .onboarding-modal .slick-prev:before,
body.color-scheme-dark .onboarding-modal .slick-next, body.color-scheme-dark .onboarding-modal .slick-prev, .content-panel.color-scheme-dark .onboarding-modal .slick-next:before, .content-panel.color-scheme-dark .onboarding-modal .slick-prev:before,
.content-panel.color-scheme-dark .onboarding-modal .slick-next, .content-panel.color-scheme-dark .onboarding-modal .slick-prev {
  color: #ccd9e8;
}

body.color-scheme-dark .invoice-w:before, .content-panel.color-scheme-dark .invoice-w:before {
  background-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .invoice-w .infos .info-2 .company-address, .content-panel.color-scheme-dark .invoice-w .infos .info-2 .company-address {
  color: #ccd9e8;
}

body.color-scheme-dark .invoice-w .terms .terms-content, .content-panel.color-scheme-dark .invoice-w .terms .terms-content {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .invoice-w .infos .info-1 .company-extra, .content-panel.color-scheme-dark .invoice-w .infos .info-1 .company-extra {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .invoice-footer, .content-panel.color-scheme-dark .invoice-footer {
  border-top-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .invoice-footer .invoice-info span + span, .content-panel.color-scheme-dark .invoice-footer .invoice-info span + span {
  border-left-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .timed-activities.compact .timed-activity:before, .content-panel.color-scheme-dark .timed-activities.compact .timed-activity:before {
  background-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .timed-activity .ta-date, .content-panel.color-scheme-dark .timed-activity .ta-date {
  color: #ccd9e8;
}

body.color-scheme-dark .timed-activity .ta-date span:after, .content-panel.color-scheme-dark .timed-activity .ta-date span:after {
  background-color: #293145;
}

body.color-scheme-dark .timed-activity .ta-date:before, .content-panel.color-scheme-dark .timed-activity .ta-date:before {
  background-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .timed-activity .ta-timestamp:after, .content-panel.color-scheme-dark .timed-activity .ta-timestamp:after {
  color: #ccd9e8;
}

body.color-scheme-dark .timed-activity .ta-timestamp, .content-panel.color-scheme-dark .timed-activity .ta-timestamp {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .timed-activity .ta-timestamp strong, .content-panel.color-scheme-dark .timed-activity .ta-timestamp strong {
  color: #ccd9e8;
}

body.color-scheme-dark .profile-tile, .content-panel.color-scheme-dark .profile-tile {
  color: #ccd9e8;
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .profile-tile .profile-tile-box, .content-panel.color-scheme-dark .profile-tile .profile-tile-box {
  color: #fff;
}

body.color-scheme-dark .profile-tile .pt-user-name, .content-panel.color-scheme-dark .profile-tile .pt-user-name {
  border-top-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .profile-tile .profile-tile-meta ul, .content-panel.color-scheme-dark .profile-tile .profile-tile-meta ul {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .profile-tile .profile-tile-meta ul strong, .content-panel.color-scheme-dark .profile-tile .profile-tile-meta ul strong {
  color: #ccd9e8;
}

body.color-scheme-dark .user-profile .up-head-w .decor .decor-path, .content-panel.color-scheme-dark .user-profile .up-head-w .decor .decor-path {
  fill: #333c55;
}

body.color-scheme-dark .value-pair .label, .content-panel.color-scheme-dark .value-pair .label {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .box-style legend span, body.color-scheme-dark .user-profile legend span, body.color-scheme-dark .element-box legend span, body.color-scheme-dark .invoice-w legend span, body.color-scheme-dark .big-error-w legend span, body.color-scheme-dark .activity-boxes-w .activity-box legend span, .activity-boxes-w body.color-scheme-dark .activity-box legend span, body.color-scheme-dark .post-box legend span, body.color-scheme-dark .projects-list .project-box legend span, .projects-list body.color-scheme-dark .project-box legend span, body.color-scheme-dark .order-box legend span, body.color-scheme-dark .ecommerce-customer-info legend span,
body.color-scheme-dark .user-profile legend span,
body.color-scheme-dark .element-box legend span,
body.color-scheme-dark .invoice-w legend span,
body.color-scheme-dark .big-error-w legend span,
body.color-scheme-dark .invoice-w legend span,
body.color-scheme-dark .big-error-w legend span,
body.color-scheme-dark .activity-boxes-w .activity-box legend span,
body.color-scheme-dark .projects-list .project-box legend span,
body.color-scheme-dark .profile-tile .profile-tile-box legend span, .content-panel.color-scheme-dark .box-style legend span, .content-panel.color-scheme-dark .user-profile legend span, .content-panel.color-scheme-dark .element-box legend span, .content-panel.color-scheme-dark .invoice-w legend span, .content-panel.color-scheme-dark .big-error-w legend span, .content-panel.color-scheme-dark .activity-boxes-w .activity-box legend span, .activity-boxes-w .content-panel.color-scheme-dark .activity-box legend span, .content-panel.color-scheme-dark .post-box legend span, .content-panel.color-scheme-dark .projects-list .project-box legend span, .projects-list .content-panel.color-scheme-dark .project-box legend span, .content-panel.color-scheme-dark .order-box legend span, .content-panel.color-scheme-dark .ecommerce-customer-info legend span,
.content-panel.color-scheme-dark .user-profile legend span,
.content-panel.color-scheme-dark .element-box legend span,
.content-panel.color-scheme-dark .invoice-w legend span,
.content-panel.color-scheme-dark .big-error-w legend span,
.content-panel.color-scheme-dark .invoice-w legend span,
.content-panel.color-scheme-dark .big-error-w legend span,
.content-panel.color-scheme-dark .activity-boxes-w .activity-box legend span,
.content-panel.color-scheme-dark .projects-list .project-box legend span,
.content-panel.color-scheme-dark .profile-tile .profile-tile-box legend span {
  background-color: #303950;
  color: #fff;
}

body.color-scheme-dark .box-style legend:before, body.color-scheme-dark .user-profile legend:before, body.color-scheme-dark .element-box legend:before, body.color-scheme-dark .invoice-w legend:before, body.color-scheme-dark .big-error-w legend:before, body.color-scheme-dark .activity-boxes-w .activity-box legend:before, .activity-boxes-w body.color-scheme-dark .activity-box legend:before, body.color-scheme-dark .post-box legend:before, body.color-scheme-dark .projects-list .project-box legend:before, .projects-list body.color-scheme-dark .project-box legend:before, body.color-scheme-dark .order-box legend:before, body.color-scheme-dark .ecommerce-customer-info legend:before,
body.color-scheme-dark .user-profile legend:before,
body.color-scheme-dark .element-box legend:before,
body.color-scheme-dark .invoice-w legend:before,
body.color-scheme-dark .big-error-w legend:before,
body.color-scheme-dark .invoice-w legend:before,
body.color-scheme-dark .big-error-w legend:before,
body.color-scheme-dark .activity-boxes-w .activity-box legend:before,
body.color-scheme-dark .projects-list .project-box legend:before,
body.color-scheme-dark .profile-tile .profile-tile-box legend:before, .content-panel.color-scheme-dark .box-style legend:before, .content-panel.color-scheme-dark .user-profile legend:before, .content-panel.color-scheme-dark .element-box legend:before, .content-panel.color-scheme-dark .invoice-w legend:before, .content-panel.color-scheme-dark .big-error-w legend:before, .content-panel.color-scheme-dark .activity-boxes-w .activity-box legend:before, .activity-boxes-w .content-panel.color-scheme-dark .activity-box legend:before, .content-panel.color-scheme-dark .post-box legend:before, .content-panel.color-scheme-dark .projects-list .project-box legend:before, .projects-list .content-panel.color-scheme-dark .project-box legend:before, .content-panel.color-scheme-dark .order-box legend:before, .content-panel.color-scheme-dark .ecommerce-customer-info legend:before,
.content-panel.color-scheme-dark .user-profile legend:before,
.content-panel.color-scheme-dark .element-box legend:before,
.content-panel.color-scheme-dark .invoice-w legend:before,
.content-panel.color-scheme-dark .big-error-w legend:before,
.content-panel.color-scheme-dark .invoice-w legend:before,
.content-panel.color-scheme-dark .big-error-w legend:before,
.content-panel.color-scheme-dark .activity-boxes-w .activity-box legend:before,
.content-panel.color-scheme-dark .projects-list .project-box legend:before,
.content-panel.color-scheme-dark .profile-tile .profile-tile-box legend:before {
  background-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .element-wrapper .element-inner-desc, .content-panel.color-scheme-dark .element-wrapper .element-inner-desc {
  color: #ccd9e8;
}

body.color-scheme-dark .element-box .element-box-header, body.color-scheme-dark .invoice-w .element-box-header, body.color-scheme-dark .big-error-w .element-box-header, body.color-scheme-dark .invoice-w .element-box-header, body.color-scheme-dark .big-error-w .element-box-header, .content-panel.color-scheme-dark .element-box .element-box-header, .content-panel.color-scheme-dark .invoice-w .element-box-header, .content-panel.color-scheme-dark .big-error-w .element-box-header, .content-panel.color-scheme-dark .invoice-w .element-box-header, .content-panel.color-scheme-dark .big-error-w .element-box-header {
  color: #6a759c;
}

body.color-scheme-dark .element-wrapper .element-header, .content-panel.color-scheme-dark .element-wrapper .element-header {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .users-list-w .user-w .user-role, .content-panel.color-scheme-dark .users-list-w .user-w .user-role {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .users-list-w .user-w.with-status .user-avatar-w:before, .content-panel.color-scheme-dark .users-list-w .user-w.with-status .user-avatar-w:before {
  box-shadow: 0px 0px 3px #293145;
}

body.color-scheme-dark .projects-list .project-head .project-users .avatar, .content-panel.color-scheme-dark .projects-list .project-head .project-users .avatar {
  box-shadow: 0px 0px 0px 5px #323c58;
}

body.color-scheme-dark .element-balances .balance-title, .content-panel.color-scheme-dark .element-balances .balance-title {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .element-balances .balance-value, .content-panel.color-scheme-dark .element-balances .balance-value {
  color: #fff;
}

body.color-scheme-dark .activity-boxes-w .activity-role, .content-panel.color-scheme-dark .activity-boxes-w .activity-role {
  color: #fff;
}

body.color-scheme-dark .activity-boxes-w .activity-title, .content-panel.color-scheme-dark .activity-boxes-w .activity-title {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .activity-boxes-w .activity-time, .content-panel.color-scheme-dark .activity-boxes-w .activity-time {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .activity-boxes-w .activity-box:before, .content-panel.color-scheme-dark .activity-boxes-w .activity-box:before {
  background-color: #fff;
  border-color: #293145;
}

body.color-scheme-dark .activity-boxes-w .activity-box-w:before, .content-panel.color-scheme-dark .activity-boxes-w .activity-box-w:before {
  background-color: #1f62e0;
}

body.color-scheme-dark .activity-boxes-w .activity-box-w:after, .content-panel.color-scheme-dark .activity-boxes-w .activity-box-w:after {
  background-color: #38435f;
}

body.color-scheme-dark a.el-tablo, .content-panel.color-scheme-dark a.el-tablo {
  color: #fff;
}

body.color-scheme-dark a.el-tablo:hover, .content-panel.color-scheme-dark a.el-tablo:hover {
  box-shadow: 0px 15px 25px rgba(22, 22, 35, 0.25);
}

body.color-scheme-dark .el-tablo .label, .content-panel.color-scheme-dark .el-tablo .label {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .el-tablo .value, .content-panel.color-scheme-dark .el-tablo .value {
  color: #fff;
}

body.color-scheme-dark .floated-colors-btn,
body.color-scheme-dark .floated-chat-btn, .content-panel.color-scheme-dark .floated-colors-btn,
.content-panel.color-scheme-dark .floated-chat-btn {
  box-shadow: 0 2px 20px 0 rgba(12, 16, 23, 0.26);
}

body.color-scheme-dark .fc-other-month, .content-panel.color-scheme-dark .fc-other-month {
  background-color: #293148;
}

body.color-scheme-dark .fc-event, .content-panel.color-scheme-dark .fc-event {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
}

body.color-scheme-dark .fc-unthemed td.fc-today, .content-panel.color-scheme-dark .fc-unthemed td.fc-today {
  background: #6b385f;
}

body.color-scheme-dark .fc-day-number, .content-panel.color-scheme-dark .fc-day-number {
  color: #ccd9e8;
}

body.color-scheme-dark .fc-day-header, .content-panel.color-scheme-dark .fc-day-header {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .fc-unthemed .fc-content, body.color-scheme-dark .fc-unthemed .fc-divider, body.color-scheme-dark .fc-unthemed .fc-list-heading td, body.color-scheme-dark .fc-unthemed .fc-list-view, body.color-scheme-dark .fc-unthemed .fc-popover, body.color-scheme-dark .fc-unthemed .fc-row, body.color-scheme-dark .fc-unthemed tbody, body.color-scheme-dark .fc-unthemed td, body.color-scheme-dark .fc-unthemed th, body.color-scheme-dark .fc-unthemed thead, .content-panel.color-scheme-dark .fc-unthemed .fc-content, .content-panel.color-scheme-dark .fc-unthemed .fc-divider, .content-panel.color-scheme-dark .fc-unthemed .fc-list-heading td, .content-panel.color-scheme-dark .fc-unthemed .fc-list-view, .content-panel.color-scheme-dark .fc-unthemed .fc-popover, .content-panel.color-scheme-dark .fc-unthemed .fc-row, .content-panel.color-scheme-dark .fc-unthemed tbody, .content-panel.color-scheme-dark .fc-unthemed td, .content-panel.color-scheme-dark .fc-unthemed th, .content-panel.color-scheme-dark .fc-unthemed thead {
  border-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .os-progress-bar .bar-label-right span.info, .content-panel.color-scheme-dark .os-progress-bar .bar-label-right span.info {
  color: #7281b7;
}

body.color-scheme-dark .os-progress-bar.blue .bar-level-1,
body.color-scheme-dark .os-progress-bar.primary .bar-level-1,
body.color-scheme-dark .os-progress-bar.warning .bar-level-1,
body.color-scheme-dark .os-progress-bar.info .bar-level-1,
body.color-scheme-dark .os-progress-bar.danger .bar-level-1,
body.color-scheme-dark .os-progress-bar.success .bar-level-1, .content-panel.color-scheme-dark .os-progress-bar.blue .bar-level-1,
.content-panel.color-scheme-dark .os-progress-bar.primary .bar-level-1,
.content-panel.color-scheme-dark .os-progress-bar.warning .bar-level-1,
.content-panel.color-scheme-dark .os-progress-bar.info .bar-level-1,
.content-panel.color-scheme-dark .os-progress-bar.danger .bar-level-1,
.content-panel.color-scheme-dark .os-progress-bar.success .bar-level-1 {
  background-color: #293148;
}

body.color-scheme-dark .os-progress-bar.blue .bar-level-2, body.color-scheme-dark .os-progress-bar.primary .bar-level-2, .content-panel.color-scheme-dark .os-progress-bar.blue .bar-level-2, .content-panel.color-scheme-dark .os-progress-bar.primary .bar-level-2 {
  background-color: #3870ef;
}

body.color-scheme-dark .os-progress-bar.blue .bar-level-3, body.color-scheme-dark .os-progress-bar.primary .bar-level-3, .content-panel.color-scheme-dark .os-progress-bar.blue .bar-level-3, .content-panel.color-scheme-dark .os-progress-bar.primary .bar-level-3 {
  background-color: #c5d3ff;
}

body.color-scheme-dark .el-chart-w .inside-donut-chart-label strong, .content-panel.color-scheme-dark .el-chart-w .inside-donut-chart-label strong {
  color: #fff;
}

body.color-scheme-dark .el-chart-w .inside-donut-chart-label span, .content-panel.color-scheme-dark .el-chart-w .inside-donut-chart-label span {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .el-chart-w + .el-legend, .content-panel.color-scheme-dark .el-chart-w + .el-legend {
  border-top-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .big-error-w form, .content-panel.color-scheme-dark .big-error-w form {
  border-top-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .form-control, .content-panel.color-scheme-dark .form-control {
  background-color: rgba(18, 22, 41, 0.22);
  border-color: rgba(18, 22, 41, 0.34);
  color: #ccd9e8;
}

body.color-scheme-dark .form-control::-webkit-input-placeholder, .content-panel.color-scheme-dark .form-control::-webkit-input-placeholder {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .form-control::-moz-placeholder, .content-panel.color-scheme-dark .form-control::-moz-placeholder {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .form-control:-ms-input-placeholder, .content-panel.color-scheme-dark .form-control:-ms-input-placeholder {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .form-control::-ms-input-placeholder, .content-panel.color-scheme-dark .form-control::-ms-input-placeholder {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .form-control::placeholder, .content-panel.color-scheme-dark .form-control::placeholder {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .form-desc, .content-panel.color-scheme-dark .form-desc {
  color: #ccd9e8;
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .input-group-prepend,
body.color-scheme-dark .input-group-text, .content-panel.color-scheme-dark .input-group-prepend,
.content-panel.color-scheme-dark .input-group-text {
  background-color: rgba(18, 22, 41, 0.22);
  border-color: rgba(18, 22, 41, 0.14);
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .select2-container--default .select2-selection--multiple, .content-panel.color-scheme-dark .select2-container--default .select2-selection--multiple {
  background-color: rgba(18, 22, 41, 0.22);
  border-color: rgba(18, 22, 41, 0.11);
}

body.color-scheme-dark .select2-container--default .select2-selection--multiple .select2-selection__choice, .content-panel.color-scheme-dark .select2-container--default .select2-selection--multiple .select2-selection__choice {
  color: #fff;
  background-color: #422af5;
  border-color: #422af5;
}

body.color-scheme-dark .select2-container--default .select2-selection--multiple .select2-selection__choice__remove, .content-panel.color-scheme-dark .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #fff;
}

body.color-scheme-dark .form-buttons-w, .content-panel.color-scheme-dark .form-buttons-w {
  border-top-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .step-triggers, .content-panel.color-scheme-dark .step-triggers {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .step-triggers .step-trigger:hover, .content-panel.color-scheme-dark .step-triggers .step-trigger:hover {
  color: #fff;
}

body.color-scheme-dark .step-triggers .step-trigger.active, .content-panel.color-scheme-dark .step-triggers .step-trigger.active {
  color: #fff;
}

body.color-scheme-dark .step-triggers .step-trigger, .content-panel.color-scheme-dark .step-triggers .step-trigger {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .dropzone, .content-panel.color-scheme-dark .dropzone {
  background-color: #252c3f;
  border-color: #030e19;
}

body.color-scheme-dark .cke .cke_top, .content-panel.color-scheme-dark .cke .cke_top {
  background-color: #323c58;
  border-bottom-color: #202637;
}

body.color-scheme-dark .cke .cke_bottom, .content-panel.color-scheme-dark .cke .cke_bottom {
  background-color: #323c58;
}

body.color-scheme-dark .cke iframe .cke_editable, .content-panel.color-scheme-dark .cke iframe .cke_editable {
  background-color: #293148;
}

body.color-scheme-dark .cke .cke_button_icon, .content-panel.color-scheme-dark .cke .cke_button_icon {
  -webkit-filter: invert(1);
          filter: invert(1);
}

body.color-scheme-dark .cke .cke_toolbar_separator, body.color-scheme-dark .cke .cke_toolgroup a.cke_button:last-child::after, body.color-scheme-dark .cke .cke_toolgroup a.cke_button.cke_button_disabled:hover:last-child::after, .content-panel.color-scheme-dark .cke .cke_toolbar_separator, .content-panel.color-scheme-dark .cke .cke_toolgroup a.cke_button:last-child::after, .content-panel.color-scheme-dark .cke .cke_toolgroup a.cke_button.cke_button_disabled:hover:last-child::after {
  border-color: rgba(255, 255, 255, 0.05);
  background-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .floated-chat-w .floated-chat-i, .content-panel.color-scheme-dark .floated-chat-w .floated-chat-i {
  box-shadow: 0px 5px 15px rgba(22, 22, 35, 0.1);
}

body.color-scheme-dark .full-chat-w .full-chat-i, .content-panel.color-scheme-dark .full-chat-w .full-chat-i {
  background-color: #293145;
}

body.color-scheme-dark .full-chat-w .chat-info-section .ci-header span, .content-panel.color-scheme-dark .full-chat-w .chat-info-section .ci-header span {
  color: #ccd9e8;
}

body.color-scheme-dark .full-chat-w .user-intro, .content-panel.color-scheme-dark .full-chat-w .user-intro {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .full-chat-w .user-intro .user-intro-info .user-sub, .content-panel.color-scheme-dark .full-chat-w .user-intro .user-intro-info .user-sub {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .full-chat-w .chat-info-section + .chat-info-section, .content-panel.color-scheme-dark .full-chat-w .chat-info-section + .chat-info-section {
  border-top-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .full-chat-w .full-chat-middle, .content-panel.color-scheme-dark .full-chat-w .full-chat-middle {
  background-color: #333c55;
}

body.color-scheme-dark .full-chat-w .full-chat-middle .chat-content-w .chat-message .chat-message-avatar img, .content-panel.color-scheme-dark .full-chat-w .full-chat-middle .chat-content-w .chat-message .chat-message-avatar img {
  box-shadow: #333c55 0px 0px 0px 10px;
}

body.color-scheme-dark .full-chat-w .full-chat-middle .chat-content-w .chat-date-separator:before, .content-panel.color-scheme-dark .full-chat-w .full-chat-middle .chat-content-w .chat-date-separator:before {
  background-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .full-chat-w .full-chat-middle .chat-content-w .chat-date-separator span, .content-panel.color-scheme-dark .full-chat-w .full-chat-middle .chat-content-w .chat-date-separator span {
  background-color: #333c55;
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .full-chat-w .full-chat-middle .chat-content-w .chat-message .chat-message-date, .content-panel.color-scheme-dark .full-chat-w .full-chat-middle .chat-content-w .chat-message .chat-message-date {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .full-chat-w .chat-content-w .chat-message .chat-message-content, .content-panel.color-scheme-dark .full-chat-w .chat-content-w .chat-message .chat-message-content {
  background-color: #3c4865;
  color: #ccd9e8;
}

body.color-scheme-dark .full-chat-w .chat-content-w .chat-message.self .chat-message-content, .content-panel.color-scheme-dark .full-chat-w .chat-content-w .chat-message.self .chat-message-content {
  background-color: #293145;
}

body.color-scheme-dark .full-chat-w .chat-controls, .content-panel.color-scheme-dark .full-chat-w .chat-controls {
  border-top-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .full-chat-w .chat-input input[type="text"]::-webkit-input-placeholder, .content-panel.color-scheme-dark .full-chat-w .chat-input input[type="text"]::-webkit-input-placeholder {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .full-chat-w .chat-input input[type="text"]::-moz-placeholder, .content-panel.color-scheme-dark .full-chat-w .chat-input input[type="text"]::-moz-placeholder {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .full-chat-w .chat-input input[type="text"]:-ms-input-placeholder, .content-panel.color-scheme-dark .full-chat-w .chat-input input[type="text"]:-ms-input-placeholder {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .full-chat-w .chat-input input[type="text"]::-ms-input-placeholder, .content-panel.color-scheme-dark .full-chat-w .chat-input input[type="text"]::-ms-input-placeholder {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .full-chat-w .chat-input input[type="text"]::placeholder, .content-panel.color-scheme-dark .full-chat-w .chat-input input[type="text"]::placeholder {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .full-chat-w .chat-input input[type="text"], .content-panel.color-scheme-dark .full-chat-w .chat-input input[type="text"] {
  background-color: #333c55;
  color: #ccd9e8;
}

body.color-scheme-dark .full-chat-w .full-chat-right, .content-panel.color-scheme-dark .full-chat-w .full-chat-right {
  background-color: #252c3f;
}

body.color-scheme-dark .full-chat-w .full-chat-left, .content-panel.color-scheme-dark .full-chat-w .full-chat-left {
  background-color: #293145;
}

body.color-scheme-dark .full-chat-w .full-chat-left .nav-link i, .content-panel.color-scheme-dark .full-chat-w .full-chat-left .nav-link i {
  color: #59647b;
}

body.color-scheme-dark .full-chat-w .full-chat-left .nav-link.active i, .content-panel.color-scheme-dark .full-chat-w .full-chat-left .nav-link.active i {
  color: #047bf8;
}

body.color-scheme-dark .full-chat-w .full-chat-left .element-search input, .content-panel.color-scheme-dark .full-chat-w .full-chat-left .element-search input {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: #161a25;
}

body.color-scheme-dark .full-chat-w .full-chat-left .element-search input::-webkit-input-placeholder, .content-panel.color-scheme-dark .full-chat-w .full-chat-left .element-search input::-webkit-input-placeholder {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .full-chat-w .full-chat-left .element-search input::-moz-placeholder, .content-panel.color-scheme-dark .full-chat-w .full-chat-left .element-search input::-moz-placeholder {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .full-chat-w .full-chat-left .element-search input:-ms-input-placeholder, .content-panel.color-scheme-dark .full-chat-w .full-chat-left .element-search input:-ms-input-placeholder {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .full-chat-w .full-chat-left .element-search input::-ms-input-placeholder, .content-panel.color-scheme-dark .full-chat-w .full-chat-left .element-search input::-ms-input-placeholder {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .full-chat-w .full-chat-left .element-search:before, body.color-scheme-dark .full-chat-w .full-chat-left .element-search input::placeholder, .content-panel.color-scheme-dark .full-chat-w .full-chat-left .element-search:before, .content-panel.color-scheme-dark .full-chat-w .full-chat-left .element-search input::placeholder {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .full-chat-w .full-chat-left .user-list .user-w .last-message, .content-panel.color-scheme-dark .full-chat-w .full-chat-left .user-list .user-w .last-message {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .full-chat-w .full-chat-left .user-list .user-w .user-date, .content-panel.color-scheme-dark .full-chat-w .full-chat-left .user-list .user-w .user-date {
  background-color: #1f2635;
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .full-chat-w .full-chat-left .user-list .user-w, .content-panel.color-scheme-dark .full-chat-w .full-chat-left .user-list .user-w {
  border-bottom-color: rgba(0, 0, 0, 0.2);
}

body.color-scheme-dark .full-chat-w .full-chat-left .user-list .user-w:hover .user-date, .content-panel.color-scheme-dark .full-chat-w .full-chat-left .user-list .user-w:hover .user-date {
  background-color: #0362c6;
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .table-bordered th, body.color-scheme-dark .table-bordered td, .content-panel.color-scheme-dark .table-bordered th, .content-panel.color-scheme-dark .table-bordered td {
  border-color: rgba(159, 172, 199, 0.12);
}

body.color-scheme-dark .table th, body.color-scheme-dark .table td, .content-panel.color-scheme-dark .table th, .content-panel.color-scheme-dark .table td {
  border-color: rgba(159, 172, 199, 0.12);
}

body.color-scheme-dark .table.table-v2 thead tr th, body.color-scheme-dark .table.table-v2 tfoot tr th, .content-panel.color-scheme-dark .table.table-v2 thead tr th, .content-panel.color-scheme-dark .table.table-v2 tfoot tr th {
  border-color: rgba(159, 172, 199, 0.12);
}

body.color-scheme-dark .table.table-v2 tbody tr td, .content-panel.color-scheme-dark .table.table-v2 tbody tr td {
  border-color: rgba(159, 172, 199, 0.12);
}

body.color-scheme-dark .table .cell-image-list .cell-img-more, .content-panel.color-scheme-dark .table .cell-image-list .cell-img-more {
  background-color: #2b334b;
}

body.color-scheme-dark .table thead th, .content-panel.color-scheme-dark .table thead th {
  border-bottom-color: rgba(255, 255, 255, 0.05);
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .table .cell-image-list .cell-img, .content-panel.color-scheme-dark .table .cell-image-list .cell-img {
  box-shadow: 3px 2px 10px rgba(25, 38, 66, 0.8);
}

body.color-scheme-dark .table .row-actions a, .content-panel.color-scheme-dark .table .row-actions a {
  color: #bbc9da;
}

body.color-scheme-dark .controls-below-table .table-records-info,
body.color-scheme-dark .controls-below-table .table-records-pages ul li a.current, .content-panel.color-scheme-dark .controls-below-table .table-records-info,
.content-panel.color-scheme-dark .controls-below-table .table-records-pages ul li a.current {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .table.table-padded tbody td, .content-panel.color-scheme-dark .table.table-padded tbody td {
  background-color: #303950;
}

body.color-scheme-dark .table.table-padded tbody tr:hover, .content-panel.color-scheme-dark .table.table-padded tbody tr:hover {
  box-shadow: 0px 5px 15px rgba(22, 22, 35, 0.1);
}

body.color-scheme-dark .table .lighter, .content-panel.color-scheme-dark .table .lighter {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .table-striped tbody tr:nth-of-type(odd), .content-panel.color-scheme-dark .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(16, 27, 33, 0.05);
}

body.color-scheme-dark .dataTables_wrapper .row:first-child, .content-panel.color-scheme-dark .dataTables_wrapper .row:first-child {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .dataTables_wrapper .row:last-child, .content-panel.color-scheme-dark .dataTables_wrapper .row:last-child {
  border-top-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark div.dataTables_wrapper div.dataTables_info, .content-panel.color-scheme-dark div.dataTables_wrapper div.dataTables_info {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .table.table-editable td:hover, .content-panel.color-scheme-dark .table.table-editable td:hover {
  background-color: #293145;
}

body.color-scheme-dark .table.table-editable + input, .content-panel.color-scheme-dark .table.table-editable + input {
  background-color: #1f2635;
  color: #fff;
}

body.color-scheme-dark .nav-pills .nav-link, .content-panel.color-scheme-dark .nav-pills .nav-link {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .nav-pills .nav-link.active, body.color-scheme-dark .nav-pills .nav-item.show .nav-link, .content-panel.color-scheme-dark .nav-pills .nav-link.active, .content-panel.color-scheme-dark .nav-pills .nav-item.show .nav-link {
  color: #fff;
}

body.color-scheme-dark .nav-tabs, body.color-scheme-dark .nav-pills, .content-panel.color-scheme-dark .nav-tabs, .content-panel.color-scheme-dark .nav-pills {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

body.color-scheme-dark .nav-tabs .nav-link, .content-panel.color-scheme-dark .nav-tabs .nav-link {
  color: rgba(218, 226, 243, 0.4);
}

body.color-scheme-dark .nav-tabs .nav-link.active, body.color-scheme-dark .nav-tabs .nav-item.show .nav-link, body.color-scheme-dark .nav-tabs .nav-link:hover, body.color-scheme-dark .nav-tabs .nav-item:hover .nav-link, .content-panel.color-scheme-dark .nav-tabs .nav-link.active, .content-panel.color-scheme-dark .nav-tabs .nav-item.show .nav-link, .content-panel.color-scheme-dark .nav-tabs .nav-link:hover, .content-panel.color-scheme-dark .nav-tabs .nav-item:hover .nav-link {
  color: #fff;
}

body.color-scheme-dark .page-link, .content-panel.color-scheme-dark .page-link {
  background-color: #293145;
  border-color: #293145;
}

body.color-scheme-dark .page-item.disabled .page-link, .content-panel.color-scheme-dark .page-item.disabled .page-link {
  background-color: #293145;
  border-color: #293145;
  color: rgba(218, 226, 243, 0.4);
}

/* ##. Responsive Styles */
/* 

Contents: 

#1.  Screen size <= 1650px
#2.  Screen size <= 1550px
#3.  Screen size <= 1400px
#4.  Screen size <= 1350px
#5.  Screen size between 1100px - 1350px
#6.  Screen size <= 1250px
#7.  Screen size <= 1150px
#8.  Tablets and phones  (< 1024px)
#9.  Tablets (768px - 1024px)
#10. Phones & Vertical Tablets (<= 768px)
#11. Phones (< 767px)
#12. Screen Height >= 700px


*/
/* #1.  Screen size <= 1650px */
@media (max-width: 1650px) {
  body,
  body.auth-wrapper .all-wrapper {
    padding: 40px;
  }
  .content-panel {
    padding: 2rem;
    -webkit-box-flex: 0;
            flex: 0 0 350px;
  }
  .content-panel.compact {
    -webkit-box-flex: 0;
            flex: 0 0 300px;
    padding: 2rem 1rem;
  }
  .content-box {
    padding: 2rem;
  }
  .all-wrapper {
    max-width: 100%;
  }
}

/* #2.  Screen size <= 1550px */
@media (max-width: 1550px) {
  body,
  body.auth-wrapper .all-wrapper {
    padding: 20px;
  }
  .menu-side-w ul.main-menu {
    padding: 1.5rem 1rem;
  }
  .menu-side-w ul.main-menu > li .icon-w {
    padding-left: 1rem;
    width: 75px;
  }
  .menu-side-w ul.sub-menu {
    padding-left: 37px;
  }
  .menu-side-w {
    width: 260px;
  }
  .menu-side-w .logged-user-menu ul {
    padding-left: 30px;
    padding-right: 30px;
  }
  .support-index .support-ticket-content-w .ticket-reply .ticket-reply-info .badge {
    position: absolute;
    top: -10px;
    right: 0px;
  }
}

/* #3.  Screen size <= 1400px */
@media (max-width: 1400px) {
  .menu-side-w ul.main-menu > li .icon-w {
    padding-left: 10px;
    width: 65px;
  }
  .menu-side-w {
    width: 230px;
  }
  .menu-side-w .side-menu-magic {
    padding: 30px 20px;
  }
  .menu-side-w .logged-user-menu ul {
    padding-left: 15px;
    padding-right: 15px;
  }
  .menu-side-w ul.sub-menu {
    padding-left: 30px;
  }
  .menu-top-image-w ul.main-menu > li .icon-w {
    padding-left: 20px;
  }
  .menu-top-image-w ul.main-menu > li span {
    padding-right: 20px;
  }
  .full-chat-w .chat-content-w .chat-message .chat-message-content {
    max-width: 320px;
  }
  .support-index .support-ticket-content-w .ticket-reply .ticket-reply-content {
    margin-left: 0px;
  }
  .support-index .support-ticket-content-w .support-ticket-content {
    padding-right: 30px;
    padding-left: 20px;
  }
  .support-index .support-ticket-content-w.folded-info .support-ticket-content {
    padding-right: 20px;
  }
  .support-index .support-tickets {
    -webkit-box-flex: 0;
            flex: 0 0 400px;
  }
  .support-index .st-foot {
    font-size: 0.72rem;
  }
  .support-index .st-foot .value + .label {
    margin-left: 10px;
  }
  .support-index .support-tickets {
    margin-right: 15px;
    padding-right: 15px;
  }
}

/* #4.  Screen size <= 1350px */
@media (max-width: 1350px) {
  .top-bar .fs-extra-info {
    display: none;
  }
  .menu-side-w .logo-w {
    padding: 1.5rem 1rem;
  }
  .menu-top-w .menu-top-i .logo-w {
    padding: 0rem 1rem;
    width: 50px;
  }
  .menu-top-w .menu-top-i ul.main-menu {
    padding-left: 0px;
  }
  .support-index .support-tickets {
    -webkit-box-flex: 0;
            flex: 0 0 390px;
  }
}

/* #5.  Screen size between 1100px - 1350px */
@media (min-width: 1100px) and (max-width: 1350px) {
  .content-panel {
    -webkit-box-flex: 0;
            flex: 0 0 300px;
    padding: 1.5rem;
  }
  .padded {
    padding: 0.5rem 1rem;
  }
  .content-box {
    padding: 1.5rem;
  }
  .activity-boxes-w .activity-avatar {
    margin-right: 1rem;
    width: 40px;
  }
  .activity-boxes-w .activity-time {
    -webkit-box-flex: 0;
            flex: 0 0 90px;
  }
  .activity-boxes-w .activity-box-w:before {
    left: 65px;
  }
  .activity-boxes-w .activity-box-w:after {
    left: 70px;
  }
  .profile-tile .profile-tile-box {
    width: 95px;
  }
  .profile-tile .pt-avatar-w img {
    width: 50px;
  }
  .profile-tile .profile-tile-meta {
    padding-left: 15px;
  }
}

/* #6.  Screen size <= 1250px */
@media (max-width: 1250px) {
  .element-box, .invoice-w, .big-error-w, .invoice-w, .big-error-w {
    padding: 1rem 1.5rem;
  }
  .element-box .os-tabs-controls, .invoice-w .os-tabs-controls, .big-error-w .os-tabs-controls, .invoice-w .os-tabs-controls, .big-error-w .os-tabs-controls {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  /* body,
  body.auth-wrapper .all-wrapper {
    padding: 0px;
  } */
  .content-w,
  .menu-side-w,
  .all-wrapper {
    border-radius: 0px !important;
    overflow: hidden;
  }
  .menu-side-w .logged-user-menu {
    left: 0px;
    right: 0px;
  }
  .support-index .support-ticket-content-w .support-ticket-info {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 250px;
    box-shadow: 0px 20px 60px rgba(4, 123, 248, 0.3);
    background-color: #fff;
    background-image: none;
    display: none;
  }
  .support-index .support-ticket-content-w .support-ticket-content .show-ticket-info {
    display: block;
  }
  .support-index .support-ticket-content-w.force-show-folded-info .support-ticket-info {
    display: block;
  }
  .support-index .support-ticket-content-w .support-ticket-content {
    padding-right: 20px;
    padding-left: 20px;
  }
  .support-index .support-ticket-content-w.folded-info .support-ticket-content {
    padding: 10px 20px;
  }
}

/* #7.  Screen size <= 1150px */
@media (max-width: 1150px) {
  .ae-content {
    padding: 0px;
  }
  .aec-full-message-w {
    margin-bottom: 0px;
  }
  .ae-content-w {
    background-image: none;
    background-color: #fff;
  }
  .aec-full-message-w .more-messages {
    top: -32px;
    background-color: #fff;
    padding: 3px 5px;
    border-radius: 4px;
    color: #047bf8;
  }
  .ae-list-w {
    -webkit-box-flex: 0;
            flex: 0 0 300px;
  }
  .ae-list-w .ae-list .aei-image {
    display: none;
  }
  .layout-w {
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
  }
  .content-box:after {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(4, 36, 113, 0.6);
    content: "";
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .content-panel {
    max-width: 400px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    background-color: #fff;
    background-image: -webkit-gradient(linear, right top, left top, color-stop(8%, #EFF2F9), color-stop(90%, #fff));
    background-image: linear-gradient(-90deg, #EFF2F9 8%, #fff 90%);
    z-index: 4;
    border: none;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
    visibility: hidden;
    z-index: 11;
  }
  .content-panel.compact {
    max-width: 320px;
  }
  .content-panel .content-panel-close {
    display: block;
  }
  .content-panel.color-scheme-dark {
    background-color: #293145;
    background-image: -webkit-gradient(linear, right top, left top, from(#293145), to(#293145));
    background-image: linear-gradient(-90deg, #293145, #293145);
  }
  body.color-scheme-dark .content-panel {
    background-color: #293145;
    background-image: -webkit-gradient(linear, right top, left top, from(#293145), to(#293145));
    background-image: linear-gradient(-90deg, #293145, #293145);
  }
  .content-panel-toggler {
    display: block;
  }
  .content-i, .with-side-panel .content-i {
    display: block;
  }
  .content-i .content-box, .with-side-panel .content-i .content-box {
    display: block;
  }
  .content-panel-active .content-panel {
    -webkit-transform: translateX(80px);
            transform: translateX(80px);
    visibility: visible;
    box-shadow: 0 2px 80px 0 rgba(0, 0, 0, 0.4);
  }
  .content-panel-active .layout-w {
    -webkit-transform: translateX(-80px);
            transform: translateX(-80px);
  }
  .content-panel-active .content-box:after {
    opacity: 1;
    visibility: visible;
  }
}

/* #8.  Tablets and phones  (< 1024px) */
@media (max-width: 1024px) {
  .invoice-w {
    padding: 50px;
  }
  .ae-list-w {
    -webkit-box-flex: 0;
            flex: 0 0 230px;
  }
  .ae-list-w .ae-list .ae-item {
    padding: 15px;
  }
  .table th,
  .table td {
    padding: 0.7rem 0.5rem;
  }
  .content-w {
    border-radius: 0px !important;
  }
  /* .menu-w {
    display: none !important;
  } */
}

/* #9.  Tablets (768px - 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .top-bar .logo-w {
    display: none;
  }
  .top-bar .fs-extra-info {
    display: none;
  }
  .element-wrapper {
    padding-bottom: 2rem;
  }
  .element-balances {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .full-chat-w .full-chat-right {
    display: none;
  }
  .full-chat-w .chat-content-w .chat-content {
    padding: 30px;
  }
  .top-bar {
    padding: 0px;
  }
  .top-bar > ul {
    white-space: nowrap;
  }
  .top-bar > ul li a {
    padding: 15px 10px;
    margin: 0px 10px;
  }
  .top-bar.with-overflow {
    overflow: visible;
  }
  .nav.upper.nav-tabs .nav-link {
    margin-right: 0.7rem;
    padding-bottom: 10px;
    font-size: 0.72rem;
  }
  .display-type {
    content: "tablet";
  }
  .content-box {
    padding: 1.5rem;
  }
  .padded {
    padding: 0.3rem 0.8rem;
  }
  .element-box, .invoice-w, .big-error-w, .invoice-w, .big-error-w {
    padding: 1rem 1rem;
  }
  .element-box .os-tabs-controls, .invoice-w .os-tabs-controls, .big-error-w .os-tabs-controls, .invoice-w .os-tabs-controls, .big-error-w .os-tabs-controls {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .layout-w {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
  .menu-mobile {
    width: 70px;
    display: table-cell;
  }
  .menu-mobile .menu-and-user {
    display: block;
  }
  .menu-mobile ul.sub-menu {
    padding: 1rem 1.5rem;
  }
  .menu-mobile .mobile-menu-magic {
    display: none;
  }
  .menu-mobile .logged-user-w {
    padding: 1rem;
    text-align: center;
  }
  .menu-mobile .logged-user-w .avatar-w img {
    width: 35px;
  }
  .menu-mobile .logged-user-w .logged-user-info-w {
    display: none;
  }
  .menu-mobile ul.main-menu {
    padding: 1rem 0px;
  }
  .menu-mobile ul.main-menu > li.has-sub-menu > a:before {
    display: none;
  }
  .menu-mobile .mm-logo-buttons-w {
    text-align: center;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .menu-mobile .mm-logo-buttons-w .mm-buttons {
    display: none;
  }
  .menu-mobile .mm-logo-buttons-w .mm-logo {
    display: block;
    text-align: center;
  }
  .menu-mobile .mm-logo-buttons-w .mm-logo span {
    display: none;
  }
  .menu-mobile.color-scheme-dark ul.main-menu > li > a > span {
    color: rgba(255, 255, 255, 0.2);
  }
  .menu-mobile.color-scheme-dark ul.sub-menu {
    background-color: #323c58;
  }
  .menu-mobile.color-scheme-dark ul.sub-menu:before {
    border-right-color: #203c9b;
  }
  .menu-mobile ul.main-menu > li > a > span {
    position: absolute;
    top: 10px;
    left: 90px;
    color: rgba(0, 0, 0, 0.2);
    font-size: 1.53rem;
    font-weight: 500;
    display: none;
    white-space: nowrap;
    z-index: 1000;
    padding: 0px;
  }
  .menu-mobile ul.main-menu li {
    position: relative;
  }
  .menu-mobile ul.main-menu > li .icon-w {
    padding: 20px 5px;
  }
  .menu-mobile ul.sub-menu {
    position: absolute;
    top: 0px;
    left: 70px;
    background-color: #fff;
    min-width: 220px;
    z-index: 999;
    list-style: none;
    padding: 20px 15px;
    padding-top: 50px;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
  }
  .menu-mobile ul.sub-menu li {
    padding: 10px 10px;
  }
  .menu-mobile .main-menu li > a:hover > span {
    display: block;
  }
  .menu-mobile .has-sub-menu.active .icon-w {
    color: #3E4B5B;
  }
  .menu-mobile .has-sub-menu.active .sub-menu {
    display: block;
  }
  .user-profile .up-header {
    font-size: 2.5rem;
  }
  .user-profile .up-sub-header {
    font-size: 1rem;
  }
  .todo-app-w .todo-sidebar {
    padding: 30px;
    -webkit-box-flex: 0;
            flex: 0 0 280px;
  }
  .todo-app-w .todo-sidebar .todo-sidebar-section {
    padding: 10px;
  }
  .todo-app-w .todo-content {
    padding: 30px;
  }
}

/* #10. Phones & Vertical Tablets (<= 768px) */
@media (max-width: 768px) {
  .order-box {
    padding: 15px;
  }
  .order-box .order-items-table {
    text-align: center;
    border-bottom: none;
    margin-bottom: 0px;
  }
  .order-box .order-items-table .quantity-input .form-control {
    -webkit-box-flex: 1;
            flex: 1;
  }
  .order-box .order-items-table .product-price {
    font-size: 1.5rem;
    color: #047bf8;
  }
  .order-box .order-items-table .product-image {
    max-width: 120px;
    max-height: 120px;
    margin: 0px auto;
  }
  .order-box .order-items-table .product-remove-btn {
    position: absolute;
    top: 10px;
    right: 0px;
    font-size: 24px;
  }
  .order-box .order-items-table .table thead {
    display: none;
  }
  .order-box .order-items-table .table tbody tr {
    display: block;
    border-bottom: 1px solid #eee;
    position: relative;
  }
  .order-box .order-items-table .table tbody tr td {
    display: block;
    border: none;
    padding: 5px;
  }
  .ecommerce-customer-info {
    margin-top: 20px;
  }
  .element-balances.mobile-full-width > .balance {
    -webkit-box-flex: 1;
            flex: 1;
    padding: 0px;
    margin-bottom: 30px;
    text-align: center;
  }
  .element-balances.mobile-full-width > .balance-table {
    -webkit-box-flex: 1;
            flex: 1;
    padding: 0px;
    text-align: center;
  }
  .os-tabs-w .os-tabs-controls {
    margin-bottom: 1rem;
  }
  .os-tabs-w .os-tabs-complex .nav-item {
    -webkit-box-flex: 1;
            flex: 1;
  }
  .os-tabs-w .os-tabs-complex .nav-item .nav-link {
    border-right: 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    text-align: center;
    padding: 15px;
  }
  .os-tabs-w .os-tabs-complex .nav-item:first-child .nav-link {
    border-top: none;
  }
  .todo-app-w .todo-sidebar {
    padding: 30px 20px 20px 45px;
  }
  .todo-app-w .todo-sidebar .todo-sidebar-section {
    padding: 10px 0px;
  }
  .todo-app-w .todo-sidebar .todo-sidebar-section-toggle {
    left: -22px;
  }
  .todo-app-w .todo-sidebar .todo-sidebar-section-header {
    font-size: 1.25rem;
  }
  .todo-app-w .todo-sidebar .todo-sidebar-section-sub-section, .todo-app-w .todo-sidebar ul.projects-list {
    padding-left: 10px;
  }
  .todo-app-w .todo-content {
    padding: 30px;
  }
  .todo-app-w .todo-content .all-tasks-w {
    padding-left: 5px;
  }
  .todo-app-w .todo-content .tasks-header-w {
    padding-left: 20px;
  }
  .todo-app-w .todo-content .tasks-header-w .tasks-header-toggler {
    left: -5px;
  }
  .todo-app-w .todo-content .todo-content-header {
    font-size: 1.25rem;
  }
  .todo-app-w .todo-content .all-tasks-w {
    padding-top: 0px;
    padding-right: 0px;
  }
  .todo-app-w .todo-content .tasks-list li.draggable-task {
    padding-right: 10px;
  }
  .support-index .support-tickets {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    margin-right: 0px;
    padding-right: 0px;
    border-right: 0px;
  }
  .support-index .support-ticket-content-w {
    display: none;
  }
  .support-index .support-ticket-content-w .support-ticket-content {
    padding: 10px 5px;
  }
  .support-index .support-ticket-content-w.folded-info .support-ticket-content {
    padding: 10px 5px;
  }
  .support-index.show-ticket-content {
    position: relative;
  }
  .support-index.show-ticket-content .support-ticket-content-w {
    display: block;
    -webkit-box-flex: 1;
            flex: 1;
  }
  .support-index.show-ticket-content .support-tickets {
    display: none;
  }
  .support-index.show-ticket-content .support-ticket-content-w .support-ticket-content .back-to-index {
    display: block;
    position: absolute;
    top: 0px;
    left: 10px;
  }
  .support-index.show-ticket-content .support-ticket-content-w .support-ticket-content .show-ticket-info {
    position: absolute;
    top: 0px;
    right: 10px;
  }
  .support-index .support-ticket-content-w .support-ticket-content .support-ticket-content-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    padding-top: 0px;
    padding-top: 20px;
  }
  .support-index .support-ticket-content-w .support-ticket-content .support-ticket-content-header .show-ticket-info {
    padding-top: 0px;
  }
  .support-index .support-ticket-content-w .support-ticket-content .support-ticket-content-header .ticket-header {
    padding-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.07);
  }
}

/* #11. Phones (< 767px) */
@media (max-width: 767px) {
  .layout-w {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .floated-customizer-btn {
    display: none;
  }
  .onboarding-modal .onboarding-content.with-gradient {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 50px;
  }
  .onboarding-modal .onboarding-content .onboarding-text {
    font-size: 0.9rem;
    padding-left: 0px;
    padding-right: 0px;
  }
  .onboarding-modal .onboarding-slide .onboarding-side-by-side .onboarding-media {
    padding-bottom: 0px;
  }
  .onboarding-modal .onboarding-slide .onboarding-side-by-side .onboarding-content.with-gradient {
    padding-bottom: 70px;
  }
  .onboarding-modal .onboarding-side-by-side {
    flex-wrap: wrap;
  }
  .onboarding-modal .onboarding-side-by-side .onboarding-media {
    -webkit-box-flex: 1;
            flex: 1;
    padding-bottom: 0px;
    padding-top: 20px;
  }
  .onboarding-modal .onboarding-side-by-side .onboarding-content.with-gradient {
    background-position: top center;
    padding: 40px 20px;
  }
  .hidden-mobile {
    display: none !important;
  }
  .support-index .st-foot {
    padding: 10px 10px;
  }
  .support-index .support-ticket + .support-ticket {
    margin-top: 15px;
  }
  .top-bar .logo-w {
    display: none;
  }
  .top-bar.with-overflow {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
  }
  .top-bar.with-overflow .top-menu-controls {
    display: none;
  }
  .top-bar .fs-extra-info {
    display: none;
  }
  .element-balances {
    flex-wrap: wrap;
  }
  .element-balances .balance {
    border: none !important;
  }
  .cell-with-media {
    text-align: center;
  }
  .cell-with-media img {
    margin-bottom: 5px;
  }
  .cell-with-media img + span {
    margin-left: 0px !important;
  }
  .element-wrapper .element-actions {
    float: none;
    margin-bottom: 20px;
  }
  .element-wrapper .element-actions.actions-only {
    float: right;
    margin-bottom: 0;
  }
  .el-tablo.smaller .value {
    font-size: 1.26rem;
    margin-top: 3px;
  }
  .todo-app-w {
    display: block;
  }
  .todo-app-w .todo-sidebar {
    padding: 30px 15px 20px 40px;
    -webkit-box-flex: 1;
            flex: 1;
  }
  .todo-app-w .todo-sidebar .todo-sidebar-section {
    padding: 10px 0px;
  }
  .todo-app-w .todo-sidebar .todo-sidebar-section-toggle {
    left: -22px;
  }
  .todo-app-w .todo-sidebar .todo-sidebar-section-header {
    font-size: 1.25rem;
  }
  .todo-app-w .todo-sidebar .todo-sidebar-section-sub-section, .todo-app-w .todo-sidebar ul.projects-list {
    padding-left: 10px;
  }
  .todo-app-w .todo-content {
    padding: 30px 20px;
  }
  .todo-app-w .todo-content .todo-content-header {
    font-size: 1.25rem;
  }
  .todo-app-w .todo-content .all-tasks-w {
    padding-top: 0px;
    padding-right: 0px;
  }
  .todo-app-w .todo-content .tasks-list li.draggable-task {
    padding-right: 10px;
  }
  .full-chat-w .full-chat-i {
    display: block;
  }
  .projects-list .project-head {
    padding: 20px 30px;
    display: block;
    text-align: center;
  }
  .projects-list .project-head .project-users {
    text-align: center;
    margin-top: 2rem;
  }
  .projects-list .project-info {
    padding: 20px 30px;
  }
  .projects-list .project-info .el-tablo {
    text-align: center;
    margin-bottom: 0.5rem;
  }
  .display-type {
    content: "phone";
  }
  .ae-list-w {
    -webkit-box-flex: 1;
            flex: 1 1 230px;
  }
  .ae-content-w {
    display: none;
  }
  .top-bar {
    padding: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .top-bar .fancy-selector-w {
    width: 100%;
  }
  .top-bar .fancy-selector-w .fancy-selector-current {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .top-bar .fancy-selector-w .fancy-selector-current .fs-selector-trigger {
    margin-left: auto;
  }
  .top-bar > ul {
    white-space: nowrap;
  }
  .top-bar > ul li a {
    padding: 15px 10px;
    margin: 0px 10px;
  }
  .top-bar.with-overflow {
    overflow: visible;
  }
  .nav.upper.nav-tabs .nav-link {
    margin-right: 0.7rem;
    padding-bottom: 10px;
    font-size: 0.72rem;
  }
  .app-email-w.forse-show-content .ae-content-w {
    display: block;
  }
  .app-email-w.forse-show-content .ae-list-w {
    display: none;
  }
  .aec-full-message-w .message-content {
    padding: 15px;
  }
  .aec-full-message-w .message-attachments {
    text-align: center;
  }
  .aec-full-message-w .message-head .user-w .user-role span {
    margin-left: 0;
  }
  .aec-reply {
    padding: 15px;
  }
  .aec-reply .reply-header {
    padding-bottom: 15px;
    padding-top: 15px;
    text-align: center;
  }
  .aec-reply .buttons-w {
    display: block;
    text-align: center;
  }
  .aec-reply .buttons-w .btn, .aec-reply .buttons-w .all-wrapper .fc-button, .all-wrapper .aec-reply .buttons-w .fc-button {
    margin-bottom: 10px;
  }
  .floated-chat-btn i + span {
    display: none;
  }
  .floated-chat-btn {
    padding: 10px 12px;
  }
  .floated-colors-btn {
    padding: 10px 12px;
  }
  .floated-colors-btn.second-floated-btn {
    right: auto;
    left: 10px;
  }
  .floated-colors-btn span + span {
    display: none;
  }
  .padded {
    padding: 1rem;
  }
  .user-profile .up-head-w .up-social {
    top: 5px;
    right: 10px;
  }
  .user-profile .up-head-w .up-social a {
    font-size: 24px;
  }
  .user-profile .up-main-info {
    padding-bottom: 10%;
  }
  .user-profile .up-header {
    font-size: 1.75rem;
    margin-bottom: 5px;
    padding-bottom: 5px;
  }
  .user-profile .up-sub-header {
    font-size: 1rem;
  }
  .user-profile .up-controls {
    text-align: center;
  }
  .user-profile .up-controls .value-pair {
    margin-bottom: 1rem;
  }
  .user-profile .up-controls .text-right {
    text-align: center !important;
  }
  .timed-activities {
    padding: 0;
  }
  .timed-activity {
    display: block;
    font-size: 0.9rem;
  }
  .timed-activity .ta-date {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin-bottom: 3rem;
    text-align: left;
  }
  .timed-activity .ta-date span:after {
    -webkit-transform: none;
            transform: none;
    left: 0px;
  }
  .timed-activity .ta-record-w {
    padding-left: 0px;
  }
  .timed-activity .ta-record {
    display: block;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  .timed-activity .ta-activity {
    font-size: 0.81rem;
  }
  .post-box {
    flex-wrap: wrap;
  }
  .post-box .post-media {
    -webkit-box-flex: 0;
            flex: 0 0 100%;
    padding-bottom: 60%;
  }
  .post-box .post-content {
    padding: 20px 15px;
  }
  .content-panel-toggler {
    display: none;
  }
  .menu-mobile {
    display: block;
  }
  .element-info .element-search {
    margin-top: 1rem;
  }
  .element-box .os-tabs-controls, .invoice-w .os-tabs-controls, .big-error-w .os-tabs-controls, .invoice-w .os-tabs-controls, .big-error-w .os-tabs-controls {
    display: block;
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .element-box .os-tabs-controls .nav + .nav, .invoice-w .os-tabs-controls .nav + .nav, .big-error-w .os-tabs-controls .nav + .nav, .invoice-w .os-tabs-controls .nav + .nav, .big-error-w .os-tabs-controls .nav + .nav {
    margin-top: 1rem;
  }
  .element-box.el-tablo, .el-tablo.invoice-w, .el-tablo.big-error-w {
    text-align: center;
  }
  .invoice-w .infos {
    display: block;
  }
  .invoice-w .infos .info-2 {
    text-align: left;
    padding-top: 30px;
  }
  .invoice-heading {
    margin-bottom: 2rem;
    margin-top: 4rem;
  }
  .invoice-body {
    display: block;
  }
  .invoice-body .invoice-desc {
    margin-bottom: 2rem;
  }
  .invoice-footer {
    display: block;
    text-align: center;
  }
  .invoice-footer .invoice-logo {
    margin-bottom: 1rem;
  }
  .controls-above-table .form-control {
    width: 110px;
    display: inline-block;
  }
  .controls-above-table .btn, .controls-above-table .all-wrapper .fc-button, .all-wrapper .controls-above-table .fc-button {
    margin-bottom: 0.5rem;
  }
  .layout-w {
    display: block;
  }
  .content-w, .menu-side .content-w {
    display: block;
  }
  .content-i {
    display: block;
  }
  .content-i .content-box {
    display: block;
    padding: 15px;
  }
  .content-i .content-panel {
    padding: 30px 15px;
    border-left: none;
    display: block;
    width: auto;
  }
  .big-error-w {
    padding: 1rem;
  }
  .element-wrapper {
    padding-bottom: 1.5rem;
  }
  .element-wrapper.compact {
    padding-bottom: 1rem;
  }
  .element-box, .invoice-w, .big-error-w {
    padding: 1rem;
  }
  canvas {
    max-width: 100%;
  }
  table {
    max-width: 100%;
  }
  .invoice-w {
    padding: 30px;
  }
  .breadcrumb {
    margin-bottom: 1rem;
  }
}

/* #12. Screen Height >= 700px */
@media (min-height: 700px) and (min-width: 768px) {
  .modal-dialog.modal-centered {
    top: 50%;
    -webkit-transform: translate(0, -80%);
            transform: translate(0, -80%);
    margin: 0px auto;
  }
  .modal.show .modal-dialog.modal-centered {
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    margin: 0px auto;
  }
}

/* ##. User Override Styles */
/*# sourceMappingURL=main.css.map */


.fs-10 {
  font-size: 10px !important; }

.fs-11 {
  font-size: 11px !important; }

.fs-12 {
  font-size: 12px !important; }

.fs-13 {
  font-size: 13px !important; }

.fs-14 {
  font-size: 14px !important; }

.fs-15 {
  font-size: 15px !important; }

.fs-16 {
  font-size: 16px !important; }

.fs-18 {
  font-size: 18px !important; }

.fs-20 {
  font-size: 20px !important; }

.fs-22 {
  font-size: 22px !important; }

.fs-24 {
  font-size: 24px !important; }

.fs-26 {
  font-size: 26px !important; }

.fs-28 {
  font-size: 28px !important; }

.fs-30 {
  font-size: 30px !important; }

.fs-32 {
  font-size: 32px !important; }

.fs-34 {
  font-size: 34px !important; }

.fs-36 {
  font-size: 36px !important; }

.fs-38 {
  font-size: 38px !important; }

.fs-40 {
  font-size: 40px !important; }

@media (max-width: 991px) {
  .sm-fs-10 {
    font-size: 10px !important; }
  .sm-fs-11 {
    font-size: 11px !important; }
  .sm-fs-12 {
    font-size: 12px !important; }
  .sm-fs-13 {
    font-size: 13px !important; }
  .sm-fs-14 {
    font-size: 14px !important; }
  .sm-fs-15 {
    font-size: 15px !important; }
  .sm-fs-16 {
    font-size: 16px !important; }
  .sm-fs-18 {
    font-size: 18px !important; }
  .sm-fs-20 {
    font-size: 20px !important; }
  .sm-fs-22 {
    font-size: 22px !important; }
  .sm-fs-24 {
    font-size: 24px !important; }
  .sm-fs-26 {
    font-size: 26px !important; }
  .sm-fs-28 {
    font-size: 28px !important; }
  .sm-fs-30 {
    font-size: 30px !important; }
  .sm-fs-32 {
    font-size: 32px !important; }
  .sm-fs-34 {
    font-size: 34px !important; }
  .sm-fs-36 {
    font-size: 36px !important; }
  .sm-fs-38 {
    font-size: 38px !important; } }

    /* Generic Padding Helpers
------------------------------------
*/
.p-t-0 {
  padding-top: 0px !important; }

.p-r-0 {
  padding-right: 0px !important; }

.p-l-0 {
  padding-left: 0px !important; }

.p-b-0 {
  padding-bottom: 0px !important; }

.padding-0 {
  padding: 0px !important; }

.p-t-5 {
  padding-top: 5px !important; }

.p-r-5 {
  padding-right: 5px !important; }

.p-l-5 {
  padding-left: 5px !important; }

.p-b-5 {
  padding-bottom: 5px !important; }

.padding-5 {
  padding: 5px !important; }

.p-t-10 {
  padding-top: 10px !important; }

.p-r-10 {
  padding-right: 10px !important; }

.p-l-10 {
  padding-left: 10px !important; }

.p-b-10 {
  padding-bottom: 10px !important; }

.padding-10 {
  padding: 10px !important; }

.p-t-15 {
  padding-top: 15px !important; }

.p-r-15 {
  padding-right: 15px !important; }

.p-l-15 {
  padding-left: 15px !important; }

.p-b-15 {
  padding-bottom: 15px !important; }

.padding-15 {
  padding: 15px !important; }

.p-t-20 {
  padding-top: 20px !important; }

.p-r-20 {
  padding-right: 20px !important; }

.p-l-20 {
  padding-left: 20px !important; }

.p-b-20 {
  padding-bottom: 20px !important; }

.padding-20 {
  padding: 20px !important; }

.p-t-25 {
  padding-top: 25px !important; }

.p-r-25 {
  padding-right: 25px !important; }

.p-l-25 {
  padding-left: 25px !important; }

.p-b-25 {
  padding-bottom: 25px !important; }

.padding-25 {
  padding: 25px !important; }

.p-t-30 {
  padding-top: 30px !important; }

.p-r-30 {
  padding-right: 30px !important; }

.p-l-30 {
  padding-left: 30px !important; }

.p-b-30 {
  padding-bottom: 30px !important; }

.padding-30 {
  padding: 30px !important; }

.p-t-35 {
  padding-top: 35px !important; }

.p-r-35 {
  padding-right: 35px !important; }

.p-l-35 {
  padding-left: 35px !important; }

.p-b-35 {
  padding-bottom: 35px !important; }

.padding-35 {
  padding: 35px !important; }

.p-t-40 {
  padding-top: 40px !important; }

.p-r-40 {
  padding-right: 40px !important; }

.p-l-40 {
  padding-left: 40px !important; }

.p-b-40 {
  padding-bottom: 40px !important; }

.padding-40 {
  padding: 40px !important; }

.p-t-45 {
  padding-top: 45px !important; }

.p-r-45 {
  padding-right: 45px !important; }

.p-l-45 {
  padding-left: 45px !important; }

.p-b-45 {
  padding-bottom: 45px !important; }

.padding-45 {
  padding: 45px !important; }

.p-t-50 {
  padding-top: 50px !important; }

.p-r-50 {
  padding-right: 50px !important; }

.p-l-50 {
  padding-left: 50px !important; }

.p-b-50 {
  padding-bottom: 50px !important; }

.padding-50 {
  padding: 50px !important; }

.p-t-55 {
  padding-top: 55px !important; }

.p-r-55 {
  padding-right: 55px !important; }

.p-l-55 {
  padding-left: 55px !important; }

.p-b-55 {
  padding-bottom: 55px !important; }

.padding-55 {
  padding: 55px !important; }

.p-t-60 {
  padding-top: 60px !important; }

.p-r-60 {
  padding-right: 60px !important; }

.p-l-60 {
  padding-left: 60px !important; }

.p-b-60 {
  padding-bottom: 60px !important; }

.padding-60 {
  padding: 60px !important; }

.p-t-65 {
  padding-top: 65px !important; }

.p-r-65 {
  padding-right: 65px !important; }

.p-l-65 {
  padding-left: 65px !important; }

.p-b-65 {
  padding-bottom: 65px !important; }

.padding-65 {
  padding: 65px !important; }

.p-t-70 {
  padding-top: 70px !important; }

.p-r-70 {
  padding-right: 70px !important; }

.p-l-70 {
  padding-left: 70px !important; }

.p-b-70 {
  padding-bottom: 70px !important; }

.padding-70 {
  padding: 70px !important; }

.p-t-75 {
  padding-top: 75px !important; }

.p-r-75 {
  padding-right: 75px !important; }

.p-l-75 {
  padding-left: 75px !important; }

.p-b-75 {
  padding-bottom: 75px !important; }

.padding-75 {
  padding: 75px !important; }

.p-t-80 {
  padding-top: 80px !important; }

.p-r-80 {
  padding-right: 80px !important; }

.p-l-80 {
  padding-left: 80px !important; }

.p-b-80 {
  padding-bottom: 80px !important; }

.padding-80 {
  padding: 80px !important; }

.p-t-85 {
  padding-top: 85px !important; }

.p-r-85 {
  padding-right: 85px !important; }

.p-l-85 {
  padding-left: 85px !important; }

.p-b-85 {
  padding-bottom: 85px !important; }

.padding-85 {
  padding: 85px !important; }

.p-t-90 {
  padding-top: 90px !important; }

.p-r-90 {
  padding-right: 90px !important; }

.p-l-90 {
  padding-left: 90px !important; }

.p-b-90 {
  padding-bottom: 90px !important; }

.padding-90 {
  padding: 90px !important; }

.p-t-95 {
  padding-top: 95px !important; }

.p-r-95 {
  padding-right: 95px !important; }

.p-l-95 {
  padding-left: 95px !important; }

.p-b-95 {
  padding-bottom: 95px !important; }

.padding-95 {
  padding: 95px !important; }

.p-t-100 {
  padding-top: 100px !important; }

.p-r-100 {
  padding-right: 100px !important; }

.p-l-100 {
  padding-left: 100px !important; }

.p-b-100 {
  padding-bottom: 100px !important; }

.padding-100 {
  padding: 100px !important; }

.p-t-105 {
  padding-top: 105px !important; }

.p-r-105 {
  padding-right: 105px !important; }

.p-l-105 {
  padding-left: 105px !important; }

.p-b-105 {
  padding-bottom: 105px !important; }

.padding-105 {
  padding: 105px !important; }

.p-t-110 {
  padding-top: 110px !important; }

.p-r-110 {
  padding-right: 110px !important; }

.p-l-110 {
  padding-left: 110px !important; }

.p-b-110 {
  padding-bottom: 110px !important; }

.padding-110 {
  padding: 110px !important; }

.p-t-115 {
  padding-top: 115px !important; }

.p-r-115 {
  padding-right: 115px !important; }

.p-l-115 {
  padding-left: 115px !important; }

.p-b-115 {
  padding-bottom: 115px !important; }

.padding-115 {
  padding: 115px !important; }

.p-t-120 {
  padding-top: 120px !important; }

.p-r-120 {
  padding-right: 120px !important; }

.p-l-120 {
  padding-left: 120px !important; }

.p-b-120 {
  padding-bottom: 120px !important; }

.padding-120 {
  padding: 120px !important; }

.p-t-125 {
  padding-top: 125px !important; }

.p-r-125 {
  padding-right: 125px !important; }

.p-l-125 {
  padding-left: 125px !important; }

.p-b-125 {
  padding-bottom: 125px !important; }

.padding-125 {
  padding: 125px !important; }

    /* Generic Margin Helpers
    ------------------------------------
     */
    .m-t-0 {
      margin-top: 0px !important; }

    .m-r-0 {
      margin-right: 0px !important; }

    .m-l-0 {
      margin-left: 0px !important; }

    .m-b-0 {
      margin-bottom: 0px !important; }

    .m-t-5 {
      margin-top: 5px !important; }

    .m-r-5 {
      margin-right: 5px !important; }

    .m-l-5 {
      margin-left: 5px !important; }

    .m-b-5 {
      margin-bottom: 5px !important; }

    .m-t-10 {
      margin-top: 10px !important; }

    .m-r-10 {
      margin-right: 10px !important; }

    .m-l-10 {
      margin-left: 10px !important; }

    .m-b-10 {
      margin-bottom: 10px !important; }

    .m-t-15 {
      margin-top: 15px !important; }

    .m-r-15 {
      margin-right: 15px !important; }

    .m-l-15 {
      margin-left: 15px !important; }

    .m-b-15 {
      margin-bottom: 15px !important; }

    .m-t-20 {
      margin-top: 20px !important; }

    .m-r-20 {
      margin-right: 20px !important; }

    .m-l-20 {
      margin-left: 20px !important; }

    .m-b-20 {
      margin-bottom: 20px !important; }

    .m-t-25 {
      margin-top: 25px !important; }

    .m-r-25 {
      margin-right: 25px !important; }

    .m-l-25 {
      margin-left: 25px !important; }

    .m-b-25 {
      margin-bottom: 25px !important; }

    .m-t-30 {
      margin-top: 30px !important; }

    .m-r-30 {
      margin-right: 30px !important; }

    .m-l-30 {
      margin-left: 30px !important; }

    .m-b-30 {
      margin-bottom: 30px !important; }

    .m-t-35 {
      margin-top: 35px !important; }

    .m-r-35 {
      margin-right: 35px !important; }

    .m-l-35 {
      margin-left: 35px !important; }

    .m-b-35 {
      margin-bottom: 35px !important; }

    .m-t-40 {
      margin-top: 40px !important; }

    .m-r-40 {
      margin-right: 40px !important; }

    .m-l-40 {
      margin-left: 40px !important; }

    .m-b-40 {
      margin-bottom: 40px !important; }

    .m-t-45 {
      margin-top: 45px !important; }

    .m-r-45 {
      margin-right: 45px !important; }

    .m-l-45 {
      margin-left: 45px !important; }

    .m-b-45 {
      margin-bottom: 45px !important; }

    .m-t-50 {
      margin-top: 50px !important; }

    .m-r-50 {
      margin-right: 50px !important; }

    .m-l-50 {
      margin-left: 50px !important; }

    .m-b-50 {
      margin-bottom: 50px !important; }

    .m-t-55 {
      margin-top: 55px !important; }

    .m-r-55 {
      margin-right: 55px !important; }

    .m-l-55 {
      margin-left: 55px !important; }

    .m-b-55 {
      margin-bottom: 55px !important; }

    .m-t-60 {
      margin-top: 60px !important; }

    .m-r-60 {
      margin-right: 60px !important; }

    .m-l-60 {
      margin-left: 60px !important; }

    .m-b-60 {
      margin-bottom: 60px !important; }

    .m-t-65 {
      margin-top: 65px !important; }

    .m-r-65 {
      margin-right: 65px !important; }

    .m-l-65 {
      margin-left: 65px !important; }

    .m-b-65 {
      margin-bottom: 65px !important; }

    .m-t-70 {
      margin-top: 70px !important; }

    .m-r-70 {
      margin-right: 70px !important; }

    .m-l-70 {
      margin-left: 70px !important; }

    .m-b-70 {
      margin-bottom: 70px !important; }

    .m-t-75 {
      margin-top: 75px !important; }

    .m-r-75 {
      margin-right: 75px !important; }

    .m-l-75 {
      margin-left: 75px !important; }

    .m-b-75 {
      margin-bottom: 75px !important; }

    .m-t-80 {
      margin-top: 80px !important; }

    .m-r-80 {
      margin-right: 80px !important; }

    .m-l-80 {
      margin-left: 80px !important; }

    .m-b-80 {
      margin-bottom: 80px !important; }

    .m-t-85 {
      margin-top: 85px !important; }

    .m-r-85 {
      margin-right: 85px !important; }

    .m-l-85 {
      margin-left: 85px !important; }

    .m-b-85 {
      margin-bottom: 85px !important; }

    .m-t-90 {
      margin-top: 90px !important; }

    .m-r-90 {
      margin-right: 90px !important; }

    .m-l-90 {
      margin-left: 90px !important; }

    .m-b-90 {
      margin-bottom: 90px !important; }

    .m-t-95 {
      margin-top: 95px !important; }

    .m-r-95 {
      margin-right: 95px !important; }

    .m-l-95 {
      margin-left: 95px !important; }

    .m-b-95 {
      margin-bottom: 95px !important; }

    .m-t-100 {
      margin-top: 100px !important; }

    .m-r-100 {
      margin-right: 100px !important; }

    .m-l-100 {
      margin-left: 100px !important; }

    .m-b-100 {
      margin-bottom: 100px !important; }

    .m-t-105 {
      margin-top: 105px !important; }

    .m-r-105 {
      margin-right: 105px !important; }

    .m-l-105 {
      margin-left: 105px !important; }

    .m-b-105 {
      margin-bottom: 105px !important; }

    .m-t-110 {
      margin-top: 110px !important; }

    .m-r-110 {
      margin-right: 110px !important; }

    .m-l-110 {
      margin-left: 110px !important; }

    .m-b-110 {
      margin-bottom: 110px !important; }

    .m-t-115 {
      margin-top: 115px !important; }

    .m-r-115 {
      margin-right: 115px !important; }

    .m-l-115 {
      margin-left: 115px !important; }

    .m-b-115 {
      margin-bottom: 115px !important; }

    .m-t-120 {
      margin-top: 120px !important; }

    .m-r-120 {
      margin-right: 120px !important; }

    .m-l-120 {
      margin-left: 120px !important; }

    .m-b-120 {
      margin-bottom: 120px !important; }

    .m-t-125 {
      margin-top: 125px !important; }

    .m-r-125 {
      margin-right: 125px !important; }

    .m-l-125 {
      margin-left: 125px !important; }

    .m-b-125 {
      margin-bottom: 125px !important; }

    .full-height {
      height: 100% !important; }

    .full-width {
      width: 100%; }
      @media (max-width: 767px) {
      .sm-p-t-0 {
        padding-top: 0px !important; }
      .sm-p-r-0 {
        padding-right: 0px !important; }
      .sm-p-l-0 {
        padding-left: 0px !important; }
      .sm-p-b-0 {
        padding-bottom: 0px !important; }
      .sm-p-t-5 {
        padding-top: 5px !important; }
      .sm-p-r-5 {
        padding-right: 5px !important; }
      .sm-p-l-5 {
        padding-left: 5px !important; }
      .sm-p-b-5 {
        padding-bottom: 5px !important; }
      .sm-p-t-10 {
        padding-top: 10px !important; }
      .sm-p-r-10 {
        padding-right: 10px !important; }
      .sm-p-l-10 {
        padding-left: 10px !important; }
      .sm-p-b-10 {
        padding-bottom: 10px !important; }
      .sm-p-t-15 {
        padding-top: 15px !important; }
      .sm-p-r-15 {
        padding-right: 15px !important; }
      .sm-p-l-15 {
        padding-left: 15px !important; }
      .sm-p-b-15 {
        padding-bottom: 15px !important; }
      .sm-p-t-20 {
        padding-top: 20px !important; }
      .sm-p-r-20 {
        padding-right: 20px !important; }
      .sm-p-l-20 {
        padding-left: 20px !important; }
      .sm-p-b-20 {
        padding-bottom: 20px !important; }
      .sm-p-t-25 {
        padding-top: 25px !important; }
      .sm-p-r-25 {
        padding-right: 25px !important; }
      .sm-p-l-25 {
        padding-left: 25px !important; }
      .sm-p-b-25 {
        padding-bottom: 25px !important; }
      .sm-p-t-30 {
        padding-top: 30px !important; }
      .sm-p-r-30 {
        padding-right: 30px !important; }
      .sm-p-l-30 {
        padding-left: 30px !important; }
      .sm-p-b-30 {
        padding-bottom: 30px !important; }
      .sm-p-t-35 {
        padding-top: 35px !important; }
      .sm-p-r-35 {
        padding-right: 35px !important; }
      .sm-p-l-35 {
        padding-left: 35px !important; }
      .sm-p-b-35 {
        padding-bottom: 35px !important; }
      .sm-p-t-40 {
        padding-top: 40px !important; }
      .sm-p-r-40 {
        padding-right: 40px !important; }
      .sm-p-l-40 {
        padding-left: 40px !important; }
      .sm-p-b-40 {
        padding-bottom: 40px !important; }
      .sm-p-t-45 {
        padding-top: 45px !important; }
      .sm-p-r-45 {
        padding-right: 45px !important; }
      .sm-p-l-45 {
        padding-left: 45px !important; }
      .sm-p-b-45 {
        padding-bottom: 45px !important; }
      .sm-p-t-50 {
        padding-top: 50px !important; }
      .sm-p-r-50 {
        padding-right: 50px !important; }
      .sm-p-l-50 {
        padding-left: 50px !important; }
      .sm-p-b-50 {
        padding-bottom: 50px !important; }
      .sm-m-t-0 {
        margin-top: 0px !important; }
      .sm-m-r-0 {
        margin-right: 0px !important; }
      .sm-m-l-0 {
        margin-left: 0px !important; }
      .sm-m-b-0 {
        margin-bottom: 0px !important; }
      .sm-m-t-5 {
        margin-top: 5px !important; }
      .sm-m-r-5 {
        margin-right: 5px !important; }
      .sm-m-l-5 {
        margin-left: 5px !important; }
      .sm-m-b-5 {
        margin-bottom: 5px !important; }
      .sm-m-t-10 {
        margin-top: 10px !important; }
      .sm-m-r-10 {
        margin-right: 10px !important; }
      .sm-m-l-10 {
        margin-left: 10px !important; }
      .sm-m-b-10 {
        margin-bottom: 10px !important; }
      .sm-m-t-15 {
        margin-top: 15px !important; }
      .sm-m-r-15 {
        margin-right: 15px !important; }
      .sm-m-l-15 {
        margin-left: 15px !important; }
      .sm-m-b-15 {
        margin-bottom: 15px !important; }
      .sm-m-t-20 {
        margin-top: 20px !important; }
      .sm-m-r-20 {
        margin-right: 20px !important; }
      .sm-m-l-20 {
        margin-left: 20px !important; }
      .sm-m-b-20 {
        margin-bottom: 20px !important; }
      .sm-m-t-25 {
        margin-top: 25px !important; }
      .sm-m-r-25 {
        margin-right: 25px !important; }
      .sm-m-l-25 {
        margin-left: 25px !important; }
      .sm-m-b-25 {
        margin-bottom: 25px !important; }
      .sm-m-t-30 {
        margin-top: 30px !important; }
      .sm-m-r-30 {
        margin-right: 30px !important; }
      .sm-m-l-30 {
        margin-left: 30px !important; }
      .sm-m-b-30 {
        margin-bottom: 30px !important; }
      .sm-m-t-35 {
        margin-top: 35px !important; }
      .sm-m-r-35 {
        margin-right: 35px !important; }
      .sm-m-l-35 {
        margin-left: 35px !important; }
      .sm-m-b-35 {
        margin-bottom: 35px !important; }
      .sm-m-t-40 {
        margin-top: 40px !important; }
      .sm-m-r-40 {
        margin-right: 40px !important; }
      .sm-m-l-40 {
        margin-left: 40px !important; }
      .sm-m-b-40 {
        margin-bottom: 40px !important; }
      .sm-m-t-45 {
        margin-top: 45px !important; }
      .sm-m-r-45 {
        margin-right: 45px !important; }
      .sm-m-l-45 {
        margin-left: 45px !important; }
      .sm-m-b-45 {
        margin-bottom: 45px !important; }
      .sm-m-t-50 {
        margin-top: 50px !important; }
      .sm-m-r-50 {
        margin-right: 50px !important; }
      .sm-m-l-50 {
        margin-left: 50px !important; }
      .sm-m-b-50 {
        margin-bottom: 50px !important; }
      .sm-no-margin {
        margin: 0px; }
      .sm-no-padding {
        padding: 0px; }
      .sm-text-right {
        text-align: right !important; }
      .sm-text-left {
        text-align: left !important; }
      .sm-text-center {
        text-align: center !important; }
      .sm-pull-right {
        float: right !important; }
      .sm-pull-left {
        float: left !important; }
      .sm-pull-reset {
        float: none !important; }
      .sm-block {
        display: block; }
      }
      /* Border Helpers
      ------------------------------------
      */
      .b-a,
      .b-r,
      .b-l,
      .b-t,
      .b-b {
        border-style: solid;
        border-width: 0; }
        .b-b-blue {
          border-style: solid;
          border-width: 0; 
          border-color: #0052bb;
        }
      .b-r {
        border-right-width: 1px; }

      .b-l {
        border-left-width: 1px; }

      .b-t {
        border-top-width: 1px; }

      .b-b {
        border-bottom-width: 1px; }

      .b-a {
        border-width: 1px; }

      .b-dashed {
        border-style: dashed; }

      .b-dotted {
        border-style: dotted;
        border-color: #C5D9E8;
        /* border-image-source: url("../../assets/img/dots.svg"); */
        border-image-slice: 33% 33%;
        border-image-repeat: round; }

      .b-thick {
        border-width: 2px; }

      .b-transparent {
        border-color: rgba(0, 0, 0, 0.4); }

      .b-transparent-white {
        border-color: rgba(255, 255, 255, 0.2) !important; }

      .b-grey {
        border-color: #d6d8d9; }

      .b-white {
        border-color: #fff; }

      .b-primary {
        border-color: #0052bb; }

      .b-complete {
        border-color: #0052bb; }

      .b-success {
        border-color: #81C926; }

      .b-info {
        border-color: #3b4752; }

      .b-danger {
        border-color: #f55753; }

      .b-warning {
        border-color: #f8d053; }

      /* Border Radius
      ------------------------------------
      */
      .b-rad-sm {
        border-radius: 3px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px; }

      .b-rad-md {
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px; }

      .b-rad-lg {
        border-radius: 7px;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px; }

      .no-border {
        border: none !important; }


        /* Line-heights
        ------------------------------------
        */
        .lh-normal {
          line-height: normal; }

        .lh-10 {
          line-height: 10px; }

        .lh-11 {
          line-height: 11px; }

        .lh-12 {
          line-height: 12px; }

        .lh-13 {
          line-height: 13px; }

        .lh-14 {
          line-height: 14px; }

        .lh-15 {
          line-height: 15px; }

        .lh-16 {
          line-height: 16px; }

.z-1000{
  z-index:1000
}

.z-10000{
  z-index:10000
}