.icon-button{
    width: 35px;
    height: 35px;
    background-color: white;
    border: none;
    outline: none;
    border-radius: 20px;
}

.icon-button:hover{
    background-color: rgb(183, 237, 255);
}

.icon-button:active{
    border: none;
    outline: none;
}

.icon-button:focus{
    border: none;
    outline: none;
}