.control-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 38px;
    border-radius: 49px;
    background-color: #e8e8e8;
    padding-left: 3.5px;
    padding-right: 3.5px;
}

.option-container{
    box-sizing: border-box;
    display: flex;
    border: none;
    font-size: small;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    text-align: center;
    background-color: #e8e8e8;
    color: #0076CF;
    border-radius: 50px;
    width: 130px;
    height: 32px;
    cursor: pointer;
}

.option-container.seg-selected{
    background-color: #ffffff;
    border: none;
    color: #0061A0;
}

.option-container:active{
    font-size: 12px;
    border: none;
}

.option-container:hover{
    background-color: white;
    border: none;
}

.option-container:focus{
    border: none;
    outline: none;
}

.option-container-count-md{
    font-size: 12px;
    pointer-events: none;
    display: inline-block;
    margin-left: 6px;
    top: -13px;
    right: -3px;
    padding: 1px 7px 1px 7px;
    background-color: rgb(209, 240, 252);
    border-radius: 50px;
}

/* SMALL */
.control-container-sm{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 32px;
    width: fit-content;
    border-radius: 49px;
    background-color: #e8e8e8;
    padding-left: 3.5px;
    padding-right: 3.5px;
}

.option-container-sm{
    box-sizing: border-box;
    position: relative;
    display: flex;
    border: none;
    font-size: 12.5px;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    text-align: center;
    background-color: #e8e8e8;
    color: #0076CF;
    border-radius: 50px;
    /* min-width: 50px; */
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    height: 26px;
    cursor: pointer;
}

.option-container-sm.seg-selected{
    background-color: #ffffff;
    border: none;
    color: #0061A0;
}

.option-container-sm:active{
    font-size: 12.4px;
    border: none;
}

.option-container-sm:hover{
    background-color: white;
    border: none;
}

.option-container-sm:focus{
    border: none;
    outline: none;
}

.option-container-count-sm{
    font-size: 11px;
    pointer-events: none;
    display: inline-block;
    margin-left: 4px;
    top: -13px;
    right: -3px;
    padding: 1px 5px 1px 5px;
    background-color: rgb(209, 240, 252);
    border-radius: 50px;
    /* z-index: 1; */
}

/* LARGE */
.control-container-lg{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 42px;
    border-radius: 49px;
    background-color: #e8e8e8;
    padding-left: 3.7px;
    padding-right: 3.7px;
}

.option-container-lg{
    box-sizing: border-box;
    display: flex;
    border: none;
    height: 34px;
    font-size: 14.5px;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    text-align: center;
    background-color: #e8e8e8;
    color: #0076CF;
    border-radius: 50px;
    min-width: 150px;
    padding: 1rem;
    cursor: pointer;
}

.option-container-lg.seg-selected{
    background-color: #ffffff;
    border: none;
    color: #0061A0;
}

.option-container-lg:active{
    font-size: 13.5px;
    border: none;
}

.option-container-lg:hover{
    background-color: white;
    border: none;
}

.option-container-lg:focus{
    border: none;
    outline: none;
}



@keyframes slide {
    from {background-color: #e8e8e8 ;}
    to {background-color: white;}
  }