.merge-tickets-container {
  margin-top: 1.2rem;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
  border-left: 6px solid #0076cf;
  border-radius: 8px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.16);
  padding-top: 1rem;
  margin-right: 1rem;
  max-width: 100% !important;
  background-color: white;
  overflow-y: scroll;
}

.merge-tickets-notes-container {
  max-height: 400px;
  margin-bottom: 1rem;
}