.confirm-popover{
    min-width: 200px;
    width: auto;
    min-height: 100px;
    height: auto;
    display: block;
    padding: 1rem;
}

.confirm-popover-close-btn{
    position: absolute;
    top: 3px;
    right: 10px;
}

.confirm-popover-message{
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: center;
    margin-right: 1rem;
    font-weight: bold;
    margin-bottom: 0.7rem;
}

.confirm-button{
    display: flex;
    justify-content: center;
}